import React, { Component, Fragment } from "react";
import styles from "../../styles/detail-dropdown.module.scss";
import AccordionGroup from "./accordionGroup.js";
import { Row } from "react-bootstrap";
import MainFunc from "../../models/MainFunc.js";

class DetailDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOption: null,
      activeTab: 0,
    };
  }

  handleTabClick = (tabIndex, title) => {
    this.setState({ activeTab: tabIndex, isOpen: !this.state.isOpen });
    MainFunc.sendDataToGTMKbank({
      event: "track_event",
      event_category: "tab_content",
      event_action: "click",
      event_label: "q_group_" + title.replace(/ /g, "_"),
    });
  };

  render() {
    const { isOpen, activeTab } = this.state;

    return (
      <Fragment>
        <div className="detail-dropdown">
          <div className={styles.tabsFaqInner}>
            <div
              className={
                styles.overflowScrollX /*overflow-scroll-x m mouse-move-scoll"*/
              }
            >
              <ul className={styles.tabList}>
                {this.props.data?.faqDetails.map((item, index) => (
                  <li
                    className={activeTab === index && styles.active}
                    title={item.title}
                    data-tab-index={index}
                    onClick={() => this.handleTabClick(index, item.title)}
                  >
                    {item.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <Row className={styles.lineRow} />

        {this.props.data?.faqDetails.map((item, index) => {
          return (
            <div
              className={`${styles.faqDetails}${
                index === activeTab ? styles.activeTab : ""
              }`}
            >
              <AccordionGroup dataFaq={item} />
            </div>
          );
        })}
      </Fragment>
    );
  }
}

export default DetailDropdown;
