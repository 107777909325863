import React, { Fragment } from "react";
import styles from "../../styles/pre-select-only-one.module.scss";
import MainFunc from "../../models/MainFunc";
class PreSelectOnlyOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: this.props.value
        ? this.props.value
        : this.props.defaultValue,
      defaultSelect: "20000",
      dataSelect: [
        {
          value: "20000",
        },
        {
          value: "30000",
        },
        {
          value: "40000",
        },
        {
          value: "50000",
        },
      ],
    };
  }

  handleChange = (value) => {
    this.setState({ selectedValue: value });
    this.props.onChange(value);
  };
  render() {
    return (
      <div
        className={`${styles.preSelectOnlyOne} ${
          this.props.selectPlan ? styles.selectPlan : ""
        }`}
      >
        <label className={styles.label}>
          {this.props.paymentMode?<h3>{this.props.label}</h3> :this.props.label}{" "}
          <div className={styles.onlyOne}>
            {this.props.questionTitle ||
              this.props.questionTitle === "" ||
              MainFunc.getLabelDtos("L0810")}
          </div>
        </label>
        <div className={styles.dataSelect}>
          {this.props.select?.map((data, index) => {
            let planPremiumMode = data.planPremiumMode?.find(
              (item) => item.paymentMode === this.props.planPaymentMode
            );
            return (
              <div
                className={`${styles.select} ${
                  this.props.value === data?.paymentCode ||
                  this.props.value === data?.planId ||
                  this.props.value === data?.valueCode
                    ? styles.active
                    : ""
                    ? styles.active
                    : ""
                    ? styles.active
                    : ""
                }
                `}
                onClick={() =>
                  this.handleChange(
                    data?.paymentCode || data?.planId || data?.valueCode
                  )
                }
              >
                {data?.paymentCode === "TWELVE_MONTHS" && (
                  <div className={`${styles.savePrice} ${styles.active}`}>
                    ประหยัดกว่า {this.props?.premiumSave} %
                  </div>
                )}
                <div>
                  <p className={styles.title}>
                    {data.paymentName ? data.paymentName : ""}
                    {planPremiumMode?.premium
                      ? MainFunc.numberWithCommas(planPremiumMode?.premium) +
                        " " +
                        MainFunc.getLabelDtos("L0104")
                      : ""}
                    {data.valueText ? data.valueText : ""}
                    {planPremiumMode?.premium
                      ? ` / ${planPremiumMode?.paymentUnitTitle}`
                      : ""}
                  </p>
                  {this.props.premium && (
                    <span className={styles.subTitle}>
                      {this.props.premium.find(
                        (item) => item.paymentCode === data.paymentCode
                      )?.premium
                        ? MainFunc.numberWithCommas(
                            this.props.premium.find(
                              (item) => item.paymentCode === data.paymentCode
                            )?.premium
                          ) +
                          " " +
                          MainFunc.getLabelDtos("L0104") +
                          "/งวด"
                        : ""}
                    </span>
                  )}
                </div>
                {false && (
                  <span className={styles.titleValue}>{data.value} บาท</span>
                )}
                {data?.planHighlights?.map((item, index) =>
                  item?.titles?.map((title, index) => {
                    return (
                      <Fragment>
                        <div className={styles.sub}>
                          <span className={styles.subTitle}>
                            {title?.title}
                          </span>
                          <span className={styles.subValue}>
                            {title?.titleValue}
                          </span>
                        </div>
                        {title?.subTitles?.map((subTitle, index) => {
                          return (
                            <div className={styles.sub}>
                              <span className={styles.subTitle}>
                                {subTitle?.subTitle}
                              </span>
                              <span className={styles.subValue}>
                                {subTitle?.subTitleValue}
                              </span>
                            </div>
                          );
                        })}
                      </Fragment>
                    );
                  })
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default PreSelectOnlyOne;
