import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import styles from "../../styles/planselect.module.scss";
// import ImgArrowDown from "../../images/arrow_down_icon.svg";
import MainFunc from "../../models/MainFunc";
import MainData from "../../models/MainData";
import { ReactComponent as ImgArrowDown } from "../../images/arrow_down_icon.svg";
class MyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initialize your state here
      IsBodySummaryNormalPlanDetail: false,
      IsConditionsCoverageDetail: false,
      dataShow: this.props.data?.planBenefit
        ? this.props.data?.planBenefit
        : null,
      selectedPlan: {},
      riderInfo: null,
    };
  }

  showNormalPlanDetail() {
    this.setState({
      IsBodySummaryNormalPlanDetail: !this.state.IsBodySummaryNormalPlanDetail,
    });
    let data = this.props.data;
    if (this.state.IsBodySummaryNormalPlanDetail === true) {
      this.setState({ dataShow: data.planBenefit, riderInfo: null });
    } else {
      MainFunc.sendDataToGTMKbank({
        event: "track_event",
        event_category: "toggle_content",
        event_action: "click_open",
        event_label: "แสดงรายละเอียดเพิ่มเติม",
      });
      this.setState({ dataShow: data.planDetails, riderInfo: data?.riderInfo });
    }
  }

  render() {
    return (
      // JSX code for rendering your component
      <div className="container">
        <div className={styles.scHeading}>
          <h1 className={styles.title}>
            {MainFunc.getLabelDtos("L0809") /*สรุปข้อมูลแผนประกัน*/}
          </h1>
        </div>

        <div className={styles.summaryImgPlanMobile}>
          {this.props.data?.summaryIcon && (
            <img src={this.props.data?.summaryIcon} alt="shield" />
          )}
        </div>

        <div className={styles.summaryMain}>
          <div className={styles.headerSummary}>
            <div className={styles.summaryImgPlanDasktop}>
              {this.props.data?.summaryIcon && (
                <img src={this.props.data?.summaryIcon} alt="shield" />
              )}
            </div>
            <div className={styles.summaryContent}>
              <div className={styles.header}>
                <div className={styles.product}>
                  <span className={styles.title}>
                    {MainFunc.getLabelDtos("L0765") /*แผนประกันที่คุณเลือก*/}
                  </span>
                  <span className={styles.productName}>
                    {this.props.productName}
                    {" "}
                    {this.props.data?.planPremium?.planName}
                  </span>
                </div>
                <div className={styles.premium}>
                  <span className={styles.premiumType}>
                    {MainFunc.getLabelDtos("L0711") /*เบี้ยประกันภัย*/}
                    {MainData.userSelectedData.selectedPlan?.paymentName}
                  </span>
                  <span className={styles.premiumValue}>
                    {" "}
                    {MainFunc.numberWithCommas(
                      MainData.userSelectedData.selectedPlan?.premium
                    ) +
                      " " +
                      MainFunc.getLabelDtos("L0104")}
                  </span>
                </div>
              </div>
              <div className={styles.body}>
                {/* end แสดงรายละเอียดเพิ่มเติม */}
                {/* start แสดงรายละเอียดเพิ่มเติม riderPlan*/}
                {this.props.data?.riderInfo && (
                  <Fragment>
                    {this.props.data?.riderInfo?.map((riderPlan, index) => (
                      <Fragment>
                        <div className={styles.riderPlanSummary}>
                          <div className={styles.line}></div>
                          <Row>
                            <Col
                              md={riderPlan.riderSpecials ? 6 : 12}
                              className={styles.riderPlanData}
                            >
                              <Row>
                                <Col
                                  md={6}
                                  xs={6}
                                  className={styles.riderPlanDataTitle}
                                >
                                  {
                                    MainFunc.getLabelDtos(
                                      "L0722"
                                    ) /*เบี้ยกรมธรรม์หลัก*/
                                  }
                                </Col>
                                <Col
                                  md={6}
                                  xs={6}
                                  className={styles.riderPlanDataDetail}
                                >
                                  {" "}
                                  {MainFunc.numberWithCommas(
                                    this.props.notRiderPlan?.premium
                                  ) +
                                    " " +
                                    MainFunc.getLabelDtos("L0104")}{" "}
                                  / {this.props.notRiderPlan?.paymentUnitTitle}
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  md={6}
                                  xs={6}
                                  className={styles.riderPlanDataTitle}
                                >
                                  {index === 0
                                    ? riderPlan.groupDescription
                                    : ""}
                                </Col>
                                {(riderPlan.riderBenefits?.length === 0 ||
                                  riderPlan.riderBenefits == null) && (
                                  <Col
                                    md={6}
                                    xs={6}
                                    className={styles.riderPlanDataDetail}
                                  >
                                    {MainFunc.getLabelDtos("L0204") /*ไม่มี*/}
                                  </Col>
                                )}
                              </Row>
                              {riderPlan.riderBenefits?.map(
                                (riderBenefit, index) => (
                                  <div className={styles.riderPlanList}>
                                    <li className={styles.riderPlanDataTitle}>
                                      {riderBenefit.riderTitle}
                                    </li>
                                    <div className={styles.riderPlanDataDetail}>
                                      {MainFunc.numberWithCommas(
                                        riderBenefit.riderPremium
                                      ) +
                                        " " +
                                        MainFunc.getLabelDtos("L0104")}{" "}
                                      /{" "}
                                      {
                                        this.props.notRiderPlan
                                          ?.paymentUnitTitle
                                      }
                                    </div>
                                  </div>
                                )
                              )}
                            </Col>
                            {riderPlan.riderSpecials?.map((riderSpecial) => (
                              <Col
                                md={6}
                                className={styles.riderPlanDataAdditional}
                              >
                                <span>
                                  {
                                    MainFunc.getLabelDtos(
                                      "L0767"
                                    ) /*ค่าบริการเสริม*/
                                  }
                                </span>
                                <li>
                                  <span className={styles.riderPlanDataTitle}>
                                    {riderSpecial.riderSpecialName}
                                  </span>
                                  <span className={styles.free}>
                                    {riderSpecial.riderSpecialValue}
                                  </span>
                                </li>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </Fragment>
                    ))}
                  </Fragment>
                )}
                {/* end แสดงรายละเอียดเพิ่มเติม riderPlan*/}
              </div>
            </div>
          </div>
          <div className={styles.bodySummary}>
            {/* start แสดงรายละเอียดเพิ่มเติม */}
            <Fragment>
              <div
                className={`${styles.bodyDetail}  ${
                  this.state.IsBodySummaryNormalPlanDetail ? styles.active : ""
                }`}
              >
                {this.state.dataShow?.map((data, index) => (
                  <div className={styles.normalPlan}>
                    <div className={styles.line}></div>
                    <div
                      className={`${styles.group} ${
                        (data.groupTitle || data.groupValue)
                          ? styles.groupHaveData
                          : ""
                      }`}
                    >
                      <div
                        className={styles.groupTitle}
                        dangerouslySetInnerHTML={{
                          __html: data.groupTitle,
                        }}
                      />
                      <div
                        className={styles.groupValue}
                        dangerouslySetInnerHTML={{
                          __html: data.groupValue,
                        }}
                      />
                    </div>
                    <Row>
                      {data.titles?.map((title, index) => (
                        <Col md={4} className={styles.normalPlanData}>
                          <div className={styles.normalPlanTitle}>
                            <span className={styles.title}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: title.title,
                                }}
                              ></div>
                            </span>
                            <div
                              className={styles.value}
                              dangerouslySetInnerHTML={{
                                __html: title.titleValue,
                              }}
                            ></div>
                          </div>
                          {title.subTitles?.map((subItem, subIndex) => (
                            <div className={styles.sub}>
                              <div className={styles.subtitle}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: subItem.subTitle,
                                  }}
                                ></div>
                              </div>
                              <div
                                className={styles.subValue}
                                dangerouslySetInnerHTML={{
                                  __html: subItem.subTitleValue,
                                }}
                              ></div>
                            </div>
                          ))}
                        </Col>
                      ))}
                    </Row>
                  </div>
                ))}

                {/* บริการเสริม */}
                {this.state.riderInfo?.map((riderPlan, index) => (
                  <div className={styles.additionalServices}>
                    <div className={styles.line}></div>
                    <div className={styles.group}>
                      <span className={styles.groupTitle}>
                        {riderPlan.groupDescription}
                      </span>
                    </div>
                    <Row>
                      {riderPlan.riderSpecials?.map((riderSpecial) => (
                        <Col md={4} className={styles.additionalServicesData}>
                          <div className={styles.additionalServicesTitle}>
                            <span className={styles.title}>
                              {riderSpecial.riderSpecialName}
                            </span>
                            <span className={styles.value}>
                              {/* {riderSpecial.riderSpecialDescription} */}
                            </span>
                          </div>
                          <div className={styles.sub}>
                            <span className={styles.subtitle}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: riderSpecial.riderSpecialTitle,
                                }}
                              ></div>
                            </span>
                            <span className={styles.subValue}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: riderSpecial.riderSpecialValue,
                                }}
                              ></div>
                            </span>
                          </div>
                        </Col>
                      ))}
                      {riderPlan.riderBenefits?.map((riderBenefits) => (
                        <Col md={4} className={styles.additionalServicesData}>
                          <div className={styles.additionalServicesTitle}>
                            <span className={styles.title}>
                              {riderBenefits.riderName}
                            </span>
                            <span className={styles.value}>
                              {riderBenefits.titleValue}
                            </span>
                          </div>
                          <div className={styles.sub}>
                            <span className={styles.subtitle}>
                              {riderBenefits.riderTitle}{" "}
                              {MainFunc.numberWithCommas(
                                riderBenefits?.riderPremium
                              ) +
                                " " +
                                MainFunc.getLabelDtos("L0104")}
                              /{this.props.notRiderPlan?.paymentUnitTitle}
                            </span>
                            <span className={styles.subValue}></span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ))}
              </div>
              {(JSON.stringify(this.props.data?.planBenefit) !==
                JSON.stringify(this.props.data?.planDetails) ||
                this.props.data?.riderInfo) && (
                <div className={styles.btnShowDetail}>
                  <a
                    onClick={() => this.showNormalPlanDetail()}
                    className={styles.showDetail}
                    title="แสดงรายละเอียดเพิ่มเติม"
                  >
                    {
                      !this.state.IsBodySummaryNormalPlanDetail
                        ? MainFunc.getLabelDtos(
                            "L0819"
                          ) /*แสดงรายละเอียดเพิ่มเติม*/
                        : MainFunc.getLabelDtos(
                            "L0820"
                          ) /*ซ่อนรายละเอียดเพิ่มเติม*/
                    }
                    <ImgArrowDown
                      className={`${styles.imgArrowDown}  ${
                        this.state.IsBodySummaryNormalPlanDetail
                          ? styles.active
                          : ""
                      }`}
                      stroke={"var(--mainColor)"}
                    />
                  </a>
                </div>
              )}
            </Fragment>
            {/* end แสดงรายละเอียดเพิ่มเติม */}
            {this.props.data?.coverageBrief && (
              <Fragment>
                {/* start เงื่อนไขความคุ้มครอง */}
                <div className={styles.line}></div>
                <div
                  className={`${styles.conditionsCoverage}  ${
                    this.state.IsConditionsCoverageDetail ? styles.active : ""
                  }`}
                >
                  <div className={styles.normalPlan}>
                    <div className={styles.coverageGroupTitle}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            this.props.data.coverageBrief?.coverageBriefTitle,
                        }}
                      ></div>
                    </div>
                    <div
                      className={styles.conditionsCoverageData}
                      dangerouslySetInnerHTML={{
                        __html:
                          this.props.data.coverageBrief
                            ?.coverageBriefDescription,
                      }}
                    ></div>
                  </div>
                </div>
                <div className={styles.btnShowDetail}>
                  <a
                    onClick={() =>
                      this.setState({
                        IsConditionsCoverageDetail:
                          !this.state.IsConditionsCoverageDetail,
                      })
                    }
                    className={styles.showDetail}
                    title="แสดงเงื่อนไขความคุ้มครอง"
                  >
                    {
                      !this.state.IsConditionsCoverageDetail
                        ? MainFunc.getLabelDtos(
                            "L0817"
                          ) /*แสดงเงื่อนไขความคุ้มครอง*/
                        : MainFunc.getLabelDtos(
                            "L0818"
                          ) /*ซ่อนเงื่อนไขความคุ้มครอง*/
                    }
                    <ImgArrowDown
                      className={`${styles.imgArrowDown}  ${
                        this.state.IsConditionsCoverageDetail
                          ? styles.active
                          : ""
                      }`}
                      stroke={"var(--mainColor)"}
                    />
                  </a>
                </div>
                {/* end เงื่อนไขความคุ้มครอง */}
              </Fragment>
            )}
          </div>
        </div>

        <div className={styles.btnPremium}>
          <a
            onClick={() => {
              this.props.nextPage();
              MainFunc.sendDataToGTMKbank({
                event: "track_event",
                event_category: "link_click",
                event_action: "click",
                event_label: `ซื้อเลย_${this.props.data?.planPremium?.planName.replace(
                  / /g,
                  "_"
                )}_${MainData.userSelectedData.selectedPlan.planId}`,
                position: "inform_btn",
                plan_code: `${MainData.userSelectedData.selectedPlan.planId}`,
              });
            }}
            className={styles.btnInsure}
            title="ซื้อเลย"
          >
            {MainFunc.getLabelDtos("L0768") /*ซื้อเลย*/}
          </a>
          <a
            onClick={() => this.props.backToSelectPlan()}
            className={styles.btnBackCal}
            title="คำนวณเบี้ยใหม่"
          >
            {MainFunc.getLabelDtos("L0769") /*คำนวณเบี้ยใหม่*/}
          </a>
          <a
            onClick={() => this.props.activeCompornent()}
            className={styles.btnInsureSecond}
            title="ปรึกษาผู้เชี่ยวชาญ"
          >
            {MainFunc.getLabelDtos("L0764") /*ปรึกษาผู้เชี่ยวชาญ*/}
          </a>
        </div>
      </div>
    );
  }
}

export default MyComponent;
