import React, { Component } from "react";
import ReactPlayer from 'react-player'
import MainFunc from "../../models/MainFunc";
import styles from "../../styles/video.module.scss";
class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoUrl: "",
      isPlaying: true,
    };
  }

  tagGa = () => {
    console.log(document.querySelectorAll(".displayYoutubePC iframe")[0].title)
    MainFunc.sendDataToGTMKbank({
      'event': 'track_event',
      'event_category': 'youtube_video',
      'event_action': 'play',
      'event_label': 'video_'+ document.querySelectorAll(".displayYoutubePC iframe")[0].title.replace(/ /g, "_") 
      })
  } 


  render() {
    return (
      <div className="container">
        <div className={styles.video}>
          {this.props.data?.iFrameContentTitle && (
            <div className={styles.scHeading}>
              <h2 className={styles.h1}>
                <strong>{this.props.data?.iFrameContentTitle}</strong>
              </h2>
            </div>
          )}
          <div className={styles.videoPlayer}>
            <div className={styles.videoZone}>
              <div
                className="displayYoutubePC"
              >
                <ReactPlayer onStart={(e)=>this.tagGa()} url={this.props.data?.iFrameContentPC} controls={true} width='100%' height='100%' />
                

              </div>
            </div>
          </div>
          {this.props.data?.iFrameContentRemark && (
            <div className={styles.remarkBox}>
              <div className={styles.remark}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.iFrameContentRemark,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Video;
