import React, { Component } from "react";
import ActiveBenefit from "./activeBenefit.js";
import styles from "../../styles/benefit.module.scss";
import MainFunc from "../../models/MainFunc";
class Benefit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      benefitActive: 0,
      direction: "left",
      widthCard: 300,
      translateX: 8,
      touchStatus: false,
      touchClientX: 0,
    };
    this.myRefMobile = React.createRef();
    this.myRef = React.createRef();
  }

  componentDidMount() {}
  touchStart = (event) => {
    this.setState({
      touchStatus: true,
      touchClientX: event.touches[0]?.clientX,
    });
  };
  touchMove = (event) => {
    setTimeout(() => {
      if (this.state.touchStatus) {
        let index = this.state.benefitActive;
        if (this.state.touchClientX < event.touches[0]?.clientX && index > 0) {
          index = index - 1;
          this.setState({
            touchStatus: false,
            touchClientX: 0,
            benefitActive: index,
            translateX:
              index == 0
                ? 8
                : -index *
                  (this.myRefMobile.current.getBoundingClientRect().width +
                    (index === this.props.data.length - 1
                      ? this.myRefMobile.current.getBoundingClientRect().width >
                        281
                        ? -32
                        : -16
                      : 16)),
          });
        } else if (
          this.state.touchClientX >= event.touches[0]?.clientX &&
          index < this.props.data.length - 1
        ) {
          index = index + 1;
          this.setState({
            touchStatus: false,
            touchClientX: 0,
            benefitActive: index,
            translateX:
              -index *
              (this.myRefMobile.current.getBoundingClientRect().width +
                (index === this.props.data.length - 1
                  ? this.myRefMobile.current.getBoundingClientRect().width > 281
                    ? -32
                    : -16
                  : 16)),
          });
        }
      }
    }, 100);
  };

  render() {
    const { data, benefitActive, direction, widthCard, translateX } =
      this.state;
    const clickDot = (index) => {
      this.setState({ direction: index > benefitActive ? "right" : "left" });
      this.setState({ benefitActive: index });
      this.setState({
        translateX:
          index == 0
            ? 8
            : -index *
              (this.myRefMobile.current.getBoundingClientRect().width +
                (index === this.props.data.length - 1
                  ? this.myRefMobile.current.getBoundingClientRect().width > 281
                    ? -32
                    : -16
                  : 16)),
      });
    };
    return (
      <div
        className={`container ${
          this.props?.data?.length == 1 ? "" : styles.container
        }`}
      >
        <div className={styles.benefit}>
          <div className={styles.scHeading}>
            <h2 className={styles.h1} style={{"fontWeight":"bold"}}>
                {" "}
                {MainFunc.getLabelDtos("L0778")?MainFunc.getLabelDtos("L0778"):"สิทธิพิเศษสำหรับคุณ"}
            </h2>
          </div>
          <div>
            <div className={styles.activeCardMobile}>
              <div
                onTouchStart={this.touchStart}
                onTouchMove={this.touchMove}
                style={{
                  transform: `translateX(${
                    this.props?.data?.length == 1 ? 0 : this.state.translateX
                  }px)`,
                  transition: `transform 0.5s`,
                }}
              >
                <div
                  ref={this.myRefMobile}
                  className={`${styles.activeBenefit} ${
                    this.props?.data?.length == 1 ? styles.oneItem : ""
                  }`}
                >
                  {this.props.channel?.css && this.props.data?.map((item, index) => {
                    return (
                      <ActiveBenefit
                        benefitActive={benefitActive === index}
                        data={item}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles.activeCard}>
              <div>
                <div ref={this.myRef} className={styles.activeBenefit}>
                  {this.props.data
                    ?.filter((item, index) => benefitActive === index)
                    .map((item, index) => (
                      <ActiveBenefit benefitActive={true} data={item} channel={this.props.channel}/>
                    ))}
                </div>
              </div>
            </div>
            <div className={styles.benefitList}>
              {this.props.data?.map((item, index) => {
                return (
                  <img
                    className={`${styles.benefitImage} ${
                      benefitActive === index ? styles.active : ""
                    }`}
                    src={item.promotionImg}
                    alt="Card Image"
                    onClick={() => this.setState({ benefitActive: index })}
                  />
                );
              })}
            </div>
            <div className={styles.dotSlideShow}>
              {this.props.data?.map((item, index) => {
                return (
                  <span
                    className={`${styles.dot} ${
                      benefitActive === index ? styles.active : ""
                    }`}
                    onClick={() => clickDot(index)}
                  ></span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Benefit;
