import React from "react";
import styles from "../../styles/cardRelatedProducts.module.scss";
import MainFunc from "../../models/MainFunc";
class CardRelatedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        className={styles.cardRelatedProducts}
        onClick={() =>{
          MainFunc.sendDataToGTMKbank({
            event: "track_event",
            event_category: "link_click",
            event_action: "click",
            event_label:
              "product_relate_" +
              this.props.data?.productRelateTitle.replace(/ /g, "_"),
          });
          if(this.props.data?.productRelateLink){
            window.open(this.props.data?.productRelateLink, "_blank")
          }
        }}
      >
        <div>
          <img
            className={styles.productRelateImg}
            src={this.props.data?.productRelateImg}
            alt="Product"
          />
          <div className={styles.cardContent}>
            <div className={styles.cardTitle}>
              {this.props.data?.productRelateTitle}
            </div>
            <p className={styles.cardDescription}>
              {this.props.data?.productRelateDescription}
            </p>
            <a
              className={styles.cardLink}
              // href={this.props.data?.productRelateLink}
              title={this.props.data?.productRelateTitle}
              // target="_blank"
            >
              {MainFunc.getLabelDtos("L0455") /*อ่านเพิ่มเติม*/}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default CardRelatedProducts;
