import axios from "axios";
import MainData from "./MainData";
import MainDataGTM from "./MainDataGTM";
import moment from "moment";
class MainFunc {
  static getServiceURL() {
    return MainData.serviceURL;
  }

  static getVersion() {
    return MainData.version;
  }

  static ourLog(logA, logB) {
    // ใช้เวลา log ข้อมูล
    if (MainData.isDev) {
      console.log(logA, !logB ? "" : logB);
    }
  }

  static getLabelDtos(code) {
    let newLabelDtos = MainData.initialData.labelDtos.find(
      (x) => x.labelCode === code
    );
    if (!newLabelDtos) {
      return "";
    } else {
      return newLabelDtos.labelTitle;
    }
  }

  static getConfigDtos(code) {
    let newConfigDtos = MainData.initialData.configDtos.find(
      (x) => x.configCode === code
    );
    if (!newConfigDtos) {
      return "";
    } else {
      return newConfigDtos.config;
    }
  }

  static getUserBirthDate(adORbe) {
    // ใช้สำหรับ  get วันเกิด user หากใส่ 'be' จะได้ ปีั พศ
    let year = MainData.screenSM001.birthdate.split("/")[2];
    if (adORbe !== "be") {
      year = year - 543;
    }
    let month = MainData.screenSM001.birthdate.split("/")[1];
    if (month < 10 && month.length === 1) {
      month = "0" + month;
    }
    let day = MainData.screenSM001.birthdate.split("/")[0];
    if (day < 10 && day.length === 1) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  }

  static numberWithCommas(num) {
    num = parseFloat(num);
    return num
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static phoneFormat(phone) {
    let newPhone = phone;
    phone = phone.replace(/[^\d]/g, "");
    switch (phone.length) {
      case 6:
        newPhone = phone.replace(/(\d{3})(\d{3})/, "$1-$2");
        break;
      case 7:
        newPhone = phone.replace(/(\d{2})(\d{5})/, "$1-$2");
        break;
      case 8:
        newPhone = phone.replace(/(\d{2})(\d{3})(\d{3})/, "$1-$2-$3");
        break;
      case 9:
        newPhone = phone.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
        break;
      case 10:
        newPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        break;
      default:
        break;
    }
    return newPhone;
  }

  static idcardFormat(idCard) {
    let newIDCard = idCard;
    idCard = idCard.replace(/[^\d]/g, "");
    switch (idCard.length) {
      case 2:
        newIDCard = idCard.replace(/(\d{1})(\d{1})/, "$1-$2");
        break;
      case 3:
        newIDCard = idCard.replace(/(\d{1})(\d{2})/, "$1-$2");
        break;
      case 4:
        newIDCard = idCard.replace(/(\d{1})(\d{3})/, "$1-$2");
        break;
      case 5:
        newIDCard = idCard.replace(/(\d{1})(\d{4})/, "$1-$2");
        break;
      case 6:
        newIDCard = idCard.replace(/(\d{1})(\d{4})(\d{1})/, "$1-$2-$3");
        break;
      case 7:
        newIDCard = idCard.replace(/(\d{1})(\d{4})(\d{2})/, "$1-$2-$3");
        break;
      case 8:
        newIDCard = idCard.replace(/(\d{1})(\d{4})(\d{3})/, "$1-$2-$3");
        break;
      case 9:
        newIDCard = idCard.replace(/(\d{1})(\d{4})(\d{4})/, "$1-$2-$3");
        break;
      case 10:
        newIDCard = idCard.replace(/(\d{1})(\d{4})(\d{5})/, "$1-$2-$3");
        break;
      case 11:
        newIDCard = idCard.replace(
          /(\d{1})(\d{4})(\d{5})(\d{1})/,
          "$1-$2-$3-$4"
        );
        break;
      case 12:
        newIDCard = idCard.replace(
          /(\d{1})(\d{4})(\d{5})(\d{2})/,
          "$1-$2-$3-$4"
        );
        break;
      case 13:
        newIDCard = idCard.replace(
          /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/,
          "$1-$2-$3-$4-$5"
        );
        break;
      default:
        break;
    }
    return newIDCard;
  }

  static genTextToDate(text) {
    let newTextDate = text;
    text = text.replace(/[^\d]/g, "");
    switch (text.length) {
      case 1:
        newTextDate = text.replace(/(\d{1})/, "$1");
        break;
      case 2:
        newTextDate = text.replace(/(\d{2})/, "$1/");
        break;
      case 3:
        newTextDate = text.replace(/(\d{2})(\d{1})/, "$1/$2");
        break;
      case 4:
        newTextDate = text.replace(/(\d{2})(\d{2})/, "$1/$2/");
        break;
      case 5:
        newTextDate = text.replace(/(\d{2})(\d{2})(\d{1})/, "$1/$2/$3");
        break;
      case 6:
        newTextDate = text.replace(/(\d{2})(\d{2})(\d{2})/, "$1/$2/$3");
        break;
      case 7:
        newTextDate = text.replace(/(\d{2})(\d{2})(\d{3})/, "$1/$2/$3");
        break;
      case 8:
        newTextDate = text.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
        break;
      default:
        break;
    }
    return newTextDate;
  }

  static setNewThaiMonth(monthArrIndex, isFull) {
    var months_th = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    var months_th_mini = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];
    if (!isFull) {
      return months_th_mini[monthArrIndex];
    } else {
      return months_th[monthArrIndex];
    }
  }

  static async sendLogToService(
    functionAction,
    action,
    statusAction,
    statusDetailAction,
    oldValue,
    newValue
  ) {
    let date = new Date();
    let mainPage = MainData.masPage.find((x) => x.name === functionAction);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      dateAction:
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate(),
      timeAction:
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds(),
      channelCode: MainData.initialData.channelCode,
      userAccount: "-",
      userRole: "-",
      ModuleAction: !mainPage ? null : mainPage.mainMenuName,
      action: action,
      functionAction: functionAction,
      objectAction: "-",
      statusAction: statusAction,
      statusDetailAction: statusDetailAction,
      oldValue: oldValue,
      newValue: newValue,
    };
    axios
      .post(MainData.serviceURL + "/Log/ActivityLog", data, {
        headers: headers,
      })
      .then((res) => {})
      .catch((error) => {});
  }

  //PDPA
  static async logPDPA(
    action,
    location1,
    location2,
    object,
    oldValue,
    newValue,
    status,
    statusDetail
  ) {
    let date = new Date();
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      Sid: "BAOS",
      Username: null,
      Role: null,
      Action: action,
      OldValue: oldValue,
      Object: object,
      Location: location1 + " | " + location2,
      Status: status,
      StatusDetail: statusDetail,
      NewValue: newValue,
    };
    axios
      .post(MainData.serviceURL + "/Log/PDPALogActive", data, {
        headers: headers,
      })
      .then((res) => {})
      .catch((error) => {});
  }

  static blurIdcard(idCard) {
    var blurID = "";
    var i = 0;
    for (let subObj of idCard) {
      switch (i) {
        case 0:
          blurID += "x-";
          break;
        case 1:
          blurID += "x";
          break;
        case 2:
          blurID += "x";
          break;
        case 3:
          blurID += "x";
          break;
        case 4:
          blurID += "x-";
          break;
        case 5:
          blurID += "x";
          break;
        case 6:
          blurID += "x";
          break;
        case 7:
          blurID += "x";
          break;
        case 8:
          blurID += "x";
          break;
        case 9:
          blurID += subObj + "-";
          break;
        case 10:
          blurID += subObj;
          break;
        case 11:
          blurID += subObj + "-";
          break;
        case 12:
          blurID += subObj;
          break;
        default:
          break;
      }
      i++;
    }
    return blurID;
  }

  static blurPhoneNum(phoneNum) {
    var blurPhone = "";
    var i = 0;
    for (let subObj of phoneNum) {
      switch (i) {
        case 0:
          blurPhone += subObj;
          break;
        case 1:
          blurPhone += subObj + " ";
          break;
        case 2:
          blurPhone += "x";
          break;
        case 3:
          blurPhone += "x";
          break;
        case 4:
          blurPhone += "x";
          break;
        case 5:
          blurPhone += "x ";
          break;
        case 6:
          blurPhone += subObj;
          break;
        case 7:
          blurPhone += subObj;
          break;
        case 8:
          blurPhone += subObj;
          break;
        case 9:
          blurPhone += subObj;
          break;
        case 10:
          blurPhone += subObj;
          break;
        default:
          break;
      }
      i++;
    }
    return blurPhone;
  }

  static blurName(name) {
    var blurName = "";
    var i = 0;
    if (name != null) {
      for (let subObj of name) {
        switch (i) {
          case 0:
            blurName += subObj;
            break;
          case 1:
            blurName += subObj;
            break;
          default:
            blurName += "x";
            break;
        }
        i++;
      }
    }
    return blurName;
  }

  static blurEmail(email) {
    if (!email) {
      email = "";
    }
    var blurEmail = "";
    var i = 0;
    email = email.split("@");
    var email1 = email[0];
    var email2 = email[1];
    for (let subObj of email1) {
      switch (i) {
        case 0:
          blurEmail += subObj;
          break;
        case 1:
          blurEmail += subObj;
          break;
        default:
          blurEmail += "x";
          break;
      }
      i++;
    }
    return blurEmail + "@" + email2;
  }

  static blurAll(text) {
    var blurAll = "";
    if (text != null) {
      for (let subObj of text) {
        blurAll += "x";
      }
    }
    return blurAll;
  }

  static sendDataToGTM(cat, action, label) {
    if (MainData.initialData.channelCode != "KBANK") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "kbankCustomEvent",
        category: cat,
        action: action,
        label: label,
      });
    }
  }
  static sendDataToGTMKbank(data) {
    if (MainData.initialData.channelCode == "KBANK") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
      console.log("dataLayer :",JSON.stringify(window.dataLayer[window.dataLayer.length-1]))
    }
  }

  static getSystemConfigData() {
    return new Promise(function (resolve, reject) {
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("bearerAuth")}`,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin,Accept,X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      };
      const data = {
        channelCode: sessionStorage.getItem("channelName"),
      };
      axios
        .post(MainData.serviceURL + "/Sales/SystemConfig", data, {
          headers: headers,
        })
        .then((response) => {
          if (response.status == 299) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  static SetMainDataFromStorage() {
    let MainDataStorage = JSON.parse(sessionStorage.getItem("MainData"));
    MainData.initialData.labelDtos = MainDataStorage.initialData.labelDtos;
    MainData.Screen1.birthdate = MainDataStorage.Screen1.birthdate;
    MainData.Screen1.gender = MainDataStorage.Screen1.gender;
    MainData.Screen3.answerList = MainDataStorage.Screen3.answerList;
    MainData.Screen4.addressBuilding = MainDataStorage.Screen4.addressBuilding;
    MainData.Screen4.addressNumber = MainDataStorage.Screen4.addressNumber;
    MainData.Screen4.career = MainDataStorage.Screen4.career;
    MainData.Screen4.careerDetail = MainDataStorage.Screen4.careerDetail;
    MainData.Screen4.district = MainDataStorage.Screen4.district;
    MainData.Screen4.email = MainDataStorage.Screen4.email;
    MainData.Screen4.fatca = MainDataStorage.Screen4.fatca;
    MainData.Screen4.firstName = MainDataStorage.Screen4.firstName;
    MainData.Screen4.formalAddress = MainDataStorage.Screen4.formalAddress;
    MainData.Screen4.formalAddressBuilding =
      MainDataStorage.Screen4.formalAddressBuilding;
    MainData.Screen4.formalAddressNumber =
      MainDataStorage.Screen4.formalAddressNumber;
    MainData.Screen4.formalDistrict = MainDataStorage.Screen4.formalDistrict;
    MainData.Screen4.formalMoo = MainDataStorage.Screen4.formalMoo;
    MainData.Screen4.formalProvince = MainDataStorage.Screen4.formalProvince;
    MainData.Screen4.formalSoi = MainDataStorage.Screen4.formalSoi;
    MainData.Screen4.formalStreet = MainDataStorage.Screen4.formalStreet;
    MainData.Screen4.formalSubDisrtict =
      MainDataStorage.Screen4.formalSubDisrtict;
    MainData.Screen4.formalZipCode = MainDataStorage.Screen4.formalZipCode;
    MainData.Screen4.heir = MainDataStorage.Screen4.heir;
    MainData.Screen4.idCard = MainDataStorage.Screen4.idCard;
    MainData.Screen4.idCardExpDate = MainDataStorage.Screen4.idCardExpDate;
    MainData.Screen4.idCardExpDateNone =
      MainDataStorage.Screen4.idCardExpDateNone;
    MainData.Screen4.idCardExpMonth = MainDataStorage.Screen4.idCardExpMonth;
    MainData.Screen4.idCardExpYear = MainDataStorage.Screen4.idCardExpYear;
    MainData.Screen4.identified = MainDataStorage.Screen4.identified;
    MainData.Screen4.isEqualBenefit = MainDataStorage.Screen4.isEqualBenefit;
    MainData.Screen4.lastName = MainDataStorage.Screen4.lastName;
    MainData.Screen4.moo = MainDataStorage.Screen4.moo;
    MainData.Screen4.nameTitle = MainDataStorage.Screen4.nameTitle;
    MainData.Screen4.phoneNumber = MainDataStorage.Screen4.phoneNumber;
    MainData.Screen4.province = MainDataStorage.Screen4.province;
    MainData.Screen4.reciveAtNowAddress =
      MainDataStorage.Screen4.reciveAtNowAddress;
    MainData.Screen4.soi = MainDataStorage.Screen4.soi;
    MainData.Screen4.street = MainDataStorage.Screen4.street;
    MainData.Screen4.subDisrtict = MainDataStorage.Screen4.subDisrtict;
    MainData.Screen4.taxSupport = MainDataStorage.Screen4.taxSupport;
    MainData.Screen4.zipCode = MainDataStorage.Screen4.zipCode;
    MainData.channelLogo = MainDataStorage.channelLogo;
    MainData.checkAgeMax = MainDataStorage.checkAgeMax;
    MainData.checkAgeMaxUnit = MainDataStorage.checkAgeMaxUnit;
    MainData.checkAgeMin = MainDataStorage.checkAgeMin;
    MainData.checkAgeMinUnit = MainDataStorage.checkAgeMinUnit;
    MainData.checkGender = MainDataStorage.checkGender;
    MainData.firstPage = MainDataStorage.firstPage;
    MainData.headerText.mainText = MainDataStorage.headerText.mainText;
    MainData.headerText.subText = MainDataStorage.headerText.subText;
    MainData.headerText.thirdText = MainDataStorage.headerText.thirdText;
    MainData.lang = MainDataStorage.lang;
    MainData.productList = MainDataStorage.productList;
    MainData.productTitle = MainDataStorage.productTitle;
    MainData.screenSM001.birthdate = MainDataStorage.screenSM001.birthdate;
    MainData.screenSM001.gender = MainDataStorage.screenSM001.gender;
    MainData.screenSM002.cost = MainDataStorage.screenSM002.cost;
    MainData.screenSM002.paymentCode = MainDataStorage.screenSM002.paymentCode;
    MainData.screenSM002.paymentName = MainDataStorage.screenSM002.paymentName;
    MainData.screenSM002.planId = MainDataStorage.screenSM002.planId;
    MainData.screenSM002.planName = MainDataStorage.screenSM002.planName;
    MainData.screenSM002.costPayment = MainDataStorage.screenSM002.costPayment;
    MainData.screenSM002.productName = MainDataStorage.screenSM002.productName;
    MainData.screenSM003.userHealthAns =
      MainDataStorage.screenSM003.userHealthAns;
    MainData.screenSM004.fatcaAns = MainDataStorage.screenSM004.fatcaAns;
    MainData.screenSM004.fatcaAnsForservice =
      MainDataStorage.screenSM004.fatcaAnsForservice;
    MainData.screenSM005.cusId = MainDataStorage.screenSM005.cusId;
    MainData.screenSM005.idCard = MainDataStorage.screenSM005.idCard;
    MainData.screenSM006.otpCode = MainDataStorage.screenSM006.otpCode;
    MainData.screenSM006.phoneNumber = MainDataStorage.screenSM006.phoneNumber;
    MainData.screenSM006.refCode = MainDataStorage.screenSM006.refCode;
    MainData.screenSM007.otpCode = MainDataStorage.screenSM007.otpCode;
    MainData.screenSM008.email = MainDataStorage.screenSM008.email;
    MainData.screenSM008.firstName = MainDataStorage.screenSM008.firstName;
    MainData.screenSM008.idCardExp = MainDataStorage.screenSM008.idCardExp;
    MainData.screenSM008.idCardExpDateNone =
      MainDataStorage.screenSM008.idCardExpDateNone;
    MainData.screenSM008.job = MainDataStorage.screenSM008.job;
    MainData.screenSM008.jobDescription =
      MainDataStorage.screenSM008.jobDescription;
    MainData.screenSM008.lastName = MainDataStorage.screenSM008.lastName;
    MainData.screenSM008.titleName = MainDataStorage.screenSM008.titleName;
    MainData.screenSM008.firstNameEN = MainDataStorage.screenSM008.firstNameEN;
    MainData.screenSM008.lastNameEN = MainDataStorage.screenSM008.lastNameEN;
    MainData.screenSM008.titleNameEN = MainDataStorage.screenSM008.titleNameEN;
    MainData.screenSM009.address = MainDataStorage.screenSM009.address;
    MainData.screenSM009.district = MainDataStorage.screenSM009.district;
    MainData.screenSM009.formalAddress =
      MainDataStorage.screenSM009.formalAddress;
    MainData.screenSM009.formalDistrict =
      MainDataStorage.screenSM009.formalDistrict;
    MainData.screenSM009.formalMoo = MainDataStorage.screenSM009.formalMoo;
    MainData.screenSM009.formalProvince =
      MainDataStorage.screenSM009.formalProvince;
    MainData.screenSM009.formalSoi = MainDataStorage.screenSM009.formalSoi;
    MainData.screenSM009.formalStreet =
      MainDataStorage.screenSM009.formalStreet;
    MainData.screenSM009.formalSubDistrict =
      MainDataStorage.screenSM009.formalSubDistrict;
    MainData.screenSM009.formalVillage =
      MainDataStorage.screenSM009.formalVillage;
    MainData.screenSM009.formalZipCode =
      MainDataStorage.screenSM009.formalZipCode;
    MainData.screenSM009.insuranceTrack =
      MainDataStorage.screenSM009.insuranceTrack;
    MainData.screenSM009.isDocToNowAddress =
      MainDataStorage.screenSM009.isDocToNowAddress;
    MainData.screenSM009.isSameAddress =
      MainDataStorage.screenSM009.isSameAddress;
    MainData.screenSM009.moo = MainDataStorage.screenSM009.moo;
    MainData.screenSM009.province = MainDataStorage.screenSM009.province;
    MainData.screenSM009.soi = MainDataStorage.screenSM009.soi;
    MainData.screenSM009.street = MainDataStorage.screenSM009.street;
    MainData.screenSM009.subDistrict = MainDataStorage.screenSM009.subDistrict;
    MainData.screenSM009.village = MainDataStorage.screenSM009.village;
    MainData.screenSM009.zipCode = MainDataStorage.screenSM009.zipCode;
    MainData.screenSM010.heir = MainDataStorage.screenSM010.heir;
    MainData.screenSM010.isAgreeRD = MainDataStorage.screenSM010.isAgreeRD;
    MainData.screenSM010.isHeire = MainDataStorage.screenSM010.isHeire;
    MainData.screenSM012 = MainDataStorage.screenSM012;
    MainData.screenSM013.beneficiariesOfOrder =
      MainDataStorage.screenSM013.beneficiariesOfOrder;
    MainData.screenSM013.districtCode =
      MainDataStorage.screenSM013.districtCode;
    MainData.screenSM013.formalDistrictCode =
      MainDataStorage.screenSM013.formalDistrictCode;
    MainData.screenSM013.formalProvinceCode =
      MainDataStorage.screenSM013.formalProvinceCode;
    MainData.screenSM013.formalSubDistrictCode =
      MainDataStorage.screenSM013.formalSubDistrictCode;
    MainData.screenSM013.healthQuestions =
      MainDataStorage.screenSM013.healthQuestions;
    MainData.screenSM013.jobInformationsDescription =
      MainDataStorage.screenSM013.jobInformationsDescription;
    MainData.screenSM013.jobInformationsOccupation =
      MainDataStorage.screenSM013.jobInformationsOccupation;
    MainData.screenSM013.jobInformationsType =
      MainDataStorage.screenSM013.jobInformationsType;
    MainData.screenSM013.logHeire = MainDataStorage.screenSM013.logHeire;
    MainData.screenSM013.provinceCode =
      MainDataStorage.screenSM013.provinceCode;
    MainData.screenSM013.subDistrictCode =
      MainDataStorage.screenSM013.subDistrictCode;
    MainData.screenSM013.titleCode = MainDataStorage.screenSM013.titleCode;
    MainData.screenSM013.titleCodeEN = MainDataStorage.screenSM013.titleCodeEN;
    MainData.screenSM014.uploadedFilesFace_shape =
      MainDataStorage.screenSM014.uploadedFilesFace_shape;
    MainData.screenSM014.uploadedFilesId_card =
      MainDataStorage.screenSM014.uploadedFilesId_card;
    MainData.userSelectedData.selectedPlan =
      MainDataStorage.userSelectedData.selectedPlan;
    MainData.version = MainDataStorage.version;
    MainData.masterMessage = MainDataStorage.masterMessage;
    MainData.initialData.bearerAuth = MainDataStorage.initialData.bearerAuth;
    MainData.messageErr.errMessage = MainDataStorage.messageErr.errMessage;
    MainData.dataBroker.planInfo = MainDataStorage.dataBroker.planInfo;
    MainData.dataBroker.contactWidget = MainDataStorage.dataBroker.contactWidget;
    MainData.dataBroker.gender = MainDataStorage.dataBroker.gender;
    MainData.dataBroker.birthdate = MainDataStorage.dataBroker.birthdate;
    MainData.dataBroker.selectRider = MainDataStorage.dataBroker.selectRider;
    MainData.dataBroker.riderPlan = MainDataStorage.dataBroker.riderPlan;    
    MainData.userSelectedData.selectedPlan = MainDataStorage.userSelectedData.selectedPlan;    
    MainData.userSelectedData.notRiderPlan = MainDataStorage.userSelectedData.notRiderPlan;
    MainData.screenSM013.promotion_code = MainDataStorage.screenSM013.promotion_code;
    MainData.screenSM013.referral_code = MainDataStorage.screenSM013.referral_code;
    MainData.screenSM013.agent_code = MainDataStorage.screenSM013.agent_code;
  }
  static SetScriptGTM(channelCode) {
    // var scriptDefaultOld = document.createElement("script");
    // scriptDefaultOld.innerHTML = MainDataGTM.scriptDefaultOld;
    // document.getElementsByTagName("head")[0].appendChild(scriptDefaultOld);
    // var noscriptDefaultOld = document.createElement("noscript");
    // noscriptDefaultOld.innerHTML = MainDataGTM.noscriptDefaultOld;
    // document.getElementsByTagName("body")[0].appendChild(noscriptDefaultOld);
    if (channelCode == "LINEBK") {
      var scriptLineBK = document.createElement("script");
      scriptLineBK.innerHTML = MainDataGTM.scriptLineBK;
      document.getElementsByTagName("head")[0].appendChild(scriptLineBK);
      var noscriptLineBK = document.createElement("noscript");
      noscriptLineBK.innerHTML = MainDataGTM.noscriptLineBK;
      document.getElementsByTagName("body")[0].appendChild(noscriptLineBK);
    } else {
      var scriptDefault = document.createElement("script");
      scriptDefault.innerHTML = MainDataGTM.scriptDefault;
      document.getElementsByTagName("head")[0].appendChild(scriptDefault);
      var noscriptDefault = document.createElement("noscript");
      noscriptDefault.innerHTML = MainDataGTM.noscriptDefault;
      document.getElementsByTagName("body")[0].appendChild(noscriptDefault);
    }
  }

  static formatDateTimeDisplay = (date, time, lang) => {
    let newFormat = "";
    let checkTime = false;
    let checkSecond = false;
    let SlashOrGrade = "";
    if (date) {
      if (date.indexOf(":") > 0) {
        checkTime = true;
        var strLeDate = date.length;
        var strLeDateNew = date.split(":").join("").length;
        var num = strLeDate - strLeDateNew;
        if (num == 1) {
          checkSecond = false;
        } else if (num == 2) {
          checkSecond = true;
        }
      }
      if (checkTime) {
        let arrDateTime = date.split(" ");
        let arrDate = arrDateTime[0].split(/[/-]/);
        let arrTime = arrDateTime[1].split(":");
        let newDate = "";
        if (checkSecond) {
          if (arrDate[0].length == 4) {
            newDate = new Date(
              arrDate[0],
              arrDate[1] - 1,
              arrDate[2],
              arrTime[0],
              arrTime[1],
              arrTime[2]
            );
          } else {
            newDate = new Date(
              arrDate[2],
              arrDate[1] - 1,
              arrDate[0],
              arrTime[0],
              arrTime[1],
              arrTime[2]
            );
          }
          if (lang.toUpperCase() == "TH") {
            newFormat =
              moment(newDate).format("DD/MM/") +
              moment(newDate).add(+543, "year").format("YYYY") +
              " " +
              moment(newDate).format("HH:mm:ss");
          } else {
            newFormat = moment(newDate).format("DD/MM/YYYY HH:mm:ss");
          }
        } else {
          if (arrDate[0].length == 4) {
            newDate = new Date(
              arrDate[0],
              arrDate[1] - 1,
              arrDate[2],
              arrTime[0],
              arrTime[1]
            );
          } else {
            newDate = new Date(
              arrDate[2],
              arrDate[1] - 1,
              arrDate[0],
              arrTime[0],
              arrTime[1]
            );
          }
          if (lang.toUpperCase() == "TH") {
            newFormat =
              moment(newDate).format("DD/MM/") +
              moment(newDate).add(+543, "year").format("YYYY") +
              " " +
              moment(newDate).format("HH:mm");
          } else {
            newFormat = moment(newDate).format("DD/MM/YYYY HH:mm");
          }
        }
      } else {
        let arrDate = date.split(/[/-]/);
        let newDate = "";
        if (arrDate[0].length == 4) {
          newDate = new Date(arrDate[0], arrDate[1] - 1, arrDate[2]);
        } else {
          newDate = new Date(arrDate[2], arrDate[1] - 1, arrDate[0]);
        }
        if (lang.toUpperCase() == "TH") {
          newFormat =
            moment(newDate).format("DD/MM/") +
            moment(newDate).add(+543, "year").format("YYYY");
          if (time) {
            newFormat += "";
          }
        } else {
          newFormat = moment(newDate).format("DD/MM/YYYY");
        }
      }
    }
    return newFormat;
  };
}

export default MainFunc;
