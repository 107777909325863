import React, { Component } from "react";
import styles from "../../styles/insuranceTerms.module.scss";
class InsuranceTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { terms } = this.state;
    return (
      <div className="container">
        <div className={styles.insuranceTerms}>
          <div className={styles.scHeading}>
            <h2 className={styles.h1}>เงื่อนไขการรับประกัน</h2>
          </div>
          <div style={{ maxWidth: "860px" }}>
            <div className={styles.contentTableTerms}>
              <table className={styles.table}>
                <tbody>
                  {this.props.data?.warrantyDetails.map((item, index) => (
                    <tr>
                      <td className={styles.widthTableTrems}>
                        <strong>{item.warrantyconditionTitle}</strong>
                      </td>
                      <td>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.warrantyDetail,
                          }}
                        ></div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {this.props.data?.warrantyRemark && (
              <div className={styles.remark}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.warrantyRemark,
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default InsuranceTerms;
