import React, { Component } from "react";

import {
  Row,
} from "react-bootstrap";

import Highlight from "./highlight";
import styles from "../../styles/highlightProduct.module.scss";

class HighlightProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      title2: "",
      value: [],
    };
  }

  render() {
    const { title, title2, value } = this.state;

    return (
      <div className="container">
        <div className={styles.highlightProduct}>
          <div className={styles.scHeading}>
            <h2 className={styles.title}>{this.props.data?.hightlightTitle}</h2>
            <div className={styles.title2}>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.data?.hightlightDescription,
                }}
              ></div>
            </div>
          </div>
          <Row className={styles.highlightCard}>
            {this.props.data?.highlightDetails?.map((item, index) => {
              let lastOddNumber = false;
              let fourItem = false;
              if (
                index + 1 === this.props.data?.highlightDetails?.length &&
                index % 2 === 0
              ) {
                lastOddNumber = true;
              }
              if (this.props.data?.highlightDetails?.length % 4 === 0) {
                fourItem = true;
              }

              return (
                <div
                  className={`${styles.highlightItem} ${
                    lastOddNumber ? styles.lastOddNumber : ""
                  } 
                  ${fourItem ? styles.fourItem : ""}`}
                  key={index}
                >
                  <Highlight
                    bgColor={item.backgroundColor}
                    value={item}
                    lastOddNumber={lastOddNumber}
                  />
                </div>
              );
            })}
          </Row>

          {this.props.data?.hightlightRemark && (
            <div className={styles.remark}>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.data.hightlightRemark,
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default HighlightProduct;
