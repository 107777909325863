import React from "react";
import styles from "../../styles/pre-select-muti.module.scss";
import MainFunc from "../../models/MainFunc";
import MainData from "../../models/MainData";
class PreSelectMuti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: [],
    };
  }
  handleChange = (value) => {
    const { selectedValue } = this.state;
    let termValue = selectedValue;
    if (this.props.select.riderTypeCode === "MULTI_SELECT") {
      if (termValue.includes(value)) {
        // Remove the value from selectedValue if it already exists
        termValue = selectedValue.filter((item) => item !== value);
      } else {
        // Add the value to selectedValue if it doesn't exist
        termValue = [...selectedValue, value];
      }
    } else {
      // Set the selectedValue to the new value
      if (termValue.includes(value)) {
        // Remove the value from selectedValue if it already exists
        termValue = selectedValue.filter((item) => item !== value);
      } else {
        termValue = [value];
      }
    }

    this.setState({
      selectedValue: termValue,
    });

    this.props.onChange(termValue);
  };
  render() {
    return (
      <div
        className={`${styles.preSelectMuti} ${
          this.props.selectPlan ? styles.selectPlan : ""
        }`}
      >
        {this.props.label && (
          <label className={styles.label}>
            {this.props.label}{" "}
            <span className={styles.onlyOne}>
              {this.props.select.riderTypeCode === "MULTI_SELECT"
                ? MainFunc.getLabelDtos("L0811")
                : MainFunc.getLabelDtos("L0810")}
            </span>
          </label>
        )}

        <div className={styles.dataSelect}>
          {this.props.select?.riderBenefits?.map((data, index) => {
            return (
              <div
                className={`${styles.select} ${
                  this.state.selectedValue.includes(data.riderCode)
                    ? styles.active
                    : ""
                }`}
                onClick={() => this.handleChange(data.riderCode)}
              >
                <div className={styles.header}>
                  <span className={styles.title}>{data.riderName}</span>
                  <span className={styles.supTitle}>{data.riderTitle}</span>
                </div>
                <span className={styles.value}>
                  {MainFunc.getLabelDtos("L0816") +
                    " " +
                    MainFunc.numberWithCommas(data.riderPremium) +
                    " " +
                    MainFunc.getLabelDtos("L0104")}
                  /{MainData.userSelectedData?.selectedPlan?.paymentUnitTitle}
                </span>
              </div>
            );
          })}
          {this.props.select?.riderSpecials?.map((data, index) => {
            return (
              <div
                className={`${styles.select} ${styles.active}`}
              >
                <div className={styles.header}>
                  <span className={styles.title}>{data.riderSpecialName}</span>
                  <span className={styles.supTitle}>
                    {data.riderSpecialTitle}
                  </span>
                </div>
                <span className={styles.value}>{data.riderSpecialValue}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default PreSelectMuti;
