// "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COLOR_PURPLE = exports.COLOR_WARNING = exports.COLOR_YELLOW_1 = exports.COLOR_DARK_1 = exports.COLOR_GRAY_TRANSPARENT_3 = exports.COLOR_GRAY_TRANSPARENT_2 = exports.COLOR_GRAY_TRANSPARENT_1 = exports.COLOR_GRAY_4 = exports.COLOR_GRAY_3 = exports.COLOR_GRAY_2 = exports.COLOR_GRAY_1 = exports.COLOR_ERROR_ACTIVE = exports.COLOR_ERROR = exports.COLOR_SUCCESS_LIGHT = exports.COLOR_SUCCESS_ACTIVE = exports.COLOR_SUCCESS = exports.COLOR_BRAND_2 = exports.COLOR_BRAND_LIGHT = exports.COLOR_BRAND_ACTIVE = exports.COLOR_BRAND = exports.COLOR_WHITE = void 0;
// white
var COLOR_WHITE = '#fff'; // brand colors

exports.COLOR_WHITE = COLOR_WHITE;
var COLOR_BRAND = '#01b6f5';
exports.COLOR_BRAND = COLOR_BRAND;
var COLOR_BRAND_ACTIVE = '#009acf';
exports.COLOR_BRAND_ACTIVE = COLOR_BRAND_ACTIVE;
var COLOR_BRAND_LIGHT = 'rgba(0, 184, 245, 0.2)';
exports.COLOR_BRAND_LIGHT = COLOR_BRAND_LIGHT;
var COLOR_BRAND_2 = '#07b0ea'; // success colors

exports.COLOR_BRAND_2 = COLOR_BRAND_2;
var COLOR_SUCCESS = '#1de9b6';
exports.COLOR_SUCCESS = COLOR_SUCCESS;
var COLOR_SUCCESS_ACTIVE = '#1ad1a3';
exports.COLOR_SUCCESS_ACTIVE = COLOR_SUCCESS_ACTIVE;
var COLOR_SUCCESS_LIGHT = 'rgba(29, 233, 182, 0.2)'; // error colors

exports.COLOR_SUCCESS_LIGHT = COLOR_SUCCESS_LIGHT;
var COLOR_ERROR = '#fe4849';
exports.COLOR_ERROR = COLOR_ERROR;
var COLOR_ERROR_ACTIVE = '#ea4243'; // gray colors

exports.COLOR_ERROR_ACTIVE = COLOR_ERROR_ACTIVE;
var COLOR_GRAY_1 = '#F6F7F9';
exports.COLOR_GRAY_1 = COLOR_GRAY_1;
var COLOR_GRAY_2 = '#D7D9E2';
exports.COLOR_GRAY_2 = COLOR_GRAY_2;
var COLOR_GRAY_3 = '#a4a7b5';
exports.COLOR_GRAY_3 = COLOR_GRAY_3;
var COLOR_GRAY_4 = '#3F4954';
exports.COLOR_GRAY_4 = COLOR_GRAY_4;
var COLOR_GRAY_TRANSPARENT_1 = 'rgba(244, 246, 249, 0.4)';
exports.COLOR_GRAY_TRANSPARENT_1 = COLOR_GRAY_TRANSPARENT_1;
var COLOR_GRAY_TRANSPARENT_2 = 'rgba(82, 95, 127, 0.3)';
exports.COLOR_GRAY_TRANSPARENT_2 = COLOR_GRAY_TRANSPARENT_2;
var COLOR_GRAY_TRANSPARENT_3 = 'rgba(82, 95, 127, 0.15)'; // text colors

exports.COLOR_GRAY_TRANSPARENT_3 = COLOR_GRAY_TRANSPARENT_3;
var COLOR_DARK_1 = '#2A3039'; // yellow colors
// TODO: only warning const should remain

exports.COLOR_DARK_1 = COLOR_DARK_1;
var COLOR_YELLOW_1 = '#fc0';
exports.COLOR_YELLOW_1 = COLOR_YELLOW_1;
var COLOR_WARNING = COLOR_YELLOW_1; // purple colors

exports.COLOR_WARNING = COLOR_WARNING;
var COLOR_PURPLE = '#663398';
exports.COLOR_PURPLE = COLOR_PURPLE;