// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = bound01;

var _isOnePointZero = _interopRequireDefault(require("./isOnePointZero"));

var _isPercentage = _interopRequireDefault(require("./isPercentage"));

/* eslint-disable no-param-reassign */
function bound01(value, max) {
  if ((0, _isOnePointZero["default"])(value)) {
    value = '100%';
  }

  var processPercent = (0, _isPercentage["default"])(value);
  value = Math.min(max, Math.max(0, parseFloat(value))); // Automatically convert percentage into number

  if (processPercent) {
    value = parseInt(value * max, 10) / 100;
  } // Handle floating point rounding errors


  if (Math.abs(value - max) < 0.000001) {
    return 1;
  } // Convert into [0, 1] range if it isn't already


  return value % max / parseFloat(max);
}