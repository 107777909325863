import React, { Component } from "react";
import styles from "../../styles/box-widget.module.scss";
import IconBoxWidget from "../../images/icon_box-widgets.svg";
import IconClose from "../../images/icon_box-widgets-close.svg";
import BoxWidgetsBody from "./boxWidgetsBody.js";
class boxWidgets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    return (
      <div className={styles.boxWidgets}>
        <BoxWidgetsBody
          isOpen={this.props.isOpenContact}
          data={this.props.contactWidget}
          mainPage={this.props.mainPage}
          activePage={this.props.activePage}
          checkKplus={this.props.checkKplus}
        />
        <div onClick={() => this.props.setIsOpenContact()}>
          {this.props.isOpenContact ? (
            <img
              src={IconClose}
              className={styles.mainIcon}
              alt="icon-box-widget"
            />
          ) : (
            <img
              src={IconBoxWidget}
              className={styles.mainIcon}
              alt="icon-box-widget"
            />
          )}
        </div>
      </div>
    );
  }
}

export default boxWidgets;
