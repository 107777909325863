import React, { Component } from "react";
import MainFunc from "../../models/MainFunc";
import { ReactComponent as IconEmpty } from "../../images/icon_empty.svg";
import styles from "../../styles/HistorySms.module.scss";
class LinkExpired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "LinkExpired",
    };
  }

  componentDidMount() {}

  handleClick = () => {};

  render() {
    return (
      <div className="container-fulid">
        <div className={styles.historySms}>
          <div className={styles.historySmsPage}>
            <div className={styles.content}>
              <div className={styles.emptyData}>
                <div className={styles.emptyDataImg}>
                  <IconEmpty fill={"var(--mainColor)"} />
                </div>
                <div className={styles.emptyDataTitle}>
                  {MainFunc.getLabelDtos("L0708") /* ขออภัย ลิ้งค์หมดอายุ */}
                </div>
                <div className={styles.emptyDataSubTitle}>
                  {
                    MainFunc.getLabelDtos(
                      "L0709"
                    ) /* ไม่สามารถทำรายการซื้อประกันที่ท่านได้ทำค้างไว้ในระบบ
                  กรุณากลับไปยังหน้าซื้อประกัน เพื่อทำรายการใหม่อีกครั้ง */
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LinkExpired;
