import React, { Component, Fragment } from "react";
import styles from "../../styles/service.module.scss";
import MainFunc from "../../models/MainFunc";
class service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  render() {
    return (
      <div className={styles.service}>
        <div className="container">
          {this.props.dataContactDetail && (
            <Fragment>
              <div className={styles.scHeading}>
                <h3 className={styles.h1} style={{ fontWeight: "bold" }}>
                  {MainFunc.getLabelDtos("L0777")?MainFunc.getLabelDtos("L0777"):"ผู้ให้บริการประกัน"}
                </h3>
              </div>
              <div className={styles.cardService}>
                <div className={styles.cardServiceInner}>
                  <div className={styles.cardTitleService}>
                    <strong>
                      {this.props.dataContactDetail?.contactDetailTitle}
                    </strong>
                  </div>
                  <ui
                  >
                    <h4
                      dangerouslySetInnerHTML={{
                        __html:
                          this.props.dataContactDetail
                            ?.contactDetailDescription,
                      }}
                    ></h4>
                  </ui>
                </div>
              </div>
            </Fragment>
          )}

          {this.props.dataRemark?.productInformation && (
            <div className={styles.cardRemark}>
              <div className={styles.cardRemarkInner}>
                <div
                  className={styles.remark}
                  dangerouslySetInnerHTML={{
                    __html: this.props.dataRemark.productInformation,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default service;
