import axios from "axios";
import React, { Component } from "react";

import { Row, Col, Image, Button, Card, Spinner, Form } from "react-bootstrap";
import { Collapse } from "@material-ui/core";
import SyncLoader from "../../customLib/react-spinners/SyncLoader";

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

import logo2 from "../../images/logo.png";
import logocheckout0 from "../../images/notFound.svg";
import IcoCorrect from "../../images/ico_correct.png";
import IcoTelGreen from "../../images/ico_tel_green.png";
import IcoCrossSign from "../../images/ico_closesign.png";
import IcoError from "../../images/ico_error.png";
import KbankHeaderBusiness from "../Broker/KbankHeaderBusiness";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conetntHeight: 0,
      screen: "SM013",
      isLoading: true,
      isLoadingPage: true,
      serviceError: false,
      serviceKPayPlusError: false,
      css: "",
      logo: "",
      configDes:
        "ไม่พบข้อมูลแบบประกันที่ท่านเลือก กรุณาเลือกแบบประกันใหม่อีกครั้ง",
      channelName: "",
      logoWidth: 70,
      showDropLead: false,
      step: "1",
      name: "",
      phoneNumber: "",
      email: "",
      selectDate: "",
      validateDroplead: false,
      nameerror: "",
      emailerror: "",
      failPhone: "",
      failselectDate: "",
      checkboxDroplead: false,
      DropoffModal: false,
      checkInputname: false,
      checkInputemail: false,
      checkInputphoneNumber: false,
      styleButton: 0,
      conetntStyleButton: 0,
      ssrRuning:true
    };
  }

  componentDidMount() {
    this.setState({css:sessionStorage?.getItem("customCss"),logo:sessionStorage?.getItem("channelLogo"),channelName:sessionStorage.getItem("channelName"),ssrRuning:false})
    var head = document.getElementsByTagName("head")[0];
    var link = document.createElement("link");
    link.id = "customCss";
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = this.state.css;
    link.media = "all";
    head.appendChild(link);
    MainData.initialData.bearerAuth = sessionStorage.getItem("bearerAuth");
    MainData.initialData.labelDtos = JSON.parse(
      sessionStorage.getItem("labelDtos")
    );
    MainData.initialData.configDtos = JSON.parse(
      sessionStorage.getItem("configDtos")
    );
    MainData.dropleadDtos = JSON.parse(sessionStorage.getItem("dropleadDtos"));
    MainData.initialData.productId = parseInt(
      sessionStorage.getItem("productId")
    );
    MainData.initialData.channelCode = sessionStorage.getItem("channelName");
    if (window.location.origin.includes("localhost")) {
      MainData.serviceURL = `https://kbankonlinesale-uat.muangthai.co.th${MainData.servicePath}api`;
    } else {
      MainData.serviceURL =
        window.location.origin + `${MainData.servicePath}api`;
    }
    this.setState({ isLoading: false });
  }

  handleInputChange = (name, val) => {
    switch (name) {
      case "name":
        if (val[0] === " ") {
          break;
        }
        let checkWord = /^[ก-์ ]+$/.test(val);
        this.setState((state) => ({
          name: !checkWord && val ? state.name : val,
        }));
        this.setState({ checkInputname: false });
        if (this.state.name.split(" ")[0] || this.state.name.split(" ")[1]) {
          this.setState({ nameerror: "" });
        }
        break;
      case "phoneNumber":
        val = val.replace(/[^\d]/g, "");
        if (val.length == 1 && val != "0") {
          this.setState({ phoneNumber: "0" + val });
        } else if (val.length <= 10) {
          this.setState({ phoneNumber: val, failPhone: "" });
        }
        this.setState({ checkInputphoneNumber: false });
        break;
      case "email":
        let checkWord4 = /^[a-zA-Z0-9-@_.]+$/.test(val);
        this.setState((state) => ({
          email: !checkWord4 && val ? state.email : val,
          emailerror: "",
        }));
        this.setState({ checkInputemail: false });
        break;
      case "selectDate":
        this.setState({ selectDate: val, failselectDate: "" });
        break;
      default:
        break;
    }
  };

  closeDroplead = () => {
    document.getElementById("dropLeadBox").click();
    setTimeout(() => {
      this.setState({
        showDropLead: false,
        step: "1",
        validateDroplead: false,
        checkboxDroplead: false,
        checkInputname: false,
        checkInputemail: false,
        checkInputphoneNumber: false,
      });
      this.setState({ name: "", phoneNumber: "", email: "", selectDate: "" });
      this.setState({
        nameerror: "",
        failPhone: "",
        emailerror: "",
        failselectDate: "",
      });
    }, 100);
  };

  serviceDroplead = async () => {
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if (statusSystemConfig) {
      window.location.href = MainData.mainPath + "shutdown";
      return;
    }
    this.setState({
      nameerror: "",
      emailerror: "",
      failPhone: "",
      failselectDate: "",
    });
    this.setState({
      validateDroplead: true,
      checkInputname: false,
      checkInputemail: false,
    });
    let validate = false;
    if (
      !this.state.name ||
      !this.state.phoneNumber ||
      !this.state.email ||
      !this.state.selectDate
    ) {
      validate = true;
    }
    if (this.state.name === "") {
      this.setState({ nameerror: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (
      !this.state.name.split(/ (.*)/s)[0] ||
      !this.state.name.split(/ (.*)/s)[1]
    ) {
      this.setState({ nameerror: "กรุณาตรวจสอบข้อมูล", checkInputname: true });
      validate = true;
    }

    if (this.state.email === "") {
      this.setState({ emailerror: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (this.validateEmail(this.state.email) !== true) {
      this.setState({
        emailerror: "กรุณาตรวจสอบข้อมูล",
        checkInputemail: true,
      });
      validate = true;
    }

    if (this.state.phoneNumber == "") {
      this.setState({ failPhone: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (this.state.phoneNumber.length <= 9) {
      this.setState({
        failPhone: "กรุณาตรวจสอบข้อมูล",
        checkInputphoneNumber: true,
      });
      validate = true;
    }

    if (this.state.selectDate == "") {
      this.setState({ failselectDate: "กรุณาระบุข้อมูล" });
      validate = true;
    }

    if (validate) {
      return;
    }

    this.setState({ step: "2" });

    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      channel: MainData.initialData.channelCode,
      firstName: this.state.name.split(" ")[0].trim(),
      lastName: this.state.name.split(" ").slice(1).filter(Boolean).join(" "),
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      productId: MainData.initialData.productId,
      contactTimeName: this.state.selectDate,
    };

    axios
      .post(MainData.serviceURL + "/Customer/GetDropLead", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (res.data.success) {
          this.setState({ step: "3" });
          this.setState({
            contactTimeId: data.contactTimeId,
            contactTimeCode: data.contactTimeCode,
            contactTimeName: data.contactTimeName,
          });
        } else {
          this.setState({ step: "2" });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          this.props.loadController(false);
          this.validateBadRequest(error.response.data.errors);
        } else {
          this.setState({ step: "4" });
        }
      });
  };
  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <div
            className="w-100 h-100 d-flex justify-content-center align-items-center"
            style={{
              zIndex: "99",
              position: "fixed",
              backgroundColor: "white",
              left: 0,
              top: 0,
            }}
          >
            <div
              className="w-100 h-100 d-flex justify-content-center align-items-center mainLoading"
              style={{ zIndex: "99", position: "fixed" }}
            >
              <Row>
                <Col xs={12} className="w-100 d-flex justify-content-center">
                  <h3 className="csMainColor">Loading</h3>
                </Col>
                <Col xs={12} className="w-100 d-flex justify-content-center">
                  <SyncLoader size={15} loading={true} />
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
        <Collapse in={this.state.showDropLead} timeout="auto" unmountOnExit>
          <div className={"drop--lead--box"} id={"dropLeadBox"}>
            <Card
              class="text-center csMainBG text-white card-header"
              border="light"
            >
              {this.state.channelName == "KBANK" ? (
                <Card.Header class="text-center csMainBG text-white card-header">
                  <Image
                    className="IcoCrossSign"
                    src={this.state.ssrRuning?"":IcoCrossSign}
                    rounded
                    onClick={() => this.closeDroplead()}
                  ></Image>
                  <Row
                    id="logoSection"
                    className="pl-2 pr-2 pt-2 pb-2 csMainBG d-flex align-items-center"
                    style={{ height: "45px" }}
                  >
                    <div>
                      <Form.Label className="labelDropLeadKBANK">
                        {
                          MainFunc.getLabelDtos(
                            "L0466"
                          ) /* ให้เจ้าหน้าที่ติดต่อกลับ */
                        }
                      </Form.Label>
                    </div>
                  </Row>
                </Card.Header>
              ) : (
                <Card.Header class="text-center csMainBG text-white card-header">
                  <Image
                    className="IcoCrossSign"
                    src={this.state.ssrRuning?"":IcoCrossSign}
                    rounded
                    onClick={() => this.closeDroplead()}
                  ></Image>
                  <Row
                    id="logoSection"
                    className="pl-2 pr-2 pt-2 pb-2 csMainBG d-flex align-items-center"
                    style={{ height: "45px" }}
                  >
                    <Image
                      id="channelLogo"
                      src={this.state.ssrRuning?"":!this.state.logo ? logo2 : this.state.logo}
                      rounded
                      style={{
                        height: "95px",
                        position: "absolute",
                        left: "0",
                        top: "-5px",
                      }}
                      onLoad={(e) =>
                        this.setState({ logoWidth: e.target.width + 5 })
                      }
                    />
                    <div>
                      <Form.Label className="labelDropLead">
                        {
                          MainFunc.getLabelDtos(
                            "L0466"
                          ) /* ให้เจ้าหน้าที่ติดต่อกลับ */
                        }
                      </Form.Label>
                    </div>
                  </Row>
                </Card.Header>
              )}

              {this.state.step == "1" ? (
                <Card.Body>
                  <div
                    className="subFontFamily"
                    style={{
                      height: "420px",
                      overflow: "hidden",
                      overflowY: "scroll",
                      padding: "12px",
                    }}
                  >
                    <Form>
                      <Form.Group className="font-weight-bold " hasValidation>
                        <Form.Label>
                          {MainFunc.getLabelDtos("L0467") /* ชื่อ - นามสกุล */}
                        </Form.Label>
                        <Form.Control
                          className={`input-background ${
                            (this.state.validateDroplead && !this.state.name) ||
                            this.state.checkInputname
                              ? "input--error"
                              : ""
                          }`}
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={(e) =>
                            this.handleInputChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                          autoComplete="off"
                        />
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          {this.state.nameerror}
                        </span>
                      </Form.Group>
                      <Form.Group className="font-weight-bold ">
                        <Form.Label>
                          {MainFunc.getLabelDtos("L0468") /* เบอร์มือถือ */}
                        </Form.Label>
                        <Form.Control
                          className={`input-background ${
                            (this.state.validateDroplead &&
                              !this.state.phoneNumber) ||
                            this.state.checkInputphoneNumber
                              ? "input--error"
                              : ""
                          }`}
                          type="phoneNumber"
                          name="phoneNumber"
                          value={this.state.phoneNumber}
                          onChange={(e) =>
                            this.handleInputChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                          autoComplete="off"
                        />
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          {this.state.failPhone}
                        </span>
                      </Form.Group>
                      <Form.Group className="font-weight-bold ">
                        <Form.Label>
                          {MainFunc.getLabelDtos("L0469") /* อีเมล */}
                        </Form.Label>
                        <Form.Control
                          className={`input-background ${
                            (this.state.validateDroplead &&
                              !this.state.email) ||
                            this.state.checkInputemail
                              ? "input--error"
                              : ""
                          }`}
                          type="text"
                          name="email"
                          placeholder="yourmail@mail.com"
                          value={this.state.email}
                          onChange={(e) =>
                            this.handleInputChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                          autoComplete="off"
                        />
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          {this.state.emailerror}
                        </span>
                      </Form.Group>
                      <Form.Label className="font-weight-bold ">
                        {
                          MainFunc.getLabelDtos(
                            "L0470"
                          ) /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */
                        }
                      </Form.Label>
                      <Form.Group>
                        <Form.Control
                          className={`input-background ${
                            this.state.validateDroplead &&
                            !this.state.selectDate
                              ? "input--error"
                              : ""
                          }`}
                          as="select"
                          name="selectDate"
                          placeholder="ช่วงเวาลาที่สะดวกให้ติดต่อกลับ"
                          value={this.state.selectDate}
                          onChange={(e) =>
                            this.handleInputChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                        >
                          <option value="" disabled selected>
                            {
                              MainFunc.getLabelDtos(
                                "L0470"
                              ) /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */
                            }
                          </option>
                          {MainData.dropleadDtos.map((item, key) => {
                            return (
                              <option value={item.contactTimeName}>
                                {item.contactTimeName}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          {this.state.failselectDate}
                        </span>
                      </Form.Group>
                      <Form.Group
                        style={{ marginTop: 20 }}
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          type="checkbox"
                          value={this.state.checkboxDroplead}
                          onChange={(e) => {
                            this.setState({
                              checkboxDroplead: e.target.checked,
                            });
                          }}
                          label="ข้าพเจ้ายินยอมให้ บมจ.เมืองไทยประกันชีวิต ทำการเก็บรวบรวมและใช้ข้อมูลที่ข้าพเจ้าให้ไว้ นายหน้าประกันชีวิต และพันธมิตร/คู่ค้า เพื่อวัตถุประสงค์ในการนำเสนอขายประกันชีวิตแบบต่างๆ และ/หรือนำเสนอสิทธิประโยชน์อื่นๆ ให้แก่ข้าพเจ้าได้"
                        />
                      </Form.Group>
                    </Form>
                    <Col className="d-flex justify-content-center pt-3">
                      <Button
                        variant=""
                        className="mainBtnRadius font-weight-bold"
                        style={{ fontSize: "1.1rem" }}
                        onClick={() => {
                          this.serviceDroplead();
                        }}
                        disabled={!this.state.checkboxDroplead}
                      >
                        {MainFunc.getLabelDtos("L0471") /* ส่งข้อมูลสอบถาม */}
                      </Button>
                    </Col>
                    <br></br>
                    <span className="dropLeadContactText">
                      {MainFunc.getLabelDtos("L0472") /* ติดต่อเจ้าหน้าที่ */}
                    </span>
                    <br></br>
                    <span className="dropLeadContactTimeText">
                      {
                        MainFunc.getLabelDtos(
                          "L0473"
                        ) /* ทุกวันทำการ จันทร์ - ศุกร์ เวลา 08:00 - 18:00 */
                      }
                    </span>
                    <br></br>
                    <div className="drop--lead--call-row">
                      <div>
                        <Image
                          className=""
                          src={this.state.ssrRuning?"":IcoTelGreen}
                          rounded
                          style={{
                            height: "30px",
                            left: "0",
                            top: "-5px",
                            paddingLeft: "1.25rem",
                          }}
                          onLoad={(e) =>
                            this.setState({ logoWidth: e.target.width + 5 })
                          }
                        />
                      </div>
                      <div className="drop--lead--call">
                        <a
                          href={`tel:${MainFunc.getLabelDtos("L0183")}`}
                          className="font-weight-bold telClass"
                          style={{ fontSize: "1.6rem", paddingLeft: "1.25rem" }}
                        >
                          {MainFunc.getLabelDtos("L0183")}
                        </a>
                        <a
                          className="font-weight-bold callToClass"
                          style={{ fontSize: "1.3rem", paddingLeft: "1.25rem" }}
                        >
                          {MainFunc.getLabelDtos("L0183")}
                        </a>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              ) : (
                [
                  this.state.step == "2" ? (
                    <Card.Body>
                      <div style={{ marginTop: 150 }}>
                        <Spinner
                          className="img-center"
                          animation="border"
                          role="status"
                          style={{ width: "60px", height: "60px" }}
                        >
                          <span className="visually-hidden"></span>
                        </Spinner>
                      </div>
                    </Card.Body>
                  ) : (
                    [
                      this.state.step == "3" ? (
                        <Card.Body>
                          <div style={{ margin: 40 }}>
                            <Image
                              className="img-center"
                              src={this.state.ssrRuning?"":IcoCorrect}
                            ></Image>
                            <div className="text-align-center ">
                              <span
                                className="font-weight-bold text-center"
                                style={{ fontSize: "1.2rem" }}
                              >
                                {
                                  MainFunc.getLabelDtos(
                                    "L0474"
                                  ) /* บริษัทฯ ได้รับข้อมูลเรียบร้อยแล้ว กรุณารอเจ้าหน้าที่ติดต่อกลับภายใน 1-2 วันทำการ */
                                }
                              </span>
                              <br></br>
                              <span
                                className="font-weight-bold text-center font-collor1 "
                                style={{ fontSize: "1.2rem" }}
                              >
                                {
                                  MainFunc.getLabelDtos(
                                    "L0475"
                                  ) /* ขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน) */
                                }
                              </span>
                              <br></br>
                            </div>
                            <Col className="d-flex justify-content-center pt-3">
                              <Button
                                variant=""
                                className="mainBtnRadius font-weight-bold"
                                style={{ fontSize: "1.1rem", width: 200 }}
                                onClick={() => this.closeDroplead()}
                              >
                                {MainFunc.getLabelDtos("L0185") /** ตกลง */}
                              </Button>
                            </Col>
                          </div>
                        </Card.Body>
                      ) : (
                        <Card.Body>
                          <div style={{ margin: 70 }}>
                            <Image
                              className="img-center"
                              src={this.state.ssrRuning?"":IcoError}
                            ></Image>
                            <div
                              className="text-align-center "
                              style={{ marginTop: 30 }}
                            >
                              <span
                                className="font-weight-bold text-center"
                                style={{ fontSize: "1.2rem" }}
                              >
                                {
                                  MainFunc.getLabelDtos(
                                    "L0476"
                                  ) /** ขออภัยในความไม่สะดวก */
                                }
                              </span>
                              <br></br>
                              <span
                                className="font-weight-bold text-center font-collor1 "
                                style={{ fontSize: "1.2rem" }}
                              >
                                {
                                  MainFunc.getLabelDtos(
                                    "L0477"
                                  ) /** กรุณาลองใหม่อีกครั้ง */
                                }
                              </span>
                              <br></br>
                            </div>
                            <Col
                              className="d-flex justify-content-center pt-3"
                              style={{ marginTop: 30 }}
                            >
                              <Button
                                variant=""
                                className="mainBtnRadius font-weight-bold"
                                style={{ fontSize: "1.1rem", width: 200 }}
                                onClick={() => this.closeDroplead()}
                              >
                                {MainFunc.getLabelDtos("L0188") /** ปิด */}
                              </Button>
                            </Col>
                          </div>
                        </Card.Body>
                      ),
                    ]
                  ),
                ]
              )}
            </Card>
          </div>
        </Collapse>
        <KbankHeaderBusiness
          channelName={this.state.channelName}
          initialSalesStatus={true}
          channel={{ logo: this.state.logo }}
          isLoading={this.state.isLoading}
        >
          <div className="p-3 mt-3 mb-3 w-100 container">
            <Row className="d-flex justify-content-center pt-3 text--shutdown text-center mt-5">
              <h4 className="text-notfound"
                dangerouslySetInnerHTML={{ __html: this.state.configDes }}
              ></h4>
            </Row>
            <Row className="d-flex justify-content-center pt-3">
              <Image
                className="mt-2 mb-2"
                src={this.state.ssrRuning?"":logocheckout0}
                rounded
                style={{width:"50%"}}
              />
            </Row>
            <Row className="d-flex justify-content-center pt-3 mt-5">
              <Button
                variant=""
                className="mainBtnRadiusV3 font-weight-bold"
                onClick={() => {
                  this.setState({ showDropLead: !this.state.showDropLead });
                }}
              >
                ต้องการให้เจ้าหน้าที่ติดต่อกลับ
              </Button>
            </Row>
          </div>
        </KbankHeaderBusiness>
      </>
    );
  }
}

export default NotFound;
