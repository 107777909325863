import React, { Component } from "react";
import styles from "../../styles/summaryBar.module.scss";
import MainFunc from "../../models/MainFunc";
import MainData from "../../models/MainData";
class SummaryBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className={styles.summaryBar}>
        <div className="container">
          <div className={styles.content}>
            <div>
              <div className={styles.productName}>
                {MainFunc.getLabelDtos("L0765") /*แผนประกันที่คุณเลือก*/}
              </div>
              <div className={styles.productName}>
                {this.props.productName}
                {" "}
                {MainData.userSelectedData.selectedPlan?.planName}
              </div>
            </div>
            <div>
              <div className={styles.paymentType}>
                {MainFunc.getLabelDtos("L0726")}
                {MainData.userSelectedData.selectedPlan?.paymentUnitTitle}
              </div>
              <div className={styles.premium}>
                {" "}
                {MainFunc.numberWithCommas(
                  MainData.userSelectedData.selectedPlan?.premium
                ) +
                  " " +
                  MainFunc.getLabelDtos("L0104")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryBar;
