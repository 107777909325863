import axios from "axios";
import React, { Component , Fragment} from "react";
import Select from '@material-ui/core/Select';
import {Row, Col, Button} from "react-bootstrap";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MAutocompleteInList from "../MAutocompleteInList";
import { GoAlert } from 'react-icons/go';
import SweetAlert from 'react-bootstrap-sweetalert';
import MainFunc from "../../models/MainFunc";
import MainData from "../../models/MainData";

class SM008 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM008",
      validation: false,
      fieldNotValid: [],
      job:  MainData.screenSM008.job?MainData.screenSM008.job?MainData.screenSM008.job:"":MainData.screenSM008Preview.job,
      email:  MainData.screenSM008.email?MainData.screenSM008.email?MainData.screenSM008.email:"":MainData.screenSM008Preview.email,
      lastName:  MainData.screenSM008.lastName?MainData.screenSM008.lastName?MainData.screenSM008.lastName:"":MainData.screenSM008Preview.lastName,
      titleName:  MainData.screenSM008.titleName?MainData.screenSM008.titleName?MainData.screenSM008.titleName:"":MainData.screenSM008Preview.titleName,
      firstName:  MainData.screenSM008.firstName?MainData.screenSM008.firstName?MainData.screenSM008.firstName:"":MainData.screenSM008Preview.firstName,
      idCardExp:  MainData.screenSM008.idCardExp?MainData.screenSM008.idCardExp:MainData.screenSM008Preview.idCardExp,
      jobDescription:  MainData.screenSM008.jobDescription?MainData.screenSM008.jobDescription:MainData.screenSM008Preview.jobDescription,
      idCardExpDateNone:  MainData.screenSM008.idCardExpDateNone?MainData.screenSM008.idCardExpDateNone:MainData.screenSM008Preview.idCardExpDateNone,
      titleNameEN: MainData.screenSM008.titleNameEN?MainData.screenSM008.titleNameEN:MainData.screenSM008Preview.titleNameEN,
      firstNameEN: MainData.screenSM008.firstNameEN?MainData.screenSM008.firstNameEN:MainData.screenSM008Preview.firstNameEN,
      lastNameEN: MainData.screenSM008.lastNameEN?MainData.screenSM008.lastNameEN:MainData.screenSM008Preview.lastNameEN,
      laserId: !MainData.screenSM008.laserId ? "" : MainData.screenSM008.laserId,
      isErrorBirthday:false
    };
  }

  componentDidMount(){
    this.props.calContentHeight()
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
  }

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }

    this.validateInput()
    let flagVerify = "";
    if((!MainData.initialData.title.find(item=>item.titleTitles == this.state.titleName)||!this.state.titleName)|| !this.state.firstName || !this.state.lastName || !this.state.job || !this.state.jobDescription || !this.state.email || (!this.state.titleNameEN && MainData.initialData.productId == 98) || (!this.state.firstNameEN && MainData.initialData.productId == 98) || (!this.state.lastNameEN && MainData.initialData.productId == 98) || (!this.state.laserId && !MainData.screenMenuKPlus.onKPlus) || (this.state.laserId.length < 12 && !MainData.screenMenuKPlus.onKPlus)){
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG003');
      this.props.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
      if(MainData.screenSM008.titleName != null || MainData.screenSM008.firstName != null || MainData.screenSM008.lastName != null || MainData.screenSM008.email != null|| MainData.screenSM008.job != null|| MainData.screenSM008.jobDescription !=null ){
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {titleName: MainData.screenSM008.titleName, firstName: MainData.screenSM008.firstName, lastName: MainData.screenSM008.lastName, titleNameEN: MainData.screenSM008.titleNameEN, firstNameEN: MainData.screenSM008.firstNameEN, lastNameEN: MainData.screenSM008.lastNameEN, occupationName: MainData.screenSM008.job, natureOfBusiness: MainData.screenSM008.jobDescription, Email: MainData.screenSM008.email}, {titleName: this.state.titleName, firstName: this.state.firstName, lastName: this.state.lastName, titleNameEN: this.state.titleNameEN, firstNameEN: this.state.firstNameEN, lastNameEN: this.state.lastNameEN, occupationName: this.state.job ,natureOfBusiness: this.state.jobDescription, Email: this.state.email}, 'Fail', errMsg.msgDescription)
      }else{  
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, {titleName: this.state.titleName, firstName: this.state.firstName, lastName: this.state.lastName, titleNameEN: this.state.titleNameEN, firstNameEN: this.state.firstNameEN, lastNameEN: this.state.lastNameEN, occupationName: this.state.job, natureOfBusiness: this.state.jobDescription, Email:this.state.email}, 'Fail', errMsg.msgDescription)
      }
      MainData.screenSM008.job = this.state.job;
      MainData.screenSM008.email = this.state.email;
      MainData.screenSM008.lastName = this.state.lastName;
      MainData.screenSM008.titleName = this.state.titleName;
      MainData.screenSM008.firstName = this.state.firstName;
      MainData.screenSM008.jobDescription = this.state.jobDescription;
      MainData.screenSM008.laserId = this.state.laserId;
      this.setState({validation: true})
      if(!this.state.titleName){
        flagVerify += flagVerify?"|1":"1"
      }
      if(!this.state.firstName){
        flagVerify += flagVerify?"|2":"2"
      }
      if(!this.state.lastName){
        flagVerify += flagVerify?"|3":"3"
      }
      if(!this.state.laserId){
        flagVerify += flagVerify?"|7":"7"
      }
      if(!this.state.job){
        flagVerify += flagVerify?"|8":"8"
      }
      if(!this.state.jobDescription){
        flagVerify += flagVerify?"|9":"9"
      }
      if(!this.state.email){
        flagVerify += flagVerify?"|10":"10"
      }
      MainFunc.sendDataToGTMKbank({
        'event': 'track_event',
        'event_category': 'link_click',
        'event_action': 'click',
        'event_label': 'step_fail' ,
        'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
        'flag_page' : 'onboard07',
        'flag_verify' : flagVerify 
      });
    }else if((this.state.validation===false) && (!this.state.idCardExp) && (!this.state.idCardExpDateNone)){
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG018');
      this.props.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
      if(MainData.screenSM008.titleName != null || MainData.screenSM008.firstName != null || MainData.screenSM008.lastName != null || MainData.screenSM008.email != null|| MainData.screenSM008.job != null|| MainData.screenSM008.jobDescription !=null ){        
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {titleName: MainData.screenSM008.titleName, firstName: MainData.screenSM008.firstName, lastName: MainData.screenSM008.lastName, titleNameEN: MainData.screenSM008.titleNameEN, firstNameEN: MainData.screenSM008.firstNameEN, lastNameEN: MainData.screenSM008.lastNameEN, occupationName: MainData.screenSM008.job, natureOfBusiness: MainData.screenSM008.jobDescription, Email: MainData.screenSM008.email}, {titleName: this.state.titleName, firstName: this.state.firstName, lastName: this.state.lastName, titleNameEN: this.state.titleNameEN, firstNameEN: this.state.firstNameEN, lastNameEN: this.state.lastNameEN, occupationName: this.state.job ,natureOfBusiness: this.state.jobDescription, Email: this.state.email}, 'Fail', errMsg.msgDescription)
      }else{
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, {titleName: this.state.titleName, firstName: this.state.firstName, lastName: this.state.lastName, titleNameEN: this.state.titleNameEN, firstNameEN: this.state.firstNameEN, lastNameEN: this.state.lastNameEN, occupationName: this.state.job, natureOfBusiness: this.state.jobDescription, Email:this.state.email}, 'Fail', errMsg.msgDescription)
      }
      MainData.screenSM008.job = this.state.job;
      MainData.screenSM008.email = this.state.email;
      MainData.screenSM008.lastName = this.state.lastName;
      MainData.screenSM008.titleName = this.state.titleName;
      MainData.screenSM008.firstName = this.state.firstName;
      MainData.screenSM008.jobDescription = this.state.jobDescription;
      MainData.screenSM008.laserId = this.state.laserId;
      this.setState({validation: true})
    }else if(this.validateEmail(this.state.email)!==true){
      if(!this.state.email){
        flagVerify += flagVerify?"|10":"10"
      }
      MainFunc.sendDataToGTMKbank({
        'event': 'track_event',
        'event_category': 'link_click',
        'event_action': 'click',
        'event_label': 'step_fail' ,
        'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
        'flag_page' : 'onboard07',
        'flag_verify' : flagVerify 
      });
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG019');
      this.props.errorAlert(true, !errMsg?"ท่านกรอกข้อมูลไม่ถูกต้อง":errMsg.msgDescription, " ")
      if(MainData.screenSM008.titleName != null || MainData.screenSM008.firstName != null || MainData.screenSM008.lastName != null || MainData.screenSM008.email != null|| MainData.screenSM008.job != null|| MainData.screenSM008.jobDescription !=null ){
         MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {titleName: MainData.screenSM008.titleName, firstName: MainData.screenSM008.firstName, lastName: MainData.screenSM008.lastName, titleNameEN: MainData.screenSM008.titleNameEN, firstNameEN: MainData.screenSM008.firstNameEN, lastNameEN: MainData.screenSM008.lastNameEN, lastNameEN: this.state.lastNameEN, occupationName: MainData.screenSM008.job,natureOfBusiness: MainData.screenSM008.jobDescription, Email: MainData.screenSM008.email}, {titleName: this.state.titleName, firstName: this.state.firstName, lastName: this.state.lastName, titleNameEN: this.state.titleNameEN, firstNameEN: this.state.firstNameEN, lastNameEN: this.state.lastNameEN, occupationName: this.state.job ,natureOfBusiness: this.state.jobDescription, Email: this.state.email}, 'Fail', errMsg.msgDescription)
      }else{
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, {titleName: this.state.titleName, firstName: this.state.firstName, lastName: this.state.lastName, titleNameEN: this.state.titleNameEN, firstNameEN: this.state.firstNameEN, lastNameEN: this.state.lastNameEN, occupationName: this.state.job, natureOfBusiness: this.state.jobDescription, Email: this.state.email}, 'Fail', errMsg.msgDescription)
      }
      MainData.screenSM008.job = this.state.job;
      MainData.screenSM008.email = this.state.email;
      MainData.screenSM008.lastName = this.state.lastName;
      MainData.screenSM008.titleName = this.state.titleName;
      MainData.screenSM008.firstName = this.state.firstName;
      MainData.screenSM008.jobDescription = this.state.jobDescription;
      MainData.screenSM008.laserId = this.state.laserId;
      this.setState({validation: true})
    }else{
      MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลผู้ขอเอาประกัน', {titleName: MainData.screenSM008.titleName, firstName: MainFunc.blurName(MainData.screenSM008.firstName), lastName: MainFunc.blurName(MainData.screenSM008.lastName), jobDescription: MainData.screenSM008.jobDescription, email: MainFunc.blurEmail(MainData.screenSM008.email)}, {titleName: this.state.titleName, firstName: MainFunc.blurName(this.state.firstName), lastName: MainFunc.blurName(this.state.lastName), jobDescription: this.state.jobDescription, email: MainFunc.blurEmail(this.state.email)});

      if(MainData.screenSM008.titleName != null || MainData.screenSM008.firstName != null || MainData.screenSM008.lastName != null || MainData.screenSM008.email != null|| MainData.screenSM008.job != null|| MainData.screenSM008.jobDescription !=null ){        
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {titleName: MainData.screenSM008.titleName, firstName: MainData.screenSM008.firstName, lastName: MainData.screenSM008.lastName, titleNameEN: MainData.screenSM008.titleNameEN, firstNameEN: MainData.screenSM008.firstNameEN, lastNameEN: MainData.screenSM008.lastNameEN, occupationName: MainData.screenSM008.job,natureOfBusiness: MainData.screenSM008.jobDescription, Email: MainData.screenSM008.email}, {titleName: this.state.titleName, firstName: this.state.firstName, lastName: this.state.lastName, titleNameEN: this.state.titleNameEN, firstNameEN: this.state.firstNameEN, lastNameEN: this.state.lastNameEN, occupationName: this.state.job ,natureOfBusiness: this.state.jobDescription, Email: this.state.email}, 'Success', null)
      }else{
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, {titleName: this.state.titleName, firstName: this.state.firstName, lastName: this.state.lastName, titleNameEN: this.state.titleNameEN, firstNameEN: this.state.firstNameEN, lastNameEN: this.state.lastNameEN, occupationName: this.state.job ,natureOfBusiness: this.state.jobDescription, Email: this.state.email}, 'Success', null)
      }
      let newOccupation = MainData.initialData.occupation.find(x=>x.occupationCode === this.state.job)
      let newNatureOfBusinesses = MainData.initialData.natureOfBusinesses?.find(item=>item.natureOfBusinessesCode === this.state.jobDescription);
      MainData.screenSM013.jobInformationsType = newOccupation?.occupationId;
      MainData.screenSM013.jobInformationsOccupation = newOccupation?.occupationCode;
      MainData.screenSM013.jobInformationsDescription = this.state.jobDescription; 
      MainData.screenSM013.titleCode = MainData.initialData.title.find(x=>x.titleTitles === this.state.titleName)?.titleCode; 
      MainData.screenSM013.titleCodeEN = MainData.initialData.titleEN.find(x=>x.titleTitles === this.state.titleNameEN)?.titleCode;
      
      MainData.screenSM008.job = this.state.job?.trim();
      MainData.screenSM008.email = this.state.email?.trim();
      MainData.screenSM008.lastName = this.state.lastName?.trim();
      MainData.screenSM008.titleName = this.state.titleName?.trim();
      MainData.screenSM008.firstName = this.state.firstName?.trim();
      MainData.screenSM008.titleNameEN = this.state.titleNameEN?.trim();
      MainData.screenSM008.firstNameEN = this.state.firstNameEN?.trim();
      MainData.screenSM008.lastNameEN = this.state.lastNameEN?.trim();
      MainData.screenSM008.idCardExp = this.state.idCardExp?.trim();
      MainData.screenSM008.jobDescription = this.state.jobDescription?.trim();
      MainData.screenSM008.idCardExpDateNone = this.state.idCardExpDateNone;
      MainData.screenSM008.laserId = this.state.laserId?.trim();
      this.checkDopa()
      this.props.loadController(true)
    }
  }

  AddOrUpdateCustomerInfo = (occupationCode) => {
    this.setState({ fieldNotValid: [] })
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "email": this.state.email?.trim(),
      "lastName": this.state.lastName?.trim(),
      "occupationCode": occupationCode?.trim(),
      "firstName": this.state.firstName?.trim(),
      "isExpiredCitizenNo": this.state.idCardExpDateNone===true?"Y":"N", // ตลอดชีพ "Y"
      "natureOfBusiness": this.state.jobDescription?.trim(),
      "titleCode": MainData.initialData.title.find(x=>x.titleTitles === this.state.titleName)?.titleCode,
      "titleCodeEN": MainData.initialData.titleEN.find(x=>x.titleTitles === this.state.titleNameEN)?.titleCode,
      "lastNameEN": this.state.lastNameEN?.trim(),
      "firstNameEN": this.state.firstNameEN?.trim(),
      "citizenNoExpiredDate": this.state.idCardExpDateNone===true?null:this.state.idCardExp.split("/")[2]+"-"+this.state.idCardExp.split("/")[1]+"-"+this.state.idCardExp.split("/")[0], // ตลอดชีพ null 
      "submenuCode": this.state.screen,
      "laserId" :btoa(this.state.laserId?.trim())
    }
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerInfo', data, {
      headers: headers
    })
    .then(res => {
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
        this.props.setPage(this.props.next)
      }
      else if(res.data.message == "MSG008"){ //Dopaไม่ผ่าน
        this.props.loadController(false);
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
        this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription,`${MainData.initialData.codeTracking}STEP2`,"Click",`${MainData.initialData.codeTracking}STEP2_Click_BtnCloseModalReject`);
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription, ' ')
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        this.validateBadRequest(error.response.data.errors)
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }
  
  validateInput = () => {
    this.setState(state => (!state.titleName || !MainData.initialData.title.find(item=>item.titleTitles == this.state.titleName) ?
      { fieldNotValid: [...state.fieldNotValid, "titleName"] }:
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "titleName")] }
    ))
    this.setState(state => (state.firstName?.trim() !== state.firstName || !state.firstName ?
      { fieldNotValid: [...state.fieldNotValid, "firstName"] }:
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "firstName")] }
    ))
    this.setState(state => (state.lastName?.trim() !== state.lastName || !state.lastName ?
      { fieldNotValid: [...state.fieldNotValid, "lastName"] }:
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "lastName")] }
    ))
    this.setState(state => (!state.job ?
      { fieldNotValid: [...state.fieldNotValid, "job"] }:
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "job")] }
    ))
    this.setState(state => (!state.jobDescription ?
      { fieldNotValid: [...state.fieldNotValid, "jobDescription"] }:
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "jobDescription")] }
    ))
    this.setState(state => (!state.email ?
      { fieldNotValid: [...state.fieldNotValid, "email"] }:
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "email")] }
    ))
    this.setState(state => (!state.titleNameEN ?
      { fieldNotValid: [...state.fieldNotValid, "titleNameEN"] }:
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "titleNameEN")] }
    ))
    this.setState(state => (state.firstNameEN?.trim() !== state.firstNameEN || !state.firstNameEN ?
      { fieldNotValid: [...state.fieldNotValid, "firstNameEN"] }:
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "firstNameEN")] }
    ))
    this.setState(state => (state.lastNameEN?.trim() !== state.lastNameEN || !state.lastNameEN ?
      { fieldNotValid: [...state.fieldNotValid, "lastNameEN"] }:
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "lastNameEN")] }
    ))
    this.setState(state => (!state.laserId || state.laserId.length < 12 ?
      { fieldNotValid: [...state.fieldNotValid, "laserId"] } :
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "laserId")] }
    ))
  }

  validateBadRequest = (fieldNameList) => {
    let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG018');
    this.props.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
    this.setState({validation: true})
    let flagVerify = "";
    if(fieldNameList.find(data => data.field.toLowerCase() === "titleCode".toLowerCase() )){
      this.setState({ fieldNotValid: [...this.state.fieldNotValid, 'titleName'] })
      flagVerify += flagVerify?"|1":"1"
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "firstName".toLowerCase() )){
      this.setState({ fieldNotValid: [...this.state.fieldNotValid, 'firstName'] })
      flagVerify += flagVerify?"|2":"2"
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "lastName".toLowerCase() )){
      this.setState({ fieldNotValid: [...this.state.fieldNotValid, 'lastName'] })
      flagVerify += flagVerify?"|3":"3"
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "occupationCode".toLowerCase() )){
      this.setState({ fieldNotValid: [...this.state.fieldNotValid, 'job'] })
      flagVerify += flagVerify?"|8":"8"
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "natureOfBusiness".toLowerCase() )){
      this.setState({ fieldNotValid: [...this.state.fieldNotValid, 'jobDescription'] })
      flagVerify += flagVerify?"|9":"9"
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "email".toLowerCase() )){
      this.setState({ fieldNotValid: [...this.state.fieldNotValid, 'email'] })
      flagVerify += flagVerify?"|10":"10"
    }

    if(fieldNameList.find(data => data.field.toLowerCase() === "titleCodeEN".toLowerCase() )){
      this.setState({ fieldNotValid: [...this.state.fieldNotValid, 'titleNameEN'] })
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "lastNameEN".toLowerCase() )){
      this.setState({ fieldNotValid: [...this.state.fieldNotValid, 'lastNameEN'] })
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "firstNameEN".toLowerCase() )){
      this.setState({ fieldNotValid: [...this.state.fieldNotValid, 'firstNameEN'] })
    }
    MainFunc.sendDataToGTMKbank({
      'event': 'track_event',
      'event_category': 'link_click',
      'event_action': 'click',
      'event_label': 'step_fail' ,
      'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
      'flag_page' : 'onboard07',
      'flag_verify' : flagVerify 
    });
  }

  handleInputChange = (name, val) => {
    switch(name){
      case "titleName":
        this.setState({titleName: val,titleNameEN: MainData.initialData.titleEN.find((dataEN)=>dataEN.titleCode == MainData.initialData.title.find((data)=>data.titleTitles == val)?.titleCode)?.titleTitles})
        break;
      case "firstName":
        if (val[0] === " ") { break; }
        let checkWord = /^[ก-์ ]+$/.test(val);
        this.setState((state) => ({firstName: !checkWord&&val?state.firstName:val}))
        break;
      case "lastName":
        if (val[0] === " ") { break; }
        let checkWord2 = /^[ก-์ ]+$/.test(val);
        this.setState((state) => ({lastName: !checkWord2&&val?state.lastName:val}))
        break;
      case "titleNameEN":
        this.setState({titleNameEN: val,titleName: MainData.initialData.title.find((data)=>data.titleCode == MainData.initialData.titleEN.find((dataEN)=>dataEN.titleTitles == val)?.titleCode)?.titleTitles})
        break;
      case "firstNameEN":
        let checkWord5 = /^[a-zA-Z]+$/.test(val);
        this.setState((state) => ({firstNameEN: !checkWord5&&val?state.firstNameEN:val}))
        break;
      case "lastNameEN":
        if (val[0] === " ") { break; }
        let checkWord6 = /^[a-zA-Z ]+$/.test(val);
        this.setState((state) => ({lastNameEN: !checkWord6&&val?state.lastNameEN:val}))
        break;
      case "idCardExpDateNone":
        this.setState({idCardExpDateNone: val})
        break;
      case "idCardExp":
          val = val.replace(/[^\d]/g, ""); if(val.length<=8) this.setState({idCardExp: MainFunc.genTextToDate(val)})
        break;
      case "job":
        this.setState({job: val})
        break;
      case "jobDescription":
        if (val[0] === " ") { break; }
        let checkWord3 = /^[a-zA-Zก-๙ -]+$/.test(val);
        this.setState((state) => ({jobDescription:!checkWord3&&val?state.jobDescription:val}))
        break;
      case "email":
        let checkWord4 = /^[a-zA-Z0-9-@_.]+$/.test(val);
        this.setState((state) => ({email: !checkWord4&&val?state.email:val}))
        break;
      case "laserId":
        let checkLaserId = /^[a-zA-Z0-9]+$/.test(val);
        if(val.length<=12) this.setState((state) => ({ laserId: !checkLaserId && val ? state.laserId : val }))
        break
      default:
    }
    this.validateInput()
  }

  validateEmail = (email) => {
    if (email == null) { return false }
    if (this.state.fieldNotValid.includes('email')) { return false }
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  willingChangePlan = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
      setTimeout(() => {
        this.props.setHeaderText(MainData.productTitle, "", "", true)
        let findPlanPage = MainData.masPage.find(page=>page.subMenuCode === 'SM002');
        this.props.setPage(findPlanPage.seq)
      }, 500);
    }
    
  }
  checkDopa = () => { //checkDopa อีกรอบ laserId
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": MainData.screenSM005.cusId 
  }
    const data = {
      "idCard" : MainData.screenSM005.idCard,
      "planId" : MainData.screenSM002.planId,
      "birthDate" : MainFunc.getUserBirthDate("ac"),
      "productId" : MainData.initialData.productId,
      "channelCode" : MainData.initialData.channelCode,
      "submenuCode": this.state.screen,
      "laserId": btoa(this.state.laserId?.trim()),
      "firstName": this.state.firstName?.trim(),
      "lastName": this.state.lastName?.trim(),

    }
    axios.post(MainData.serviceURL+'/VerifyIDCard/VerifyID', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      let data = res.data.data;
      let newOccupation = MainData.initialData.occupation.find(x=>x.occupationCode === this.state.job)
      MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการตรวจสอบข้อมูลบัตรประชาชนกับ Service DOPA', {}, {idCard: MainFunc.blurIdcard(MainData.screenSM005.idCard), result: data.checkCustomer.blackList});
      MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลบัตรประชาชนกับ Service MIB', {}, {idCard: MainFunc.blurIdcard(MainData.screenSM005.idCard), result: data.checkCustomer.checkMIB});
      MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการตรวจสอบบัตรประชาชนกับ Service Backlists', {}, {idCard: MainFunc.blurIdcard(MainData.screenSM005.idCard), result: data.checkCustomer.dopa});
      if(res.data.success===true){ 
        MainFunc.sendDataToGTMKbank({
          'event': 'track_event',
          'event_category': 'link_click',
          'event_action': 'click',
          'event_label': 'step_success' ,
          'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
          'flag_page' : 'onboard07',
          'flag_verify' : 'y'  
        });
        if(data.checkCustomerStatus===true){
          if(MainData.screenSM005.idCard != null)
          {
            MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, 'Success', null)
          }
          else
          {
            MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, 'Success', null)
          }
          if(data.checkCustomer.dopa.isValid){
            this.AddOrUpdateCustomerInfo(newOccupation.occupationCode)
          }
        }
      }
      else if (res.data.data.messageCode == 'MSG084'){ //dopa ไม่ผ่าน จะแสดงข้อมูลไม่ผ่านการพิจารณา error เส้นแดงพวกชื่อ
        MainFunc.sendDataToGTMKbank({
          'event': 'track_event',
          'event_category': 'link_click',
          'event_action': 'click',
          'event_label': 'step_fail' ,
          'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
          'flag_page' : 'onboard07',
          'flag_verify' : '1|2|3|7'  
        });
        this.props.loadController(false)
        this.validateInput()
        let fieldError = [];
        fieldError.push("titleName","firstName","lastName","laserId");
        this.setState({validation: true,fieldNotValid:fieldError})
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG084');
        this.props.errorAlert(true, !errMsg?"ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง":errMsg.msgDescription, " ");
        if(MainData.screenSM005.idCard != null)
        {
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard:MainData.screenSM005.idCard}, 'Fail', errMsg.msgDescription)
        }
        else
        {
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard:MainData.screenSM005.idCard}, 'Fail', errMsg.msgDescription)
        }  
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
        this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription)
        if(MainData.screenSM005.idCard)
        {
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, 'Fail', errMsg.msgDescription)
        }
      else
        {
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, 'Fail', errMsg.msgDescription)
        }
      }
    })
    .catch(error => {
      this.props.loadController(false)
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
      this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription))
    });
  }

  skipFirthPage = () => {
    MainData.screenSM002.paymentCode = null;
    MainData.screenSM002.planId = null;
    MainData.screenSM002.productName = "";
    MainData.screenSM002.planName = "";
    MainData.screenSM002.paymentName = "";
    MainData.screenSM002.cost = 0;
    MainData.screenSM002.costPayment = 0;
    MainData.screenSM002.paymentId = null;
    MainData.screenSM002.insureRequiredIdentification = true;
    MainData.screenSM002.insureSelfie = true;
    MainData.screenSM002.screen = "";
    this.props.setPage("1")
  }

  render(){
    return (
      <div className="container-fulid">
        <Row>
          <Col id="titelForScreen" xs={12} className="oppositeWhiteStep">
            <span className="font-weight-bold" dangerouslySetInnerHTML={{__html: this.props.subMenuName}}></span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>

          <Col xs={12} className="pt-2 pb-3 subFontFamily">
            <span className="font-weight-bold">{MainFunc.getLabelDtos("L0030")}</span>
            <form noValidate autoComplete="off" className="pt-3">
              <FormControl className="w-100" error={this.state.validation&&(!this.state.titleName||!MainData.initialData.title.find(item=>item.titleTitles == this.state.titleName))?true:false}>
                <InputLabel id="titleNameLabel">{MainFunc.getLabelDtos("L0031")}<span className="text-danger">*</span></InputLabel>
                <Select labelId="titleNameLabel" name="titleName" className={!this.state.titleName?"text-placeholder":null} value={!this.state.titleName?"none":this.state.titleName} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} error={this.state.validation&&this.state.fieldNotValid.includes('titleName')}>
                  <MenuItem value={"none"} hidden>{MainFunc.getLabelDtos("L0032")}</MenuItem>
                  {MainData.initialData.title.map((item, key)=>{
                    return(
                      <MenuItem key={key} value={item.titleTitles}>{item.titleTitles}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <TextField inputProps={{autocomplete: 'off'}} name="firstName" label={<div>{MainFunc.getLabelDtos("L0033")}<span className="text-danger">*</span></div>} placeholder={MainFunc.getLabelDtos("L0034")} value={this.state.firstName} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&this.state.fieldNotValid.includes('firstName')} disabled={MainData.screenMenuKPlus.onKPlus && MainData.screenSM008.firstName ? true:false}/>
              <TextField inputProps={{autocomplete: 'off'}} name="lastName" label={<>{MainFunc.getLabelDtos("L0035")}<span className="text-danger">*</span></>} placeholder={MainFunc.getLabelDtos("L0036")} value={this.state.lastName} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&this.state.fieldNotValid.includes('lastName')} disabled={MainData.screenMenuKPlus.onKPlus && MainData.screenSM008.lastName ? true:false}/>
              <TextField inputProps={{autocomplete: 'off'}} label={<>{MainFunc.getLabelDtos("L0750")}<span className="text-danger">*</span></>} value={MainData.screenSM001.birthdate} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" disabled={true}/>
              <TextField inputProps={{autocomplete: 'off'}} label={<>{MainFunc.getLabelDtos("L0751")}<span className="text-danger">*</span></>} value={MainData.screenSM006.phoneNumber} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" disabled={true}/>
              <TextField inputProps={{autocomplete: 'off'}} label={<>{MainFunc.getLabelDtos("L0752")}<span className="text-danger">*</span></>} value={MainData.screenSM005.idCard} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" disabled={true}/>
              {!MainData.screenMenuKPlus.onKPlus && (
                <TextField inputProps={{ autocomplete: 'off' }} name="laserId" label={<>{MainFunc.getLabelDtos("L0639")/* เลขหลังบัตรประชาชน (โปรดระบุ) */}<span className="text-danger">*</span></>} placeholder={MainFunc.getLabelDtos("L0640")/* เลขหลังบัตรประชาชน */} value={this.state.laserId} onChange={(e) => this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3 addMarginTop" error={this.state.validation && this.state.fieldNotValid.includes('laserId')} />
              )}
              
              { MainData.initialData.productId == 98 && (
                <Fragment>
              <FormControl className="w-100 mt-3" error={this.state.validation&&!this.state.titleNameEN?true:false}>
              <InputLabel id="titleNameENLabel">{MainFunc.getLabelDtos("L0429")}<span className="text-danger">*</span></InputLabel>
              <Select labelId="titleNameENLabel" name="titleNameEN" className={!this.state.titleNameEN?"text-placeholder":null} value={!this.state.titleNameEN?"none":this.state.titleNameEN} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} error={ this.state.validation && ( this.state.fieldNotValid.includes('titleNameEN') || !this.state.titleNameEN) } disabled={MainData.screenMenuKPlus.onKPlus && MainData.screenSM008.titleNameEN ? true :false}>
                <MenuItem value={"none"} hidden>{MainFunc.getLabelDtos("L0432")}</MenuItem>
                {MainData.initialData.titleEN.map((item, key)=>{
                  return(
                    <MenuItem key={key} value={item.titleTitles}>{item.titleTitles}</MenuItem>
                  )
                })}
              </Select>
            </FormControl> 

            <TextField inputProps={{autocomplete: 'off'}} name="firstNameEN" label={<>{MainFunc.getLabelDtos("L0430")}<span className="text-danger">*</span></>} placeholder={MainFunc.getLabelDtos("L0433")} value={this.state.firstNameEN} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&this.state.fieldNotValid.includes('firstNameEN')} disabled={MainData.screenMenuKPlus.onKPlus && MainData.screenSM008.firstNameEN ? true :false}/>
            <TextField inputProps={{autocomplete: 'off'}} name="lastNameEN" label={<>{MainFunc.getLabelDtos("L0431")}<span className="text-danger">*</span></>} placeholder={MainFunc.getLabelDtos("L0434")} value={this.state.lastNameEN} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&this.state.fieldNotValid.includes('lastNameEN')} disabled={MainData.screenMenuKPlus.onKPlus && MainData.screenSM008.lastNameEN ? true :false}/>
            </Fragment>
              )}      

              {/* ใส่เงื่อนไขกรณีที่ ผ่าน OCR จะ disable อาชีพ  */}
              <FormControl className="w-100 mt-3 detailJobBox" error={this.state.validation&&!this.state.job?true:false}>
              <InputLabel name="job" className="labelJobBox job">{MainFunc.getLabelDtos("L0041")}<span className="text-danger">*</span></InputLabel>
              <MAutocompleteInList choices={MainData.initialData.occupation?.filter((obj, index) => MainData.initialData.occupation.findIndex((item) => item.occupationCode === obj.occupationCode) === index).map(item => { return { value: item.occupationCode, name: item.occupationTitle } })} value={this.state.job} onChange={(val)=>{this.setState({job:val})}} disabled={false} error={this.state.validation&&this.state.fieldNotValid.includes('job')} placeholder={MainFunc.getLabelDtos("L0041")/*เลือกอาชีพ*/} onEnter={()=>{this.setState({job:"ข070008-000"}); setTimeout(() => {document.getElementById("jobDescription")?.focus();}, 100);}} matchJob={(val)=>{this.setState({job:val})}}/>
              </FormControl>
              <TextField inputProps={{autocomplete: 'off'}} name="jobDescription" id="jobDescription" label={<>{MainFunc.getLabelDtos("L0042")}<span className="text-danger">*</span></>} placeholder={MainFunc.getLabelDtos("L0043")} value={this.state.jobDescription} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&this.state.fieldNotValid.includes('jobDescription')} disabled={!MainData.initialData.occupation?.find(item=>item.occupationCode === this.state.job) ?true:false} />
              <TextField inputProps={{autocomplete: 'off'}} name="email" label={<>{MainFunc.getLabelDtos("L0044")}<span className="text-danger">*</span></>} placeholder={MainFunc.getLabelDtos("L0045")} value={this.state.email} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3 addMarginTop" error={this.state.validation&&!this.validateEmail(this.state.email)} />
            </form>
          </Col>

          {!this.props.next?null:
            <Col xs={!this.props.prev?12:12} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pl-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-5_Click_BtnNext":"STEP2-4_Click_BtnNext"}`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processNext() }}>{MainFunc.getLabelDtos("L0021")}</Button>
            </Col>
          }
        </Row>
        <SweetAlert 
            show={this.state.isErrorBirthday} 
            title={""} 
            customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} 
            custom 
            onConfirm={() => {this.setState({isErrorBirthday: false});}} 
            showCancel 
            closeOnClickOutside={false} 
            customButtons={
              <Row className="twob">
                <Col xs={6}><Button onClick={()=>this.skipFirthPage()} className="subBtnRadius">{MainFunc.getLabelDtos("L0151")}</Button></Col>
                <Col xs={6}><Button onClick={() => {this.setState({isErrorBirthday: false});}} className="mainBtnRadius">{MainFunc.getLabelDtos("L0188") /** ปิด */}</Button></Col>
              </Row>
            } 
          >
              <h5 className="" dangerouslySetInnerHTML={{ __html: MainFunc.getLabelDtos("L0165") }}></h5><span className="text--muted" dangerouslySetInnerHTML={{ __html: this.state.isErrorBirthdayDesc}} ></span>
          </SweetAlert>
      </div>
    )
  }
}

export default SM008;