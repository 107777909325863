import React, { Component } from "react";
import styles from "../../styles/preDatePicker.module.scss";
import Input from "@material-ui/core/Input";
import IconErrorInput from "../../images/icon_error_input.svg";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import moment from "moment";
import { ReactComponent as IconDatePicker } from "../../images/table.svg";

class preDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      inputValue: "",
      id: this.props.id ? this.props.id : "",
      name: this.props.name ? this.props.name : "",
      type: this.props.type ? this.props.type : "text",
      placeholder: this.props.placeholder ? this.props.placeholder : "",
      maxlength: this.props.maxlength ? this.props.maxlength : "",
      hide: this.props.hide ? this.props.hide : false,
      label: this.props.label ? this.props.label : "",
      helpText: this.props.helpText ? this.props.helpText : "",
      openCalendar: false,
      openCalendarYear: false,
      openCalendarMonth: false,
      year: [],
      month: [
        {
          value: 1,
          text: "มกราคม",
        },
        {
          value: 2,
          text: "กุมภาพันธ์",
        },
        {
          value: 3,
          text: "มีนาคม",
        },
        {
          value: 4,
          text: "เมษายน",
        },
        {
          value: 5,
          text: "พฤษภาคม",
        },
        {
          value: 6,
          text: "มิถุนายน",
        },
        {
          value: 7,
          text: "กรกฎาคม",
        },
        {
          value: 8,
          text: "สิงหาคม",
        },
        {
          value: 9,
          text: "กันยายน",
        },
        {
          value: 10,
          text: "ตุลาคม",
        },
        {
          value: 11,
          text: "พฤศจิกายน",
        },
        {
          value: 12,
          text: "ธันวาคม",
        },
      ],
      day: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      dayTitle: [
        {
          th: "อา",
          enFull: "Sunday",
        },
        {
          th: "จ",
          enFull: "Monday",
        },
        {
          th: "อ",
          enFull: "Tuesday",
        },
        {
          th: "พ",
          enFull: "Wednesday",
        },
        {
          th: "พฤ",
          enFull: "Thursday",
        },
        {
          th: "ศ",
          enFull: "Friday",
        },
        {
          th: "ส",
          enFull: "Saturday",
        },
      ],
      selectYear: parseInt(moment().format("YYYY")),
      selectMonth: 1,
      selectDay: 1,
      selectYearOld: parseInt(moment().format("YYYY")),
      selectMonthOld: 1,
      selectDayOld: 1,
      selectStatus: false,
    };
  }
  componentDidMount() {
    let mapYear = [];
    let minAge = this.props.min ? this.props.min : 0;
    let maxAge = this.props.max ? this.props.max : 100;

    for (
      let i = parseInt(moment().add(-maxAge, "year").format("YYYY"));
      i <= parseInt(moment().add(-minAge, "year").format("YYYY"));
      i++
    ) {
      mapYear.push(i);
    }
    this.setState({
      year: mapYear,
      selectYear:mapYear[mapYear.length-1]
    });
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.value.length == 10) {
      this.setState({
        selectYear: parseInt(parseInt(prevProps.value.split("/")[2]) - 543),
        selectMonth: parseInt(prevProps.value.split("/")[1]),
        selectDay: parseInt(prevProps.value.split("/")[0]),
        selectYearOld: parseInt(parseInt(prevProps.value.split("/")[2]) - 543),
        selectMonthOld: parseInt(prevProps.value.split("/")[1]),
        selectDayOld: parseInt(prevProps.value.split("/")[0]),
        selectStatus: true,
      });
    } else {
      this.setState({
        selectStatus: false,
      });
    }
    let mapYear = [];
    let minAge = prevProps.min ? prevProps.min : 0;
    let maxAge = prevProps.max ? prevProps.max : 100;

    for (
      let i = parseInt(moment().add(-maxAge, "year").format("YYYY"));
      i <= parseInt(moment().add(-minAge, "year").format("YYYY"));
      i++
    ) {
      mapYear.push(i);
    }
    this.setState({
      year: mapYear,
    });
  }

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });
  };

  handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      const { inputValue, tags } = this.state;
      const newTags = [...tags, inputValue];
      this.setState({ tags: newTags, inputValue: "" });
    }
  };

  onClickDatePicker = (date) => {
    this.setState({
      openCalendar: false,
      selectDay: parseInt(date.format("D")),
    });
    this.props.onClickDatePicker(
      `${date.format("DD")}/${date.format("MM")}/${
        parseInt(date.format("YYYY")) + 543
      }`
    );
  };

  render() {
    const {
      tags,
      inputValue,
      id,
      type,
      placeholder,
      maxlength,
      hide,
      label,
      helpText,
      name,
    } = this.state;
    const { classes } = this.props;
    return (
      <div
        className={`${styles.preInput} ${
          this.props.selectPlan ? styles.selectPlan : ""
        }
        `}
      >
        {label && (
          <label className={styles.label}>
            {label}{" "}
            {this.props.required && <span className={styles.required}>*</span>}
          </label>
        )}
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() =>
            this.setState({
              openCalendar: false,
              openCalendarMonth: false,
              openCalendarYear: false,
              selectYear: this.state.selectYearOld,
              selectMonth: this.state.selectMonthOld,
              selectDay: this.state.selectDayOld,
            })
          }
        >
          <div className={styles.mainInputCalendar}>
            <Input
              className={`${styles.input}   ${
                this.props.selectPlan && this.props.value && !this.props.error
                  ? styles.haveData
                  : ""
              }
              ${this.props.error ? styles.error : ""}
              ${classes.root} `}
              id={id}
              label={label}
              type={type}
              name={name}
              placeholder={placeholder}
              value={this.props.value}
              onClick={() =>
                this.setState({
                  openCalendar: !this.state.openCalendar,
                  openCalendarMonth: false,
                  openCalendarYear: false,
                })
              }
              onChange={(event) => this.props.onChange(event)}
              autoComplete="off"
              maxlength={maxlength}
              hidden={hide}
              error={this.props.error}
              endAdornment={
                this.props.error ? (
                  <img className={styles.iconErrorInput} src={IconErrorInput} />
                ) : (
                  // <img className={styles.iconDatePicker} src={IconDatePicker} />
                  <IconDatePicker
                    className={styles.iconDatePicker}
                    fill={"var(--mainColor)"}
                  />
                )
              }
            />
            {this.state.openCalendar && (
              <div className={styles.calendar}>
                <div className={styles.calendarTitle}>
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() =>
                      this.setState({ openCalendarMonth: false })
                    }
                  >
                    <div className={styles.select}>
                      <div
                        onClick={() =>
                          this.setState({
                            openCalendarMonth: !this.state.openCalendarMonth,
                          })
                        }
                      >
                        {this.state.selectMonth && this.state.month.length > 0
                          ? this.state.month[this.state.selectMonth - 1].text
                          : this.state.month.length > 0
                          ? this.state.month[0].text
                          : ""}
                      </div>
                      {this.state.openCalendarMonth && (
                        <div className={`${styles.lists}`}>
                          {this.state.month.map((item) => (
                            <div
                              className={`${styles.item}  ${
                                this.state.selectMonth == item.value
                                  ? styles.active
                                  : ""
                              }`}
                              onClick={() => {
                                this.setState({
                                  selectMonth: item.value,
                                  openCalendarMonth: false,
                                });
                              }}
                            >
                              {item.text}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </ClickAwayListener>
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() =>
                      this.setState({ openCalendarYear: false })
                    }
                  >
                    <div className={styles.select}>
                      <div
                        onClick={(e) => {
                          this.setState({
                            openCalendarYear: !this.state.openCalendarYear,
                          });
                          if(!this.state.openCalendarYear){
                            setTimeout(() => {
                              document.getElementById("test").scrollTo({ top: (20*(this.state.year.findIndex(item => item == this.state.selectYear)))-100, behavior: "smooth" })
                            }, 100);
                          }
                        }}
                      >
                        {this.state.selectYear
                          ? this.state.selectYear + 543
                          : this.state.year.length > 0
                          ? this.state.year[this.state.year.length - 1] + 543
                          : ""}
                      </div>
                      {this.state.openCalendarYear && (
                        <div id="test" className={styles.lists}>
                          {this.state.year.map((item) => (
                            <div
                              className={`${styles.item}  ${
                                this.state.selectYear == item
                                  ? styles.active
                                  : ""
                              }`}
                              onClick={() => {
                                this.setState({
                                  selectYear: item,
                                  openCalendarYear: false,
                                });
                              }}
                            >
                              {item + 543}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </ClickAwayListener>
                </div>
                <div className={styles.calendarTitleDay}>
                  {this.state.dayTitle.length > 0 &&
                    this.state.dayTitle.map((item) => <div>{item.th}</div>)}
                </div>
                <div className={styles.calendarDay}>
                  {this.state.day.map((item) => {
                    let dayActive =
                      this.state.selectStatus &&
                      item == this.state.selectDayOld &&
                      this.state.selectMonth == this.state.selectMonthOld &&
                      this.state.selectYear == this.state.selectYearOld
                        ? true
                        : false;
                    if (item == 1) {
                      let mapBreak = false;
                      let day = this.state.dayTitle.map((supItem) => {
                        if (!mapBreak) {
                          if (
                            moment(
                              `${supItem.enFull}, ${this.state.selectMonth} ${item}, ${this.state.selectYear}`
                            )
                              .locale("en")
                              .format("dddd") == supItem.enFull
                          ) {
                            mapBreak = true;
                            return (
                              <div className={dayActive ? styles.active : ""}>
                                <div
                                  onClick={() =>
                                    this.onClickDatePicker(
                                      moment([
                                        parseInt(this.state.selectYear),
                                        parseInt(this.state.selectMonth) - 1,
                                        parseInt(item),
                                      ])
                                    )
                                  }
                                >
                                  {item}
                                </div>
                              </div>
                            );
                          } else {
                            return <div></div>;
                          }
                        }
                      });
                      return day;
                    } else {
                      var m = moment([
                        parseInt(this.state.selectYear),
                        parseInt(this.state.selectMonth) - 1,
                        parseInt(item),
                      ]).format("M");
                      var y = moment([
                        parseInt(this.state.selectYear),
                        parseInt(this.state.selectMonth) - 1,
                        parseInt(item),
                      ]).format("YYYY");
                      if (
                        parseInt(m) == this.state.selectMonth &&
                        parseInt(y) == this.state.selectYear
                      ) {
                        return (
                          <div className={dayActive ? styles.active : ""}>
                            <div
                              onClick={() =>
                                this.onClickDatePicker(
                                  moment([
                                    parseInt(this.state.selectYear),
                                    parseInt(this.state.selectMonth) - 1,
                                    parseInt(item),
                                  ])
                                )
                              }
                            >
                              {item}
                            </div>
                          </div>
                        );
                      }
                    }
                  })}
                </div>
              </div>
            )}
          </div>
        </ClickAwayListener>
        <p
          className={`${styles.textRequired} ${
            this.props.selectPlan ? styles.selectPlan : ""
          }
        `}
          hidden={!this.props.error}
        >
          {this.props.helpText}
        </p>
      </div>
    );
  }
}

const style = {
  root: {
    "& .MuiInputBase-input": {
      fontFamily: "var(--mainFontFamily) !important",
      color: "var(--mainColor)",
      "&::placeholder": {
        "@media (max-width: 374px)": {
          fontSize: "11px",
        },
      },
    },
  },
};

export default withStyles(style)(preDatePicker);
