import React, { Component } from "react";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
import SweetAlert from 'react-bootstrap-sweetalert';
import {Row, Col, Button, Form} from "react-bootstrap";
import axios from "axios";
import styles from "../../styles/ScreenMenu015.module.scss";
class SM011 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM027",
      confirmModal: false,
      agreeTerms: MainData.screenSM015.agreeTerms,
    };
  }

  componentDidMount(){
    this.props.loadController(false)
    if(!MainData.initialData.confirmMessage && !MainData.initialData.importantInformation){
      this.props.setPage(this.props.next)
    }
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
  }

  agreeConfirmMessage = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    MainFunc.sendLogToService(this.props.name, 'Accept', 'Success', 'เก็บการดูข้อมูลคำยินยอมและถ้อยแถลง และข้อมูลสำคัญ', { }, { agree: true });
    this.props.setPage(this.props.next)
    MainData.screenSM015.agreeTerms = "checked";
  }
  setAgreeTerms = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    MainFunc.sendDataToGTMKbank({
      'event': 'track_event',
      'event_category': 'link_click',
      'event_action': 'click',
      'event_label': 'ย้อนกลับ',
      'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
      'flag_page' : 'onboard13'
     });
    MainData.screenSM015.agreeTerms = "";
    this.props.setPage(this.props.prev)
  }

  render(){
    return (
      <div className="container-fulid">
        <Row>
          <Col id="titelForScreen" xs={12} className="oppositeWhiteStep">
            <span className="font-weight-bold" dangerouslySetInnerHTML={{__html: this.props.subMenuName}}></span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>

            <Col xs={12} className="pt-2 pb-3">
              <span className="text-html-alarm subFontFamily pt-1" dangerouslySetInnerHTML={{__html:MainFunc.getConfigDtos("CF027")}}></span>
            </Col>
          
          <Col xs={12} className=" pt-3 pb-1">
            <Form.Group controlId="terms">
                    <Form.Check checked={this.state.agreeTerms} onChange={(e)=>{this.setState({agreeTerms: e.target.checked})}} type="checkbox"  custom style={{display: 'initial'}} />
            <span className="font-weight-bold pt-1 subFontFamily" >{MainFunc.getLabelDtos("L0443")}</span>
            </Form.Group>
          </Col>
          
          {!this.props.prev?null:
            <Col xs={!this.props.next?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pr-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-10_Click_BtnBack":"STEP2-10_Click_BtnBack"}`} variant="" className="subBtnRadius font-weight-bold" onClick={() => this.setAgreeTerms()}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
          {!this.props.next?null:
            <Col xs={!this.props.prev?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pl-2"}>
              <Button 
                id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-10_Click_BtnConfirm":"STEP2-10_Click_BtnConfirm"}`} 
                variant="" 
                className="mainBtnRadius font-weight-bold" 
                onClick={() => { 
                  this.setState({confirmModal: true});
                  MainFunc.sendDataToGTMKbank({
                    'event': 'track_event',
                    'event_category': 'link_click',
                    'event_action': 'click',
                    'event_label': 'step_success',
                    'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
                    'flag_page' : 'onboard13',
                    'flag_verify' : 'y' 
                   });
                }} 
                disabled={!this.state.agreeTerms}
              >
                {MainFunc.getLabelDtos("L0150")}
              </Button>
            </Col>
          }
        </Row>
        <SweetAlert  
          title={MainFunc.getLabelDtos("L0427")}
          onConfirm={()=>{this.processNext()}} 
          onCancel={() => {this.setState({confirmModal: false})}}
          show={this.state.confirmModal}
          customButtons={
            <React.Fragment>
              <Row className="twob">
                <Col xs={6} className={styles.btnBox}>
                  <Button 
                    id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-10_Click_BtnModalBack":"STEP2_Click_Modal_BtnBack"}`} 
                    variant="" 
                    className="subBtnRadius font-weight-bold" 
                    onClick={() => {
                      this.setState({confirmModal: false});
                      MainFunc.sendDataToGTMKbank({
                        'event': 'track_event',
                        'event_category': 'link_click',
                        'event_action': 'click',
                        'event_label': 'ย้อนกลับ',
                        'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
                        'flag_page' : 'onboard14'
                       });
                    }}
                  >
                    {MainFunc.getLabelDtos("L0020")}
                  </Button>
                </Col>
                <Col xs={6} className={styles.btnBox}>
                  <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-10_Click_BtnModalConfirm":"STEP2_Click_Modal_BtnConfirm"}`} variant="" className="mainBtnRadius font-weight-bold" onClick={()=>{this.agreeConfirmMessage()}}>{MainFunc.getLabelDtos("L0150")}</Button>
                </Col>
              </Row>
            </React.Fragment>
          }
        >
          <div className="subFontFamily">{!MainData.masterMessage.find(x=>x.msgCode === 'MSG014')?"ยืนยันข้อมูลซื้อประกัน หลังจากคลิกยืนยันแล้ว จะไม่สามารถย้อนกลับมาแก้ไขข้อมูลได้":MainData.masterMessage.find(x=>x.msgCode === 'MSG014').msgDescription}</div>
        </SweetAlert>
      </div>
    )
  }
}

export default SM011;