import axios from "axios";
import React, { Component, Fragment } from "react";
import { GoAlert } from "react-icons/go";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Image, Button, Card } from "react-bootstrap";
import SyncLoader from "../../customLib/react-spinners/SyncLoader";
import { isBrowser } from "react-device-detect";
import { AiFillCloseCircle } from "react-icons/ai";
import parse from "html-react-parser";

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

import IcoSuccessPayment from "../../images/ico_success_payment.svg";
import APP_Deep_Linked_AW_Thank_You_Page from "../../images/APP_Deep_Linked_AW_Thank_You_Page.png";

import IcoCrossSign from "../../images/ico_closesign.png";
import KbankHeaderBusiness from "../Broker/KbankHeaderBusiness";

import SelectAPPorCCP from "./selectAPPorCCP";
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conetntHeight: 0,
      screen: "SM013",
      isLoading: false,
      isLoadingPage: true,
      serviceError: false,
      serviceKPayPlusError: false,
      cusId: "",
      logo: "",
      token: "",
      channel: { name: "", css: null, logo: null, font: null },
      detailInsuranceTime: 1,
      detailPremium: 1500.0,
      detailFirstName: "ชื่อจริง",
      detailLastName: "นามสกุล",
      paymentChannelCode: "kbank",
      detailSumInsured: 40000000,
      detailDateStartCover: "2021-01-15",
      detailProductName: "product_name",
      paymentTime: "2021-01-15 17:12:00",
      detailApplicationNumber: "64xxxxxxxx",
      paymentChannelName: "",
      isTaxConsent: false,
      userId: [],
      connectionId: [],
      serviceKPayPlusError1Code: "",
      noPayment: false,
      cookieCode: "",
      cookieDetail: "",
      cookieDescription: "",
      cookieDescriptionCheck: false,
      cookieVersionDisplay: "",
      cookieVersionOrder: 0,
      _cookie: "",
      channelCodeIni: "",
      productIdIni: "",
      sourceIni: "",
      utm_mediumIni: "",
      utm_campaignIni: "",
      utm_contentIni: "",
      utm_termIni: "",
      cIni: "",
      errMsg: "",
      errSubMsg: "",
      isError: false,
      isErrorCreate: false,
      device: "",
      appStatus: false,
      appStatusMsgCode: false,
      channelCodeSlug: "",
      productIdSlug: "",
      initialSalesStatus: false,
      channelName: "",
      screenHeight: null,
      ssrRuning: true,
      cTBAppCcpStatus: null,
    };
  }

  componentDidMount() {
    this.setState({
      token: /token=([^&]+)/.exec(window.location.href)
        ? /token=([^&]+)/.exec(window.location.href)[1]
        : null,
      channelCodeIni: /channelCode=([^&]+)/.exec(
        sessionStorage.getItem("homePage")
      )
        ? /channelCode=([^&]+)/.exec(sessionStorage.getItem("homePage"))[1]
        : "",
      productIdIni: /productId=([^&]+)/.exec(sessionStorage.getItem("homePage"))
        ? /productId=([^&]+)/.exec(sessionStorage.getItem("homePage"))[1]
        : "",
      sourceIni: /utm_source=([^&]+)/.exec(sessionStorage.getItem("homePage"))
        ? /utm_source=([^&]+)/.exec(sessionStorage.getItem("homePage"))[1]
        : "",
      utm_mediumIni: /utm_medium=([^&]+)/.exec(
        sessionStorage.getItem("homePage")
      )
        ? /utm_medium=([^&]+)/.exec(sessionStorage.getItem("homePage"))[1]
        : "",
      utm_campaignIni: /utm_campaign=([^&]+)/.exec(
        sessionStorage.getItem("homePage")
      )
        ? /utm_campaign=([^&]+)/.exec(sessionStorage.getItem("homePage"))[1]
        : "",
      utm_contentIni: /utm_content=([^&]+)/.exec(
        sessionStorage.getItem("homePage")
      )
        ? /utm_content=([^&]+)/.exec(sessionStorage.getItem("homePage"))[1]
        : "",
      utm_termIni: /utm_term=([^&]+)/.exec(sessionStorage.getItem("homePage"))
        ? /utm_term=([^&]+)/.exec(sessionStorage.getItem("homePage"))[1]
        : "",
      cIni: /c=([^&]+)/.exec(sessionStorage.getItem("homePage"))
        ? /c=([^&]+)/.exec(sessionStorage.getItem("homePage"))[1]
        : "",
      _cookie: sessionStorage.getItem("_cookie"),
      cusId: sessionStorage.getItem("cusId"),
      screenHeight: window.innerHeight,
      ssrRuning: false,
    });
    setTimeout(() => {
      if (this.state.token) {
        sessionStorage.setItem("cusId", this.state.token);
        if (!sessionStorage.getItem("MainData")) {
          if (!sessionStorage.getItem("homePage")) {
            let ualparameter = `?channelCode=${
              /channelCode=([^&]+)/.exec(window.location.href)
                ? /channelCode=([^&]+)/.exec(window.location.href)[1]
                : ""
            }`;
            ualparameter += `&productId=${
              /productId=([^&]+)/.exec(window.location.href)
                ? /productId=([^&]+)/.exec(window.location.href)[1]
                : ""
            }`;
            window.location.href =
              window.location.origin +
              MainData.mainPath +
              "checkout/" +
              ualparameter;
            sessionStorage.setItem(
              "homePage",
              window.location.origin + MainData.mainPath + ualparameter
            );
          }
          window.location.href = MainData.mainPath + "checkout";
        } else {
          window.location.href = MainData.mainPath + "checkout";
        }
      } else {
        if (sessionStorage.getItem("MainData")) {
          MainFunc.SetMainDataFromStorage();
        }
        if (window.location.origin.includes("localhost")) {
          MainData.serviceURL = `https://kbankonlinesale-uat.muangthai.co.th${MainData.servicePath}api`;
        } else {
          MainData.serviceURL =
            window.location.origin + `${MainData.servicePath}api`;
        }
        // this.getData();
        if (this.state.channelCodeIni) {
          this.getData();
        } else {
          let homePage = sessionStorage.getItem("homePage").split("?")[0];
          let slug = homePage
            .replace(window.location.origin + MainData.mainPath, "")
            .split("/");
          if (slug.length > 1 && slug[1]) {
            this.setState({ channelCodeSlug: slug[0], productIdSlug: slug[1] });
          } else {
            this.setState({ channelCodeSlug: "KBANK", productIdSlug: slug[0] });
          }
          this.getData();
        }
      }
    }, 100);
  }

  getCardInquiryData = (errorCreate) => {
    this.setState({
      appStatus: false,
      isLoading: true,
      isErrorCreate: false,
      cTBAppCcpStatus: null,
      appStatusMsgCode: "",
      initialSalesStatus: false,
    });
    const headers = {
      AuthId: this.state.cusId,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {};
    axios
      .post(MainData.serviceURL + "/Payment/GetInquiryData", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data;
        if (data.paymentStatus === true) {
          this.setState({
            paymentTime: data.paymentDetail.paymentTime,
            premium: data.paymentDetail.premium,
            paymentChannelCode: data.paymentDetail.paymentChannelCode,
            detailFirstName: data.paymentDetail.detail.firstName,
            detailLastName: data.paymentDetail.detail.lastName,
            detailApplicationNumber:
              data.paymentDetail.detail.applicationNumber,
            detailProductName: data.paymentDetail.detail.productName,
            detailSumInsured: data.paymentDetail.detail.sumInsured,
            detailInsuranceTime: data.paymentDetail.detail.insuranceTime,
            detailDateStartCover: data.paymentDetail.detail.dateStartCover,
            detailPremium: data.paymentDetail.detail.premium,
            paymentChannelName: data.paymentDetail.paymentChannelName,
            isLoadingPage: false,
            isLoading: false,
            isTaxConsent: data?.isTaxConsent,
          });
          let newValue = {
            paymentChannelName: data.paymentDetail.paymentChannelName,
            paymentDate: data.paymentDetail.paymentTime,
            applicationID: data.paymentDetail.detail.applicationNumber,
          };
          if (
            data.cTBAppCcpStatus.appStatus ||
            data.cTBAppCcpStatus.ccpStatus
          ) {
            this.setState({
              appStatus: true,
              appStatusMsgCode: data.messageCode,
              isLoadingPage: false,
              cTBAppCcpStatus: data.cTBAppCcpStatus,
              initialSalesStatus: true,
            });
            if (data.messageCode == "MSG194" || data.messageCode == "MSG193") {
              !errorCreate &&
                this.setState({
                  appStatus: true,
                  appStatusMsgCode: data.messageCode,
                  isLoadingPage: true,
                  initialSalesStatus: false,
                });
            }
          } else if (
            data.messageCode == "MSG186" ||
            data.messageCode == "MSG195" ||
            data.messageCode == "MSG196"
          ) {
            !errorCreate &&
              this.setState({
                appStatus: false,
                appStatusMsgCode: data.messageCode,
                isLoadingPage: true,
                initialSalesStatus: false,
              });
          } else {
            MainFunc.sendDataToGTMKbank({
              event: "track_event",
              event_category: "link_click",
              event_action: "click",
              event_label: "step_success",
              flag_kplus: MainData.screenMenuKPlus.onKPlus ? 1 : 0,
              flag_page: "onboard17",
            });
            if (data.messageCode == "MSG194" || data.messageCode == "MSG193") {
              !errorCreate &&
                this.setState({
                  appStatus: false,
                  appStatusMsgCode: data.messageCode,
                  isLoadingPage: true,
                  initialSalesStatus: false,
                });
            } else {
              this.setState({ initialSalesStatus: true });
            }
          }
          MainFunc.logPDPA(
            "Add",
            "การชำระเงิน",
            "จ่ายเงินค่าเบี้ยประกัน",
            null,
            null,
            newValue,
            "Success"
          );
        } else {
          this.setState({ serviceError: true, isLoading: false });
          let newValue = {
            paymentChannelName: data.paymentDetail.paymentChannelName,
            paymentDate: data.paymentDetail.paymentTime,
            applicationID: data.paymentDetail.detail.applicationNumber,
          };
          MainFunc.logPDPA(
            "Add",
            "การชำระเงิน",
            "จ่ายเงินค่าเบี้ยประกัน",
            null,
            null,
            null,
            "Fail",
            MainData.masterMessage.find((x) => x.msgCode === "MSG016")
              .msgDescription
          );
        }
      })
      .catch((error) => {
        this.setState({
          serviceError: true,
          serviceKPayPlusError: true,
          isLoading: false,
        });
        MainFunc.logPDPA(
          "Add",
          "การชำระเงิน",
          "จ่ายเงินค่าเบี้ยประกัน",
          null,
          null,
          null,
          "Fail",
          "ชำระเงินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        );
        sessionStorage.removeItem("bearerAuth");
      });
  };

  getData = async (lang) => {
    this.setState({ device: await this.getMobileOperatingSystem() });
    this.setState({ _cookie: "true", isLoading: true });
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      lang: lang === "en" ? "EN" : "TH",
      channelCode: this.state.channelCodeIni,
      productId: this.state.productIdIni,
      utm_source: this.state.sourceIni,
      utm_medium: this.state.utm_mediumIni,
      utm_campaign: this.state.utm_campaignIni,
      utm_content: this.state.utm_contentIni,
      utm_term: this.state.utm_termIni,
      c: this.state.cIni,
      transUrl: window.location.href,
      deviceType: this.state.device,
      channelCodeSlug: this.state.channelCodeSlug,
      productIdSlug: this.state.productIdSlug,
    };
    axios
      .post(MainData.serviceURL + "/Sales/InitialSales", data, {
        headers: headers,
      })
      .then(async (res) => {
        if (res.status === 299) {
          this.setState({ _cookie: "true" });
        } else {
          let data = res?.data?.data;
          if (data?.cookies?.cookieDetail != null) {
            this.setState({
              cookieCode: data.cookies.cookieCode,
              cookieVersionDisplay: data.cookies.cookieVersionDisplay,
              cookieVersionOrder: data.cookies.cookieVersionOrder,
              cookieDetail: data.cookies.cookieDetail,
              cookieDescription: data.cookies.cookieDescription,
            });
            sessionStorage.setItem("_cookie", "");
            this.setState({ _cookie: "" });
          } else {
            sessionStorage.setItem("_cookie", "true");
            this.setState({ _cookie: "true" });
          }
          var head = document.getElementsByTagName("head")[0];
          var link = document.createElement("link");
          link.id = "customCss";
          link.rel = "stylesheet";
          link.type = "text/css";
          link.href = data.channel.css;
          link.media = "all";
          head.appendChild(link);
          sessionStorage.setItem("customCss", data.channel.css);
          sessionStorage.setItem("channelLogo", data.channel.logo);
          sessionStorage.setItem("channelName", data.channelCode);
          MainData.initialData.labelDtos = data.labelDtos;
          MainData.initialData.bearerAuth = data.authKey;
          MainData.initialData.channelCode = data.channelCode;
          MainData.initialData.configDtos = data.configDtos;
          this.setState({
            logo: data.channel.logo,
            channel: data.channel,
            channelName: data.channelCode,
          });
          if (MainData.isPRD || data.channelCode == "KBANK") {
            MainFunc.SetScriptGTM(data.channelCode);
          }
          axios
            .get(MainData.serviceURL + "/MasterData/getMessage", {
              headers: {
                Authorization: `Bearer ${data.authKey}`,
              },
            })
            .then((res) => {
              if (res.status === 299) {
                window.location.href = MainData.mainPath + "shutdown";
                return;
              }
              let newmasterDataRes = res.data.data;
              if (!newmasterDataRes) {
                this.setState({ isLoading: false, serviceError: true });
                this.errorAlert(
                  true,
                  "พบปัญหาในการเรียกใช้งาน service กรุณาลองใหม่อีกครั้งครับ/ค่ะ",
                  " "
                );
                return;
              }
              MainData.masterMessage = newmasterDataRes;
              if (sessionStorage.getItem("submitOrderPayment")) {
                let submitOrderPayment = JSON.parse(
                  sessionStorage.getItem("submitOrderPayment")
                )?.paymentDetail;
                this.setState({
                  paymentTime: submitOrderPayment.paymentTime,
                  premium: submitOrderPayment.premium,
                  paymentChannelCode: submitOrderPayment.paymentChannelCode,
                  detailFirstName: submitOrderPayment.detail.firstName,
                  detailLastName: submitOrderPayment.detail.lastName,
                  detailApplicationNumber:
                    submitOrderPayment.detail.applicationNumber,
                  detailProductName: submitOrderPayment.detail.productName,
                  detailSumInsured: submitOrderPayment.detail.sumInsured,
                  detailInsuranceTime: submitOrderPayment.detail.insuranceTime,
                  detailDateStartCover:
                    submitOrderPayment.detail.dateStartCover,
                  detailPremium: submitOrderPayment.detail.premium,
                  paymentChannelName: submitOrderPayment.paymentChannelName,
                  isLoadingPage: false,
                  isLoading: false,
                  noPayment: true,
                  isTaxConsent: JSON.parse(
                    sessionStorage.getItem("submitOrderPayment")
                  )?.isTaxConsent,
                });
                let newValue = {
                  paymentChannelName: submitOrderPayment.paymentChannelName,
                  paymentDate: submitOrderPayment.paymentTime,
                  applicationID: submitOrderPayment.detail.applicationNumber,
                };
                MainFunc.logPDPA(
                  "Add",
                  "การชำระเงิน",
                  "จ่ายเงินค่าเบี้ยประกัน",
                  null,
                  null,
                  newValue,
                  "Success",
                  null
                );
                this.setState({ initialSalesStatus: true });
              } else {
                this.getCardInquiryData();
              }
            })
            .catch((masterDataError) => {
              this.setState({ isLoading: false, serviceError: true });
              this.errorAlert(
                true,
                "พบปัญหาในการเรียกใช้งาน service กรุณาลองใหม่อีกครั้งครับ/ค่ะ",
                " "
              );
              this.setState({ initialSalesStatus: true });
            });
        }
      })
      .catch((error) => {
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
        this.setState({
          isLoading: false,
          serviceError: true,
          initialSalesStatus: true,
        });
      });
  };

  goToWeb = () => {
    window.open("https://sl.muangthai.co.th/s/PyVmCTQu", "_blank");
  };

  goToAndroid = () => {
    if (isBrowser) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.thmtlphoenix",
        "_blank"
      );
    } else {
      setTimeout(function () {
        window.open(
          "market://details?id=com.thmtlphoenix",
          "_system",
          "location=no"
        );
      }, 25);
      window.open("phoenix://healthcare", "_system", "location=no");
    }
  };

  goToiOS = () => {
    if (isBrowser) {
      window.open("https://apps.apple.com/th/app/id1440560140", "_blank");
    } else {
      setTimeout(function () {
        window.open(
          "itms-apps://itunes.apple.com/th/app/mtl-click/id1440560140",
          "_system",
          "location=no"
        );
      }, 25);
      window.open("phoenix://", "_system", "location=no");
    }
  };

  openCookieDescription = () => {
    this.setState({ cookieDescriptionCheck: true });
  };

  closeCookie = () => {
    sessionStorage.setItem("_cookie", "close");
    this.setState({ _cookie: "close" });
  };

  SaveCookie = () => {
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      cusId: null,
      cookieCode: this.state.cookieCode,
      cookieVersionDisplay: this.state.cookieVersionDisplay,
      cookieVersionOrder: this.state.cookieVersionOrder,
      channelCode: this.state.channelName,
    };
    axios
      .post(MainData.serviceURL + "/Customer/AddCustomerCookie", data, {
        headers: headers,
      })
      .then(async (res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        if (!res.data.success) {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  "AddCustomerCookie"
                ),
            " "
          );
          this.setState({ isLoading: false });
        } else {
          sessionStorage.setItem("_cookie", "true");
          this.setState({ _cookie: "true" });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  "AddCustomerCookie"
                ),
            " "
          );
          this.setState({ isLoading: false });
        } else {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
          this.setState({ isLoading: false });
        }
      });
  };

  errorAlert = (isErr, msg, subMsg) => {
    this.setState({
      isError: isErr,
      errMsg: msg,
      errSubMsg: !subMsg ? null : subMsg,
      appStatus: false,
      isLoading: false,
      initialSalesStatus: false,
    });
  };

  errorConfirm = () => {
    this.setState({
      isError: false,
      errMsg: "ERROR!",
      initialSalesStatus: true,
    });
  };

  backFirthPages = () => {
    MainFunc.sendDataToGTMKbank({
      event: "track_event",
      event_category: "link_click",
      event_action: "click",
      event_label: "กลับสู่หน้าแรก",
      flag_kplus: MainData.screenMenuKPlus.onKPlus ? 1 : 0,
      flag_page: "onboard17",
    });
    sessionStorage.removeItem("MainData");
    sessionStorage.removeItem("paymentError");
    sessionStorage.removeItem("seqPage");
    if (this.state.channelName == "LINEBK") {
      window.location.href = sessionStorage.getItem("homePage");
    } else {
      let ualLanding = "";
      if (this.state.channelCodeIni) {
        ualLanding = `?channelCode=${this.state.channelCodeIni}&productId=${this.state.productIdIni}`;
      }
      if (this.state.sourceIni) {
        ualLanding += `&utm_source=${this.state.sourceIni}`;
      }
      if (this.state.utm_mediumIni) {
        ualLanding += `&utm_medium=${this.state.utm_mediumIni}`;
      }
      if (this.state.utm_campaignIni) {
        ualLanding += `&utm_campaign=${this.state.utm_campaignIni}`;
      }
      if (this.state.utm_contentIni) {
        ualLanding += `&utm_content=${this.state.utm_contentIni}`;
      }
      if (this.state.utm_termIni) {
        ualLanding += `&utm_term=${this.state.utm_termIni}`;
      }
      if (this.state.cIni) {
        ualLanding += `&c=${this.state.cIni}`;
      }
      if (this.state.productIdSlug) {
        window.location.href =
          window.location.origin +
          MainData.mainPath +
          `${
            this.state.channelCodeSlug == "KBANK"
              ? ""
              : this.state.channelCodeSlug + "/"
          }${this.state.productIdSlug + "/"}${ualLanding.replace("&", "?")}`;
      } else {
        window.location.href =
          window.location.origin + MainData.mainPath + "" + ualLanding;
      }
    }
  };
  getMobileOperatingSystem = async () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "Browser";
  };

  createAPP = () => {
    this.setState({ isLoading: true });
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      AuthId: this.state.cusId,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {};
    axios
      .post(MainData.serviceURL + "/ctbapp/create", data, {
        headers: headers,
      })
      .then((res) => {
        let data = res?.data?.data;
        if (res.data.success === true) {
          if (data?.appUrl) {
            window.location.href = data.appUrl;
          }
        } else if (data.messageCode == "MSG186") {
          this.setState({
            appStatus: false,
            appStatusMsgCode: data.messageCode,
            isLoadingPage: true,
            isLoading: false,
            initialSalesStatus: false,
          });
        } else if (
          data.messageCode == "MSG193" ||
          data.messageCode == "MSG194"
        ) {
          this.setState({
            isErrorCreate: true,
            appStatusMsgCode: data.messageCode,
            isLoadingPage: true,
            isLoading: false,
            initialSalesStatus: false,
          });
        } else {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === data.messageCode
          );
          this.errorAlert(true, "", errMsg.msgDescription);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        if (error?.response?.status == 400) {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  "AddCustomerCookie"
                ),
            " "
          );
          this.setState({ isLoading: false });
        } else {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
          this.setState({ isLoading: false });
        }
      });
  };

  createCCP = () => {
    this.setState({ isLoading: true });
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      AuthId: this.state.cusId,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {};
    axios
      .post(MainData.serviceURL + "/ctbccp/create", data, {
        headers: headers,
      })
      .then((res) => {
        let data = res?.data?.data;
        if (res.data.success === true) {
          if (data?.ccpUrl) {
            window.location.href = data.ccpUrl;
          }
        } else if (data.messageCode == "MSG196") {
          this.setState({
            appStatus: false,
            appStatusMsgCode: data.messageCode,
            isLoadingPage: true,
            isLoading: false,
            initialSalesStatus: false,
          });
        } else if (
          data.messageCode == "MSG193" ||
          data.messageCode == "MSG194"
        ) {
          this.setState({
            isErrorCreate: true,
            appStatusMsgCode: data.messageCode,
            isLoadingPage: true,
            isLoading: false,
            initialSalesStatus: false,
          });
        } else {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === data.messageCode
          );
          this.errorAlert(true, "", errMsg.msgDescription);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        if (error?.response?.status == 400) {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  "AddCustomerCookie"
                ),
            " "
          );
          this.setState({ isLoading: false });
        } else {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
          this.setState({ isLoading: false });
        }
      });
  };

  submitPayment = (timeout) => {
    this.setState({
      appStatus: false,
      appStatusMsgCode: "",
      isLoading: false,
      isLoadingPage: false,
      initialSalesStatus: true,
      isErrorCreate: false,
    })
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      AuthId: this.state.cusId,
      action: timeout ? "false" : "true",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {};
    axios
      .post(MainData.serviceURL + "/payment/confirm", data, {
        headers: headers,
      })
      .then((res) => {})
      .catch((error) => {});
    MainFunc.sendDataToGTMKbank({
      event: "track_event",
      event_category: "link_click",
      event_action: "click",
      event_label: "step_success",
      flag_kplus: MainData.screenMenuKPlus.onKPlus ? 1 : 0,
      flag_page: "onboard17",
    });
  };

  returnToThePaymentPage = () => {
    MainFunc.sendDataToGTMKbank({
      event: "track_event",
      event_category: "link_click",
      event_action: "click",
      event_label: "step_fail",
      flag_kplus: MainData.screenMenuKPlus.onKPlus ? 1 : 0,
      flag_page: "onboard15",
      flag_verify: "",
      promotion_code: MainData.screenSM013.promotion_code,
      referral_code: MainData.screenSM013.referral_code,
      agent_code: MainData.screenSM013.agent_code,
    });
    sessionStorage.setItem("MainData", JSON.stringify(MainData));
    sessionStorage.setItem("paymentError", "true");
    window.location.href = sessionStorage.getItem("homePage");
  };

  loadController = (isLoad) => {
    this.setState({ isLoading: isLoad });
  };

  render() {
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            className="w-100 h-100 d-flex justify-content-center align-items-center"
            style={{
              zIndex: "99",
              position: "fixed",
              backgroundColor: "white",
              left: 0,
              top: 0,
            }}
          >
            <div
              className="w-100 h-100 d-flex justify-content-center align-items-center mainLoading"
              style={{ zIndex: "99", position: "fixed" }}
            >
              <Row>
                <Col xs={12} className="w-100 d-flex justify-content-center">
                  <h3 className="csMainColor">Loading</h3>
                </Col>
                <Col xs={12} className="w-100 d-flex justify-content-center">
                  <SyncLoader size={15} loading={true} />
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
        {this.state._cookie === "true"
          ? null
          : [
              this.state._cookie === "close" ? null : (
                <div
                  className="text-white w-100 d-flex justify-content-center"
                  style={{
                    zIndex: "5000",
                    position: "fixed",
                    bottom: 0,
                    backgroundColor: "rgba(0,0,0,0.8)",
                    left: 0,
                  }}
                >
                  <Image
                    src={this.state.ssrRuning ? "" : IcoCrossSign}
                    rounded
                    style={{
                      position: "absolute",
                      right: "0",
                      padding: "10px",
                      filter: "opacity(0.4) drop-shadow(0 0 0 #DDDEE0)",
                      width: "30px",
                      height: "30px",
                    }}
                    onClick={() => this.closeCookie()}
                  ></Image>
                  <div className="pl-3 pr-3 pb-4 pt-3 mt-4">
                    <span className="justify-content-center cookieDetail">
                      {parse(
                        this.state.cookieDetail ? this.state.cookieDetail : ""
                      )}
                    </span>
                    <span
                      className="spanLink"
                      onClick={() => this.openCookieDescription()}
                    >
                      {MainFunc.getLabelDtos("L0440")}
                    </span>
                    <div className="w-100 d-flex justify-content-center pt-2">
                      <Button
                        variant="info pl-4 pr-4"
                        style={{ borderRadius: "25px" }}
                        onClick={() => {
                          this.SaveCookie();
                        }}
                      >
                        ยอมรับ
                      </Button>
                    </div>
                  </div>
                </div>
              ),
            ]}
        {this.state.appStatus === true && this.state.isLoadingPage === true && (
          <div
            className="w-100 d-flex align-items-center justify-content-center"
            style={{ height: this.state.screenHeight }}
          >
            <SweetAlert
              show={!this.state.isError}
              title={""}
              customIcon={
                <GoAlert
                  size={70}
                  className="text-center w-100 text-danger pb-2"
                />
              }
              custom
              onConfirm={() => window.close()}
              showCancel
              closeOnClickOutside={false}
              customButtons={
                <Row className="twob">
                  <Fragment>
                    <Col xs={6} className="w-100 d-flex justify-content-center">
                      <Button
                        onClick={() => {
                          this.submitPayment();
                        }}
                        className="subBtnRadius"
                      >
                        {MainFunc.getLabelDtos("L0188") /* ปิด */}
                      </Button>
                    </Col>
                    <Col xs={6} className="w-100 d-flex justify-content-center">
                      <Button
                        onClick={() => {
                          this.setState({
                            appStatus: true,
                            isLoadingPage: false,
                            initialSalesStatus: true,
                          });
                          MainFunc.sendDataToGTMKbank({
                            event: "track_event",
                            event_category: "link_click",
                            event_action: "click",
                            event_label: "step_success",
                            flag_kplus: MainData.screenMenuKPlus.onKPlus
                              ? 1
                              : 0,
                            flag_page: "onboard17",
                          });
                        }}
                        className="mainBtnRadius"
                      >
                        {MainFunc.getLabelDtos("L0167") /* ดำเนินการต่อ */}
                      </Button>
                    </Col>
                  </Fragment>
                </Row>
              }
            >
              <h5
                className=""
                dangerouslySetInnerHTML={{
                  __html: MainData.masterMessage.find(
                    (x) => x.msgCode === this.state.appStatusMsgCode
                  ).msgDescription,
                }}
              ></h5>
            </SweetAlert>
            <SweetAlert
              show={this.state.isError}
              title={""}
              customIcon={
                <GoAlert
                  size={70}
                  className="text-center w-100 text-danger pb-2"
                />
              }
              custom
              onConfirm={() => this.errorConfirm}
              showCancel
              closeOnClickOutside={false}
              customButtons={
                <Row>
                  <Button
                    onClick={() => this.errorConfirm}
                    className="mainBtnRadius"
                  >
                    ปิด
                  </Button>
                </Row>
              }
            >
              <h5
                className=""
                dangerouslySetInnerHTML={{ __html: this.state.errMsg }}
              ></h5>
              <span
                className="text--muted"
                dangerouslySetInnerHTML={{
                  __html: !this.state.errSubMsg
                    ? "ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา"
                    : this.state.errSubMsg,
                }}
              ></span>
            </SweetAlert>
          </div>
        )}
        {this.state.appStatus === false &&
          this.state.isLoadingPage === true &&
          (this.state.appStatusMsgCode == "MSG193" ||
            this.state.appStatusMsgCode == "MSG194" ||
            this.state.appStatusMsgCode == "MSG186" ||
            this.state.appStatusMsgCode == "MSG195" ||
            this.state.appStatusMsgCode == "MSG196") && (
            <div
              className="w-100 d-flex align-items-center justify-content-center"
              style={{ height: this.state.screenHeight }}
            >
              <SweetAlert
                show={!this.state.isError}
                title={""}
                customIcon={
                  <GoAlert
                    size={70}
                    className="text-center w-100 text-danger pb-2"
                  />
                }
                custom
                onConfirm={() => window.close()}
                showCancel
                closeOnClickOutside={false}
                customButtons={
                  <Row className="twob">
                    <Fragment>
                      <Button
                        onClick={() => {
                          this.submitPayment();
                        }}
                        className="mainBtnRadius"
                      >
                        {MainFunc.getLabelDtos("L0167") /* ดำเนินการต่อ */}
                      </Button>
                    </Fragment>
                  </Row>
                }
              >
                <h5
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: MainData.masterMessage.find(
                      (x) => x.msgCode === this.state.appStatusMsgCode
                    ).msgDescription,
                  }}
                ></h5>
              </SweetAlert>
            </div>
          )}
        {this.state.isErrorCreate && (
          <div
            className="w-100 d-flex align-items-center justify-content-center"
            style={{ height: this.state.screenHeight }}
          >
            <SweetAlert
              show={true}
              title={""}
              customIcon={
                <GoAlert
                  size={70}
                  className="text-center w-100 text-danger pb-2"
                />
              }
              custom
              onConfirm={() => window.close()}
              showCancel
              closeOnClickOutside={false}
              customButtons={
                <Row className="twob">
                  <Fragment>
                    <Col xs={6} className="w-100 d-flex justify-content-center">
                      <Button
                        onClick={() => {
                          this.submitPayment();
                        }}
                        className="subBtnRadius"
                      >
                        {MainFunc.getLabelDtos("L0188") /* ปิด */}
                      </Button>
                    </Col>
                    <Col xs={6} className="w-100 d-flex justify-content-center">
                      <Button
                        onClick={() => {
                          this.getCardInquiryData(true);
                          MainFunc.sendDataToGTMKbank({
                            event: "track_event",
                            event_category: "link_click",
                            event_action: "click",
                            event_label: "step_success",
                            flag_kplus: MainData.screenMenuKPlus.onKPlus
                              ? 1
                              : 0,
                            flag_page: "onboard17",
                          });
                        }}
                        className="mainBtnRadius"
                      >
                        {MainFunc.getLabelDtos("L0167") /* ดำเนินการต่อ */}
                      </Button>
                    </Col>
                  </Fragment>
                </Row>
              }
            >
              <h5
                className=""
                dangerouslySetInnerHTML={{
                  __html: MainData.masterMessage.find(
                    (x) => x.msgCode === this.state.appStatusMsgCode
                  ).msgDescription,
                }}
              ></h5>
            </SweetAlert>
          </div>
        )}

        <SweetAlert
          show={this.state.cookieDescriptionCheck}
          title={""}
          onConfirm={() => {
            this.setState({ cookieDescriptionCheck: false });
          }}
          onCancel={() => {
            this.setState({ cookieDescriptionCheck: false });
          }}
          customButtons={<></>}
        >
          <div className="container-fulid">
            <Image
              src={this.state.ssrRuning ? "" : IcoCrossSign}
              rounded
              onClick={() => this.setState({ cookieDescriptionCheck: false })}
              style={{
                height: "10px",
                left: "0",
                position: "absolute",
                top: "0",
                right: "10px",
                fontSize: "2rem",
                width: 20,
                margin: 20,
                marginTop: 22,
              }}
            ></Image>
            <div
              onClick={() => this.setState({ cookieDescriptionCheck: false })}
              style={{
                position: "absolute",
                top: "0",
                right: "10px",
                fontSize: "2rem",
                color: "rgb(212, 212, 212)",
              }}
            >
              <AiFillCloseCircle />
            </div>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">นโยบายคุกกี้และการตั้งค่า</h4>` +
                  this.state.cookieDescription,
              }}
              style={{ textAlign: "left" }}
            ></span>
          </div>
        </SweetAlert>

        {this.state.serviceError && (
          <div
            className="w-100 d-flex align-items-center justify-content-center"
            style={{ height: this.state.screenHeight }}
          >
            <SweetAlert
              show={true}
              title={""}
              customIcon={
                <GoAlert
                  size={70}
                  className="text-center w-100 text-danger pb-2"
                />
              }
              custom
              onConfirm={() => window.close()}
              showCancel
              closeOnClickOutside={false}
              customButtons={
                <Row>
                  <Button
                    onClick={() => {
                      this.returnToThePaymentPage();
                    }}
                    className="mainBtnRadius"
                  >
                    กลับไปที่หน้าการชำระเงิน
                  </Button>
                </Row>
              }
            >
              <h5 className="">
                {this.state.serviceKPayPlusError
                  ? "ชำระเงินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
                  : MainData.masterMessage.find((x) => x.msgCode === "MSG016")
                      .msgDescription}
              </h5>
              <span className="text--muted">
                {"ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา"}
              </span>
            </SweetAlert>
          </div>
        )}

        <KbankHeaderBusiness
          isLoading={this.state.isLoading}
          activePage={this.state.activePage}
          checkKplus={this.state.checkKplus}
          contentSectionHeight={this.state.contentSectionHeight}
          channelName={this.state.channelName}
          channel={this.state.channel}
          isSelectedForHeder={this.state.isSelectedForHeder}
          mainHeaderText={this.state.mainHeaderText}
          subHeaderText={this.state.subHeaderText}
          thirdHeaderText={this.state.thirdHeaderText}
          initialSalesStatus={this.state.initialSalesStatus}
        >
          {this.state.isLoadingPage === false && (
            <Fragment>
              {this.state.appStatus === false &&
                (this.state.serviceError === true ? (
                  <div
                    className="w-100 d-flex align-items-center justify-content-center"
                    style={{ height: this.state.screenHeight }}
                  >
                    <SweetAlert
                      show={true}
                      title={""}
                      customIcon={
                        <GoAlert
                          size={70}
                          className="text-center w-100 text-danger pb-2"
                        />
                      }
                      custom
                      onConfirm={() => window.close()}
                      showCancel
                      closeOnClickOutside={false}
                      customButtons={
                        <Row>
                          <Button
                            onClick={() => {
                              this.returnToThePaymentPage();
                            }}
                            className="mainBtnRadius"
                          >
                            กลับไปที่หน้าการชำระเงิน
                          </Button>
                        </Row>
                      }
                    >
                      <h5 className="">
                        {this.state.serviceKPayPlusError
                          ? "ชำระเงินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
                          : MainData.masterMessage.find(
                              (x) => x.msgCode === "MSG016"
                            ).msgDescription}
                      </h5>
                      <span className="text--muted">
                        {"ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา"}
                      </span>
                    </SweetAlert>
                  </div>
                ) : (
                  <div className="container-fulid">
                    <SweetAlert
                      show={this.state.isError}
                      title={""}
                      customIcon={
                        <GoAlert
                          size={70}
                          className="text-center w-100 text-danger pb-2"
                        />
                      }
                      custom
                      onConfirm={() => this.errorConfirm}
                      showCancel
                      closeOnClickOutside={false}
                      customButtons={
                        <Row>
                          <Button
                            onClick={() => this.errorConfirm}
                            className="mainBtnRadius"
                          >
                            ปิด
                          </Button>
                        </Row>
                      }
                    >
                      <h5
                        className=""
                        dangerouslySetInnerHTML={{ __html: this.state.errMsg }}
                      ></h5>
                      <span
                        className="text--muted"
                        dangerouslySetInnerHTML={{
                          __html: !this.state.errSubMsg
                            ? "ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา"
                            : this.state.errSubMsg,
                        }}
                      ></span>
                    </SweetAlert>
                    {this.state.isLoadingPage === false ? (
                      // ชำระเงินสำเร็จแล้ว
                      <Row className="m-0">
                        <Col xs={12}>
                          <Card
                            className="p-3 mb-3 w-100 container"
                            style={{ border: "unset" }}
                          >
                            <Row className="d-flex justify-content-center pt-3">
                              <Image
                                className="mt-2 mb-2 mr-2"
                                src={
                                  this.state.ssrRuning ? "" : IcoSuccessPayment
                                }
                                rounded
                                onLoad={(e) =>
                                  this.setState({
                                    logoWidth: e.target.width + 5,
                                  })
                                }
                              />
                            </Row>
                            <Row style={{ gap: "12px" }}>
                              <Col
                                xs={12}
                                className="d-flex justify-content-center "
                              >
                                <span
                                  className="pt-2 font-weight-bold subFontCenter"
                                  style={{ fontSize: "24px" }}
                                >
                                  {MainFunc.getLabelDtos("L0156")}
                                </span>
                              </Col>
                              <Col
                                xs={12}
                                className="d-flex justify-content-center subFontFamily"
                              >
                                {!this.state.paymentTime ? null : (
                                  <span className="">
                                    {new Date(
                                      this.state.paymentTime.split(" ")[0]
                                    ).getDate()}{" "}
                                    {MainFunc.setNewThaiMonth(
                                      new Date(
                                        this.state.paymentTime.split(" ")[0]
                                      ).getMonth(),
                                      false
                                    )}{" "}
                                    {new Date(
                                      this.state.paymentTime.split(" ")[0]
                                    ).getFullYear() + 543}
                                    , {this.state.paymentTime.split(" ")[1]}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xs={12}
                                className="d-flex justify-content-center subFontFamily"
                              >
                                <small className=" text--muted">
                                  {MainFunc.getLabelDtos("L0157")}
                                </small>
                              </Col>
                              <Col
                                xs={12}
                                className="d-flex justify-content-center subFontFamily "
                              >
                                <h3
                                  className="csMainColor font-weight-bold"
                                  style={{ margin: 0 }}
                                >
                                  {MainFunc.numberWithCommas(
                                    this.state.detailPremium
                                  )}{" "}
                                  {MainFunc.getLabelDtos("L0104")}
                                </h3>
                              </Col>
                              {!this.state.noPayment &&
                                this.state.paymentChannelName && (
                                  <Fragment>
                                    <Col
                                      xs={12}
                                      className="d-flex justify-content-center  subFontFamily"
                                    >
                                      <small className=" text--muted">
                                        {MainFunc.getLabelDtos("L0158")}
                                      </small>
                                    </Col>
                                    <Col
                                      xs={12}
                                      className="d-flex justify-content-center subFontFamily"
                                    >
                                      <h5 className="m-0 subFontCenter">
                                        {this.state.paymentChannelName}
                                      </h5>
                                    </Col>
                                  </Fragment>
                                )}
                            </Row>
                            <Row className="subFontFamily">
                              <Col xs={12}>
                                <div
                                  className="mt-4 mb-4"
                                  style={{
                                    borderBottom: "2px solid rgba(0,0,0,0.1)",
                                  }}
                                ></div>
                              </Col>

                              <Col
                                xs={12}
                                className="d-flex justify-content-center "
                              >
                                <span className="text-center">
                                  {MainFunc.getLabelDtos("L0159")}
                                </span>
                              </Col>
                              {!this.state.isTaxConsent && (
                                <Col
                                  xs={12}
                                  className="d-flex justify-content-center "
                                >
                                  <span
                                    className="pt-2 text-center"
                                    style={{
                                      fontSize: "18px",
                                      color: "var(--mainColor)",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {MainFunc.getLabelDtos("L0499")}
                                  </span>
                                </Col>
                              )}
                            </Row>
                            <Row className="mt-3 subFontFamily">
                              <Col xs={12}>
                                <Row
                                  className=""
                                  style={{
                                    backgroundColor: "#F8F8F8",
                                    borderRadius: "9px",
                                  }}
                                >
                                  <Col xs={12}>
                                    <Card
                                      className="pt-3 pb-3 summary-of-detailed-information"
                                      style={{
                                        border: "unset",
                                        backgroundColor: "unset",
                                      }}
                                    >
                                      <div className="desktop">
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0106")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {this.state.detailApplicationNumber}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0711")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {MainFunc.numberWithCommas(
                                              this.state.detailPremium
                                            )}{" "}
                                            {MainFunc.getLabelDtos("L0104")}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0160")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {this.state.detailFirstName}{" "}
                                            {this.state.detailLastName}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0162")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {new Date(
                                              this.state.detailDateStartCover
                                            ).getDate()}{" "}
                                            {MainFunc.setNewThaiMonth(
                                              new Date(
                                                this.state.detailDateStartCover
                                              ).getMonth(),
                                              true
                                            )}{" "}
                                            {new Date(
                                              this.state.detailDateStartCover
                                            ).getFullYear() + 543}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0161")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {this.state.detailProductName}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0113")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {MainFunc.numberWithCommas(
                                              this.state.detailSumInsured
                                            )}{" "}
                                            {MainFunc.getLabelDtos("L0104")}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0013")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {this.state.detailInsuranceTime}{" "}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="mobile">
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0106")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {this.state.detailApplicationNumber}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0160")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {this.state.detailFirstName}{" "}
                                            {this.state.detailLastName}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0161")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {this.state.detailProductName}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0013")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {this.state.detailInsuranceTime}{" "}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0113")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {MainFunc.numberWithCommas(
                                              this.state.detailSumInsured
                                            )}{" "}
                                            {MainFunc.getLabelDtos("L0104")}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0162")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {new Date(
                                              this.state.detailDateStartCover
                                            ).getDate()}{" "}
                                            {MainFunc.setNewThaiMonth(
                                              new Date(
                                                this.state.detailDateStartCover
                                              ).getMonth(),
                                              true
                                            )}{" "}
                                            {new Date(
                                              this.state.detailDateStartCover
                                            ).getFullYear() + 543}
                                          </span>
                                        </div>
                                        <div>
                                          <small className="text--muted">
                                            {MainFunc.getLabelDtos("L0711")}
                                          </small>
                                          <span className="font-weight-bold">
                                            {MainFunc.numberWithCommas(
                                              this.state.detailPremium
                                            )}{" "}
                                            {MainFunc.getLabelDtos("L0104")}
                                          </span>
                                        </div>
                                      </div>
                                    </Card>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                          <div className="p-3 mt-3 mb-3 w-100">
                            <Col
                              xs={12}
                              className="d-flex justify-content-center "
                            >
                              <Button
                                onClick={() => {
                                  this.backFirthPages();
                                }}
                                className="mainBtnRadius"
                              >
                                กลับไปหน้าแรก
                              </Button>
                            </Col>
                          </div>
                          <Row>
                            <Col xs={12} className="text-center">
                              <Card
                                className="w-100 container p-0 mb-4"
                                style={{ border: "0px" }}
                              >
                                <Image
                                  className="appDeepLinkedAWThankYouPageImage"
                                  src={
                                    this.state.ssrRuning
                                      ? ""
                                      : APP_Deep_Linked_AW_Thank_You_Page
                                  }
                                  onClick={() => {
                                    this.goToWeb();
                                  }}
                                />
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                ))}
              {this.state.appStatus === true && (
                <SelectAPPorCCP
                  loadController={(isLoad) => this.loadController(isLoad)}
                  submitPayment={(timeout) => {
                    this.submitPayment(timeout);
                  }}
                  createAPP={() => {
                    this.createAPP();
                  }}
                  createCCP={() => {
                    this.createCCP();
                  }}
                  cTBAppCcpStatus={this.state.cTBAppCcpStatus}
                />
              )}
            </Fragment>
          )}
        </KbankHeaderBusiness>
      </Fragment>
    );
  }
}

export default Checkout;
