import React from "react";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import styles from "../../styles/pre-radio.module.scss";
import { withStyles } from "@material-ui/core/styles";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
class RadioButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      checkGender: MainData.checkGender,
    };
  }

  handleChange = (event) => {
    this.setState({ selectedValue: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={`${styles.preInput} ${
          this.props.selectPlan ? styles.selectPlan : ""
        }`}
      >
        <label className={styles.label}>
          {this.props.label}{" "}
          {this.props.required && <span className={styles.required}>*</span>}
        </label>
        <RadioGroup
          aria-label="radio-buttons"
          name={this.props.name}
          value={this.props.value}
          onChange={(event) => this.props.onChange(event)}
          className={classes.root}
          row
        >
          {this.state.checkGender.map((item, key) => (
            <FormControlLabel
              value={item}
              control={<Radio />}
              label={
                item === "MALE"
                  ? MainFunc.getLabelDtos("L0002")
                  : MainFunc.getLabelDtos("L0003")
              }
            />
          ))}
        </RadioGroup>
      </div>
    );
  }
}

const style = {
  root: {
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#FF0C8F",
    },
    "& .MuiFormControlLabel-label": {
      fontWeight: "bold",
      color: "#333",
      fontSize: "16px",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 12,
    },
    "&.MuiFormGroup-row": {
      gap: "48px",
      marginLeft: "12px",
    },
  },
};

export default withStyles(style)(RadioButtons);
