import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "../styles/MOTP.module.scss";

class MOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "MOTP",
      numberBox: null,
    };
  }

  componentDidMount() {
    let numberBoxArray = [];
    for (let i = 0; i < this.props.number; i++) {
      numberBoxArray.push("");
    }
    this.setState({ numberBox: numberBoxArray });
  }

  handleInput = async (event) => {
    var value = event.target.value;
    let index = event.target.dataset.index;
    if (!value) {
      let previous = event.target.dataset.previous;
      let previousNumberBox = this.state.numberBox;
      previousNumberBox[index] = event.target.value;
      this.setState({ numberBox: previousNumberBox });
      this.props.onChange(previousNumberBox.toString().replace(/,/g, ""));
      if (previous) {
        document.getElementById(previous).focus();
      }
    }
    value = value.replace(/[^\d]/g, "");
    if (value) {
      let next = event.target.dataset.next;
      let previousNumberBox = this.state.numberBox;
      for (let i = 0; i < event.target.value.length; i++) {
        if (index < this.props.number) {
          if(event.target.value[i].replace(/[^\d]/g, "")){
            previousNumberBox[index] = event.target.value[i];
            index++;
          }
        }
      }
      this.setState({ numberBox: previousNumberBox });
      this.props.onChange(previousNumberBox.toString().replace(/,/g, ""));
      if (next) {
        setTimeout(() => {
          if (value.length > 1) {
            document.getElementById(`digit-${index}`).focus();
          } else {
            document.getElementById(next).focus();
          }
        }, 50);
      }
    }
  };
  handleInputKey = async (event) => {
    var key = event.keyCode || event.charCode;
    if( key == 8 || key == 46 ){
      if(!event.target.value){
        let previous = event.target.dataset.previous;
        if(previous){
          document.getElementById(previous).focus();
        }
        
      }
    }
    else if(key == 37){
      let previous = event.target.dataset.previous;
      if(previous){
        document.getElementById(previous).focus();
        if(document.getElementById(previous).value){
          let end = document.getElementById(previous).value.length
          document.getElementById(previous).setSelectionRange(end, end);
        }
      }
    }
    else if(key == 39){
      let next = event.target.dataset.next;
      if(next){
        document.getElementById(next).focus();
        if(document.getElementById(next).value){
          let end = document.getElementById(next).value.length
          document.getElementById(next).setSelectionRange(end, end);
        }
      }
    }   
  }

  render() {
    return (
      <Row className="mb-4">
        <Col
          id="digit-group"
          className={styles.digitGroup + " text-center"}
        >
          {this.state.numberBox?.map((item, index) => {
            if (index == 0) {
              return (
                <input
                  type="text"
                  id={`digit-${index + 1}`}
                  name={`digit-${index + 1}`}
                  data-next={`digit-${index + 2}`}
                  data-index={index}
                  className={` ${this.state.numberBox.indexOf("")<0 && !this.props.error?styles.success:""} ${(item && this.state.numberBox.length != this.props.number) || this.props.error?styles.error:""}`}
                  disabled={this.props.disabled?this.props.disabled:false}
                  value={item}
                  inputMode="numeric"
                  autoComplete="off"
                  onKeyUp={this.handleInputKey}
                  onChange={this.handleInput}
                />
              );
            } else if (index + 1 == this.state.numberBox.length) {
              return (
                <input
                  type="text"
                  id={`digit-${index + 1}`}
                  name={`digit-${index + 1}`}
                  data-previous={`digit-${index}`}
                  className={`${this.state.numberBox.indexOf("")<0 && !this.props.error?styles.success:""} ${(item && this.state.numberBox.length != this.props.number) || this.props.error|| this.props.error?styles.error:""}`}
                  disabled={this.props.disabled?this.props.disabled:false}
                  data-index={index}
                  value={item}
                  inputMode="numeric"
                  autoComplete="off"
                  onKeyUp={this.handleInputKey}
                  onChange={this.handleInput}
                />
              );
            } else {
              return (
                <input
                  type="text"
                  id={`digit-${index + 1}`}
                  name={`digit-${index + 1}`}
                  data-next={`digit-${index + 2}`}
                  data-previous={`digit-${index}`}
                  className={`${this.state.numberBox.indexOf("")<0 && !this.props.error?styles.success:""} ${((item && this.state.numberBox.length != this.props.number) || this.props.error)?styles.error:""}`}
                  disabled={this.props.disabled?this.props.disabled:false}
                  data-index={index}
                  value={item}
                  inputMode="numeric"
                  autoComplete="off"
                  onKeyUp={this.handleInputKey}
                  onChange={this.handleInput}
                />
              );
            }
          })}
        </Col>
      </Row>
    );
  }
}

export default MOTP;
