import React, { Component } from "react";
import styles from "../../styles/pre-select.module.scss";
import Input from "@material-ui/core/Input";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MainData from "../../models/MainData";
import iconArrowDown from "../../images/icon_arrow_down.svg";
import { withStyles } from "@material-ui/core/styles";
class MyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      isOpen: false,
    };
  }

  handleChange = (name, value , code) => {
    this.props.onChange(name, value, code);
    this.setState({ isOpen: false });
  };

  openOptions = (open) => {
    this.setState({ isOpen: open ? false : true });
  };
  render() {
    const { isOpen } = this.state;
    const { classes } = this.props;
    return (
      <ClickAwayListener onClickAway={() => this.setState({ isOpen: false })}>
        <div className={styles.select}>
          {this.props.label && (
            <label className={styles.label}>{this.props.label}</label>
          )}
          <Input
            id={this.props.id}
            className={`${styles.input} ${isOpen ? styles.open : ""} ${
              classes.root
            } `}
            label={this.props.placeholder}
            type={this.props.type}
            placeholder={this.props.placeholder}
            value={this.props.value}
            name={this.props.name}
            maxlength={this.props.maxlength}
            hidden={this.props.hide}
            onClick={() => this.setState({ isOpen: !isOpen })}
            endAdornment={
              <img className={`${styles.iconArrowDown}`} src={iconArrowDown} />
            }
            readOnly
          />
          <p className={styles.textRequired} hidden={true}>
            {this.props.helpText}
          </p>
          <div className={`${styles.options} ${isOpen ? styles.open : ""}`}>
            {MainData.dropleadDtos.map((item) => (
              <span
                className={styles.option}
                onClick={() =>
                  this.handleChange(this.props.name, item.contactTimeName ,item.contactTimeCode)
                }
              >
                {item.contactTimeName}
              </span>
            ))}
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}
const style = {
  root: {
    "& .MuiInputBase-input": {
      fontFamily: "var(--mainFontFamily) !important",
    },
  },
};

export default withStyles(style)(MyComponent);
