import React, { Component } from "react";
import parse from "html-react-parser";
import styles from "../../styles/insuranceCompare.module.scss";
class InsuranceCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insuranceData: [],
    };
  }

  render() {
    const { insuranceData } = this.state;

    return (
      <div className="container">
        <div className={styles.insuranceCompare}>
          {parse(
            this.props.data.briefBenefitDescription
              ? this.props.data.briefBenefitDescription.includes("||")?
                `<div class="header-insurance">${this.props.data.briefBenefitDescription.split("||")[1].replace(/\n/g, "")}</div><div class="table-insurance">${this.props.data.briefBenefitDescription.split("||")[0].replace(/\n/g, "")}</div>`
                :`<div class="table-insurance"><div>${this.props.data.briefBenefitDescription.replace(/\n/g, "")}</div></div>`
              : ""
          )}
          {this.props.data?.briefBenefitRemark && (
            <div class={styles.remark}>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.data.briefBenefitRemark,
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default InsuranceCompare;
