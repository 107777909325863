import axios from "axios";
import React, { Component, Fragment } from "react";
import {Row, Col, Button, Image, Modal} from "react-bootstrap";
import { GoAlert } from 'react-icons/go';
import SweetAlert from 'react-bootstrap-sweetalert';
import styles from "../../styles/UploadFile.module.scss";
import expFaceShape from '../../images/exp_face_shape.svg';
import expIdCard from '../../images/exp_id_card.svg';
import IcoLolLight from '../../images/ico_lol_light.svg';
import IcoOrderLight from '../../images/ico_order_light.svg';
import IcoUserCicrleLight from '../../images/ico_user_cicrle_light.svg';
import IcoLampLight from '../../images/ico_lamp_light.svg';
import IcoUserAltPhoneLight from '../../images/ico_user_alt_phone_light.svg';
import Dropzone2 from "../UploadFile/Dropzone2"
import { AiFillCloseCircle } from 'react-icons/ai';
import 'moment/locale/th';
import moment from "moment";

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

class SM014 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM014",
      eventKeyActive: false,
      uploadedFilesId_card: undefined,
      uploadedFilesFace_shape: undefined,
      modalUploaderr: false,
      errMsgFile:"",
      uploadByMoblieId_card:"UPLOAD",
      uploadByMoblieFace_shape:"UPLOAD",
      flagUploadByKPLUS:true,
    };
  }

  componentDidMount(){
    this.setState({errMsgFile: MainData.masterMessage.find(x => x.msgCode === 'MSG046').msgDescription ,flagUploadByKPLUS:MainData.screenSM014.flagUploadByKPLUS});
    if(MainData.screenSM002.insureSelfie === false && MainData.screenSM002.insureRequiredIdentification === false){
      
      MainData.screenSM014.uploadedFilesId_card = null
      MainData.screenSM014.uploadedFilesFace_shape = null
      this.props.setPage(this.props.next)  
    }

    if(MainData.screenSM014.uploadedFilesId_card){
      this.setState({uploadedFilesId_card: MainData.screenSM014.uploadedFilesId_card})
    }
    if(MainData.screenSM014.uploadedFilesFace_shape){
      this.setState({uploadedFilesFace_shape: MainData.screenSM014.uploadedFilesFace_shape,uploadByMoblieFace_shape:MainData.screenMenuKPlus.onKPlus ? "KPLUS" : "UPLOAD"})
    }
  }

  processNext = () => {
    this.props.loadController(true)
  }

  getRoundedSize = (size) => {
    return Math.round((size / 1000000) * 100) / 100;
  };

  onChangeFile = (name, files) => {
    if(name == "face_shape"){
      this.setState({uploadedFilesFace_shape: files,flagUploadByKPLUS:false})
      this.onCheckFileUpload(this.state.uploadedFilesFace_shape,name);
    }else if(name == "id_card"){
      this.setState({uploadedFilesId_card: files})
      this.onCheckFileUpload(this.state.uploadedFilesId_card,name);
    }
    
  };

  onPreviewFile = (file) => {
    this.setState({currentPreviewFile:URL.createObjectURL(file),mimeType:file.type,showModal:true});
  };
  
  cutFileName = (file) => {
   var fileName = '';
    if(file){
      const name = file[0] ?.file ?.name;
      const [fileName, file_type] = name.split(/\.(?=[^\.]+$)/);
      return fileName;
    }else{
      return fileName;
    }
   
  }

  onCheckFile = () => {
    const _ = require('lodash');
    let dataPDPAOldValue = {}
    if(MainData.screenSM014PDPA.uploadedFilesId_card){
      dataPDPAOldValue.insuredDocumentType = MainFunc.getLabelDtos("L0264") /* บัตรประชาชน */;
      dataPDPAOldValue.insuredDocumentFileName = this.cutFileName(MainData.screenSM014PDPA.uploadedFilesId_card).toString();
      dataPDPAOldValue.insuredDocumentFileType = MainData.screenSM014PDPA.uploadedFilesId_card[0] ?.file ?.type.split("/")[1].toString();
    }
    if(MainData.screenSM014PDPA.uploadedFilesFace_shape){
      dataPDPAOldValue.insuredKYCDocumentType = MainFunc.getLabelDtos("L0479") /** รูปหน้าของผู้ขอเอาประกันภัย */;
      dataPDPAOldValue.insuredKYCDocumentFileName = this.cutFileName(MainData.screenSM014PDPA.uploadedFilesFace_shape).toString();
      dataPDPAOldValue.insuredKYCDocumentFileType = MainData.screenSM014PDPA.uploadedFilesFace_shape[0] ?.file ?.type.split("/")[1].toString();
    }

    let dataPDPANewValue = {}
    if(this.state.uploadedFilesId_card){
      dataPDPANewValue.insuredDocumentType = MainFunc.getLabelDtos("L0264") /* บัตรประชาชน */;
      dataPDPANewValue.insuredDocumentFileName = this.cutFileName(this.state.uploadedFilesId_card).toString();
      dataPDPANewValue.insuredDocumentFileType = this.state.uploadedFilesId_card[0] ?.file ?.type.split("/")[1].toString();
    }
    if(this.state.uploadedFilesFace_shape){
      dataPDPANewValue.insuredKYCDocumentType = MainFunc.getLabelDtos("L0479") /** รูปหน้าของผู้ขอเอาประกันภัย */;
      dataPDPANewValue.insuredKYCDocumentFileName = this.cutFileName(this.state.uploadedFilesFace_shape).toString();
      dataPDPANewValue.insuredKYCDocumentFileType = this.state.uploadedFilesFace_shape[0] ?.file ?.type.split("/")[1].toString();
    }

    if(MainData.screenSM014PDPA.dataPDPAOldValue){
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, _.isEmpty(dataPDPAOldValue)?null:dataPDPAOldValue, _.isEmpty(dataPDPANewValue)?null:dataPDPANewValue, 'Fail', this.state.errMsgFile)
        MainData.screenSM014PDPA.uploadedFilesId_card = this.state.uploadedFilesId_card;
        MainData.screenSM014PDPA.uploadedFilesFace_shape = this.state.uploadedFilesFace_shape;
        MainData.screenSM014PDPA.dataPDPAOldValue = dataPDPAOldValue;
    } 
    else {
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, _.isEmpty(dataPDPANewValue)?null:dataPDPANewValue, 'Fail', this.state.errMsgFile)
          MainData.screenSM014PDPA.uploadedFilesId_card = this.state.uploadedFilesId_card;
          MainData.screenSM014PDPA.uploadedFilesFace_shape = this.state.uploadedFilesFace_shape;
          MainData.screenSM014PDPA.dataPDPAOldValue = dataPDPAOldValue;
          }
  }

  processBack = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    MainFunc.sendDataToGTMKbank({
      'event': 'track_event',
      'event_category': 'link_click',
      'event_action': 'click',
      'event_label': 'ย้อนกลับ',
      'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
      'flag_page' : 'onboard10'
     });
    this.props.setPage(this.props.prev)
  }

  serviceUpload = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    let flagVerify = "";
    const _ = require('lodash');
    let dataPDPAOldValue = {}
    if(MainData.screenSM014PDPA.uploadedFilesId_card){
      dataPDPAOldValue.insuredDocumentType = MainFunc.getLabelDtos("L0264") /* บัตรประชาชน */;
      dataPDPAOldValue.insuredDocumentFileName = this.cutFileName(MainData.screenSM014PDPA.uploadedFilesId_card).toString();
      dataPDPAOldValue.insuredDocumentFileType = MainData.screenSM014PDPA.uploadedFilesId_card[0] ?.file ?.type.split("/")[1].toString();
    }
    if(MainData.screenSM014PDPA.uploadedFilesFace_shape){
      dataPDPAOldValue.insuredKYCDocumentType = MainFunc.getLabelDtos("L0479") /** รูปหน้าของผู้ขอเอาประกันภัย */;
      dataPDPAOldValue.insuredKYCDocumentFileName = this.cutFileName(MainData.screenSM014PDPA.uploadedFilesFace_shape).toString();
      dataPDPAOldValue.insuredKYCDocumentFileType = MainData.screenSM014PDPA.uploadedFilesFace_shape[0] ?.file ?.type.split("/")[1].toString();
    }

    let dataPDPANewValue = {}
    if(this.state.uploadedFilesId_card){
      dataPDPANewValue.insuredDocumentType = MainFunc.getLabelDtos("L0264") /* บัตรประชาชน */;
      dataPDPANewValue.insuredDocumentFileName = this.cutFileName(this.state.uploadedFilesId_card).toString();
      dataPDPANewValue.insuredDocumentFileType = this.state.uploadedFilesId_card[0] ?.file ?.type.split("/")[1].toString();
    }
    if(this.state.uploadedFilesFace_shape){
      dataPDPANewValue.insuredKYCDocumentType = MainFunc.getLabelDtos("L0479") /** รูปหน้าของผู้ขอเอาประกันภัย */;
      dataPDPANewValue.insuredKYCDocumentFileName = this.cutFileName(this.state.uploadedFilesFace_shape).toString();
      dataPDPANewValue.insuredKYCDocumentFileType = this.state.uploadedFilesFace_shape[0] ?.file ?.type.split("/")[1].toString();
    }
    
    if(this.state.uploadedFilesFace_shape == undefined || this.state.uploadedFilesId_card == undefined){
      if(this.state.uploadedFilesId_card == undefined){
        flagVerify += flagVerify?"|1":"1"
      }
      if(this.state.uploadedFilesFace_shape == undefined){
        flagVerify += flagVerify?"|2":"2"
      }
      if(MainData.screenSM014PDPA.dataPDPAOldValue){
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, _.isEmpty(dataPDPAOldValue)?null:dataPDPAOldValue, _.isEmpty(dataPDPANewValue)?null:dataPDPANewValue, 'Fail', "กรุณาอัปโหลดก่อนทำรายการ")
        MainData.screenSM014PDPA.uploadedFilesId_card = this.state.uploadedFilesId_card;
        MainData.screenSM014PDPA.uploadedFilesFace_shape = this.state.uploadedFilesFace_shape;
        MainData.screenSM014PDPA.dataPDPAOldValue = dataPDPAOldValue;
      }else{
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, _.isEmpty(dataPDPANewValue)?null:dataPDPANewValue, 'Fail', "กรุณาอัปโหลดก่อนทำรายการ")
        MainData.screenSM014PDPA.uploadedFilesId_card = this.state.uploadedFilesId_card;
        MainData.screenSM014PDPA.uploadedFilesFace_shape = this.state.uploadedFilesFace_shape;
        MainData.screenSM014PDPA.dataPDPAOldValue = dataPDPAOldValue;
      }
      this.setState({modalUploaderr: true})
      MainFunc.sendDataToGTMKbank({
        'event': 'track_event',
        'event_category': 'link_click',
        'event_action': 'click',
        'event_label': 'step_fail' ,
        'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
        'flag_page' : 'onboard10',
        'flag_verify' : flagVerify 
      });
      return;
    }

    const headers = { 
        "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
        "AuthId": MainData.screenSM005.cusId
    }

    const data = {
      
        "submenuCode" : "SM026",
        "insuredDocument" : {
            "type": "1",
            "file": this.state.uploadedFilesId_card[0] ?.data.split(",")[1],
            "file_type": this.state.uploadedFilesId_card[0] ?.file ?.type.split("/")[1],
            "uploadType":this.state.uploadByMoblieId_card 
        },
        "insuredKYCDocument" : {
            "type": "",
            "file": this.state.uploadedFilesFace_shape[0] ?.data.split(",")[1],
            "file_type": this.state.uploadedFilesFace_shape[0] ?.file ?.type.split("/")[1],
            "uploadType":this.state.uploadByMoblieFace_shape 
         },
    }
    this.props.loadController(true)
    let logData = data
    axios.post(MainData.serviceURL + '/Customer/UploadFile', data, {
      headers: headers
  
    })
      .then(res => {
        if(res.status === 299){
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        let data = res.data.data;
        if (res.data.success) {
          MainFunc.sendDataToGTMKbank({
            'event': 'track_event',
            'event_category': 'link_click',
            'event_action': 'click',
            'event_label': 'step_success' ,
            'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
            'flag_page' : 'onboard10',
            'flag_verify' : 'y'  
          });
          this.props.loadController(false)
          if(MainData.screenSM014PDPA.dataPDPAOldValue){
            MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, _.isEmpty(MainData.screenSM014PDPA.dataPDPAOldValue)?null:MainData.screenSM014PDPA.dataPDPAOldValue, dataPDPANewValue, 'Success', null)
          } 
          else {
            MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, dataPDPANewValue, 'Success', null)
          }
          MainData.screenSM014PDPA.dataPDPAOldValue = dataPDPANewValue;
          MainData.screenSM014.uploadedFilesId_card = this.state.uploadedFilesId_card
          MainData.screenSM014.uploadedFilesFace_shape = this.state.uploadedFilesFace_shape
          MainData.screenSM014.flagUploadByKPLUS = this.state.flagUploadByKPLUS
          this.props.setPage(this.props.next)      
        }
        else
        {
          if(res.data.data.messageCode == "MSG008" || res.data.data.messageCode == "MSG009"){
              this.props.loadController(false);
              let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
              this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg?.msgDescription,`${MainData.initialData.codeTracking}STEP2`,"Click",`${MainData.initialData.codeTracking}STEP2_Click_BtnCloseModalReject`);
              MainFunc.sendDataToGTMKbank({
                'event': 'track_event',
                'event_category': 'link_click',
                'event_action': 'click',
                'event_label': 'step_fail' ,
                'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
                'flag_page' : 'onboard10',
                'flag_verify' : "1|2" 
              });
              return;
          }
          else if(res.data.data.messageCode == "MSG084"){ 
            this.props.loadController(false);
            let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG084');
            this.props.errorAlert(true, !errMsg?"ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง":errMsg?.msgDescription, " ");
            MainFunc.sendDataToGTMKbank({
              'event': 'track_event',
              'event_category': 'link_click',
              'event_action': 'click',
              'event_label': 'step_fail' ,
              'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
              'flag_page' : 'onboard10',
              'flag_verify' : "1|2" 
            });
            return ;
          }
          else if(res.data.data.messageCode == "MSG170"){ 
            this.props.loadController(false);
            let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG170');
            this.props.errorAlert(true, !errMsg?"รูปบัตรประจำตัวประชาชนไม่ถูกต้อง":errMsg.msgDescription, " ");
            MainFunc.sendDataToGTMKbank({
              'event': 'track_event',
              'event_category': 'link_click',
              'event_action': 'click',
              'event_label': 'step_fail' ,
              'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
              'flag_page' : 'onboard10',
              'flag_verify' : "1" 
            });
            return ;
          }
          else{
            MainFunc.sendLogToService(this.props.name, 'Accept', 'Fail', 'เก็บข้อมูลการยินยันซื้อประกัน', {}, { data: logData });
            let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
            this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription, ' ')
          }
        }
      })
      .catch(error => {
        if(error?.response?.status == 400){
          this.props.loadController(false)
          this.validateBadRequest(error.response.data.errors)
        }
        else{
          this.props.loadController(false)
          MainFunc.sendLogToService(this.props.name, 'Accept', 'Fail', 'เก็บข้อมูลการยินยันซื้อประกัน', {}, { data: logData });
          if (error?.response?.data?.data?.messageCode == 'MSG005') {
            let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG005');
            this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg ? "ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)" : errMsg.msgDescription)
          }
          else if (error?.response?.data?.data?.messageCode == 'MSG183') {
            let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG183');
            this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg ? "กรุณาอัปโหลดไฟล์ประเภท .jpg .pdf .png ขนาดไม่เกิน 10MB" : errMsg.msgDescription)
          }
          else {
            let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
            this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error?.response?.data?.data?.messageDescription), ' ')
          }
        }
      });
  }

  validateBadRequest = (fieldNameList) => {
    let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG018');
    this.props.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
    let flagVerify = "";
    if(fieldNameList.find(data => data.field === "insuredDocument")){
      this.setState({uploadedFilesId_card: undefined});
      flagVerify += flagVerify?"|1":"1"
    }
    if(fieldNameList.find(data => data.field === "insuredKYCDocument")){
      this.setState({uploadedFilesFace_shape: undefined});
      flagVerify += flagVerify?"|2":"2"
    }
    MainFunc.sendDataToGTMKbank({
      'event': 'track_event',
      'event_category': 'link_click',
      'event_action': 'click',
      'event_label': 'step_fail' ,
      'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
      'flag_page' : 'onboard10',
      'flag_verify' : flagVerify 
    });
  }
  
  onCheckFileUpload = (file,name) =>{ 
    let filesName = this.cutFileName(file);
    if(filesName.length > 28 || (filesName.length == 21 && filesName.indexOf(moment().format("DDMMYYYY")) != -1 )){
      name == "face_shape" ? this.setState({uploadByMoblieFace_shape:"TAKEPHOTO"}) : this.setState({uploadByMoblieId_card:"TAKEPHOTO"})
    }
    else{
      name == "face_shape" ? this.setState({uploadByMoblieFace_shape:"UPLOAD"}) : this.setState({uploadByMoblieId_card:"UPLOAD"})
    }
  }

  render(){
    return (
      <div className="container-fulid">
        <Row>
          <Col id="titelForScreen" xs={12} className="oppositeWhiteStep">
            <span className="font-weight-bold" dangerouslySetInnerHTML={{__html: this.props.subMenuName}}></span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>

          <Col xs={12} className="pt-3">
            <span >{MainFunc.getLabelDtos("L0729")}</span>
          </Col>
          <Col xs={12} className="pt-3 subFontFamily">
            <div className="e-kyc-confirmation--steps">
              <div>
                <div>
                  <Image className="" src={IcoLolLight} rounded></Image>
                </div>
                <div>
                  <span>{MainFunc.getLabelDtos("L0730")}</span>
                </div>
              </div>
              <div>
                <div>
                  <Image className="" src={IcoOrderLight} rounded></Image>
                </div>
                <div>
                  <span>{MainFunc.getLabelDtos("L0731")}</span>
                </div>
              </div>
              <div>
                <div>
                  <Image className="" src={IcoUserCicrleLight} rounded></Image>
                </div>
                <div>
                  <span>{MainFunc.getLabelDtos("L0732")}</span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} className="e-kyc-title font-weight-bold mt-3">
            {MainFunc.getLabelDtos("L0733")}  
          </Col>
          {!MainData.initialData.channelCode == "LINEBK"?
          <Fragment>           
            <Col xs={12} className={MainData.screenSM002.insureRequiredIdentification?"mt-3":"d-none"}>
              <Dropzone2
                  fieldName={"id_card"}
                  currentFile={this.state.uploadedFilesId_card}
                  onSelectFile={(name, file) => this.onChangeFile(name, file)}
                  subtitle={MainFunc.getLabelDtos("L0264")}
                  getRoundedSize={this.getRoundedSize}
                  className={styles["face_shape"]}
                  dropzonetext={MainFunc.getLabelDtos("L0259")}
                  images={expIdCard}
                  onCheckFileSize={this.onCheckFile}
                  uploadFileOnKplus={false}
                  loadController={(item) => this.props.loadController(item)}
                />
            </Col>
            {!(MainData.screenSM014.uploadedFilesFace_shape && MainData.screenMenuKPlus.onKPlus && this.state.flagUploadByKPLUS) && (
              <Col xs={12} className={MainData.screenSM002.insureSelfie?"mt-3":"d-none"}>
              <Dropzone2
                  fieldName={"face_shape"}
                  currentFile={this.state.uploadedFilesFace_shape}
                  onSelectFile={(name, file) => this.onChangeFile(name, file)}
                  subtitle={MainFunc.getLabelDtos("L0263")}
                  getRoundedSize={this.getRoundedSize}
                  className={styles["face_shape"]}
                  dropzonetext={MainFunc.getLabelDtos("L0378")}
                  images={expFaceShape}
                  onCheckFileSize={this.onCheckFile}
                  uploadFileOnKplus={MainData.screenMenuKPlus.onKPlus && this.state.uploadByMoblieFace_shape == "KPLUS" && this.state.flagUploadByKPLUS ? true : false}
                  loadController={(item) => this.props.loadController(item)}
                />
            </Col>
            )}
          </Fragment>
          :
          <Fragment>
          <Col xs={12} className={MainData.screenSM002.insureRequiredIdentification?"mt-3":"d-none"}>
            <Dropzone2
                fieldName={"id_card"}
                currentFile={this.state.uploadedFilesId_card}
                onSelectFile={(name, file) => this.onChangeFile(name, file)}
                subtitle={MainFunc.getLabelDtos("L0264")}
                getRoundedSize={this.getRoundedSize}
                className={styles["id_card"]}
                dropzonetext={MainFunc.getLabelDtos("L0739")}
                images={expIdCard}
                onCheckFileSize={this.onCheckFile}
                uploadFileOnKplus={false}
                loadController={(item) => this.props.loadController(item)}
              />
          </Col>
          {!(MainData.screenSM014.uploadedFilesFace_shape && MainData.screenMenuKPlus.onKPlus && this.state.flagUploadByKPLUS) && ( 
            <Fragment>
            <Col xs={12} className="e-kyc-title font-weight-bold mt-3">
              {MainFunc.getLabelDtos("L0734")}
            </Col>
            <Col xs={12} className="pt-3 subFontFamily">
              <div className="e-kyc-confirmation--steps">
                <div>
                  <div>
                    <Image className="" src={IcoLampLight} rounded></Image>
                  </div>
                  <div>
                    <span>{MainFunc.getLabelDtos("L0735")}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <Image className="" src={IcoOrderLight} rounded></Image>
                  </div>
                  <div>
                    <span>{MainFunc.getLabelDtos("L0736")}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <Image className="" src={IcoUserAltPhoneLight} rounded></Image>
                  </div>
                  <div>
                    <span>{MainFunc.getLabelDtos("L0737")}</span>
                  </div>
                </div>
              </div>
            </Col>
              <Col xs={12} className={MainData.screenSM002.insureSelfie?"mt-3":"d-none"}>
              <Dropzone2
                  fieldName={"face_shape"}
                  currentFile={this.state.uploadedFilesFace_shape}
                  onSelectFile={(name, file) => this.onChangeFile(name, file)}
                  subtitle={MainFunc.getLabelDtos("L0263")}
                  getRoundedSize={this.getRoundedSize}
                  className={styles["face_shape"]}
                  dropzonetext={MainFunc.getLabelDtos("L0738")}
                  images={expFaceShape}
                  onCheckFileSize={this.onCheckFile}
                  uploadFileOnKplus={MainData.screenMenuKPlus.onKPlus && this.state.uploadByMoblieFace_shape == "KPLUS" && this.state.flagUploadByKPLUS ? true : false}
                  loadController={(item) => this.props.loadController(item)}
                />
            </Col>
            </Fragment>
            )}
        </Fragment>
          }
        </Row>
        <div style={{marginTop:30}}>
        <Row>
            <Col xs={6} className="w-100 d-flex justify-content-center"><Button id={`${MainData.initialData.codeTracking}KYC_Click_BtnBack`}  className="subBtnRadius" onClick={() => {this.processBack()}}>{MainFunc.getLabelDtos("L0020")}</Button></Col>
            <Col xs={6} className="w-100 d-flex justify-content-center"><Button id={`${MainData.initialData.codeTracking}KYC_Click_BtnNext`}  onClick={()=> {this.serviceUpload()}} className="mainBtnRadius">{MainFunc.getLabelDtos("L0021")}</Button></Col>
        </Row>
        </div>
        <Modal show={this.state.previewModal} title={''} onConfirm={() => window.close()} showCancel closeOnClickOutside={false} customButtons={<></>} >
        <div className="container-fulid">
          <div onClick={()=>this.setState({previewModal: false})} style={{position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseCircle/></div>      
            <p className={styles.subtitle} style={{margin:20}}>{MainFunc.getLabelDtos("L0385")}</p>
        </div>
      </Modal>

      <div className="w-100 d-flex align-items-center justify-content-center" >
          <SweetAlert show={this.state.modalUploaderr} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false} customButtons={
            <Row>
              <Button onClick={()=>this.setState({modalUploaderr: false})} className="mainBtnRadius">{MainFunc.getLabelDtos("L0188")}</Button>
            </Row>
          } ><span className="font-weight-bold text-center" style={{ fontSize: "1.2rem" }}>{MainData.masterMessage.find(x=>x.msgCode === 'MSG106')?.msgDescription}</span><br></br>
          </SweetAlert>
      </div>
      </div>
      
    )
  }
}

export default SM014;
