import React, { Component } from "react";
import styles from "../../styles/accordion.module.scss";
import MainFunc from "../../models/MainFunc";

class AccordionGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedValue: [],
      selectedValueFAQ: [],
    };
  }

  handleHeadClickFAQ = (index, question) => {
    const { selectedValueFAQ } = this.state;
    if (selectedValueFAQ.includes(index)) {
      // Remove the value from selectedValueFAQ if it already exists
      this.setState({
        selectedValueFAQ: selectedValueFAQ.filter((item) => item !== index),
      });
    } else {
      // Add the value to selectedValueFAQ if it doesn't exist
      MainFunc.sendDataToGTMKbank({
        event: "track_event",
        event_category: "faq",
        event_action: "click",
        event_label: this.props.dataFaq.title.replace(/ /g, "_")+"_"+question.replace(/ /g, "_"),
      });
      this.setState({
        selectedValueFAQ: [...selectedValueFAQ, index],
      });
    }
  };

  handleHeadClick = (index, coverageTitle) => {
    const { selectedValue } = this.state;
    if (selectedValue.includes(index)) {
      // Remove the value from selectedValue if it already exists
      this.setState({
        selectedValue: selectedValue.filter((item) => item !== index),
      });
    } else {
      // Add the value to selectedValue if it doesn't exist
      MainFunc.sendDataToGTMKbank({
        event: "track_event",
        event_category: "toggle_content",
        event_action: "click_open",
        event_label: coverageTitle.replace(/ /g, "_"),
      });
      this.setState({
        selectedValue: [...selectedValue, index],
      });
    }
  };

  render() {
    const { isOpen } = this.state;
    return (
      <div className="container-xs">
        <div className={styles.accordionGroup} data-tab-index="4">
          {/* Render FAQ */}
          {this.props.dataFaq?.faqs.map((item, index) => (
            <div className={styles.gList}>
              <div
                className={`${styles.gAccordionHead} ${
                  this.state.selectedValueFAQ.includes(index)
                    ? styles.active
                    : ""
                }`}
                onClick={() => this.handleHeadClickFAQ(index, item.question)}
                title={item.question}
              >
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.question,
                    }}
                  />
                </div>
                <i className={styles.gAccordionIc}></i>
              </div>
              <div
                className={`${styles.gAccordionContent} ${
                  this.state.selectedValueFAQ.includes(index)
                    ? styles.active
                    : ""
                }`}
              >
                <div className={styles.coverageAnswer}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.answer.includes("<a")?item.answer.replace(
                        "<a ",
                        `<a target="_blank" onclick="window.dataLayer.push({'event': 'track_event','event_category': 'link_click','event_action': 'click','event_label': '${item.answer.split('<a href="')[1]?.slice(0, item.answer.split('<a href="')[1].indexOf('"'))}'})"`
                      ):item.answer,
                    }}
                  />
                </div>
              </div>
            </div>
          ))}

          {/* Render Coverage */}
          {this.props.dataCoverage
            ?.filter(
              (coverageItem, index) =>
                coverageItem.coverageTitle !== null &&
                coverageItem.coverageTitle !== ""
            )
            .map((item, index) => (
              <div className={styles.gList}>
                <div
                  className={`${styles.gAccordionHead} ${
                    this.state.selectedValue.includes(index)
                      ? styles.active
                      : ""
                  }`}
                  onClick={() =>
                    this.handleHeadClick(index, item.coverageTitle)
                  }
                  title={item.coverageTitle}
                >
                  <span>{item.coverageTitle}</span>
                  <i className={styles.gAccordionIc}></i>
                </div>
                <div
                  className={`${styles.gAccordionContent} ${
                    this.state.selectedValue.includes(index)
                      ? styles.active
                      : ""
                  }`}
                >
                  {item.coverageDetails?.map((coverageDetail, index) => {
                    return (
                      <>
                        {coverageDetail.coverageImg && (
                          <img
                            src={coverageDetail.coverageImg}
                            className={styles.coverageImg}
                          />
                        )}
                        <div className={styles.coverageQuestion}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: coverageDetail.question,
                            }}
                          />
                        </div>
                        <div className={styles.coverageAnswer}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: coverageDetail.answer.replace(
                                "<a ",
                                `<a target="_blank" onclick="window.dataLayer.push({'event': 'track_event','event_category': 'link_click','event_action': 'click','event_label': '${coverageDetail.answer
                                  .split("<a ")[0]
                                  .replace(/ /g, "_")}'})"`
                              ),
                            }}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default AccordionGroup;
