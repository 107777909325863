import moment from "moment";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { DropzoneAreaBase } from "material-ui-dropzone";
import React, { Component, Fragment } from "react";
import localization from "moment/locale/th";
import { Row, Col, Button, Image, Modal } from "react-bootstrap";
import SweetAlert from 'react-bootstrap-sweetalert';
import { GoAlert } from 'react-icons/go';
import FilePreviewer from "react-file-previewer";
import IcoError from '../../images/ico_error.png';
import styles from "../../styles/UploadFile.module.scss";
import stylesModal from "../../styles/Modal.module.scss";
import 'moment/locale/th';

import IcoModalClose from '../../images/ico_modal_close.svg';

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

moment().locale("th", localization).add(543, "year").format('LLL')

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
        screen: "SM014",
        isError: false,
        idOpen: MainData.checkGender,
        open: false,
        openFaceShape: false,
        openIdCard: false,
        idOpen:"",
        isError: false,
        currentPreviewFile: "",
        mimeType: "",
        showModal: false,
        errMsgIdCard: "",
        errMsgFaceShape: "",
        errMsgFile: "",
        pagePDF:1

    };
  }

  componentDidMount(){
    this.setState({errMsgIdCard: MainData.masterMessage.find(x => x.msgCode === 'MSG104').msgDescription});
    this.setState({errMsgFaceShape: MainData.masterMessage.find(x => x.msgCode === 'MSG105').msgDescription});
    this.setState({errMsgFile: MainData.masterMessage.find(x => x.msgCode === 'MSG046').msgDescription});
  }

  onProceedUploadfile = () => {
    document.getElementById(this.state.idOpen).style.display = 'none';
    document.querySelector('#'+this.state.idOpen+' ~ [tabindex="0"]').click();
    this.setState({openFaceShape:false,open:false,openIdCard:false});
  }

  handleExpandClick = (id) => {
    if(id == "face_shape"){
      MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}KYC`,"Click",`${MainData.initialData.codeTracking}KYC_Click_BtnDocType1`);
      this.setState({idOpen:id,openFaceShape:true});
    }
    else{
      MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}KYC`,"Click",`${MainData.initialData.codeTracking}KYC_Click_BtnDocType2`);
      this.setState({idOpen:id,openIdCard:true
    });}
  };

  changClick = (id) => {
    document.querySelector('#'+id+' ~ [tabindex="0"]').click();
  }
  onPreviewFile = (file) => {
    this.setState({currentPreviewFile:URL.createObjectURL(file),mimeType:file.type,showModal:true});
  };

  onPreviewFilePDF = (file) => {
    this.props.loadController(true);
    this.setState({currentPreviewFile:URL.createObjectURL(file),mimeType:file.type});
      setTimeout(async () => {
        // Get the canvas element
        const canvas = document.getElementById(`${this.props.fieldName}-pdf-canvas`);
        // Load the PDF file
        // const loadingTask = {};
        const loadingTask = window.pdfjsLib?.getDocument(URL.createObjectURL(file));
        // Render the PDF file on the canvas
        await loadingTask.promise.then(pdf => {
          this.setState({pagePDF:pdf._pdfInfo.numPages});
          pdf.getPage(1).then(page => {
            const viewport = page.getViewport({ scale: 0.25 });
            const renderContext = {
              canvasContext: canvas.getContext('2d'),
              viewport: viewport
            };
            page.render(renderContext);
          });
        });
        this.props.loadController(false);
      }, 100);
  };

  showPrevivewPDF = () => {
    this.setState({showModal:true})
    this.props.loadController(true);
    setTimeout(async () => {
      document.getElementById(`${this.props.fieldName}-pdf-canvas-full`).innerHTML = "";
      for (let i = 0; i < this.state.pagePDF; i++) {
        let page = i + 1;
        document.getElementById(`${this.props.fieldName}-pdf-canvas-full`).innerHTML += `<canvas id="${this.props.fieldName}-pdf-canvas-full-${page}" style="width:100%"></canvas>`;
      }
      setTimeout(async () => {
        for (let i = 0; i < this.state.pagePDF; i++) {
          let page = i + 1;
          // Get the canvas element
          let canvas = document.getElementById(`${this.props.fieldName}-pdf-canvas-full-${page}`);
          // Load the PDF file
          let loadingTask = window.pdfjsLib.getDocument(this.state.currentPreviewFile);
          // Render the PDF file on the canvas
          await loadingTask.promise.then(pdf => {
            pdf.getPage(page).then(page => {
              const viewport = page.getViewport({ scale: 1 });
              canvas.width = viewport.width;
              canvas.height = viewport.height;

              const renderContext = {
                canvasContext: canvas.getContext('2d'),
                viewport: viewport
              };
              page.render(renderContext);
            });
          });
        }
        this.props.loadController(false);
      }, 100);
    }, 100);
  }

  checkFileType = async (files) => {
    if(files && files.length > 0 ){
      if (files[0].file.name.toLowerCase().includes("jpg") ||
        files[0].file.name.toLowerCase().includes("png") ||
        files[0].file.name.toLowerCase().includes("jpeg") ||
        files[0].file.name.toLowerCase().includes("pdf") ||
        files[0].file.name.toLowerCase().includes("heic")
      ) {
        if ((files[0].file.name.toLowerCase().includes("pdf") && files[0].data.split(",")[1].slice(0, 5).toUpperCase() != "JVBER") ||
          (files[0].file.name.toLowerCase().includes("jpeg") && files[0].data.split(",")[1].slice(0, 4).toUpperCase() != "/9J/" && files[0].data.split(",")[1].slice(0, 5).toUpperCase() != "IVBOR" && files[0].data.split(",")[1].slice(0, 4).toUpperCase() != "AAAA") ||
          (files[0].file.name.toLowerCase().includes("jpg") && files[0].data.split(",")[1].slice(0, 4).toUpperCase() != "/9J/" && files[0].data.split(",")[1].slice(0, 5).toUpperCase() != "IVBOR" && files[0].data.split(",")[1].slice(0, 4).toUpperCase() != "AAAA") ||
          (files[0].file.name.toLowerCase().includes("png") && files[0].data.split(",")[1].slice(0, 5).toUpperCase() != "IVBOR" && files[0].data.split(",")[1].slice(0, 4).toUpperCase() != "/9J/" && files[0].data.split(",")[1].slice(0, 4).toUpperCase() != "AAAA") ||
          (files[0].file.name.toLowerCase().includes("heic") && files[0].data.split(",")[1].slice(0, 4).toUpperCase() != "AAAA" && files[0].data.split(",")[1].slice(0, 4).toUpperCase() != "/9J/" && files[0].data.split(",")[1].slice(0, 5).toUpperCase() != "IVBOR")
        ) {
          this.setState({isError: true}); 
          this.props.onCheckFileSize()
        }
        else{
          if(files[0].file.name.toLowerCase().includes("heic")){
            try{
              let base64 = await this.getFileFromUrl(files[0].data.split(",")[1]);
              let object_file = this.dataURLtoFile(base64, this.checkTypeFile(base64, files[0].file.name.slice(0, files[0].file.name.lastIndexOf('.'))));
              let mapFiles = [{
                data: base64,
                file: object_file
              }]
              this.props.onSelectFile(this.props.fieldName, mapFiles);
            }
            catch (err) {
              this.props.loadController(false);
              this.setState({isError: true}); 
            }
          }
          else{
            this.props.onSelectFile(this.props.fieldName, files);
          }
          if(files[0].file.name.toLowerCase().includes("pdf")){
            this.onPreviewFilePDF(files[0].file)
          }
          else{
            this.setState({mimeType:files[0].file.type});
          }
        }
      }
      else{
        this.setState({isError: true}); 
        this.props.onCheckFileSize();
      }
    }
  }

  checkHeic = async (files) => {
    if (files[0].name.toLowerCase().includes("heic")) {
      try{
        let base64Origin = await this.getBase64FromFile(files[0]);
        let base64 = await this.getFileFromUrl(base64Origin.split(",")[1]);
        let object_file = this.dataURLtoFile(base64, this.checkTypeFile(base64, files[0].name.replace(".heic", "")));
        let mapFiles = [{
          data: base64,
          file: object_file
        }]
        this.props.onSelectFile(this.props.fieldName, mapFiles);
      }
      catch (err) {
        this.props.loadController(false);
        this.setState({isError: true}); 
      }
    }
    else {
      this.setState({isError: true}); 
      this.props.onCheckFileSize()
    }
  }

  getBase64FromFile = async (files) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  getFileFromUrl = async (base64) => {
    this.props.loadController(true);
    const data = await fetch(`${MainData.serviceURL}/helper/ConvertType`, {
      headers: {
        Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
        AuthId: !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId,
        "language-locale": process.env.NEXT_PUBLIC_LANGUAGE_LOCALE,
        "Accept": 'application/json',
        "Content-Type": 'application/json'
      },
      method: "POST",
      body: JSON.stringify({base64: base64})
    });
    const blob = await data.blob();
    this.props.loadController(false);
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  checkTypeFile = (base64, name) => {
    if (base64.includes("image/jpg")) {
      return name + ".jpg"
    }
    else if (base64.includes("image/png")) {
      return name + ".png"
    }
    else if (base64.includes("image/jpeg")) {
      return name + ".jpeg"
    }
    else if (base64.includes("application/pdf")) {
      return name + ".pdf"
    }
  }

  render(){
    return (
    <Fragment>
    <SweetAlert style={{width:600}} show={this.state.openIdCard} title={''} onConfirm={()=>{this.setState({openFaceShape: false})}}  customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2"/>} customButtons>
        <div >
                <Image className="img-center" style={{width:70}} src={IcoError} ></Image>
            <div className="text-align-center " style={{marginTop:10}}>
            <span className="font-weight-bold text-center subFontFamily">{this.state.errMsgIdCard}</span><br></br>
            </div>
            <Row className="pt-3 twob" style={{marginTop:10}}>
                <Col xs={6} className="">
                    <Button variant="" className="subBtnRadius font-weight-bold" onClick={()=> {this.setState({openIdCard: false});MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}KYC`,"Click",`${MainData.initialData.codeTracking}KYC_Click_BtnModalNotAcceptType2`);}} >{MainFunc.getLabelDtos("L0383")}</Button>
                </Col>
                <Col xs={6} className="">
                    <Button variant="" className="mainBtnRadius font-weight-bold" onClick={()=> {this.onProceedUploadfile();MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}KYC`,"Click",`${MainData.initialData.codeTracking}KYC_Click_BtnModalAcceptType2`);}}>ยอมรับ</Button>
                </Col>
            </Row>
        </div>
    </SweetAlert>
    <SweetAlert style={{width:600}} show={this.state.openFaceShape} title={''} onConfirm={()=>{this.setState({openIdCard: false})}}  customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2"/>} customButtons>
        <div >
                <Image className="img-center" src={IcoError} ></Image>
            <div className="text-align-center " style={{marginTop:10}}>
            <span className="font-weight-bold text-center subFontFamily" >{this.state.errMsgFaceShape}</span><br></br>
            </div>
            <Row className="pt-3 twob" style={{marginTop:10}}>
                <Col xs={6} className="">
                    <Button variant="" className="subBtnRadius font-weight-bold" onClick={()=> {this.setState({openFaceShape: false});MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}KYC`,"Click",`${MainData.initialData.codeTracking}KYC_Click_BtnModalNotAcceptType1`);}} >{MainFunc.getLabelDtos("L0383")}</Button>
                </Col>
                <Col xs={6} className="">
                    <Button variant="" className="mainBtnRadius font-weight-bold" onClick={()=> {this.onProceedUploadfile();MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}KYC`,"Click",`${MainData.initialData.codeTracking}KYC_Click_BtnModalAcceptType1`);}}>ยอมรับ</Button>
                </Col>
            </Row>
        </div>
    </SweetAlert>
    <SweetAlert style={{width:600}} show={this.state.isError} title={''} onConfirm={()=>{this.setState({openFaceShape: false})}}  customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2"/>} customButtons>
        <div >
                <Image className="img-center" src={IcoError} ></Image>
            <div className="text-align-center " style={{marginTop:30}}>
            <span className="font-weight-bold text-center subFontFamily">{this.state.errMsgFile}</span><br></br>
            </div>
                <Col className="d-flex justify-content-center pt-3" style={{marginTop:20}}>
                    <Button variant="" className="mainBtnRadius font-weight-bold" style={{ fontSize: "1.1rem", width:200, margin:10}} onClick={()=> this.setState({isError: false})} >{MainFunc.getLabelDtos("L0188")}</Button>
                </Col>
        </div>
    </SweetAlert>
      <Row className={this.props.className}>
        {this.props.currentFile !== undefined &&
          this.props.currentFile.length !== 0 &&(
          <Col lg={12} sm={12} xs={12}>
            <div>
              <div className={styles.images2}>
                {this.state.mimeType === "application/pdf" ? (
                  <div style={{width:"100%"}}  onClick={() => {this.showPrevivewPDF()}}>
                            <canvas id={`${this.props.fieldName}-pdf-canvas`} style={{ width: "100%", minHeight: "-webkit-fill-available" }}></canvas>
                          </div>
                ) : (
                  <FilePreviewer
                    hideControls
                    file={{
                      url: URL.createObjectURL(this.props.currentFile[0] ?.file),
                    }}
                  />
                )}
              </div>
              <div className={`${this.props.currentFile !== undefined && this.props.currentFile.length !== 0 && this.props.uploadFileOnKplus ==false ? " subFontFamily" : "d-none"}`} ><div onClick={()=>{document.querySelector('#'+this.props.fieldName+' ~ [tabindex="0"]').click();}}><CameraAltIcon style={{ color: "var(--mainColor)",fontSize: 28 }}/>{MainFunc.getLabelDtos("L0549")}</div></div>
            </div>
          </Col>
        )}
        <Col lg={12} sm={12} xs={12} style={{display:this.props.currentFile !== undefined && this.props.currentFile?.length !== 0?"none":""}}>
          <div>
            <div className={styles.upload}>
              <div
                id={this.props.fieldName}
                className={styles.filterUpload}
                onClick={() => this.handleExpandClick(this.props.fieldName)}
              ></div>
              <div
                id={this.props.fieldName+"Preview"}
                className={`${styles.previewUpload} ${this.props.currentFile !== undefined && this.props.currentFile.length !== 0 ? "" : "d-none"}`}
                onClick={() => this.onPreviewFile(this.props.currentFile[0] ?.file)}
              ></div>
              <div
                className={`${styles.changUpload} ${this.props.currentFile !== undefined && this.props.currentFile.length !== 0 && this.props.uploadFileOnKplus ==false ? "" : "d-none"}`}
              ><u onClick={() => this.changClick(this.props.fieldName+"Preview")}>{MainFunc.getLabelDtos("L0384")}</u></div>
              <DropzoneAreaBase
                maxFileSize={10000000}
                onDropRejected={(e) => this.checkHeic(e)}
                acceptedFiles={[
                  "image/jpg",
                  "application/pdf",
                  "image/png",
                  "image/jpeg",
                  "image/heic"
                ]}
                Icon={CameraAltIcon}
                onAdd={(files) => this.checkFileType(files)}
                dropzoneText={
                  (this.props.currentFile !== undefined &&
                    this.props.currentFile.length !== 0 &&
                    `${this.props.currentFile[0] ?.file ?.name}
                        (${this.props.getRoundedSize(this.props.currentFile[0] ?.file ?.size)} MB)
                        `) ||
                  `${this.props.dropzonetext}`
                }
                filesLimit={1}
                showAlerts={false}
                showPreviewsInDropzone={false}
                useChipsForPreview={false}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        scrollable={true}
        show={this.state.showModal}
        onHide={() => this.setState({showModal:!this.state.showModal})}
        backdrop={true}
        centered
        size="lg"
        dialogClassName={styles.previewModal}
      >
        <div className={stylesModal.modal}>
          <div className={stylesModal.modalHeader} >
            <img src={IcoModalClose} alt="" style={{cursor: "pointer"}} onClick={() => this.setState({showModal:!this.state.showModal})}/>
          </div>
          <div
            className={stylesModal.modalTitle}
          >
            ตัวอย่างเอกสารที่อัปโหลด
          </div>
            <div className={stylesModal.modalContent}>
              {this.state.mimeType === "application/pdf" ? (
                <div id={`${this.props.fieldName}-pdf-canvas-full`} style={{ width: "100%" }}>
                  <canvas id={`${this.props.fieldName}-pdf-canvas-full-1`} style={{ width: "100%" }}></canvas>
                  <canvas id={`${this.props.fieldName}-pdf-canvas-full-2`} style={{ width: "100%" }}></canvas>
                </div>
              ) : (
                <FilePreviewer
                  hideControls
                  file={{
                    url: this.state.currentPreviewFile,
                  }}
                />
              )}
            </div>
        </div>
      </Modal>
      </Fragment>
    )
  }
}

export default Dropzone;