// "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = isValidColor;

function isValidColor(color) {
  var element = document.createElement('a');
  element.style.color = color;
  return element.style.color !== '';
}