import React, { Component } from "react";
import {Link} from 'react-router-dom';
import MainData from '../../models/MainData';

class Error404 extends Component {
  render(){
    return (
      <div className="container-fluid pl-0 pr-0 d-flex justify-content-center">
        404: were you looking for the <Link to={MainData.mainPath}>Homepage</Link>?
      </div>
    )
  }
}

export default Error404