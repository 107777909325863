import React, { Component } from "react";
import axios from "axios";
import styles from "../../styles/contact-back.module.scss";
import PreInput from "./preInput.js";
import PreSelect from "./preSelect.js";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

class ContactBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      data: {
        mobilePhone: MainData.dataBroker.contactBack.mobilePhone
          ? MainData.dataBroker.contactBack.mobilePhone
          : "",
        confirmMobilePhone: MainData.dataBroker.contactBack.confirmMobilePhone
          ? MainData.dataBroker.contactBack.confirmMobilePhone
          : "",
        name: MainData.dataBroker.contactBack.name
          ? MainData.dataBroker.contactBack.name
          : "",
        lastName: MainData.dataBroker.contactBack.lastName
          ? MainData.dataBroker.contactBack.lastName
          : "",
        contactTime: MainData.dataBroker.contactBack.contactTime
          ? MainData.dataBroker.contactBack.contactTime
          : "",
        contractTimeCode: MainData.dataBroker.contactBack.contactTimeCode
          ? MainData.dataBroker.contactBack.contactTimeCode
          : "",
      },
      error: {},
      showConfirmMobilePhone: false,
      disableBtn: true,
      showCaptcha: true,
      sentData: false,
      dataTokenCaptcha: "",
    };
  }

  async componentDidMount() {
    if (this.state.data.mobilePhone) {
      this.setState({ showConfirmMobilePhone: true });
    }
    if (
      this.state.data.mobilePhone &&
      this.state.data.confirmMobilePhone &&
      this.state.data.name &&
      this.state.data.lastName
    ) {
      this.setState({ disableBtn: false });
    } else {
      this.setState({ disableBtn: true });
    }
  }

  handleChange = async (e) => {
    this.setState({
      error: { ...this.state.error, [e.target.name]: false },
    });
    if (e.target.name === "mobilePhone") {
      let filteredValue = e.target.value.replace(/[^\d]/g, "");
      document
        .getElementById("mobilePhone")
        .addEventListener("focusout", (event) => {
          if (filteredValue.length > 0) {
            this.setState({ showConfirmMobilePhone: true });
            this.setState({
              error: { ...this.state.error, confirmMobilePhone: false },
            });
          }
        });

      if (filteredValue.length === 1 && filteredValue !== "0") {
        await this.setState({
          data: { ...this.state.data, [e.target.name]: "0" + filteredValue },
        });
      } else if (filteredValue.length <= 10) {
        await this.setState({
          data: { ...this.state.data, [e.target.name]: filteredValue },
        });
      } else {
        return;
      }
    } else if (e.target.name === "confirmMobilePhone") {
      let filteredValue = e.target.value.replace(/[^\d]/g, "");
      if (filteredValue.length === 1 && filteredValue !== "0") {
        await this.setState({
          data: { ...this.state.data, [e.target.name]: "0" + filteredValue },
        });
      } else if (filteredValue.length <= 10) {
        await this.setState({
          data: { ...this.state.data, [e.target.name]: filteredValue },
        });
      } else {
        return;
      }
    } else if (e.target.name === "name" || e.target.name === "lastName") {
      let isThaiCharacter =
        /^[ก-์ ]+$/.test(e.target.value) || e.target.value === "";
      if (isThaiCharacter) {
        await this.setState({
          data: { ...this.state.data, [e.target.name]: e.target.value },
        });
      }
    } else {
      await this.setState({
        data: { ...this.state.data, [e.target.name]: e.target.value },
      });
    }
    if (
      this.state.data.mobilePhone &&
      this.state.data.confirmMobilePhone &&
      this.state.data.name &&
      this.state.data.lastName
    ) {
      this.setState({ disableBtn: false });
    } else {
      this.setState({ disableBtn: true });
    }
  };
  handleSelectChange(name, value, code) {
    this.setState({
      data: { ...this.state.data, [name]: value, contractTimeCode: code },
    });
    if (
      this.state.data.mobilePhone &&
      this.state.data.confirmMobilePhone &&
      this.state.data.name &&
      this.state.data.lastName
    ) {
      this.setState({ disableBtn: false });
    } else {
      this.setState({ disableBtn: true });
    }
  }
  onProcess = async () => {
    //เช็คว่ามีข้อมูลที่จำเป็นหรือไม่
    this.props.loadController(true);
    if (
      !this.state.data.mobilePhone ||
      !this.state.data.mobilePhone ||
      !this.state.data.name ||
      !this.state.data.lastName
    ) {
      this.setState({
        error: {
          mobilePhone: !this.state.data.mobilePhone ? true : false,
          confirmMobilePhone: !this.state.data.confirmMobilePhone
            ? true
            : false,
          name: !this.state.data.name ? true : false,
          lastName: !this.state.data.lastName ? true : false,
        },
      });
      this.props.loadController(false);
      return;
    }
    if (this.state.data.mobilePhone.length !== 10) {
      this.setState({
        error: {
          mobilePhone: true,
        },
      });
      this.props.loadController(false);
      return;
    }
    if (this.state.data.confirmMobilePhone.length !== 10) {
      this.setState({
        error: {
          confirmMobilePhone: true,
        },
      });
      this.props.loadController(false);
      return;
    }
    if (this.state.data.mobilePhone !== this.state.data.confirmMobilePhone) {
      this.setState({
        error: {
          confirmMobilePhone: true,
        },
      });
      this.props.loadController(false);
      return;
    }
    //token captcha
    await window.grecaptcha.enterprise
      .execute("6LfHd6QpAAAAAEcUg4RjPaPk8rtLiyHulY0YrUJV", {
        action: "drop_lead",
      })
      .then((token) => {
        this.setState({ dataTokenCaptcha: token });
      })
      .catch((error) => {
        this.props.loadController(false);
        this.props.setStepContactBack(4);
      });
    if (
      this.state.dataTokenCaptcha === "" ||
      this.state.dataTokenCaptcha === null
    ) {
      this.props.loadController(false);
      this.props.setStepContactBack(4);
    } else {
      const headers = {
        Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      };
      MainData.dataBroker.contactBack.mobilePhone = this.state.data.mobilePhone;
      MainData.dataBroker.contactBack.confirmMobilePhone =
        this.state.data.confirmMobilePhone;
      MainData.dataBroker.contactBack.name = this.state.data.name;
      MainData.dataBroker.contactBack.lastName = this.state.data.lastName;
      MainData.dataBroker.contactBack.contactTime = this.state.data.contactTime;
      MainData.dataBroker.contactBack.contactTimeCode =
        this.state.data.contractTimeCode;
      const data = {
        token: this.state.dataTokenCaptcha,
        channel: MainData.initialData.channelCode,
        productId: MainData.initialData.productId,
        firstName: this.state.data.name.split(" ")[0].trim(),
        lastName: this.state.data.lastName.split(" ")[0].trim(),
        phoneNumber: this.state.data.mobilePhone,
        contactTimeCode: this.state.data.contractTimeCode,
      };
      axios
        .post(MainData.serviceURL + "/Broker/reCaptcha", data, {
          headers: headers,
        })
        .then((res) => {
          if (res.status === 299) {
            window.location.href = MainData.mainPath + "shutdown";
            return;
          }
          if (res.data.success) {
            this.props.setStepContactBack(2);
            MainFunc.sendDataToGTMKbank({
              event: "ipdopd_register_complete",
              event_category: "form_submit",
              event_action: "success",
              event_label: "register_complete",
            });
          } else if (res.data.message === "MSG188") {
            this.props.setStepContactBack(4);
          } else {
            this.props.setStepContactBack(3);
          }
          this.props.loadController(false);
        })
        .catch((error) => {
          if (error?.response?.status == 400) {
            this.props.loadController(false);
            this.props.setStepContactBack(3);
          } else {
            this.props.loadController(false);
            this.props.setStepContactBack(3);
          }
        });
    }
  };

  render() {
    return (
      <div className={styles.contactBack}>
        <div className={styles.scStep} data-step="1">
          <div className="container">
            <div className="sc-layout">
              <div className="sc-form">
                <div className={styles.scHeading}>
                  <div className={styles.h1}>
                    <strong>กรอกข้อมูลเพื่อให้ผู้เชี่ยวชาญติดต่อกลับ</strong>
                  </div>
                  <div className="form-step-wrapper">
                    <fieldset className={styles.fieldset}>
                      <div className={styles.field}>
                        <PreInput
                          id="mobilePhone"
                          type="text"
                          className="Mandatory placeholder"
                          name="mobilePhone"
                          placeholder={
                            MainFunc.getLabelDtos(
                              "L0796"
                            ) /*กรุณากรอกเบอร์มือถือ*/ + "*"
                          }
                          tabindex="1"
                          maxlength="10"
                          label={MainFunc.getLabelDtos("L0468") /*เบอร์มือถือ*/}
                          value={this.state.data?.mobilePhone}
                          onChange={(e) => this.handleChange(e)}
                          error={this.state.error?.mobilePhone}
                          helpText={
                            MainFunc.getLabelDtos(
                              "L0796"
                            ) /*กรุณากรอกเบอร์มือถือ*/
                          }
                        />
                      </div>
                      {this.state.showConfirmMobilePhone && (
                        <div className={styles.field}>
                          <PreInput
                            id="confirmMobilePhone"
                            type="text"
                            className="Mandatory placeholder"
                            name="confirmMobilePhone"
                            placeholder={
                              MainFunc.getLabelDtos(
                                "L0797"
                              ) /*ยืนยันเบอร์มือถือ*/ + "*"
                            }
                            tabindex="1"
                            maxlength="10"
                            label={
                              MainFunc.getLabelDtos(
                                "L0797"
                              ) /*ยืนยันเบอร์มือถือ*/
                            }
                            value={this.state.data?.confirmMobilePhone}
                            onChange={(value) => this.handleChange(value)}
                            error={this.state.error?.confirmMobilePhone}
                            helpText={
                              MainFunc.getLabelDtos(
                                "L0798"
                              ) /*กรุณากรอกยืนยันเบอร์มือถือให้ถูกต้อง*/
                            }
                            // hide={true}
                          />
                        </div>
                      )}
                      <div className={styles.field}>
                        <PreInput
                          id="name"
                          type="text"
                          className="placeholder"
                          name="name"
                          placeholder={
                            MainFunc.getLabelDtos("L0799") /*กรุณากรอกชื่อ*/ +
                            "*"
                          }
                          tabindex="1"
                          label={MainFunc.getLabelDtos("L0080") /*ชื่อ*/}
                          value={this.state.data?.name}
                          onChange={(e) => this.handleChange(e)}
                          error={this.state.error?.name}
                          helpText={
                            MainFunc.getLabelDtos("L0799") /*กรุณากรอกชื่อ*/
                          }
                        />
                      </div>
                      <div className={styles.field}>
                        <PreInput
                          id="lastName"
                          type="text"
                          className="placeholder"
                          name="lastName"
                          placeholder={
                            MainFunc.getLabelDtos(
                              "L0800"
                            ) /*กรุณากรอกนามสกุล*/ + "*"
                          }
                          tabindex="1"
                          label={MainFunc.getLabelDtos("L0082") /*นามสกุล*/}
                          value={this.state.data?.lastName}
                          onChange={(e) => this.handleChange(e)}
                          error={this.state.error?.lastName}
                          helpText={
                            MainFunc.getLabelDtos("L0800") /*กรุณากรอกนามสกุล*/
                          }
                        />
                      </div>
                      <div className={styles.field}>
                        <PreSelect
                          id="contactTime"
                          name="contactTime"
                          label={
                            MainFunc.getLabelDtos(
                              "L0470"
                            ) /*ช่วงเวลาที่สะดวกให้ติดต่อกลับ*/
                          }
                          placeholder={
                            MainFunc.getLabelDtos(
                              "L0801"
                            ) /*กรุณาเลือกช่วงเวลาที่สะดวกให้ติดต่อกลับ*/
                          }
                          helpText={
                            MainFunc.getLabelDtos(
                              "L0801"
                            ) /*กรุณาเลือกช่วงเวลาที่สะดวกให้ติดต่อกลับ*/
                          }
                          value={this.state.data?.contactTime}
                          onChange={(name, value, code) =>
                            this.handleSelectChange(name, value, code)
                          }
                        />
                      </div>
                    </fieldset>

                    <div className={styles.consent}>
                      {
                        MainFunc.getLabelDtos(
                          "L0802"
                        ) /*บมจ.ธนาคารกสิกรไทย และบมจ.เมืองไทยประกันชีวิต จะเก็บรวบรวม
                      ใช้เปิดเผยข้อมูลส่วนบุคคลของคุณ เพื่อติดต่อ
                      นำเสนอและประชาสัมพันธ์ ผลิตภัณฑ์และบริการที่คุณสนใจ
                      โปรดศึกษารายละเอียดและสิทธิใน*/
                      }
                      <a
                        title="นโยบายการคุ้มครองข้อมูลส่วนบุคคล"
                        className="pxtm-click-linkClick"
                        id="LinkPolicy"
                        href="https://www.kasikornbank.com/th/privacy-policy/pages/privacy-policy.aspx"
                      >
                        <u>
                          {
                            MainFunc.getLabelDtos(
                              "L0803"
                            ) /*นโยบายการคุ้มครองข้อมูลส่วนบุคคล*/
                          }
                        </u>
                      </a>{" "}
                      และ{" "}
                      <a
                        title="นโยบายความเป็นส่วนตัว"
                        className="pxtm-click-linkClick"
                        id="LinkPolicy"
                        href="https://www.kasikornbank.com/th/disclaimer/Pages/disclaimers.aspx?redirect=aHR0cHM6Ly93d3cubXVhbmd0aGFpLmNvLnRoL3RoL3ByaXZhY3ktcG9saWN5"
                      >
                        <u>
                          {
                            MainFunc.getLabelDtos(
                              "L0141"
                            ) /*นโยบายความเป็นส่วนตัว*/
                          }
                        </u>
                      </a>
                    </div>

                    <div
                      id="applyNextButton"
                      className="action next disabled-action"
                      disable=""
                    >
                      <button
                        id="DropleadNextButton"
                        tabindex="1"
                        className={styles.btn}
                        onClick={() => this.onProcess()}
                        disabled={this.state.disableBtn}
                      >
                        {MainFunc.getLabelDtos("L0782") /*ส่งข้อมูล*/}
                      </button>
                    </div>
                    <p className={styles.reCaptcha}>
                      {
                        MainFunc.getLabelDtos(
                          "L0804"
                        ) /*This site is protected by reCAPTCHA*/
                      }{" "}
                      <br />
                      {MainFunc.getLabelDtos("L0805") /*and the Google*/}
                      <a
                        title="Privacy Policy"
                        className="pxtm-click-linkClick"
                        href="#"
                      >
                        {MainFunc.getLabelDtos("L0806") /*Privacy Policy*/}
                      </a>{" "}
                      and{" "}
                      <a
                        title="Terms of Service"
                        className="pxtm-click-linkClick"
                        href="#"
                      >
                        {MainFunc.getLabelDtos("L0807") /*Terms of Service*/}
                      </a>{" "}
                      {MainFunc.getLabelDtos("L0808") /*apply*/}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactBack;
