import React, { Component } from "react";
import styles from "../../styles/contact-back-Fail.module.scss";
import ContactBackFailIcon from "../../images/ContactBackFailIcon.svg";
import MainFunc from "../../models/MainFunc";
class MyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initialize your state here
    };
  }

  render() {
    return (
      // JSX code for rendering your component
      <div className={styles.contactBackFailIcon}>
        <div className="container">
          <h1>{MainFunc.getLabelDtos("L0476") /*ขออภัยในความไม่สะดวก*/}</h1>
          <h2>
            {MainFunc.getLabelDtos("L0477") /*กรุณาลองใหม่อีกครั้ง*/}
            <br />
            {MainFunc.getLabelDtos("L0787") /*ขอขอบคุณที่ท่านให้ความสนใจ*/}
          </h2>
          <div className={styles.img}>
            <img src={ContactBackFailIcon} />
          </div>
        </div>
        <h2>{MainFunc.getLabelDtos("L0780") /*ขอบคุณค่ะ*/}</h2>
        <div>
          <a
            onClick={() => this.props.setStepContactBack(1)}
            className={styles.btnInsure}
            title="ปิด"
          >
            {MainFunc.getLabelDtos("L0188") /*ปิด*/}
          </a>
        </div>
      </div>
    );
  }
}

export default MyComponent;
