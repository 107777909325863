import moment from "moment";
import React, { Component, Fragment } from "react";
import localization from "moment/locale/th";
import { Row, Col, Button, Image, Container } from "react-bootstrap";
import QRCode from "react-qr-code";
import "moment/locale/th";
import axios from "axios";
import { sha256 } from 'js-sha256';
// import * as workerInterval from "worker-interval";
import { GoAlert } from "react-icons/go";
import SweetAlert from "react-bootstrap-sweetalert";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
import LogoKplus1g from "../../images/logo_kplus_1_g.svg";
import LogoKplus2g from "../../images/logo_kplus_2_g.svg";
import LogoKplus3g from "../../images/logo_kplus_3_g.svg";
import LogoKplus4g from "../../images/logo_kplus_4_g.svg";
import LogoKplus1 from "../../images/logo_kplus_1.svg";
import LogoKplus2 from "../../images/logo_kplus_2.svg";
import LogoKplus3 from "../../images/logo_kplus_3.svg";
import LogoKplus4 from "../../images/logo_kplus_4.svg";

moment().locale("th", localization).add(543, "year").format("LLL");

class SM001 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SMKPlus",
      textFinal: "",
      device: "",
      browsers: "",
      showQr: false,
      showKplusSuccess: false,
      valueQr: "",
      tokenId: "",
      isErrorBirthday: false,
      isErrorBirthdayDesc: "",
      isError: false,
      errMsg: "",
      errSubMsg: "",
      errCode: "",
      kplusData: null,
      planData: null,
      skipPage: false,
      skipPageMsg: false,
      seconds: parseInt(MainFunc.getConfigDtos("CF039")),
    };
  }

  componentDidMount() {
    this.setState({
      device: this.getMobileOperatingSystem(),
      browsers: this.detectBrowsers(),
    });
    if (MainData.initialData.title.length == 0) {
      this.getMasterData();
    }
  }

  startTime = () => {
    this.setState({ otpDisable: false, failOTP: false });
    this.myInterval = setInterval(() => {
      const { seconds } = this.state;
      if (seconds > 0) {
        this.setState(({ seconds }) => ({ seconds: seconds - 1 }));
      } else {
        clearInterval(this.myInterval);
        this.gotoKplus();
      }
    }, 1000);
  };

  getMasterData = () => {
    //เพื่อดึงค่า dropdown ต่างๆมาลง
    axios
      .get(MainData.serviceURL + "/MasterData/getMasterData", {
        headers: {
          Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
        },
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let newmasterDataRes = res.data.data;
        MainData.initialData.title = newmasterDataRes.title;
        MainData.initialData.titleEN = newmasterDataRes.titleEN;
        MainData.initialData.province = newmasterDataRes.province;
        MainData.initialData.occupation = newmasterDataRes.occupation;
        MainData.initialData.relationship = newmasterDataRes.relationship;
        MainData.initialData.confirmMessage = newmasterDataRes.confirmMessage;
        MainData.initialData.importantInformation =
          newmasterDataRes.importantInformation;
        MainData.initialData.natureOfBusinesses =
          newmasterDataRes.natureOfBusinesses;
      })
      .catch((error) => {
        if (
          error?.response?.status == 404 &&
          error?.response?.data?.data?.messageCode == "MSG1001"
        ) {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.props.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
        } else {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.props.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
        }
      });
  };

  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "";
  };

  detectBrowsers = () => {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      return "Opera";
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
      return "Edge";
    } else if (
      navigator.userAgent.indexOf("Chrome") != -1 ||
      navigator.userAgent.indexOf("CriOS") != -1
    ) {
      return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return "Firefox";
    } else if (
      navigator.userAgent.indexOf("MSIE") != -1 ||
      !!document.documentMode == true
    ) {
      //IF IE > 10
      return "IE";
    } else {
      return "unknown";
    }
  };

  gotoKplus = (click) => {
    if(click){
      MainFunc.sendDataToGTMKbank({
        'event': 'track_event',
        'event_category': 'link_click',
        'event_action': 'click_connect',
        'event_label': 'kplus',
        'flag_kplus' : 1,
        'flag_page' : 'onboard01' 
      });
    }
    this.setState({ isError: false, errMsg: "ERROR!" });
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      transUrl: window.location.href,
      submenuCode: "SM031",
    };
    var newTab;
    if (this.state.device && this.state.browsers == "Safari") {
      newTab = window.open("", "_blank");
    }
    axios
      .post(MainData.serviceURL + "/KInfoSharing/GetSharingUrl", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data;
        if (data.status.status) {
          if (this.state.device) {
            if (this.state.browsers == "Safari") {
              newTab.location.href = data?.data?.token.link;
            } else {
              window.open(data?.data?.token.link, "_blank");
            }
            setTimeout(() => {
              this.props.loadController(false);
              this.setState({
                showQr: true,
                valueQr:
                  data?.data?.token?.qr ??
                  "https://online-uat.muangthai.co.th/th/",
              });
              this.props.showQrKplus(true);
            }, 10000);
          } else {
            this.props.loadController(false);
            this.setState({
              showQr: true,
              valueQr:
                data?.data?.token?.qr ??
                "https://online-uat.muangthai.co.th/th/",
            });
            this.props.showQrKplus(true);
          }
          this.setState({ tokenId: data?.data?.token?.tokenId });
          if (
            sessionStorage.getItem("_cookie") != "true" &&
            this.state.device
          ) {
            this.addOrUpdateCustomerBirthday(true);
          } else if (
            sessionStorage.getItem("_cookie") != "true" &&
            !this.state.device
          ) {
            this.addOrUpdateCustomerBirthday(true, true);
          } else {
            this.callServiceGetInfo();
          }
        } else {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === data.status.messageCode
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription,
            " ",
            data.status.messageCode
          );
          this.props.loadController(false);
        }
      })
      .catch((error) => {
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG164"
        );
        this.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " ",
          "MSG1001"
        );
        this.props.loadController(false);
      });
  };

  callServiceGetInfo = () => {
    window.intervalCallServiceGetInfo = window.setInterval(() => {
      const headers = {
        Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      };
      const data = {
        tokenId: this.state.tokenId ? this.state.tokenId : "",
        isCheckTimeOut: true,
      };
      axios
        .post(MainData.serviceURL + "/KInfoSharing/getinfo", data, {
          headers: headers,
        })
        .then((res) => {
          if (res.status === 299) {
            window.location.href = MainData.mainPath + "shutdown";
            return;
          }
          let data = res?.data?.data;
          if (res?.data.status.status) {
            if (!this.state.device) {
              MainFunc.sendDataToGTMKbank({
                'event': 'track_event',
                'event_category': 'link_click',
                'event_action': 'click',
                'event_label': 'step_qr_success',
                'flag_kplus' : 1 
              });
              if (
                MainFunc.formatDateTimeDisplay(
                  data.customerInfo.brthDt,
                  "",
                  "TH"
                ) == MainData.screenSM001.birthdate
              ) {
                MainData.screenMenuKPlus.onKPlus = true;
                this.mapDataInFo(data);
                window.clearTimeout(window.intervalCallServiceGetInfo)
                this.props.onCancel();
              } else {
                if (data.customerInfo.brthDt) {
                  window.clearTimeout(window.intervalCallServiceGetInfo)
                  this.setState({ kplusData: data });
                  MainData.screenMenuKPlus.onKPlus = true;
                  MainData.screenSM001.birthdate =
                    MainFunc.formatDateTimeDisplay(
                      data.customerInfo.brthDt,
                      "",
                      "TH"
                    );
                  this.getData();
                }
              }
            } else {
              if (data.customerInfo.brthDt) {
                window.clearTimeout(window.intervalCallServiceGetInfo)
                let errMsg = MainData.masterMessage.find(
                  (x) => x.msgCode === "MSG171"
                );
                this.setState({
                  showKplusSuccess: true,
                  showQr: false,
                  textFinal: errMsg.msgDescription,
                });
                this.props.showQrKplus(false);
                setTimeout(() => {
                  this.props.scrollContentSectionTop();
                }, 100);
              }
            }
          } else if (
            res?.data?.status?.messageCode == "MSG167" ||
            !this.state.device
          ) {
            window.clearTimeout(window.intervalCallServiceGetInfo)
            let errMsg = MainData.masterMessage.find(
              (x) => x.msgCode === res?.data?.status?.messageCode
            );
            this.errorAlert(
              true,
              !errMsg
                ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
                : errMsg.msgDescription,
              " ",
              res?.data?.status?.messageCode
            );
            this.props.loadController(false);
          } else {
            window.clearTimeout(window.intervalCallServiceGetInfo)
            let errMsg = MainData.masterMessage.find(
              (x) => x.msgCode === res?.data?.status?.messageCode
            );
            this.setState({
              showKplusSuccess: true,
              showQr: false,
              textFinal: errMsg.msgDescription,
            });
            this.props.showQrKplus(false);
            this.props.loadController(false);
            setTimeout(() => {
              this.props.scrollContentSectionTop();
            }, 100);
          }
        })
        .catch((error) => {
          window.clearTimeout(window.intervalCallServiceGetInfo)
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " ",
            "MSG1001"
          );
          this.props.loadController(false);
        });
    }, 10000);
  };

  mapDataInFo = async (data) => {
    const customerInfo = data.customerInfo;
    const registrationAddr = data.registrationAddr;
    const contactAddr = data.contactAddr;
    MainData.screenSM005.idCard = customerInfo.citizenID;

    MainData.screenSM006.phoneNumber = customerInfo.mobNo ?? "";

    MainData.screenSM008.job = customerInfo.occupationCode ?? "";
    MainData.screenSM008.email = customerInfo.email ?? "";
    MainData.screenSM008.lastName = customerInfo.thLstName ?? "";
    MainData.screenSM008.titleName = customerInfo.thTitle ?? "";
    MainData.screenSM008.firstName = customerInfo.thFstName ?? "";
    MainData.screenSM008.titleNameEN = customerInfo.enTitle ?? "";
    MainData.screenSM008.firstNameEN = customerInfo.enFstName ?? "";
    MainData.screenSM008.lastNameEN = customerInfo.enLstName ?? "";
    MainData.screenSM008.idCardExp = "";
    MainData.screenSM008.jobDescription =
      customerInfo.natureOfBusinessDescription;
    MainData.screenSM008.idCardExpDateNone = "";

    let checkRegistrationProvince = MainData.initialData.province.find(
      (x) => x.provinceCode === contactAddr.provinceCd
    );
    let checkRegistrationDistrict = checkRegistrationProvince?.district?.find(
      (x) => x.districtCode === contactAddr.districtCd
    );
    let checkRegistrationsubDistrict =
      checkRegistrationDistrict?.subDistrict?.find(
        (x) => x.subDistrictCode === contactAddr.subDistrictCd
      );
    MainData.screenSM009.address = contactAddr.mailNo ?? "";
    MainData.screenSM009.province =
      checkRegistrationProvince?.provinceTitle ?? "";
    MainData.screenSM009.district =
      checkRegistrationDistrict?.districtTitle ?? "";
    MainData.screenSM009.subDistrict =
      checkRegistrationsubDistrict?.subDistrictTitle ?? "";
    MainData.screenSM009.village = contactAddr.village ?? "";
    MainData.screenSM009.moo = contactAddr.moo ?? "";
    MainData.screenSM009.soi = contactAddr.soi ?? "";
    MainData.screenSM009.street = contactAddr.road ?? "";
    MainData.screenSM009.zipCode = contactAddr.postCd ?? "";

    let checkCurrentProvince = MainData.initialData.province.find(
      (x) => x.provinceCode === registrationAddr.provinceCd
    );
    let checkCurrentDistrict = checkCurrentProvince?.district?.find(
      (x) => x.districtCode === registrationAddr.districtCd
    );
    let checkCurrentsubDistrict = checkCurrentDistrict?.subDistrict?.find(
      (x) => x.subDistrictCode === registrationAddr.subDistrictCd
    );
    MainData.screenSM009Preview.isSameAddress = false;
    MainData.screenSM009.formalAddress = registrationAddr.mailNo ?? "";
    MainData.screenSM009.formalProvince =
      checkCurrentProvince?.provinceTitle ?? "";
    MainData.screenSM009.formalDistrict =
      checkCurrentDistrict?.districtTitle ?? "";
    MainData.screenSM009.formalSubDistrict =
      checkCurrentsubDistrict?.subDistrictTitle ?? "";
    MainData.screenSM009.formalVillage = registrationAddr.village ?? "";
    MainData.screenSM009.formalMoo = registrationAddr.moo ?? "";
    MainData.screenSM009.formalSoi = registrationAddr.soi ?? "";
    MainData.screenSM009.formalStreet = registrationAddr.road ?? "";
    MainData.screenSM009.formalZipCode = registrationAddr.postCd ?? "";
    if (data.imageToken) {
      let base64 = await this.getFileFromUrl(data.imageToken);
      let object_file = this.dataURLtoFile(
        base64,
        this.checkTypeFile(base64, "faceShape")
      );
      MainData.screenSM014.uploadedFilesFace_shape = [
        {
          data: base64,
          file: object_file,
        },
      ];
    }
    if (customerInfo.mobNo && data.imageToken && data.checksum) {
      let token256 = sha256(
        this.state.tokenId + data.imageToken + customerInfo.mobNo
      );
      if (data.checksum == token256) {
        MainData.screenMenuKPlus.rquidStatus = true;
      }
    }
    if (!this.state.kplusData) {
      this.props.onCancel();
    }
  };

  getData = () => {
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      channelCode: MainData.initialData.channelCode,
      productId: MainData.initialData.productId,
      birthDate: MainFunc.getUserBirthDate("be"),
      gender: MainData.screenSM001.gender,
      grantee: "SELF",
    };
    var dataForGet = data;
    axios
      .post(MainData.serviceURL + "/Sales/GetProduct", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (!data) {
          MainFunc.sendLogToService(
            this.props.name,
            "Search",
            "Fail",
            "เก็บข้อมูลการค้นหาแผนประกัน",
            {},
            dataForGet
          );
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG004"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "ขออภัย ไม่พบข้อมูลแบบประกันที่สามารถเลือกซื้อได้"
              : errMsg.msgDescription,
            " ",
            "GetProduct"
          );
        } else {
          let plan = data.planList.find(
            (item) => item.planId == MainData.screenSM002.planId
          );
          let planPremiumMode = plan.planPremiumMode.find(
            (item) => item.paymentId == MainData.screenSM002.paymentId
          );
          plan.planPremiumMode = planPremiumMode;
          plan.productName = data.productName;
          this.setState({ planData: plan });
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG168"
          );
          this.setState({
            isErrorBirthday: true,
            isErrorBirthdayDesc: !errMsg
              ? "วัน เดือน ปีเกิด ที่คุณระบุไม่ตรงกับฐานข้อมูลของธนาคาร ระบบจะทําการคํานวณเบี้ยตามฐานข้อมูล วันที่ {{birthDateKplus}} ซึ่งค่าเบี้ยของคุณคือ {{premium}} กรุณากดปิดเพื่อเข้าสู่หน้าเลือกแผนความคุ้มครอง"
              : errMsg.msgDescription
                  .replace(
                    "{{birthDateKplus}}",
                    MainData.screenSM001.birthdate.split("/")[0] +
                      " " +
                      MainFunc.setNewThaiMonth(
                        parseInt(MainData.screenSM001.birthdate.split("/")[1]) -
                          1,
                        true
                      ) +
                      " " +
                      MainData.screenSM001.birthdate.split("/")[2]
                  )
                  .replace(
                    "{{premium}}",
                    MainFunc.numberWithCommas(plan.planPremiumMode.premium) +
                      ` ${MainFunc.getLabelDtos("L0104")}`
                  ),
          });
        }
        this.props.loadController(false);
      })
      .catch((error) => {
        this.props.loadController(false);
        MainFunc.sendLogToService(
          this.props.name,
          "Search",
          "Fail",
          "เก็บข้อมูลการค้นหาแผนประกัน",
          {},
          dataForGet
        );
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
      });
  };

  skipPremiumCalPage = () => {
    MainData.screenSM002.paymentCode = null;
    MainData.screenSM002.planId = null;
    MainData.screenSM002.productName = "";
    MainData.screenSM002.planName = "";
    MainData.screenSM002.paymentName = "";
    MainData.screenSM002.cost = 0;
    MainData.screenSM002.costPayment = 0;
    MainData.screenSM002.paymentId = null;
    MainData.screenSM002.insureRequiredIdentification = true;
    MainData.screenSM002.insureSelfie = true;
    MainData.screenSM002.screen = "";
    this.mapDataInFo(this.state.kplusData);
    if (sessionStorage.getItem("_cookie") == "true") {
      this.addOrUpdateCustomerBirthday();
    } else {
      this.props.setPage("2");
      this.props.onCancel();
    }
  };

  skipHAndFPage = () => {
    let planData = this.state.planData;
    MainData.screenSM002.paymentCode = planData.planPremiumMode.paymentCode;
    MainData.screenSM002.planId = planData.planId;
    MainData.screenSM002.productName = planData.productName;
    MainData.screenSM002.planName = planData.planName;
    MainData.screenSM002.paymentName = planData.planPremiumMode.paymentName;
    MainData.screenSM002.cost = planData.planPremiumMode.premium;
    MainData.screenSM002.costPayment = planData.planPremiumMode.premiumPayment;
    MainData.screenSM002.paymentId = planData.planPremiumMode.paymentId;
    MainData.screenSM002.insureRequiredIdentification =
      planData.planPremiumMode.insureRequiredIdentification;
    MainData.screenSM002.insureSelfie = planData.planPremiumMode.insureSelfie;
    MainData.screenSM002.screen = "";
    this.mapDataInFo(this.state.kplusData);
    let subHeader = MainFunc.getLabelDtos("L0112") + " : ";
    this.props.setHeaderText(
      MainData.productTitle,
      subHeader +
        MainFunc.numberWithCommas(MainData.screenSM002.cost) +
        " " +
        MainFunc.getLabelDtos("L0104"),
      MainData.screenSM002.planName,
      true
    );
    if (sessionStorage.getItem("_cookie") == "true") {
      this.addOrUpdateCustomerBirthday(true);
    } else {
      this.props.onCancel();
    }
  };

  addOrUpdateCustomerBirthday = (savePlan, kplus) => {
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      AuthId: !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId,
    };
    const data = {
      channelCode: MainData.initialData.channelCode,
      productId: MainData.initialData.productId,
      gender: MainData.screenSM001.gender,
      birthDate: MainFunc.getUserBirthDate("ac"),
      age: moment().diff(MainFunc.getUserBirthDate("ac"), "years"),
      transUrl: window.location.href,
      submenuCode: "SM001",
    };
    axios
      .post(
        MainData.serviceURL + "/Customer/AddOrUpdateCustomerBirthday",
        data,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        if (res.data.success === true) {
          sessionStorage.setItem("_cookie", "true");
          MainData.screenSM005.cusId = res.data.data;
          if (savePlan) {
            this.saveDataProduct(kplus);
          } else {
            this.props.loadController(false);
            if (!this.state.device && !kplus) {
              this.props.setPage("2");
              this.props.onCancel();
            }
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          this.props.loadController(false);
        } else {
          this.props.loadController(false);
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
        }
      });
  };

  saveDataProduct = (kplus) => {
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      AuthId: MainData.screenSM005.cusId,
    };
    const data = {
      planId: MainData.screenSM002.planId,
      premium: MainData.screenSM002.cost,
      paymentId: MainData.screenSM002.paymentId,
      paymentMode: MainData.screenSM002.paymentCode,
      submenuCode: "SM002",
    };
    axios
      .post(
        MainData.serviceURL + "/Customer/AddOrUpdateCustomerPremiumCal",
        data,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        if (res.data.success === true) {
          MainData.screenSM005.cusId = res.data.data;
          if (!this.state.device && !kplus) {
            this.props.loadController(false);
            this.props.onCancel();
          } else {
            this.props.loadController(false);
            this.callServiceGetInfo();
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          this.props.loadController(false);
          this.validateBadRequest(error.response.data.errors);
        } else {
          this.props.loadController(false);
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
        }
      });
  };

  onCancel = (click) => {
    if (click) {
      MainFunc.sendDataToGTMKbank({
        'event': 'track_event',
       'event_category': 'link_click',
       'event_action': 'click_connect',
       'event_label': 'non_kplus',
       'flag_kplus' : 0,
       'flag_page' : 'onboard01'
        });
    }
    if (window.intervalCallServiceGetInfo) {
      window.clearTimeout(window.intervalCallServiceGetInfo)
    }
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      tokenId: this.state.tokenId ? this.state.tokenId : "",
      submenuCode: "SM032",
    };
    axios
      .post(MainData.serviceURL + "/KInfoSharing/CancelToken", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res?.data?.data;
        if (res?.data.status.status) {
          this.props.onCancel();
        } else {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === res?.data?.status?.messageCode
          );
          this.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription,
            " ",
            res?.data?.status?.messageCode
          );
          this.props.loadController(false);
        }
      })
      .catch((error) => {
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " ",
          "MSG1001"
        );
      });
  };

  errorAlert = (isErr, msg, subMsg, msgCode) => {
    this.setState({
      isError: isErr,
      errMsg: msg,
      errSubMsg: !subMsg ? null : subMsg,
      errCode: msgCode,
    });
  };
  errorConfirm = () => {
    if (this.state.errCode == "GetProduct") {
    } else {
      this.props.onCancel();
    }
    this.setState({ isError: false, errMsg: "ERROR!", errCode: "" });
  };

  getFileFromUrl = async (token) => {
    const data = await fetch(`${MainData.serviceURL}/Customer/GetPicture`, {
      headers: {
        Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
        AuthId: !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId,
        ImageToken: token,
        "language-locale": process.env.NEXT_PUBLIC_LANGUAGE_LOCALE,
      },
    });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  checkTypeFile = (base64, name) => {
    if (base64.includes("image/jpg")) {
      return name + ".jpg";
    } else if (base64.includes("image/png")) {
      return name + ".png";
    } else if (base64.includes("image/jpeg")) {
      return name + ".jpeg";
    } else if (base64.includes("application/pdf")) {
      return name + ".pdf";
    }
  };

  render() {
    return (
      <div
        className={`container-fulid pb-4 ${
          this.state.showQr ? "pt-4" : "k-plus"
        } mg--l`}
      >
        <SweetAlert
          show={this.state.isErrorBirthday}
          title={""}
          customIcon={
            <GoAlert size={70} className="text-center w-100 text-danger pb-2" />
          }
          custom
          onConfirm={() => {
            this.setState({ isErrorBirthday: false });
          }}
          showCancel
          closeOnClickOutside={false}
          customButtons={
            <Row>
              <Col xs={12}>
                <Button
                  onClick={() => this.skipHAndFPage()}
                  className="mainBtnRadius"
                >
                  {MainFunc.getLabelDtos("L0029") /* ยืนยัน */}
                </Button>
              </Col>
            </Row>
          }
        >
          <span
            className="text--muted"
            dangerouslySetInnerHTML={{ __html: this.state.isErrorBirthdayDesc }}
          ></span>
        </SweetAlert>
        <SweetAlert
          show={this.state.isError}
          title={""}
          customIcon={
            <GoAlert size={70} className="text-center w-100 text-danger pb-2" />
          }
          custom
          onConfirm={this.errorConfirm}
          showCancel
          closeOnClickOutside={false}
          customButtons={
            this.state.errCode == "MSG167" ? (
              <Row className="twob">
                <Col xs={6}>
                  <Button
                    onClick={() =>
                      this.state.tokenId
                        ? this.gotoKplus()
                        : this.gotoKplus()
                    }
                    className="subBtnRadius"
                  >
                    {MainFunc.getLabelDtos("L0668") /* ยืนยันตัวตน */}
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button onClick={this.errorConfirm} className="mainBtnRadius">
                    {MainFunc.getLabelDtos("L0669") /* ทํารายการต่อ */}
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={12}>
                  <Button onClick={this.errorConfirm} className="mainBtnRadius">
                    {MainFunc.getLabelDtos("L0188") /* ปิด */}
                  </Button>
                </Col>
              </Row>
            )
          }
        >
          <h5
            dangerouslySetInnerHTML={{ __html: this.state.errMsg }}
          ></h5>
          <span
            className="text--muted"
            dangerouslySetInnerHTML={{
              __html: !this.state.errSubMsg
                ? MainFunc.getLabelDtos(
                    "L0670"
                  ) /* ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา */
                : this.state.errSubMsg,
            }}
          ></span>
        </SweetAlert>
        {this.state.showQr ? (
          <Fragment>
            <Row className="mb-4">
              <Col
                xs={12}
                className="d-flex justify-content-center text-center"
              >
                <div
                  className="textTitleKplus"
                  dangerouslySetInnerHTML={{
                    __html:
                      MainFunc.getLabelDtos(
                        "L0671"
                      ) /* ขั้นตอนการดำเนินการบน K Plus */,
                  }}
                ></div>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "var(--subColor)",
                padding: "20px",
                margin: 0,
                alignItems: "flex-start",
              }}
            >
              <Col
                xs={6}
                md={3}
                className="d-flex justify-content-center text-center mb-4"
              >
                <Row>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center text-center"
                  >
                    <div className="textLogoKplus">1</div>

                    <Image src={MainData.initialData.channelCode == "KBANK"?LogoKplus1g:LogoKplus1} className="logoKplus" rounded />
                  </Col>
                  <Col
                    xs={12}
                    className="textKplusDesc"
                    dangerouslySetInnerHTML={{
                      __html:
                        MainFunc.getLabelDtos(
                          "L0672"
                        ) /* กรุณาเปิดหน้าจอ ซื้อประกันค้างไว้ */,
                    }}
                  ></Col>
                </Row>
              </Col>
              <Col
                xs={6}
                md={3}
                className="d-flex justify-content-center text-center mb-4"
              >
                <Row>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center text-center"
                  >
                    <div className="textLogoKplus">2</div>
                    <Image src={MainData.initialData.channelCode == "KBANK"?LogoKplus2g:LogoKplus2} className="logoKplus" rounded />
                  </Col>
                  <Col
                    xs={12}
                    className="textKplusDesc"
                    dangerouslySetInnerHTML={{
                      __html:
                        MainFunc.getLabelDtos(
                          "L0673"
                        ) /* สแกน QR Code เพื่อเชื่อมต่อข้อมูลบน K Plus */,
                    }}
                  ></Col>
                </Row>
              </Col>
              <Col
                xs={6}
                md={3}
                className="d-flex justify-content-center text-center mb-4"
              >
                <Row>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center text-center"
                  >
                    <div className="textLogoKplus">3</div>
                    <Image className="logoKplus" src={MainData.initialData.channelCode == "KBANK"?LogoKplus3g:LogoKplus3} rounded />
                  </Col>
                  <Col
                    xs={12}
                    className="textKplusDesc"
                    dangerouslySetInnerHTML={{
                      __html:
                        MainFunc.getLabelDtos(
                          "L0674"
                        ) /* ทำการยืนยันตัวตนบน K Plus */,
                    }}
                  ></Col>
                </Row>
              </Col>
              <Col
                xs={6}
                md={3}
                className="d-flex justify-content-center text-center mb-4"
              >
                <Row>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center text-center"
                  >
                    <div className="textLogoKplus">4</div>
                    <Image className="logoKplus" src={MainData.initialData.channelCode == "KBANK"?LogoKplus4g:LogoKplus4} rounded />
                  </Col>
                  <Col
                    xs={12}
                    className="textKplusDesc"
                    dangerouslySetInnerHTML={{
                      __html:
                        MainFunc.getLabelDtos(
                          "L0675"
                        ) /* กลับมาทำรายการต่อในหน้าซื้อประกัน */,
                    }}
                  ></Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                className="d-flex justify-content-center textKplus mt-4 mb-4"
                dangerouslySetInnerHTML={{
                  __html: MainFunc.getLabelDtos("L0676") /* สแกน QR Code */,
                }}
              ></Col>
              <Col xs={12} className="d-flex justify-content-center">
                <QRCode
                  size={256}
                  className="qrCodeKplus"
                  value={this.state.valueQr}
                  viewBox={`0 0 256 256`}
                  level={"H"}
                />
              </Col>
              <Col
                xs={12}
                className="d-flex justify-content-center textKplus text-center mt-4 mb-2"
                style={{ color: "#F61F4B" }}
                dangerouslySetInnerHTML={{
                  __html:
                    MainFunc.getLabelDtos(
                      "L0677"
                    ) /* กรุณาอย่าปิดหน้าต่างนี้ ระหว่างดำเนินการบน K Plus */,
                }}
              ></Col>
              <Col xs={12} className="d-flex justify-content-center mt-4 pb-4">
                <Button
                  variant=""
                  className="mainBtnRadius"
                  onClick={() => this.onCancel()}
                >
                  {
                    MainFunc.getLabelDtos(
                      "L0678"
                    ) /* กลับไปทำรายการโดยไม่ใช้ K Plus */
                  }
                </Button>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <Container style={{ maxWidth: "700px" }}>
            {this.state.showKplusSuccess ? (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      className="d-flex justify-content-center text-center textKPlus"
                    >
                      <h3
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: this.state.textFinal,
                        }}
                      ></h3>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row>
                {this.state.skipPage ? (
                  <Col xs={12} className="d-flex justify-content-center">
                    <div className="loader-kplus"></div>
                  </Col>
                ) : (
                  ""
                )}
                <Col xs={12} className="d-flex justify-content-center">
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      className="d-flex justify-content-center text-center textKPlus"
                    >
                      <h3
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: this.state.skipPage
                            ? this.state.skipPageMsg
                            : MainFunc.getLabelDtos(
                                "L0679"
                              ) /* หากคุณใช้บริการ K PLUS Application คุณจะได้รับความสะดวกในการกรอกข้อมูลและยืนยันตัวตนในการสมัครประกันชีวิต คุณต้องการใช้บริการเชื่อมต่อข้อมูลผ่าน KPLUS หรือไม่ */,
                        }}
                      ></h3>
                    </Col>
                  </Row>
                </Col>
                {this.state.skipPage ? (
                  ""
                ) : (
                  <Fragment>
                    <Col xs={6} className="d-flex justify-content-center pt-5">
                      <Button
                        variant=""
                        className="subBtnRadius font-weight-bold"
                        onClick={() => this.onCancel(true)}
                      >
                        {MainFunc.getLabelDtos("L0680") /* ไม่ใช่ */}
                      </Button>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-center pt-5">
                      <Button
                        variant=""
                        className="mainBtnRadius font-weight-bold"
                        onClick={() => this.gotoKplus(true)}
                      >
                        {MainFunc.getLabelDtos("L0681") /* ใช่ */}
                      </Button>
                    </Col>
                  </Fragment>
                )}
              </Row>
            )}
          </Container>
        )}
      </div>
    );
  }
}
export default SM001;
