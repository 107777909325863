import React, { Component } from "react";
import styles from "../../styles/pre-input.module.scss";
import Input from "@material-ui/core/Input";
import IconErrorInput from "../../images/icon_error_input.svg";
import { withStyles } from "@material-ui/core/styles";
class TagInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      inputValue: "",
      id: this.props.id ? this.props.id : "",
      name: this.props.name ? this.props.name : "",
      type: this.props.type ? this.props.type : "text",
      placeholder: this.props.placeholder ? this.props.placeholder : "",
      maxlength: this.props.maxlength ? this.props.maxlength : "",
      hide: this.props.hide ? this.props.hide : false,
      label: this.props.label ? this.props.label : "",
      helpText: this.props.helpText ? this.props.helpText : "",
    };
  }

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });
  };

  handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      const { inputValue, tags } = this.state;
      const newTags = [...tags, inputValue];
      this.setState({ tags: newTags, inputValue: "" });
    }
  };

  render() {
    const {
      tags,
      inputValue,
      id,
      type,
      placeholder,
      maxlength,
      hide,
      label,
      helpText,
      name,
    } = this.state;
    const { classes } = this.props;
    return (
      <div
        className={`${styles.preInput} ${
          this.props.selectPlan ? styles.selectPlan : ""
        }
      `}
      >
        {label && (
          <label className={styles.label}>
            {label}{" "}
            {this.props.required && <span className={styles.required}>*</span>}
          </label>
        )}
        <Input
          className={`${styles.input}   ${
            this.props.selectPlan && this.props.value && !this.props.error
              ? styles.haveData
              : ""
          }
          ${this.props.error ? styles.error : ""}
          ${classes.root} `}
          id={id}
          label={label}
          type={type}
          name={name}
          placeholder={placeholder}
          value={this.props.value}
          onChange={(event) => this.props.onChange(event)}
          autoComplete="off"
          maxlength={maxlength}
          hidden={hide}
          error={this.props.error}
          endAdornment={
            this.props.error ? (
              <img className={styles.iconErrorInput} src={IconErrorInput} />
            ) : (
              ""
            )
          }
        />
        <p
          className={`${styles.textRequired} ${
            this.props.selectPlan ? styles.selectPlan : ""
          }
      `}
          hidden={!this.props.error}
        >
          {this.props.helpText}
        </p>
      </div>
    );
  }
}

const style = {
  root: {
    "& .MuiInputBase-input": {
      fontFamily: "var(--mainFontFamily) !important",
      "&::placeholder": {
        "@media (max-width: 374px)": {
          fontSize: "11px",
        },
      },
    },
  },
};

export default withStyles(style)(TagInput);
