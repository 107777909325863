import React, { Component } from "react";

import styles from "../../styles/faq.module.scss";
import DetailDropdown from "./detailDropdown.js";
import MainFunc from "../../models/MainFunc";
class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "test",
      answer: "",
    };
  }

  render() {
    const { question, answer } = this.state;

    return (
      <div className="container">
        <div className={styles.faq}>
          <div className={styles.scHeading}>
            <h2 className={styles.h1} style={{"fontWeight":"bold"}}>
                {" "}
                {MainFunc.getLabelDtos("L0776")?MainFunc.getLabelDtos("L0776"):"คำถามที่พบบ่อย"}{" "}
            </h2>
          </div>
          <div className={styles.faqInner}>
            <DetailDropdown data={this.props.data} />
            {this.props.data?.faqRemark && (
              <div className={styles.remark}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.faqRemark,
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
