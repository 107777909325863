import React from "react";
import { Fragment } from "react";
import { Row, Col, Image, Button, Card } from "react-bootstrap";
import MainFunc from "../../models/MainFunc";
import styles from "../../styles/CPP.module.scss";
import iconCheckMark from "../../images/icon_check_mark.png";
import iconKBank from "../../images/icon_Kbank.png";
import iconCCP from "../../images/icon_CCP.png";
import MainData from "../../models/MainData";
class CPP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: parseInt(MainFunc.getConfigDtos("CF042")),
      // Initialize state here
    };
  }

  componentDidMount() {
    // Code to run after the component has mounted
    setTimeout(() => {
      this.props.loadController(false);
      this.startTime();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  startTime = () => {
    this.myInterval = setInterval(() => {
      const { seconds } = this.state;
      if (seconds > 0) {
        this.setState(({ seconds }) => ({ seconds: seconds - 1 }));
      }
      if (seconds === 0) {
        clearInterval(this.myInterval);
        this.props.submitPayment(true);
      }
    }, 1000);
  };

  render() {
    return (
      // JSX code for rendering the component
      <Fragment>
        <div className={styles.APPAndCCP}>
          <div className={styles.img}>
            <img src={iconCheckMark} />
          </div>
          <h1 className={styles.title}>
            {MainFunc.getLabelDtos("L0829") /*การสั่งซื้อสำเร็จ*/}{" "}
          </h1>
          <p className={styles.desciption}>
            {
              MainFunc.getLabelDtos(
                "L0830"
              ) /*เพื่อความสะดวกสบายในการชำระงวดต่อ ทั้งแบบรายเดือนและรายปี*/
            }
            <br />
            {
              MainFunc.getLabelDtos(
                "L0831"
              ) /*เท่านสามารถเลือกลงทะเบียนชำระงวดต่ออัตโนมัติได้*/
            }{" "}
            {this.props.cTBAppCcpStatus.appStatus &&
            this.props.cTBAppCcpStatus.ccpStatus
              ? 2
              : this.props.cTBAppCcpStatus.appStatus ||
                this.props.cTBAppCcpStatus.ccpStatus
              ? 1
              : 0}{" "}
            {MainFunc.getLabelDtos("L0832") /*ช่องทาง*/}
          </p>
          <div className={styles.selectAPPAndCCP}>
            {this.props.cTBAppCcpStatus.appStatus && (
              <div className={styles.app}>
                <p className={styles.subTitle}>
                  {MainFunc.getLabelDtos("L0833") /*หักบัญชีผ่านแอพ K Plus */}
                </p>
                <div className={styles.imgKbank}>
                  <img src={iconKBank} />
                </div>
                <Button
                  variant="info pl-4 pr-4"
                  className={styles.btn}
                  onClick={() => {
                    this.props.createAPP();
                  }}
                >
                  {MainFunc.getLabelDtos("L0835") /*ลงทะเบียน*/}
                </Button>
              </div>
            )}
            {this.props.cTBAppCcpStatus.appStatus &&
              this.props.cTBAppCcpStatus.ccpStatus && (
                <Fragment>
                  <div className={styles.lineR}></div>
                  <div className={styles.line}>
                    <div className={styles.lineC}></div>
                  </div>
                </Fragment>
              )}
            {this.props.cTBAppCcpStatus.ccpStatus && (
              <div className={styles.ccp}>
                <p className={styles.subTitle}>
                  {MainFunc.getLabelDtos("L0834") /*ตัดบัตรเครดิต*/}
                </p>
                <div className={styles.imgCCP}>
                  <img src={iconCCP} />
                </div>
                <Button
                  variant="info pl-4 pr-4"
                  className={styles.btn}
                  onClick={() => {
                    this.props.createCCP();
                  }}
                >
                  {MainFunc.getLabelDtos("L0835") /*ลงทะเบียน*/}
                </Button>
              </div>
            )}
          </div>

          <h2 className={styles.subTitle}>
            {MainFunc.getLabelDtos("L0836") /*หากท่านยังไม่ต้องการสมัคร คลิก*/}
            <span
              onClick={() => this.props.submitPayment()}
              className={styles.clickHere}
            >
              {MainFunc.getLabelDtos("L0837") /*ที่นี่*/}
            </span>{" "}
            {MainFunc.getLabelDtos("L0838") /*เพื่อไปยังหน้าจอถัดไป*/}
          </h2>
          <p className={styles.time}>
            {
              MainFunc.getLabelDtos(
                "L0839"
              ) /*ระบบจะไปยังหน้าจอถัดไปอัตโนมัติ ใน*/
            }{" "}
            {this.state.seconds} {MainFunc.getLabelDtos("L0840") /*วินาที*/}
          </p>
        </div>
      </Fragment>
    );
  }
}

export default CPP;
