import React, { Component } from "react";
import styles from "../../styles/highlight.module.scss";
class Highlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlight: this.props.value,
      lastOddNumber: this.props.lastOddNumber,
    };
  }

  render() {
    const { highlight, lastOddNumber } = this.state;
    return (
      <div
        className={`${styles.highlight} ${
          lastOddNumber ? styles.lastOddNumber : ""
        }`}
        style={{
          backgroundColor: this.props.bgColor ? this.props.bgColor : "",
        }}
      >
        <div
          className={`${styles.imageHighlight} ${
            lastOddNumber ? styles.lastOddNumber : ""
          }`}
        >
          <img
            src={highlight?.icon}
          />
        </div>
        <div
          className={`${styles.text} ${
            lastOddNumber ? styles.lastOddNumber : ""
          }`}
        >
          <h2
            className={styles.titleHighlight}
            dangerouslySetInnerHTML={{
              __html: highlight?.title,
            }}
          ></h2>
          <div
            className={styles.detailHighlight}
            dangerouslySetInnerHTML={{
              __html: highlight?.description,
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default Highlight;
