import React, { Component } from "react";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
import {Row, Col, Button, Form} from "react-bootstrap";
import axios from "axios";

class SM011 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM011",
      confirmModal: false,
      agreeTerms: MainData.screenSM011.agreeTerms,
    };
  }

  componentDidMount(){
    this.props.loadController(false)
    if(!MainData.initialData.confirmMessage && !MainData.initialData.importantInformation){
      this.props.setPage(this.props.next)
    }
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
  }

  processNext = async () =>{
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    this.addOrUpdateCustomerSubmenu()
  }

  setAgreeTerms = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    MainFunc.sendDataToGTMKbank({
      'event': 'track_event',
      'event_category': 'link_click',
      'event_action': 'click',
      'event_label': 'ย้อนกลับ',
      'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
      'flag_page' : 'onboard12'
     });
    MainData.screenSM011.agreeTerms = "";
    this.props.setPage(this.props.prev)
  }

  addOrUpdateCustomerSubmenu = () => {
    this.props.loadController(true)
    const headers = { 
    Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
    "Content-Type": "application/json;charset=UTF-8", 
    "Access-Control-Allow-Origin": "*", 
    "Access-Control-Allow-Credentials": "true", 
    "Access-Control-Allow-Methods": 
    "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
    "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "submenuCode": this.state.screen,
    };
    axios.post(MainData.serviceURL+'/Customer/AddCustomerSubmenuCode', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        MainFunc.sendDataToGTMKbank({
          'event': 'track_event',
          'event_category': 'link_click',
          'event_action': 'click',
          'event_label': 'step_success',
          'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
          'flag_page' : 'onboard12',
          'flag_verify' : 'y' 
         });
        this.props.setPage(this.props.next)
        MainData.screenSM011.agreeTerms = "checked";
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',"AddCustomerSubmenuCode"), ' ')
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }
  
  render(){
    return (
      <div className="container-fulid">
        <Row>
          <Col id="titelForScreen" xs={12} className="oppositeWhiteStep">
            <span className="font-weight-bold" dangerouslySetInnerHTML={{__html: this.props.subMenuName}}></span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>

          {!MainData.initialData.confirmMessage?null:
            <Col xs={12} className="pt-2 pb-3">
              <span className="text-html-alarm subFontFamily pt-1" dangerouslySetInnerHTML={{__html: MainData.initialData.confirmMessage}}></span>
            </Col>
          }

          <Col xs={12} className=" pt-3 pb-1">
            <Form.Group controlId="terms">
                    <Form.Check checked={this.state.agreeTerms} onChange={(e)=>{this.setState({agreeTerms: e.target.checked})}} type="checkbox"  custom style={{display: 'initial'}} />
                    <span className="font-weight-bold pt-1 subFontFamily" >{MainFunc.getLabelDtos("L0442")}</span>
            </Form.Group>
          </Col>

          {!this.props.prev?null:
            <Col xs={!this.props.next?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pr-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-9_Click_BtnBack":"STEP2-9_Click_BtnBack"}`} variant="" className="subBtnRadius font-weight-bold" onClick={() => {this.setAgreeTerms()}}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
          {!this.props.next?null:
            <Col xs={!this.props.prev?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pl-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-7_Click_BtnNext":"STEP2-6_Click_BtnNext"}`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processNext() }} disabled={!this.state.agreeTerms}>{MainFunc.getLabelDtos("L0167")}</Button>
            </Col>
          }
        </Row>
      </div>
    )
  }
}

export default SM011;