import React, { Component } from "react";
import styles from "../../styles/coverage-terms.module.scss";
import AccordionGroup from "./accordionGroup.js";
import { Row } from "react-bootstrap";
import MainFunc from "../../models/MainFunc";
class CoverageTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      coverage: "",
    };
  }

  render() {
    return (
      <div className="container">
        <div className={styles.coverageTerms}>
          <div className={styles.scHeading}>
            <h2 className={styles.h1} style={{"fontWeight":"bold"}}>
              {/* <strong> */}
                {MainFunc.getLabelDtos("L0773")?MainFunc.getLabelDtos("L0773"): "เงื่อนไขความคุ้มครอง"}
              {/* </strong> */}
            </h2>
          </div>
          {this.props.data?.coverageLists?.length > 0 &&
            this.props.data?.coverageLists?.length !== null &&
            this.props.data?.coverageLists?.length !== undefined &&
            this.props.data?.coverageLists
              .filter(
                (coverageItem, index) =>
                  coverageItem.coverageTitle === null ||
                  coverageItem.coverageTitle === ""
              )
              .map((item, index) =>
                item.coverageDetails.map((coverageDetail, index) => {
                  return (
                    <Row>
                      <img
                        className={styles.coverageImg}
                        src={coverageDetail.coverageImg}
                        alt={coverageDetail.coverageTitle}
                      />
                    </Row>
                  );
                })
              )}
          <div className="coverage-terms">
            <AccordionGroup dataCoverage={this.props.data?.coverageLists} />
          </div>
          {this.props.data?.coverageRemark && (
            <div className={styles.coverageRemark}>
              <div className={styles.remark}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.coverageRemark,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CoverageTerms;
