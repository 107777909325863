import axios from "axios";
import React, { Component } from "react";
import { GiSmartphone } from "react-icons/gi";
import { Row, Col, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { GoAlert } from "react-icons/go";
import SweetAlert from "react-bootstrap-sweetalert";
import { sha256 } from "js-sha256";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
import styles from "../../styles/HistorySms.module.scss";
class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM007",
      seconds: 0,
      minutes: parseInt(MainFunc.getConfigDtos("CF017")),
      failOTP: false,
      otpDisable: true,
      refCode: MainData.screenSM006.refCode,
      stateOTPCode: MainData.screenSM006.otpCode,
      otpCode: "",
      phoneNumber: !MainData.screenSM006.phoneNumber
        ? ""
        : MainData.screenSM006.phoneNumber,
      phoneNumberMarking: "",
      isCheckOTPErrorMsg: "",
      isCheckOTPError: false,
    };
  }

  componentDidMount() {
    if (!this.state.phoneNumber) {
      this.props.errorAlert(
        true,
        "พบปัญหากับหมายเลขโทรศัพท์มือถือ กรุณาทำรายการใหม่อีกครั้ง",
        " "
      );
      this.props.setPage("IdCard");
    } else {
      this.setState({
        phoneNumberMarking: MainData.screenSM006.phoneNumber.replace(
          MainData.screenSM006.phoneNumber.substring(2, 6),
          " XXXX "
        ),
      });
      this.props.loadController(true);
      setTimeout(() => {
        this.props.loadController(false);
        this.startTime();
      }, 1000);
    }
  }

  startTime = () => {
    this.setState({ otpDisable: false, failOTP: false });
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;
      if (seconds > 0) {
        this.setState(({ seconds }) => ({ seconds: seconds - 1 }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
          this.setState({ otpDisable: true, otpCode: "" });
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  };

  componentWillUnmount() {
    sessionStorage.removeItem("PDPAoldValue");
    clearInterval(this.myInterval);
  }

  getOTP = () => {
    if (!this.state.otpDisable) {
      let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG025");
      if (MainData.screenSM007.otpCode != null) {
        MainFunc.logPDPA(
          "Edit",
          "SMS",
          "OTP",
          null,
          {
            phoneNumber: MainData.screenSM006.phoneNumber,
            refCode: MainData.screenSM006.refCode,
            otpCode: MainData.screenSM007.otpCode,
          },
          {
            phoneNumber: MainData.screenSM006.phoneNumber,
            refCode: MainData.screenSM006.refCode,
            otpCode: this.state.otpCode,
          },
          "Fail",
          errMsg.msgDescription
        );
      } else {
        MainFunc.logPDPA(
          "Add",
          "SMS",
          "OTP",
          null,
          null,
          {
            phoneNumber: MainData.screenSM006.phoneNumber,
            refCode: MainData.screenSM006.refCode,
            otpCode: this.state.otpCode,
          },
          "Fail",
          errMsg.msgDescription
        );
      }
      MainData.screenSM007.otpCode = this.state.otpCode;

      this.props.errorAlert(true, errMsg.msgDescription, " ");
      return;
    }
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      AuthId: !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId,
    };
    const data = {
      phoneNumber: this.state.phoneNumber,
      channelCode: MainData.initialData.channelCode,
      isChangeNumber: false,
      isSmsHistory: true,
    };
    axios
      .post(MainData.serviceURL + "/VerifyOTP/OTP", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        this.props.loadController(false);
        if (res.data.success === true) {
          if (data.status === true) {
            this.setState({
              refCode: data.refCode,
              stateOTPCode: data.otpCode,
            });
            if (MainData.screenSM007.otpCode != null) {
              MainFunc.logPDPA(
                "Edit",
                "SMS",
                "OTP",
                null,
                {
                  phoneNumber: MainData.screenSM006.phoneNumber,
                  refCode: MainData.screenSM006.refCode,
                  otpCode: MainData.screenSM007.otpCode,
                },
                {
                  phoneNumber: MainData.screenSM006.phoneNumber,
                  refCode: this.state.refCode,
                  otpCode: this.state.otpCode,
                },
                "Success",
                ""
              );
            } else {
              MainFunc.logPDPA(
                "Add",
                "SMS",
                "OTP",
                null,
                null,
                {
                  phoneNumber: MainData.screenSM006.phoneNumber,
                  refCode: this.state.refCode,
                  otpCode: this.state.otpCode,
                },
                "Success",
                ""
              );
            }
            MainData.screenSM006.refCode = data.refCode;
            MainData.screenSM006.otpCode = data.otpCode;
            this.setState({ minutes: data.expiredTime });
            this.startTime();
            MainFunc.sendLogToService(
              "OTP",
              "Insert",
              "Success",
              "เก็บข้อมูลกดขอรับรหัสผ่านใหม่อีกครั้ง",
              {
                phoneNumber: MainFunc.blurPhoneNum(
                  MainData.screenSM006.phoneNumber
                ),
                productId: this.state.productId,
                channelCode: this.state.channelCode,
              },
              {
                phoneNumber: MainFunc.blurPhoneNum(this.state.phoneNumber),
                productId: this.state.productId,
                channelCode: this.state.channelCode,
              }
            );
          } else {
            MainFunc.sendLogToService(
              "OTP",
              "Insert",
              "Fail",
              "เก็บข้อมูลกดขอรับรหัสผ่านใหม่อีกครั้ง",
              {
                phoneNumber: MainFunc.blurPhoneNum(
                  MainData.screenSM006.phoneNumber
                ),
                productId: this.state.productId,
                channelCode: this.state.channelCode,
              },
              {
                phoneNumber: MainFunc.blurPhoneNum(this.state.phoneNumber),
                productId: this.state.productId,
                channelCode: this.state.channelCode,
              }
            );
          }
        } else {
          if (
            data.messageCode == "MSG021" ||
            data.messageCode == "MSG022" ||
            data.messageCode == "MSG023" ||
            data.messageCode == "MSG184"
          ) {
            let errMsg = MainData.masterMessage.find(
              (x) => x.msgCode === data.messageCode
            );
            if (MainData.screenSM007.otpCode != null) {
              MainFunc.logPDPA(
                "Edit",
                "SMS",
                "OTP",
                null,
                {
                  phoneNumber: MainData.screenSM006.phoneNumber,
                  refCode: MainData.screenSM006.refCode,
                  otpCode: MainData.screenSM007.otpCode,
                },
                {
                  phoneNumber: MainData.screenSM006.phoneNumber,
                  refCode: MainData.screenSM006.refCode,
                  otpCode: this.state.otpCode,
                },
                "Fail",
                errMsg.msgDescription
              );
            } else {
              MainFunc.logPDPA(
                "Add",
                "SMS",
                "OTP",
                null,
                null,
                {
                  phoneNumber: MainData.screenSM006.phoneNumber,
                  refCode: MainData.screenSM006.refCode,
                  otpCode: this.state.otpCode,
                },
                "Fail",
                errMsg.msgDescription
              );
            }

            MainData.screenSM007.otpCode = this.state.otpCode;
            this.setState({
              isCheckOTPError: true,
              isCheckOTPErrorMsg: data.messageCode,
            });
            this.props.loadController(false);
          } else {
            MainFunc.sendLogToService(
              "OTP",
              "Insert",
              "Fail",
              "เก็บข้อมูลกดขอรับรหัสผ่านใหม่อีกครั้ง",
              {
                phoneNumber: MainFunc.blurPhoneNum(
                  MainData.screenSM006.phoneNumber
                ),
                productId: this.state.productId,
                channelCode: this.state.channelCode,
              },
              {
                phoneNumber: MainFunc.blurPhoneNum(this.state.phoneNumber),
                productId: this.state.productId,
                channelCode: this.state.channelCode,
              }
            );
            let errMsg = MainData.masterMessage.find(
              (x) => x.msgCode === "MSG1001"
            );
            this.props.errorAlert(
              true,
              !errMsg
                ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
                : errMsg.msgDescription,
              " "
            );
          }
        }
      })
      .catch((error) => {
        this.props.loadController(false);
        this.props.setPage("IdCard");
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
      });
  };

  processBack = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if (statusSystemConfig) {
      window.location.href = MainData.mainPath + "shutdown";
      return;
    } else {
      this.props.loadController(false);
    }
    this.props.setPage("IdCard");
  };

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if (statusSystemConfig) {
      window.location.href = MainData.mainPath + "shutdown";
      return;
    }
    this.checkOTP();
  };

  handleOTPChange = (val) => {
    val = val.replace(/[^\d]/g, "");
    if (val.length <= 6) {
      this.setState({ otpCode: val, failOTP: false });
    }
  };

  checkOTP = () => {
    if (!this.state.minutes && !this.state.seconds) {
      this.props.loadController(false);
      let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG024");
      if (MainData.screenSM007.otpCode != null) {
        MainFunc.logPDPA(
          "Edit",
          "SMS",
          "OTP",
          null,
          {
            phoneNumber: MainData.screenSM006.phoneNumber,
            refCode: MainData.screenSM006.refCode,
            otpCode: MainData.screenSM007.otpCode,
          },
          {
            phoneNumber: MainData.screenSM006.phoneNumber,
            refCode: MainData.screenSM006.refCode,
            otpCode: this.state.otpCode,
          },
          "Fail",
          errMsg.msgDescription
        );
      } else {
        MainFunc.logPDPA(
          "Add",
          "SMS",
          "OTP",
          null,
          null,
          {
            phoneNumber: MainData.screenSM006.phoneNumber,
            refCode: MainData.screenSM006.refCode,
            otpCode: this.state.otpCode,
          },
          "Fail",
          errMsg.msgDescription
        );
      }
      this.state.otpCode = "";
      MainData.screenSM007.otpCode = this.state.otpCode;
      this.props.errorAlert(
        true,
        !errMsg ? "(กรุณาระบุ OTP)" : errMsg.msgDescription,
        " "
      );
    } else if (!this.state.otpCode) {
      this.props.loadController(false);
      this.setState({ failOTP: true });
      let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG011");
      if (MainData.screenSM007.otpCode != null) {
        MainFunc.logPDPA(
          "Edit",
          "SMS",
          "OTP",
          null,
          {
            phoneNumber: MainData.screenSM006.phoneNumber,
            refCode: MainData.screenSM006.refCode,
            otpCode: MainData.screenSM007.otpCode,
          },
          {
            phoneNumber: MainData.screenSM006.phoneNumber,
            refCode: MainData.screenSM006.refCode,
            otpCode: this.state.otpCode,
          },
          "Fail",
          errMsg.msgDescription
        );
      } else {
        MainFunc.logPDPA(
          "Add",
          "SMS",
          "OTP",
          null,
          null,
          {
            phoneNumber: MainData.screenSM006.phoneNumber,
            refCode: MainData.screenSM006.refCode,
            otpCode: this.state.otpCode,
          },
          "Fail",
          errMsg.msgDescription
        );
      }
      this.state.otpCode = "";
      MainData.screenSM007.otpCode = this.state.otpCode;
      this.props.errorAlert(
        true,
        !errMsg ? "(กรุณาระบุ OTP)" : errMsg.msgDescription,
        " "
      );
    } else {
      const headers = {
        Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
        AuthId: !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId,
      };
      const data = {
        phoneNumber: this.state.phoneNumber,
        refCode: this.state.refCode,
        OTPCode: this.state.otpCode,
        submenuCode: this.state.screen,
        isSmsHistory: true,
      };
      let tokenOtp = sha256(
        this.state.refCode +
          MainData.screenSM005.cusId +
          this.state.phoneNumber +
          this.state.otpCode
      );
      axios
        .post(MainData.serviceURL + "/VerifyOTP/checkOTP", data, {
          headers: headers,
        })
        .then((res) => {
          if (res.status === 299) {
            window.location.href = MainData.mainPath + "shutdown";
            return;
          }
          let data = res.data.data;
          if (data.isSuccess === true && tokenOtp == data.sign) {
            this.props.loadController(false);
            if (MainData.screenSM007.otpCode != null) {
              MainFunc.logPDPA(
                "Edit",
                "SMS",
                "OTP",
                null,
                {
                  phoneNumber: MainData.screenSM006.phoneNumber,
                  refCode: MainData.screenSM006.refCode,
                  otpCode: MainData.screenSM007.otpCode,
                },
                {
                  phoneNumber: MainData.screenSM006.phoneNumber,
                  refCode: MainData.screenSM006.refCode,
                  otpCode: this.state.otpCode,
                },
                "Success",
                null
              );
            } else {
              MainFunc.logPDPA(
                "Add",
                "SMS",
                "OTP",
                null,
                null,
                {
                  phoneNumber: MainData.screenSM006.phoneNumber,
                  refCode: MainData.screenSM006.refCode,
                  otpCode: this.state.otpCode,
                },
                "Success",
                null
              );
            }
            MainFunc.sendLogToService(
              "OTP",
              "Insert",
              "Success",
              "เก็บข้อมูล OTP ที่ระบุ",
              { otp: MainData.screenSM007.otpCode },
              { otp: this.state.otpCode }
            );
            MainData.screenSM007.otpCode = this.state.otpCode;
            this.props.setPage("HistorySms");
          } else {
            if (
              res.data.message == "MSG021" ||
              res.data.message == "MSG024" ||
              res.data.message == "MSG184"
            ) {
              this.setState({
                isCheckOTPError: true,
                isCheckOTPErrorMsg: res.data.message,
              });
              let errMsg = MainData.masterMessage.find(
                (x) => x.msgCode == res.data.message
              );
              if (MainData.screenSM007.otpCode != null) {
                MainFunc.logPDPA(
                  "Edit",
                  "SMS",
                  "OTP",
                  null,
                  {
                    phoneNumber: MainData.screenSM006.phoneNumber,
                    refCode: MainData.screenSM006.refCode,
                    otpCode: MainData.screenSM007.otpCode,
                  },
                  {
                    phoneNumber: MainData.screenSM006.phoneNumber,
                    refCode: MainData.screenSM006.refCode,
                    otpCode: this.state.otpCode,
                  },
                  "Fail",
                  errMsg.msgDescription
                );
              } else {
                MainFunc.logPDPA(
                  "Add",
                  "SMS",
                  "OTP",
                  null,
                  null,
                  {
                    phoneNumber: MainData.screenSM006.phoneNumber,
                    refCode: MainData.screenSM006.refCode,
                    otpCode: this.state.otpCode,
                  },
                  "Fail",
                  errMsg.msgDescription
                );
              }
              MainData.screenSM007.otpCode = this.state.otpCode;
              this.props.loadController(false);
            } else {
              this.props.loadController(false);
              MainFunc.sendLogToService(
                "OTP",
                "Insert",
                "Fail",
                "เก็บข้อมูล OTP ที่ระบุ",
                { otp: MainData.screenSM007.otpCode },
                { otp: this.state.otpCode }
              );
              let errMsg = MainData.masterMessage.find(
                (x) => x.msgCode === "MSG010"
              );
              if (MainData.screenSM007.otpCode != null) {
                MainFunc.logPDPA(
                  "Edit",
                  "SMS",
                  "OTP",
                  null,
                  {
                    phoneNumber: MainData.screenSM006.phoneNumber,
                    refCode: MainData.screenSM006.refCode,
                    otpCode: MainData.screenSM007.otpCode,
                  },
                  {
                    phoneNumber: MainData.screenSM006.phoneNumber,
                    refCode: MainData.screenSM006.refCode,
                    otpCode: this.state.otpCode,
                  },
                  "Fail",
                  errMsg.msgDescription
                );
              } else {
                MainFunc.logPDPA(
                  "Add",
                  "SMS",
                  "OTP",
                  null,
                  null,
                  {
                    phoneNumber: MainData.screenSM006.phoneNumber,
                    refCode: MainData.screenSM006.refCode,
                    otpCode: this.state.otpCode,
                  },
                  "Fail",
                  errMsg.msgDescription
                );
              }
              MainData.screenSM007.otpCode = this.state.otpCode;
              this.props.errorAlert(
                true,
                !errMsg
                  ? "ขออภัย รหัส OTP ของคุณไม่ถูกต้อง โปรดลองใหม่อีกครั้ง"
                  : errMsg.msgDescription,
                " "
              );
            }
          }
        })
        .catch((error) => {
          this.props.loadController(false);
          this.props.setPage("IdCard");
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.props.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
        });
    }
  };

  render() {
    return (
      <div className="container-fulid">
        <SweetAlert
          show={this.state.isCheckOTPError}
          title={""}
          customIcon={
            <GoAlert size={70} className="text-center w-100 text-danger pb-2" />
          }
          custom
          onConfirm={() => window.close()}
          showCancel
          closeOnClickOutside={false}
          customButtons={
            <div>
              <Row>
                <Col xs={12} className="w-50 d-flex justify-content-center">
                  <Button
                    onClick={() => {
                      this.setState({ isCheckOTPError: false });
                    }}
                    className="mainBtnRadius"
                  >
                    {MainFunc.getLabelDtos("L0188")}
                  </Button>
                </Col>
              </Row>
            </div>
          }
        >
          <h5 className="">
            {
              MainData.masterMessage?.find(
                (x) => x.msgCode === this.state.isCheckOTPErrorMsg
              )?.msgDescription
            }
          </h5>
        </SweetAlert>
        <Row className={styles.otp}>

          <Col xs={12} className="d-flex justify-content-center pt-3">
            <GiSmartphone className="csMainColor" size={50} />
          </Col>

          <Col xs={12} className="d-flex justify-content-center pt-3">
            <h5 className={`font-weight-bold ${styles.otpTitle}`}>
              {MainFunc.getLabelDtos("L0145")}
            </h5>
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            <small className="text--muted text-center pl-3 pr-3">
              {MainFunc.getLabelDtos("L0146")}
            </small>
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            <small className="text--muted text-center pl-3 pr-3">
              {MainFunc.getLabelDtos("L0147")} {this.state.phoneNumberMarking}
            </small>
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            <small className="text--muted text-center pl-3 pr-3">
              Ref = {this.state.refCode}
            </small>
          </Col>
          <Col xs={12} className="pt-3">
            <TextField
              inputProps={{ autocomplete: "off" }}
              fullWidth
              defaultValue=""
              error={this.state.failOTP}
              autoComplete="one-time-code"
              disabled={this.state.otpDisable}
              id="single-factor-code-text-field"
              label={MainFunc.getLabelDtos("L0116")}
              placeholder={MainFunc.getLabelDtos("L0116")}
              value={!this.state.otpCode ? "" : this.state.otpCode}
              helperText={this.state.failOTP ? "กรุณากรอกรหัส OTP" : ""}
              onChange={(e) => {
                this.handleOTPChange(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {this.state.minutes < 10
                      ? `0${this.state.minutes}`
                      : this.state.minutes}
                    .
                    {this.state.seconds < 10
                      ? `0${this.state.seconds}`
                      : this.state.seconds}
                    &nbsp;Mins
                  </InputAdornment>
                ),
              }}
            />
          </Col>

          <Col xs={12} className="d-flex justify-content-center pt-4">
            <small className="text--muted text-center pl-3 pr-3">
              {MainFunc.getLabelDtos("L0117")}
            </small>
          </Col>
          <Col xs={12} className="d-flex justify-content-center pb-5">
            <small className={`text--muted  ${styles.otpChangePhone}`}>
              <u
                onClick={() => {
                  this.getOTP();
                }}
                style={{ cursor: "pointer", color: "#1a0dab" }}
              >
                {MainFunc.getLabelDtos("L0118")}
              </u>
              &nbsp;{MainFunc.getLabelDtos("L0119")} ต้องการเปลี่ยนเบอร์ ติดต่อ
              1766
            </small>
          </Col>

          <Col xs={12} className={"d-flex justify-content-center pt-3"}>
            <Button
              variant=""
              className="mainBtnRadius font-weight-bold"
              onClick={() => {
                this.processNext();
              }}
              disabled={this.state.otpDisable}
            >
              {MainFunc.getLabelDtos("L0029")}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Otp;
