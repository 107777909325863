import React, { Component } from "react";
import styles from "../../styles/active-benefit.module.scss";
import Modal from "react-bootstrap/Modal";
import IconModalCloseKbank from "../../images/ico_modal_close_Kbank.svg";
import IconModalClosePMIB from "../../images/ico_modal_close_pmib.svg";
import axios from "axios";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
import { withStyles } from "@material-ui/core/styles";
class ActiveBenefit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dataPromotionCommonDetail: {},
    };
  }

  handleButtonClick = (promotionCode) => {
    MainFunc.sendDataToGTMKbank({
      event: "track_event",
      event_category: "open_popup",
      event_action: "click",
      event_label: `${MainFunc.getLabelDtos("L0778")}_ดูเงื่อนไขเพิ่มเติม`,
    });
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "language-locale": "th",
    };
    axios
      .post(
        MainData.serviceURL + "/Broker/GetPromotionCommonDetail",
        {
          promotionCode: promotionCode,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        // handle success
        this.setState({ dataPromotionCommonDetail: response.data.data });
        this.setState({ isOpen: !this.state.isOpen });
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  render() {
    const { benefitActive, data, classes } = this.props;
    return (
      <>
        <Modal
          show={this.state.isOpen}
          size={"xl"}
          onHide={() => this.setState({ isOpen: !this.state.isOpen })}
          className={`${styles.modal} ${classes.root}`}
        >
          {this.props.channel?.css && (
            <img
              className={`${styles.iconCloseModal}`}
              src={
                this.props.channel?.name == "KBANK"
                  ? IconModalCloseKbank
                  : IconModalClosePMIB
              }
              onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            />
          )}

          <div className={styles.contentModal}>
            <h5 className={styles.title}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    this.state.dataPromotionCommonDetail?.promotionDetailTitle,
                }}
              ></div>
            </h5>
            <div className={styles.content}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    this.state.dataPromotionCommonDetail
                      ?.promotionDetailDescription,
                }}
              ></div>
            </div>
          </div>
        </Modal>
        <div
          className={`${styles.benefitCard} 
      ${benefitActive ? styles.active : ""}`}
        >
          <img src={this.props.data?.promotionImg} alt="Card Image" />
          <div className={styles.benefitCardBody}>
            <div className={styles.benefitCardText}>
              <div className={styles.benefitCardTitle}>
                {this.props.data?.promotionName}
              </div>
              <p className={styles.timeline}>
                {this.props.data?.promotionStartDate} –
                {this.props.data?.promotionEndDate}
              </p>
              <div className={styles.benefitCardDesc}>
                {this.props.data?.promotionDescription}
              </div>
            </div>
            {this.props.data?.isShowPromotionDetail && (
              <div>
                <button
                  className={styles.benefitCardBtn}
                  onClick={() =>
                    this.handleButtonClick(this.props.data?.promotionCode)
                  }
                >
                  <h3>
                    {MainFunc.getLabelDtos("L0812")
                      ? MainFunc.getLabelDtos("L0812")
                      : "ดูเงื่อนไขเพิ่มเติม"}
                  </h3>
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
const style = {
  root: {
    "& .modal-content": {
      height: "100%",
    },
    "&.modal.show .modal-dialog": {
      height: "100%",
    },
  },
};

export default withStyles(style)(ActiveBenefit);
