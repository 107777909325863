import React, { Component, Fragment } from "react";
import styles from "../../styles/planSelected.module.scss";
import ContentPlanSelected from "./contentPlanSelected.js";
import MainFunc from "../../models/MainFunc";
import MainData from "../../models/MainData";
class PlanSelected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      selectedPlanDetail: [],
    };
  }

  incrementCount() {
    this.setState((prevState) => ({
      count: prevState.count + 1,
    }));
  }

  render() {
    return (
      <Fragment>
        <div className={styles.header}>
          <div className={styles.product}>
            <span className={styles.title}>
              {MainFunc.getLabelDtos("L0765") /*แผนประกันที่คุณเลือก*/}
            </span>
            <span className={styles.productName}>
              {this.props.productName}
              {" "}
              {MainData.userSelectedData.selectedPlan?.planName}
            </span>
          </div>
          <div className={styles.premium}>
            <span className={styles.premiumType}>
              {MainFunc.getLabelDtos("L0711") /*เบี้ยประกันภัย*/}

              {MainData.userSelectedData.selectedPlan?.paymentName}
            </span>
            <span className={styles.premiumValue}>
              {" "}
              {MainFunc.numberWithCommas(
                MainData.userSelectedData.selectedPlan?.premium
              ) +
                " " +
                MainFunc.getLabelDtos("L0104")}
            </span>
          </div>
        </div>
        <div className={styles.body}>
          {this.props.data?.planDetails?.map((data) => {
            return <ContentPlanSelected data={data} />;
          })}
        </div>
      </Fragment>
    );
  }
}

export default PlanSelected;
