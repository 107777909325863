import React from "react";
import styles from "../../styles/preSlider.module.scss";
import MainFunc from "../../models/MainFunc";
class preSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lengthData:
        this.props.data !== null &&
        this.props.data !== undefined &&
        this.props.data.length > 0
          ? this.props.data.length
          : 0,
      value: null,
      click: false,
      widthPreSlider: 0,
    };
    this.inputPreSliderRef = React.createRef();
  }
  componentDidMount() {
    this.setState({
      widthPreSlider: this.inputPreSliderRef?.current?.offsetWidth,
    });
  }
  componentWillReceiveProps(prevProps) {
    if (this.state.value == null && prevProps.value) {
      this.setState({ value: prevProps.value });
    }
  }

  setIndexValue = (index) => {
    this.setState({ value: this.props.data[index] });
  };

  render() {
    return (
      <div className={styles.main}>
        <input
          ref={this.inputPreSliderRef}
          type="range"
          min="0"
          max={this.state.lengthData - 1}
          value={
            !this.state.click
              ? this.props.data !== null &&
                this.props.data !== undefined &&
                this.props.data.length > 0
                ? this.props.data.findIndex(
                    (item) =>
                      item[this.props.parameterValue] ==
                      (this.props.value
                        ? this.props.value[this.props.parameterValue]
                        : null)
                  )
                : 0
              : this.props.data !== null &&
                this.props.data !== undefined &&
                this.props.data.length > 0
              ? this.props.data.findIndex(
                  (item) =>
                    item[this.props.parameterValue] ==
                    (this.state.value
                      ? this.state.value[this.props.parameterValue]
                      : null)
                )
              : 0
          }
          step={this.props.step}
          style={{
            background: `linear-gradient(to right, var(--preSlider) ${
              !this.state.click
                ? this.props.value
                  ? (this.props.data.findIndex(
                      (item) =>
                        item[this.props.parameterValue] ==
                        (this.props.value
                          ? this.props.value[this.props.parameterValue]
                          : null)
                    ) /
                      (this.state.lengthData - 1)) *
                    100
                  : 0
                : this.state.value
                ? (this.props.data.findIndex(
                    (item) =>
                      item[this.props.parameterValue] ==
                      (this.state.value
                        ? this.state.value[this.props.parameterValue]
                        : null)
                  ) /
                    (this.state.lengthData - 1)) *
                  100
                : 0
            }%, rgb(204, 204, 204) ${
              !this.state.click
                ? this.props.value
                  ? (this.props.data.findIndex(
                      (item) =>
                        item[this.props.parameterValue] ==
                        (this.props.value
                          ? this.props.value[this.props.parameterValue]
                          : null)
                    ) /
                      (this.state.lengthData - 1)) *
                    100
                  : 0
                : this.state.value
                ? (this.props.data.findIndex(
                    (item) =>
                      item[this.props.parameterValue] ==
                      (this.state.value
                        ? this.state.value[this.props.parameterValue]
                        : null)
                  ) /
                    (this.state.lengthData - 1)) *
                  100
                : 0
            }%)`,
          }}
          onChange={(e) => this.setIndexValue(e.target.value)}
          onMouseUp={(e) => {
            if (
              this.props.value[this.props.parameterValue] !=
              this.props.data[e.target.value][this.props.parameterValue]
            ) {
              this.props.onChange(this.props.data[e.target.value]);
            }
            this.setState({ click: false });
          }}
          onMouseDown={() => this.setState({ click: true })}
          onTouchEnd={(e) => {
            if (
              this.props.value[this.props.parameterValue] !=
              this.props.data[e.target.value][this.props.parameterValue]
            ) {
              this.props.onChange(this.props.data[e.target.value]);
            }
            this.setState({ click: false });
          }}
          onTouchStart={() => this.setState({ click: true })}
        ></input>
        <div className={`${styles.sliderticks}`}>
          {this.props.data !== null &&
            this.props.data !== undefined &&
            this.props.data.length > 0 &&
            this.props.data.map((item) => {
              return (
                <span
                  className={`${
                    this.state.widthPreSlider / this.props.data.length < 65
                      ? styles.sliderticksSM
                      : ""
                  }
                  ${
                    item[this.props.parameterLabel] ==
                    this.props?.value?.[this.props?.parameterLabel]
                      ? styles.sliderticksActive
                      : ""
                  }
                `}
                >
                  {item[this.props.parameterLabel].toString().indexOf(".") < 0
                    ? item[this.props.parameterLabel]
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : MainFunc.numberWithCommas(
                        item[this.props.parameterLabel]
                      )}
                </span>
              );
            })}
        </div>
      </div>
    );
  }
}

export default preSlider;
