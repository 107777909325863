import React, { Component } from "react";
import CardRelatedProduct from "./cardRelatedProduct.js";
import styles from "../../styles/related-products.module.scss";
import { Col } from "react-bootstrap";
import MainFunc from "../../models/MainFunc";
class RelatedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  render() {
    return (
      <div className={styles.relatedProducts}>
        <div className="container">
          <div
            className={styles.scHeading}
            style={{
              color: `${this.props.data?.imgProductRelate ? "#ffff" : "#333"}`,
            }}
          >
            <div className={styles.h1}>
              <strong>
                {MainFunc.getLabelDtos("L0779") /*ผลิตภัณฑ์ที่เกี่ยวข้อง*/}
              </strong>
            </div>
          </div>
          <div className={styles.cardProducts}>
            {/* Render related products */}
            {this.props.data?.productRelateDetails !== undefined &&
              this.props.data?.productRelateDetails !== null &&
              this.props.data?.productRelateDetails.map((product, index) => (
                <Col lg={4} className={styles.cardProduct}>
                  <CardRelatedProduct data={product} />
                </Col>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default RelatedProducts;
