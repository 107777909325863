import axios from "axios";
import React, { Component, Fragment } from "react";
import { Row, Col, Image, Button, Card, Form, Spinner } from "react-bootstrap";
import styles from "../../styles/box-widget.module.scss";
import { ReactComponent as IconBoxWidget } from "../../images/icon_box-widgets.svg";
import { ReactComponent as IconBoxWidgetMenuLisrt } from "../../images/icon_box-widgets-menu-list.svg";
import { ReactComponent as IconBoxWidgetMenu } from "../../images/icon_box-widgets-menu.svg";
import { ReactComponent as IconBoxWidgetMenuNotkBANK } from "../../images/icon_box-widgets-menu-notKBANK.svg";
import IconClose from "../../images/icon_box-widgets-close.svg";
import BoxWidgetsBody from "./boxWidgetsBody.js";
import { ReactComponent as IconCloseMenu } from "../../images/icon_box-widgets-menu-close.svg";
import { ReactComponent as IconHighlights } from "../../images/icon-highlights.svg";
import { ReactComponent as IconPlanSelect } from "../../images/icon-plan-select.svg";
import { ReactComponent as IconInsuranceCompare } from "../../images/icon-insurance-compare.svg";
import { ReactComponent as IconCoverageTerm } from "../../images/icon-coverage-term.svg";
import { ReactComponent as IconBenefit } from "../../images/icon-benefit.svg";
import { ReactComponent as IconFAQ } from "../../images/icon-FAQ.svg";
import { ReactComponent as IconIsDropLead } from "../../images/icon_isDropLead.svg";
import { ReactComponent as IconRemark } from "../../images/icon_remark.svg";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

import IcoCallCenter from "../../images/ico_callcenter.png";
import { Collapse } from "@material-ui/core";
import IcoError from "../../images/ico_error.png";
import IcoCrossSign from "../../images/ico_closesign.png";
import IcoCorrect from "../../images/ico_correct.png";
import logo2 from "../../images/logo.png";
import IcoTelGreen from "../../images/ico_tel_green.png";
class boxWidgetsMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      IsSummaryDetailMobile: false,
      isOpen: false,
      autoOpen: false,
      channel: this.props.channel,
      showDropLead: false,
      step: "1",
      name: "",
      phoneNumber: "",
      email: "",
      selectDate: "",
      validateDroplead: false,
      nameerror: "",
      emailerror: "",
      failPhone: "",
      failselectDate: "",
      checkboxDroplead: false,
      checkInputname: false,
      checkInputemail: false,
      checkInputphoneNumber: false,
      showScreen: false,
      categoryGTM: "",
      actionGTM: "",
      labelGTM: "",
      scroll: 0,
      successInitialSale: false,
      NotfoundProduct: false,
      cookieCode: "",
      cookieVersionDisplay: "",
      cookieVersionOrder: 0,
      cookieDescription: "",
      cookieDescriptionCheck: false,
      device: "",
    };
  }

  handleButtonBoxWidgetsBody = () => {
    this.setState({ isOpen: !this.state.isOpen });
    if (this.state.isOpen === true) {
      this.setState({ autoOpen: true });
    }
  };

  serviceDroplead = () => {
    this.setState({
      nameerror: "",
      emailerror: "",
      failPhone: "",
      failselectDate: "",
    });
    this.setState({
      validateDroplead: true,
      checkInputname: false,
      checkInputemail: false,
    });
    let validate = false;
    if (
      !this.state.name ||
      !this.state.phoneNumber ||
      !this.state.email ||
      !this.state.selectDate
    ) {
      validate = true;
    }
    if (this.state.name === "") {
      this.setState({ nameerror: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (
      !this.state.name.split(/ (.*)/s)[0] ||
      !this.state.name.split(/ (.*)/s)[1]
    ) {
      this.setState({ nameerror: "กรุณาตรวจสอบข้อมูล", checkInputname: true });
      validate = true;
    }

    if (this.state.email === "") {
      this.setState({ emailerror: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (this.validateEmail(this.state.email) !== true) {
      this.setState({
        emailerror: "กรุณาตรวจสอบข้อมูล",
        checkInputemail: true,
      });
      validate = true;
    }

    if (this.state.phoneNumber == "") {
      this.setState({ failPhone: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (this.state.phoneNumber.length <= 9) {
      this.setState({
        failPhone: "กรุณาตรวจสอบข้อมูล",
        checkInputphoneNumber: true,
      });
      validate = true;
    }

    if (this.state.selectDate == "") {
      this.setState({ failselectDate: "กรุณาระบุข้อมูล" });
      validate = true;
    }

    if (validate) {
      return;
    }

    this.setState({ step: "2" });

    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      channel: MainData.initialData.channelCode,
      firstName: this.state.name.split(" ")[0].trim(),
      lastName: this.state.name.split(" ").slice(1).filter(Boolean).join(" "),
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      productId: MainData.initialData.productId,
      contactTimeName: this.state.selectDate,
    };

    axios
      .post(MainData.serviceURL + "/Customer/GetDropLead", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (res.data.success) {
          this.setState({ step: "3" });
          this.setState({
            contactTimeId: data.contactTimeId,
            contactTimeCode: data.contactTimeCode,
            contactTimeName: data.contactTimeName,
          });
        } else {
          this.setState({ step: "2" });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          this.loadController(false);
          this.validateBadRequest(error.response.data.errors);
        } else {
          this.setState({ step: "4" });
        }
      });
  };

  validateBadRequest = (fieldNameList) => {
    let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG018");
    this.errorAlert(
      true,
      !errMsg ? "กรุณาตรวจสอบข้อมูล" : errMsg.msgDescription,
      " "
    );
    if (
      fieldNameList.find((data) => data.field === "firstName") ||
      fieldNameList.find((data) => data.field === "lastName")
    ) {
      this.setState({ nameerror: errMsg, checkInputname: true });
    }
    if (fieldNameList.find((data) => data.field === "phoneNumber")) {
      this.setState({ failPhone: errMsg, checkInputphoneNumber: true });
    }
    if (fieldNameList.find((data) => data.field === "email")) {
      this.setState({ emailerror: errMsg, checkInputemail: true });
    }
    if (fieldNameList.find((data) => data.field === "contactTimeName")) {
      this.setState({ failselectDate: errMsg });
    }
  };
  serviceDroplead = () => {
    this.setState({
      nameerror: "",
      emailerror: "",
      failPhone: "",
      failselectDate: "",
    });
    this.setState({
      validateDroplead: true,
      checkInputname: false,
      checkInputemail: false,
      checkInputemail: false,
    });
    let validate = false;
    if (
      !this.state.name ||
      !this.state.phoneNumber ||
      !this.state.email ||
      !this.state.selectDate
    ) {
      validate = true;
    }
    if (this.state.name === "") {
      this.setState({ nameerror: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (
      !this.state.name.split(/ (.*)/s)[0] ||
      !this.state.name.split(/ (.*)/s)[1]
    ) {
      this.setState({ nameerror: "กรุณาตรวจสอบข้อมูล", checkInputname: true });
      validate = true;
    }

    if (this.state.email === "") {
      this.setState({ emailerror: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (this.validateEmail(this.state.email) !== true) {
      this.setState({
        emailerror: "กรุณาตรวจสอบข้อมูล",
        checkInputemail: true,
      });
      validate = true;
    }

    if (this.state.phoneNumber == "") {
      this.setState({ failPhone: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (this.state.phoneNumber.length <= 9) {
      this.setState({
        failPhone: "กรุณาตรวจสอบข้อมูล",
        checkInputphoneNumber: true,
      });
      validate = true;
    }

    if (this.state.selectDate == "") {
      this.setState({ failselectDate: "กรุณาระบุข้อมูล" });
      validate = true;
    }

    if (validate) {
      return;
    }

    this.setState({ step: "2" });

    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      channel: MainData.initialData.channelCode,
      firstName: this.state.name.split(" ")[0].trim(),
      lastName: this.state.name.split(" ").slice(1).filter(Boolean).join(" "),
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      productId: MainData.initialData.productId,
      contactTimeName: this.state.selectDate,
    };

    axios
      .post(MainData.serviceURL + "/Customer/GetDropLead", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (res.data.success) {
          this.setState({ step: "3" });
          this.setState({
            contactTimeId: data.contactTimeId,
            contactTimeCode: data.contactTimeCode,
            contactTimeName: data.contactTimeName,
          });
        } else {
          this.setState({ step: "2" });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          this.loadController(false);
          this.validateBadRequest(error.response.data.errors);
        } else {
          this.setState({ step: "4" });
        }
      });
  };

  validateBadRequest = (fieldNameList) => {
    let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG018");
    this.errorAlert(
      true,
      !errMsg ? "กรุณาตรวจสอบข้อมูล" : errMsg.msgDescription,
      " "
    );
    if (
      fieldNameList.find((data) => data.field === "firstName") ||
      fieldNameList.find((data) => data.field === "lastName")
    ) {
      this.setState({ nameerror: errMsg, checkInputname: true });
    }
    if (fieldNameList.find((data) => data.field === "phoneNumber")) {
      this.setState({ failPhone: errMsg, checkInputphoneNumber: true });
    }
    if (fieldNameList.find((data) => data.field === "email")) {
      this.setState({ emailerror: errMsg, checkInputemail: true });
    }
    if (fieldNameList.find((data) => data.field === "contactTimeName")) {
      this.setState({ failselectDate: errMsg });
    }
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  handleInputChange = (name, val) => {
    switch (name) {
      case "name":
        if (val[0] === " ") {
          break;
        }
        let checkWord = /^[ก-์ ]+$/.test(val);
        this.setState((state) => ({
          name: !checkWord && val ? state.name : val,
        }));
        this.setState({ checkInputname: false });
        if (this.state.name.split(" ")[0] || this.state.name.split(" ")[1]) {
          this.setState({ nameerror: "" });
        }
        break;
      case "phoneNumber":
        val = val.replace(/[^\d]/g, "");
        if (val.length === 1 && val !== "0") {
          this.setState({ phoneNumber: "0" + val });
        } else if (val.length <= 10) {
          this.setState({ phoneNumber: val, failPhone: "" });
        }
        this.setState({ checkInputphoneNumber: false });
        break;
      case "email":
        let checkWord4 = /^[a-zA-Z0-9-@_.]+$/.test(val);
        this.setState((state) => ({
          email: !checkWord4 && val ? state.email : val,
          emailerror: "",
        }));
        this.setState({ checkInputemail: false });
        break;
      case "selectDate":
        this.setState({ selectDate: val, failselectDate: "" });
        break;
      default:
        break;
    }
  };

  closeDroplead = () => {
    this.setState({
      showDropLead: false,
      step: "1",
      validateDroplead: false,
      checkboxDroplead: false,
      checkInputname: false,
      checkInputemail: false,
      checkInputphoneNumber: false,
    });
    this.setState({ name: "", phoneNumber: "", email: "", selectDate: "" });
    this.setState({
      nameerror: "",
      failPhone: "",
      emailerror: "",
      failselectDate: "",
      validateDroplead: false,
    });
  };

  render() {
    return (
      // JSX code for rendering your component
      <Fragment>
        <div
          className={`${styles.menuList} ${
            this.state.isOpenMenu ? styles.activeMenu : ""
          }`}
        >
          {this.props.channel?.css && (
            <IconCloseMenu
              className={`iconCloseMenu ${styles.iconCloseMenu}`}
              onClick={() => this.setState({ isOpenMenu: false })}
            />
          )}

          <div
            className={`${styles.menuListBody} ${
              this.state.isOpenMenu ? styles.activeMenu : ""
            }`}
          >
            <div className={styles.title}>
              <span>กรุณาเลือกหัวข้อ</span>
            </div>
            <div className={styles.menuListNav}>
              <div
                className={`${styles.menuListText} ${
                  this.props.activeCompornent === "planselect"
                    ? styles.active
                    : ""
                }`}
                onClick={() => {
                  this.props.setActiveCompornent("planselect");
                  this.setState({ isOpenMenu: false });
                }}
              >
                {this.props.channel?.css && (
                  <IconPlanSelect
                    fill={
                      this.props.activeCompornent === "planselect"
                        ? "var(--mainColor)"
                        : "#666666"
                    }
                  />
                )}

                <span>
                  {MainFunc.getLabelDtos("L0770") /*คำนวณเบี้ยของคุณ*/}
                </span>
              </div>
              {((this.props.data.highlights !== null &&
                this.props.data.highlights?.highlightDetails.length !== 0) ||
                this.props.data.briefBenefit !== null ||
                this.props.data.iFrameContent !== null) && (
                <div
                  className={`${styles.menuListText} ${
                    this.props.activeCompornent === "highlight" ||
                    this.props.activeCompornent === "briefBenefit" ||
                    this.props.activeCompornent === "iFrameContent"
                      ? styles.active
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      this.props.data.highlights &&
                      this.props.data.highlights?.highlightDetails.length !== 0
                    ) {
                      this.props.setActiveCompornent("highlight");
                    } else if (this.props.data.briefBenefit) {
                      this.props.setActiveCompornent("briefBenefit");
                    } else if (this.props.data.iFrameContent) {
                      this.props.setActiveCompornent("iFrameContent");
                    }
                    this.setState({ isOpenMenu: false });
                  }}
                >
                  {this.props.channel?.css && (
                    <IconHighlights
                      fill={
                        this.props.activeCompornent === "highlight" ||
                        this.props.activeCompornent === "briefBenefit" ||
                        this.props.activeCompornent === "iFrameContent"
                          ? "var(--mainColor)"
                          : "#666666"
                      }
                    />
                  )}

                  <span>{MainFunc.getLabelDtos("L0772") /*จุดเด่น*/}</span>
                </div>
              )}

              {this.props.data.warrantys && (
                <div
                  className={`${styles.menuListText} ${
                    this.props.activeCompornent === "warrantys"
                      ? styles.active
                      : ""
                  }`}
                  onClick={() => {
                    this.props.setActiveCompornent("warrantys");
                    this.setState({ isOpenMenu: false });
                  }}
                >
                  {this.props.channel?.css && (
                    <IconInsuranceCompare
                      fill={
                        this.props.activeCompornent === "warrantys"
                          ? "var(--mainColor)"
                          : "#666666"
                      }
                    />
                  )}

                  <span>
                    {MainFunc.getLabelDtos("L0010") /*เงื่อนไขการรับประกัน*/}
                  </span>
                </div>
              )}
              {this.props.data.coverages && (
                <div
                  className={`${styles.menuListText} ${
                    this.props.activeCompornent === "coverages"
                      ? styles.active
                      : ""
                  }`}
                  onClick={() => {
                    this.props.setActiveCompornent("coverages");
                    this.setState({
                      isOpenMenu: false,
                      isOpen: this.state.autoOpen ? false : true,
                    });
                  }}
                >
                  {this.props.channel?.css && (
                    <IconCoverageTerm
                      fill={
                        this.props.activeCompornent === "coverages"
                          ? "var(--mainColor)"
                          : "#666666"
                      }
                    />
                  )}

                  <span>
                    {MainFunc.getLabelDtos("L0773") /*เงื่อนไขความคุ้มครอง*/}
                  </span>
                </div>
              )}
              {this.props.data.promotions &&
                this.props.data.promotions?.length !== 0 && (
                  <div
                    className={`${styles.menuListText} ${
                      this.props.activeCompornent === "promotions"
                        ? styles.active
                        : ""
                    }`}
                    onClick={() => {
                      this.props.setActiveCompornent("promotions");
                      this.setState({ isOpenMenu: false });
                    }}
                  >
                    {this.props.channel?.css && (
                      <IconBenefit
                        fill={
                          this.props.activeCompornent === "promotions"
                            ? "var(--mainColor)"
                            : "#666666"
                        }
                      />
                    )}

                    <span>{MainFunc.getLabelDtos("L0774") /*สิทธิพิเศษ*/}</span>
                  </div>
                )}
              {this.props.data.isDropLead && (
                <div
                  className={`${styles.menuListText} ${
                    this.props.activeCompornent === "isDropLead"
                      ? styles.active
                      : ""
                  }`}
                  onClick={() => {
                    this.props.setActiveCompornent("isDropLead");
                    this.setState({ isOpenMenu: false });
                  }}
                >
                  {this.props.channel?.css && (
                    <IconIsDropLead
                      fill={
                        this.props.activeCompornent === "isDropLead"
                          ? "var(--mainColor)"
                          : "#666666"
                      }
                    />
                  )}

                  <span>
                    {MainFunc.getLabelDtos("L0775") /*ให้เราติดต่อกลับ*/}
                  </span>
                </div>
              )}
              {this.props.data.faqProducts && (
                <div
                  className={`${styles.menuListText} ${
                    this.props.activeCompornent === "faqProducts"
                      ? styles.active
                      : ""
                  }`}
                  onClick={() => {
                    this.props.setActiveCompornent("faqProducts");
                    this.setState({ isOpenMenu: false });
                  }}
                >
                  {this.props.channel?.css && (
                    <IconFAQ
                      fill={
                        this.props.activeCompornent === "faqProducts"
                          ? "var(--mainColor)"
                          : "#666666"
                      }
                    />
                  )}

                  <span>
                    {MainFunc.getLabelDtos("L0776") /*คำถามที่พบบ่อย*/}
                  </span>
                </div>
              )}
              {this.props.data.contactDetail && (
                <div
                  className={`${styles.menuListText} ${
                    this.props.activeCompornent === "remark"
                      ? styles.active
                      : ""
                  }`}
                  onClick={() => {
                    this.props.setActiveCompornent("remark");
                    this.setState({ isOpenMenu: false });
                  }}
                >
                  {this.props.channel?.css && (
                    <IconRemark
                      fill={
                        this.props.activeCompornent === "remark"
                          ? "var(--mainColor)"
                          : "#666666"
                      }
                    />
                  )}

                  <span>
                    {MainFunc.getLabelDtos("L0777") /*ผู้ให้บริการประกัน*/}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {MainData.userSelectedData.selectedPlan &&
          this.props.stepSelectPlan !== 3 && (
            <div className={styles.summaryMobile}>
              <div className={styles.btnShowDetail}>
                <a
                  onClick={() =>
                    this.setState({
                      IsSummaryDetailMobile: !this.state.IsSummaryDetailMobile,
                    })
                  }
                  className={styles.showDetail}
                  title="แสดงรายละเอียดเพิ่มเติม"
                >
                  {
                    !this.state.IsSummaryDetailMobile
                      ? MainFunc.getLabelDtos("L0813") /*แตะเพื่อดูรายละเอียด*/
                      : MainFunc.getLabelDtos("L0814") /*แตะเพื่อปิด*/
                  }
                </a>
              </div>
              <div
                className={`${styles.summaryDetailMobile} ${
                  this.state.IsSummaryDetailMobile ? styles.activeMenu : ""
                }`}
              >
                <div className="container">
                  <div className={styles.detailContent}>
                    <div className={styles.contentPlanSelected}>
                      <div className={styles.dataTitle}>
                        <div className={styles.title}>
                          {MainFunc.getLabelDtos("L0722") /*เบี้ยกรมธรรม์หลัก*/}
                        </div>
                        <div className={styles.value}>
                          {" "}
                          {MainFunc.numberWithCommas(
                            MainData.userSelectedData.notRiderPlan?.premium
                          ) +
                            " " +
                            MainFunc.getLabelDtos("L0104")}
                        </div>
                      </div>
                    </div>
                    {MainData.dataBroker.planInfo?.planDetails?.map((data) => {
                      return (
                        <div className={styles.contentPlanSelected}>
                          <div className={styles.dataGroup}>
                            {data?.groupTitle && (
                              <div
                                className={styles.title}
                                dangerouslySetInnerHTML={{
                                  __html: data?.groupTitle,
                                }}
                              ></div>
                            )}
                            {data?.groupValue && (
                              <div
                                className={styles.value}
                                dangerouslySetInnerHTML={{
                                  __html: data?.groupValue,
                                }}
                              ></div>
                            )}
                          </div>
                          {data.titles.map((item, index) => {
                            return (
                              <Fragment>
                                <div className={styles.dataTitle}>
                                  {item.title && (
                                    <div
                                      className={styles.title}
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    ></div>
                                  )}
                                  {item.titleValue && (
                                    <div
                                      className={styles.value}
                                      dangerouslySetInnerHTML={{
                                        __html: item.titleValue,
                                      }}
                                    ></div>
                                  )}
                                </div>
                                {item.subTitles?.map((subItem, subIndex) => {
                                  return (
                                    <div className={styles.dataSubTitle}>
                                      <div
                                        className={styles.title}
                                        dangerouslySetInnerHTML={{
                                          __html: subItem.subTitle,
                                        }}
                                      ></div>
                                      <div className={styles.value}>
                                        <div
                                          className={styles.title}
                                          dangerouslySetInnerHTML={{
                                            __html: subItem.subTitleValue,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </Fragment>
                            );
                          })}
                          <div className={styles.rowline}></div>
                        </div>
                      );
                    })}
                  </div>
                  {/* บริการเสริม */}
                  {MainData.dataBroker.riderPlan?.map((riderPlan, index) => (
                    <div className={styles.detailContent}>
                      <div className={styles.contentPlanSelected}>
                        <div className={styles.dataGroupRider}>
                          {riderPlan?.groupDescription && (
                            <div className={styles.title}>
                              {riderPlan?.groupDescription}
                            </div>
                          )}
                        </div>
                        <div>
                          {riderPlan.riderBenefits?.map((riderBenefits) => {
                            if (
                              MainData.dataBroker.selectRider.includes(
                                riderBenefits.riderCode
                              )
                            ) {
                              return (
                                <Col
                                  md={4}
                                  className={styles.additionalServicesData}
                                >
                                  <div className={styles.dataTitleRider}>
                                    {riderBenefits.riderName && (
                                      <span className={styles.title}>
                                        {riderBenefits.riderName}
                                      </span>
                                    )}
                                    {riderBenefits.titleValue && (
                                      <span className={styles.value}>
                                        {riderBenefits.titleValue}
                                      </span>
                                    )}
                                  </div>
                                  <div className={styles.dataSubTitleRider}>
                                    <span className={styles.title}>
                                      {riderBenefits.riderTitle}{" "}
                                      {MainFunc.numberWithCommas(
                                        riderBenefits?.riderPremium
                                      ) +
                                        " " +
                                        MainFunc.getLabelDtos("L0104")}
                                      /
                                      {
                                        MainData.userSelectedData?.selectedPlan
                                          ?.paymentUnitTitle
                                      }
                                    </span>
                                    <span className={styles.value}></span>
                                  </div>
                                </Col>
                              );
                            }
                          })}
                          {riderPlan.riderSpecials?.map((riderSpecial) => (
                            <Col
                              md={4}
                              className={styles.additionalServicesData}
                            >
                              <div className={styles.dataTitleRiderSpecial}>
                                {riderSpecial.riderSpecialName && (
                                  <span className={styles.title}>
                                    {riderSpecial.riderSpecialName}
                                  </span>
                                )}
                              </div>
                              <div className={styles.dataSubTitleRiderSpecial}>
                                <span className={styles.title}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: riderSpecial.riderSpecialTitle,
                                    }}
                                  ></div>
                                </span>
                                <span className={styles.value}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: riderSpecial.riderSpecialValue,
                                    }}
                                  ></div>
                                </span>
                              </div>
                            </Col>
                          ))}
                        </div>
                      </div>
                      <div className={styles.rowline}></div>
                    </div>
                  ))}
                </div>
              </div>
              {MainData.userSelectedData.selectedPlan && (
                <div className="container">
                  <div className={styles.mainContent}>
                    <div className={styles.left}>
                      <div className={styles.productName}>
                        {
                          MainFunc.getLabelDtos(
                            "L0765"
                          ) /*แผนประกันที่คุณเลือก*/
                        }
                      </div>
                      <div className={styles.productName}>
                        {MainData.dataBroker.planInfo?.productName}
                        {" "}
                        {MainData.userSelectedData.selectedPlan?.planName}
                      </div>
                    </div>
                    <div className={styles.right}>
                      <div className={styles.paymentType}>
                        {MainFunc.getLabelDtos("L0726") /*เบี้ยรวมต่อ*/}
                        {
                          MainData.userSelectedData.selectedPlan
                            ?.paymentUnitTitle
                        }
                      </div>
                      <div className={styles.premium}>
                        {" "}
                        {MainFunc.numberWithCommas(
                          MainData.userSelectedData.selectedPlan?.premium
                        ) +
                          " " +
                          MainFunc.getLabelDtos("L0104")}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

        <div className={styles.boxWidgetsMobile}>
          {this.props.channel?.css && (
            <div
              className={`${styles.mainIcon} ${styles.menulistIcon}`}
              onClick={() => {
                this.setState({ isOpenMenu: !this.state.isOpenMenu });
              }}
            >
              {this.state.isOpenMenu ? (
                // <img src={IconBoxWidgetMenuLisrt} alt="icon-box-widget" />
                <IconBoxWidgetMenuLisrt className="iconBoxWidgetMenuLisrt" />
              ) : // <img src={IconBoxWidgetMenu} alt="icon-box-widget" />
              this.props.channel?.name == "KBANK" ? (
                <IconBoxWidgetMenu className="iconBoxWidgetMenu" />
              ) : (
                <IconBoxWidgetMenuNotkBANK className="iconBoxWidgetMenu" />
              )}
            </div>
          )}

          <div style={{ width: "100%" }}>
            <a
              onClick={() => this.props.onProcessData()}
              className={styles.btnInsure}
              title="ติดต่อเลย"
            >
              {
                this.props.stepSelectPlan === 1 &&
                  MainFunc.getLabelDtos("L0770") /*คำนวณเบี้ยของคุณ*/
              }
              {
                this.props.stepSelectPlan === 2 &&
                  MainFunc.getLabelDtos("L0809") /*สรุปข้อมูลแผนประกัน*/
              }
              {
                this.props.stepSelectPlan === 3 &&
                  MainFunc.getLabelDtos("L0768") /*ซื้อเลย*/
              }
            </a>
          </div>
          {this.props.channel?.name == "KBANK" ? (
            <div
              className={styles.mainIcon}
              onClick={() => {
                if (!this.props.isOpenContact) {
                  MainFunc.sendDataToGTMKbank({
                    event: "track_event",
                    event_category: "link_click",
                    event_action: "click",
                    event_label: "contact_icon",
                  });
                }
                this.props.setIsOpenContact();
              }}
            >
              <BoxWidgetsBody
                isOpen={this.props.isOpenContact}
                data={this.props.contactWidget}
              />

              {this.props.isOpenContact ? (
                <img src={IconClose} alt="icon-box-widget" />
              ) : this.props.channel?.css ? (
                <IconBoxWidget className="iconBoxWidget" />
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className={styles.mainIcon}>
              <Image
                src={IcoCallCenter}
                // className={"drop--lead--ico"}
                id={`${MainData.initialData.codeTracking}Click_BtnChat`}
                title="DropLead"
                onClick={() => {
                  this.setState({ showDropLead: !this.state.showDropLead });
                }}
              ></Image>
              <Collapse
                in={this.state.showDropLead}
                timeout="auto"
                unmountOnExit
              >
                <div className={"drop--lead--box"}>
                  <Card
                    class="text-center csMainBG text-white card-header"
                    border="light"
                  >
                    <Card.Header class="text-center csMainBG text-white card-header">
                      <Image
                        className="IcoCrossSign"
                        src={IcoCrossSign}
                        rounded
                        onClick={() => this.closeDroplead()}
                      ></Image>
                      <Row
                        id="logoSection"
                        className="pl-2 pr-2 pt-2 pb-2 csMainBG d-flex align-items-center"
                        style={{ height: "45px" }}
                      >
                        <Image
                          id="channelLogo"
                          src={
                            !this.state.channel?.logo
                              ? logo2
                              : this.state.channel.logo
                          }
                          rounded
                          style={{
                            height: "95px",
                            width: "unset",
                            position: "absolute",
                            left: "0",
                            top: "-5px",
                          }}
                          onLoad={(e) =>
                            this.setState({ logoWidth: e.target.width + 5 })
                          }
                        />
                        <div>
                          <Form.Label className="labelDropLead">
                            {
                              MainFunc.getLabelDtos(
                                "L0466"
                              ) /* ให้เจ้าหน้าที่ติดต่อกลับ */
                            }
                          </Form.Label>
                        </div>
                      </Row>
                    </Card.Header>
                    {this.state.step == "1" ? (
                      <Card.Body>
                        <div
                          style={{
                            height: "420px",
                            overflow: "hidden",
                            overflowY: "scroll",
                            padding: "12px",
                          }}
                        >
                          <span className="" style={{ fontSize: "1.1rem" }}>
                            {
                              MainFunc.getLabelDtos(
                                "L0472"
                              ) /* ติดต่อเจ้าหน้าที่ */
                            }
                          </span>
                          <br></br>
                          <span className="" style={{ fontSize: "1.1rem" }}>
                            {
                              MainFunc.getLabelDtos(
                                "L0473"
                              ) /* ทุกวันทำการ จันทร์ - ศุกร์ เวลา 08:00 - 18:00 */
                            }
                          </span>
                          <br></br>
                          <Row>
                            <Col xs={1} style={{ marginTop: 10 }}>
                              <Image
                                className="mb-2 mr-2"
                                src={IcoTelGreen}
                                rounded
                                style={{
                                  height: "30px",
                                  width: "unset",
                                  left: "0",
                                  top: "-5px",
                                }}
                                onLoad={(e) =>
                                  this.setState({
                                    logoWidth: e.target.width + 5,
                                  })
                                }
                              />
                            </Col>
                            <Col className="drop--lead--call">
                              <a
                                href={`tel:${MainFunc.getLabelDtos("L0183")}`}
                                className="font-weight-bold mt-1 telClass"
                                style={{ fontSize: "1.6rem" }}
                              >
                                {MainFunc.getLabelDtos("L0183")}
                              </a>
                              <a
                                className="font-weight-bold mt-1 callToClass"
                                style={{ fontSize: "1.6rem", width: 60 }}
                              >
                                {MainFunc.getLabelDtos("L0183")}
                              </a>
                              <br></br>
                            </Col>
                          </Row>
                          <Form>
                            <Form.Group
                              className="font-weight-bold "
                              hasValidation
                            >
                              <Form.Label>
                                {
                                  MainFunc.getLabelDtos(
                                    "L0467"
                                  ) /* ชื่อ - นามสกุล */
                                }
                              </Form.Label>
                              <Form.Control
                                className={`input-background ${
                                  (this.state.validateDroplead &&
                                    !this.state.name) ||
                                  this.state.checkInputname
                                    ? "input--error"
                                    : ""
                                }`}
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={(e) =>
                                  this.handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                autoComplete="off"
                              />
                              <span
                                style={{ fontWeight: "bold", color: "red" }}
                              >
                                {this.state.nameerror}
                              </span>
                            </Form.Group>
                            <Form.Group className="font-weight-bold ">
                              <Form.Label>
                                {
                                  MainFunc.getLabelDtos(
                                    "L0468"
                                  ) /* เบอร์มือถือ */
                                }
                              </Form.Label>
                              <Form.Control
                                className={`input-background ${
                                  (this.state.validateDroplead &&
                                    !this.state.phoneNumber) ||
                                  this.state.checkInputphoneNumber
                                    ? "input--error"
                                    : ""
                                }`}
                                type="phoneNumber"
                                name="phoneNumber"
                                value={this.state.phoneNumber}
                                onChange={(e) =>
                                  this.handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                autoComplete="off"
                              />
                              <span
                                style={{ fontWeight: "bold", color: "red" }}
                              >
                                {this.state.failPhone}
                              </span>
                            </Form.Group>
                            <Form.Group className="font-weight-bold ">
                              <Form.Label>
                                {MainFunc.getLabelDtos("L0469") /* อีเมล */}
                              </Form.Label>
                              <Form.Control
                                className={`input-background ${
                                  (this.state.validateDroplead &&
                                    !this.state.email) ||
                                  this.state.checkInputemail
                                    ? "input--error"
                                    : ""
                                }`}
                                type="text"
                                name="email"
                                placeholder="yourmail@mail.com"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                autoComplete="off"
                              />
                              <span
                                style={{ fontWeight: "bold", color: "red" }}
                              >
                                {this.state.emailerror}
                              </span>
                            </Form.Group>
                            <Form.Label className="font-weight-bold ">
                              {
                                MainFunc.getLabelDtos(
                                  "L0470"
                                ) /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */
                              }
                            </Form.Label>
                            <Form.Group>
                              <Form.Control
                                className={`input-background ${
                                  this.state.validateDroplead &&
                                  !this.state.selectDate
                                    ? "input--error"
                                    : ""
                                }`}
                                as="select"
                                name="selectDate"
                                placeholder="ช่วงเวาลาที่สะดวกให้ติดต่อกลับ"
                                value={this.state.selectDate}
                                onChange={(e) =>
                                  this.handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              >
                                <option value="" disabled selected>
                                  {
                                    MainFunc.getLabelDtos(
                                      "L0470"
                                    ) /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */
                                  }
                                </option>
                                {MainData.dropleadDtos.map((item, key) => {
                                  return (
                                    <option value={item.contactTimeName}>
                                      {item.contactTimeName}
                                    </option>
                                  );
                                })}
                              </Form.Control>
                              <span
                                style={{ fontWeight: "bold", color: "red" }}
                              >
                                {this.state.failselectDate}
                              </span>
                            </Form.Group>
                            <Form.Group
                              style={{ marginTop: 20 }}
                              className="mb-3"
                              controlId="formBasicCheckbox"
                            >
                              <Form.Check
                                type="checkbox"
                                value={this.state.checkboxDroplead}
                                onChange={(e) => {
                                  this.setState({
                                    checkboxDroplead: e.target.checked,
                                  });
                                }}
                                label="ข้าพเจ้ายินยอมให้ บมจ.เมืองไทยประกันชีวิต ทำการเก็บรวบรวมและใช้ข้อมูลที่ข้าพเจ้าให้ไว้ นายหน้าประกันชีวิต และพันธมิตร/คู่ค้า เพื่อวัตถุประสงค์ในการนำเสนอขายประกันชีวิตแบบต่างๆ และ/หรือนำเสนอสิทธิประโยชน์อื่นๆ ให้แก่ข้าพเจ้าได้"
                              />
                            </Form.Group>
                          </Form>
                          <Col className="d-flex justify-content-center pt-3">
                            <Button
                              variant=""
                              className="mainBtnRadius font-weight-bold"
                              style={{ fontSize: "1.1rem" }}
                              onClick={() => {
                                this.serviceDroplead();
                              }}
                              disabled={!this.state.checkboxDroplead}
                            >
                              {
                                MainFunc.getLabelDtos(
                                  "L0471"
                                ) /* ส่งข้อมูลสอบถาม */
                              }
                            </Button>
                          </Col>
                        </div>
                      </Card.Body>
                    ) : (
                      [
                        this.state.step == "2" ? (
                          <Card.Body>
                            <div style={{ marginTop: 150 }}>
                              <Spinner
                                className="img-center"
                                animation="border"
                                role="status"
                                style={{ width: "60px", height: "60px" }}
                              >
                                <span className="visually-hidden"></span>
                              </Spinner>
                            </div>
                          </Card.Body>
                        ) : (
                          [
                            this.state.step == "3" ? (
                              <Card.Body>
                                <div style={{ margin: 40 }}>
                                  <Image
                                    className="img-center"
                                    src={IcoCorrect}
                                  ></Image>
                                  <div className="text-align-center ">
                                    <span
                                      className="font-weight-bold text-center"
                                      style={{ fontSize: "1.2rem" }}
                                    >
                                      {
                                        MainFunc.getLabelDtos(
                                          "L0474"
                                        ) /* บริษัทฯ ได้รับข้อมูลเรียบร้อยแล้ว กรุณารอเจ้าหน้าที่ติดต่อกลับภายใน 1-2 วันทำการ */
                                      }
                                    </span>
                                    <br></br>
                                    <span
                                      className="font-weight-bold text-center font-collor1 "
                                      style={{ fontSize: "1.2rem" }}
                                    >
                                      {
                                        MainFunc.getLabelDtos(
                                          "L0475"
                                        ) /* ขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน) */
                                      }
                                    </span>
                                    <br></br>
                                  </div>
                                  <Col className="d-flex justify-content-center pt-3">
                                    <Button
                                      variant=""
                                      className="mainBtnRadius font-weight-bold"
                                      style={{ fontSize: "1.1rem", width: 200 }}
                                      onClick={() => this.closeDroplead()}
                                    >
                                      {
                                        MainFunc.getLabelDtos(
                                          "L0185"
                                        ) /** ตกลง */
                                      }
                                    </Button>
                                  </Col>
                                </div>
                              </Card.Body>
                            ) : (
                              <Card.Body>
                                <div style={{ margin: 70 }}>
                                  <Image
                                    className="img-center"
                                    src={IcoError}
                                  ></Image>
                                  <div
                                    className="text-align-center "
                                    style={{ marginTop: 30 }}
                                  >
                                    <span
                                      className="font-weight-bold text-center"
                                      style={{ fontSize: "1.2rem" }}
                                    >
                                      {
                                        MainFunc.getLabelDtos(
                                          "L0476"
                                        ) /** ขออภัยในความไม่สะดวก */
                                      }
                                    </span>
                                    <br></br>
                                    <span
                                      className="font-weight-bold text-center font-collor1 "
                                      style={{ fontSize: "1.2rem" }}
                                    >
                                      {
                                        MainFunc.getLabelDtos(
                                          "L0477"
                                        ) /** กรุณาลองใหม่อีกครั้ง */
                                      }
                                    </span>
                                    <br></br>
                                  </div>
                                  <Col
                                    className="d-flex justify-content-center pt-3"
                                    style={{ marginTop: 30 }}
                                  >
                                    <Button
                                      variant=""
                                      className="mainBtnRadius font-weight-bold"
                                      style={{ fontSize: "1.1rem", width: 200 }}
                                      onClick={() => this.closeDroplead()}
                                    >
                                      {MainFunc.getLabelDtos("L0188") /* ปิด */}
                                    </Button>
                                  </Col>
                                </div>
                              </Card.Body>
                            ),
                          ]
                        ),
                      ]
                    )}
                  </Card>
                </div>
              </Collapse>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default boxWidgetsMobile;
