// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = hslToRgb;

var _decomposeColor = _interopRequireDefault(require("./decomposeColor"));

var _recomposeColor = _interopRequireDefault(require("./recomposeColor"));

/* eslint-disable id-length */

/* eslint-disable no-param-reassign */
function hslToRgb(color) {
  color = (0, _decomposeColor["default"])(color);
  var _color = color,
      values = _color.values;
  var h = values[0];
  var s = values[1] / 100;
  var l = values[2] / 100;
  var a = s * Math.min(l, 1 - l);

  var f = function f(n) {
    var k = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (n + h / 30) % 12;
    return l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  };

  var type = 'rgb';
  var rgb = [Math.round(f(0) * 255), Math.round(f(8) * 255), Math.round(f(4) * 255)];

  if (color.type === 'hsla') {
    type += 'a';
    rgb.push(values[3]);
  }

  return (0, _recomposeColor["default"])({
    type: type,
    values: rgb
  });
}