import axios from "axios";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

import { GoAlert } from "react-icons/go";
import React, { Component, Fragment } from "react";
import { Row, Col, Image, Button, Card, Form, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import HighlightProduct from "./highlightProduct.js";
import InsuranceCompare from "./insuranceCompare.js";
import InsuranceTerms from "./insuranceTerms.js";
import CoverageTerms from "./coverageTerms.js";
import Video from "./video.js";
import Faq from "./faq.js";
import ConsultExpert from "./consultExpert.js";
import Service from "./service.js";
import ContactBack from "./contactBack.js";
import ContactBackSuccess from "./contactBackSuccess.js";
import ContactBackFail from "./contactBackFail.js";
import ContactBackCaptchaFail from "./contactBackCaptchaFail.js";
import RelatedProducts from "./relatedProducts.js";
import Benefit from "./benefit.js";
import BoxWidgets from "./boxWidgets.js";
import BoxWidgetsMobile from "./boxWidgetsMobile.js";

import NavBarBroker from "./navBarBroker.js";
import styles from "../../styles/broker.module.scss";
import Planselect from "./planselect.js";

import IcoCallCenter from "../../images/ico_callcenter.png";
import { Collapse } from "@material-ui/core";
import IcoError from "../../images/ico_error.png";
import IcoCrossSign from "../../images/ico_closesign.png";
import IcoCorrect from "../../images/ico_correct.png";
import logo2 from "../../images/logo.png";
import IcoTelGreen from "../../images/ico_tel_green.png";

class BrokerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "BrokerPage",
      stepContactBack: 1, // 1.Form, 2.Success, 3.Fail
      dataDeteil: {},
      activeCompornent: "",
      activeNavBroker: false,
      isLoading: false,
      isError: false,
      errMsg: "",
      errSubMsg: "",
      categoryGTM: "",
      actionGTM: "",
      labelGTM: "",
      checkKplus: false,
      stepSelectPlan: 1,
      activeCompornentStatus: false,
      isOpenContact: false,
      isOpenContactAuto: false,
      channel: {
        name: "",
        css: "",
        logo: MainData.channelLogo,
        font: null,
      },
      showDropLead: false,
      step: "1",
      name: "",
      phoneNumber: "",
      email: "",
      selectDate: "",
      validateDroplead: false,
      nameerror: "",
      emailerror: "",
      failPhone: "",
      failselectDate: "",
      checkboxDroplead: false,
      checkInputname: false,
      checkInputemail: false,
      checkInputphoneNumber: false,
      showScreen: false,
      categoryGTM: "",
      actionGTM: "",
      labelGTM: "",
      scroll: 0,
      successInitialSale: false,
      NotfoundProduct: false,
      cookieCode: "",
      cookieVersionDisplay: "",
      cookieVersionOrder: 0,
      cookieDescription: "",
      cookieDescriptionCheck: false,
      device: "",
      labelMenu: [
        {
          menu: "planselect",
          label: MainFunc.getLabelDtos("L0771") /*เลือกแผนประกัน*/,
        },
        {
          menu: "highlight",
          label: MainFunc.getLabelDtos("L0772") /*จุดเด่น*/,
        },
        {
          menu: "briefBenefit",
          label: MainFunc.getLabelDtos("L0772") /*จุดเด่น*/,
        },
        {
          menu: "iFrameContent",
          label: MainFunc.getLabelDtos("L0772") /*จุดเด่น*/,
        },
        {
          menu: "warrantys",
          label: MainFunc.getLabelDtos("L0010") /*เงื่อนไขการรับประกัน*/,
        },
        {
          menu: "coverages",
          label: MainFunc.getLabelDtos("L0773") /*เงื่อนไขความคุ้มครอง*/,
        },
        {
          menu: "promotions",
          label: MainFunc.getLabelDtos("L0774") /*สิทธิพิเศษ*/,
        },
        {
          menu: "isDropLead",
          label: MainFunc.getLabelDtos("L0775") /*ให้เราติดต่อกลับ*/,
        },
        {
          menu: "faqProducts",
          label: MainFunc.getLabelDtos("L0776") /*คำถามที่พบบ่อย*/,
        },
        {
          menu: "remark",
          label: MainFunc.getLabelDtos("L0777") /*ผู้ให้บริการประกัน*/,
        },
      ],
    };
    this.highlightRef = React.createRef();
    this.briefBenefit = React.createRef();
    this.iFrameContent = React.createRef();
    this.warrantys = React.createRef();
    this.coverages = React.createRef();
    this.promotions = React.createRef();
    this.isDropLead = React.createRef();
    this.consultExpert = React.createRef();
    this.faqProducts = React.createRef();
    this.remark = React.createRef();
    this.productRelate = React.createRef();
    this.planselect = React.createRef();
    this.planSelectComponent = React.createRef();
  }

  UNSAFE_componentWillMount() {
    if (this.props.productDetailData) {
      this.setState({
        dataDeteil: this.props.productDetailData,
        channel: {
          name: this.props.productDetailData?.channelCode,
          css: "",
          logo: MainData.channelLogo,
          font: null,
        },
      });
    }
  }

  componentDidMount() {
    this.setState({
      channel: {
        name: sessionStorage.getItem("channelName"),
        css: sessionStorage.getItem("customCss"),
        logo: MainData.channelLogo,
        font: null,
      },
    });
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "language-locale": "th",
    };
    axios
      .post(
        MainData.serviceURL + "/Broker/GetProductDetail",
        {
          productId: MainData.initialData.productId,
          channelCode: MainData.initialData.channelCode,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        // handle success
        MainData.dataBroker.contactWidget = response.data.data.contactWidget;
        this.setState({ dataDeteil: response.data.data });
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    document.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  }

  handleScroll = (e) => {
    if (window.scrollY < 10 && this.state.activeNavBroker) {
      document
        .getElementById("navigation-header")
        .classList.remove("activeNavBroker");
      this.setState({ activeNavBroker: false });
      return;
    }
    if (this.planselect.current?.getBoundingClientRect().top <= 56) {
      this.setState({ activeNavBroker: true });
    } else {
      this.setState({ activeNavBroker: false });
    }

    if (this.planselect.current?.getBoundingClientRect().top <= 172) {
      document
        .getElementById("navigation-header")
        .classList.add("activeNavBroker");
    } else {
      document
        .getElementById("navigation-header")
        .classList.remove("activeNavBroker");
    }

    if (this.coverages.current?.getBoundingClientRect().top <= 900) {
      this.setState({
        isOpenContact: this.state.isOpenContactAuto ? false : true,
      });
    } else {
      this.setState({
        isOpenContact: false,
      });
    }

    if (this.state.activeCompornentStatus) {
      return;
    }
    if (this.planselect.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "planselect" });
    }
    if (this.highlightRef.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "highlight" });
    }
    if (this.briefBenefit.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "highlight" });
    }
    if (this.iFrameContent.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "highlight" });
    }
    if (this.warrantys.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "warrantys" });
    }

    if (this.coverages.current?.getBoundingClientRect().top <= 58) {
      this.setState({
        activeCompornent: "coverages",
      });
    }
    if (this.promotions.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "promotions" });
    }
    if (this.isDropLead.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "isDropLead" });
    }
    if (this.consultExpert.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "consultExpert" });
    }
    if (this.faqProducts.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "faqProducts" });
    }
    if (this.remark.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "remark" });
    }
    if (this.productRelate.current?.getBoundingClientRect().top <= 58) {
      this.setState({ activeCompornent: "productRelate" });
    }
  };

  activeCompornent(activeCompornent, mobile) {
    this.setState({ activeCompornentStatus: true });
    let yOffset = this.state.activeNavBroker || mobile ? -58 : -245;
    let y;
    if (
      activeCompornent === "planselect" ||
      activeCompornent === "planselectMain"
    ) {
      y =
        this.planselect.current?.getBoundingClientRect().top +
        window.pageYOffset +
        (this.state.activeNavBroker || mobile ? 0 : -130);
    }
    if (activeCompornent === "highlight") {
      y =
        this.highlightRef.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
    }
    if (activeCompornent === "briefBenefit") {
      y =
        this.briefBenefit.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
    }
    if (activeCompornent === "iFrameContent") {
      y =
        this.iFrameContent.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
    }
    if (activeCompornent === "warrantys") {
      y =
        this.warrantys.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
    }
    if (activeCompornent === "coverages") {
      y =
        this.coverages.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      this.setState({
        isOpenContact: this.state.isOpenContactAuto ? false : true,
      });
    }
    if (activeCompornent === "promotions") {
      y =
        this.promotions.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
    }
    if (activeCompornent === "isDropLead") {
      y =
        this.isDropLead.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
    }
    if (activeCompornent === "consultExpert") {
      y =
        this.consultExpert.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
    }
    if (activeCompornent === "faqProducts") {
      y =
        this.faqProducts.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
    }
    if (activeCompornent === "remark") {
      y =
        this.remark.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
    }
    if (activeCompornent === "productRelate") {
      y =
        this.productRelate.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
    }
    setTimeout(() => {
      window.scrollTo({ top: y, behavior: "smooth" });
      this.setState({
        activeCompornent:
          activeCompornent === "planselectMain"
            ? "planselect"
            : activeCompornent,
      });
    }, 100);

    setTimeout(() => {
      this.setState({ activeCompornentStatus: false });
    }, 1000);
    if (activeCompornent !== "planselect") {
      MainFunc.sendDataToGTMKbank({
        event: "track_event",
        event_category: "anchor_nav",
        event_action: "click",
        event_label:
          "nav_" +
          this.state.labelMenu.find(
            (menu) =>
              menu.menu ==
              (activeCompornent === "planselectMain"
                ? "planselect"
                : activeCompornent)
          ).label,
      });
    } else {
      MainFunc.sendDataToGTMKbank({
        event: "track_event",
        event_category: "floating_apply",
        event_action: "click",
        event_label: "btn_apply",
      });
    }
  }

  loadController = (isLoad) => {
    this.props.loadController(isLoad);
  };

  errorAlert = (isErr, msg, subMsg, category, action, label) => {
    this.setState({
      isError: isErr,
      errMsg: msg,
      errSubMsg: !subMsg ? null : subMsg,
      categoryGTM: category,
      actionGTM: action,
      labelGTM: label,
    });
  };

  errorConfirm = () => {
    this.setState({ isError: false, errMsg: "ERROR!" });
  };

  setHeaderText = (main, sec, third, isSelect) => {
    let subHeader = MainFunc.getLabelDtos("L0112") + " : ";
    this.props.setHeaderText(
      MainData.productTitle,
      subHeader +
        MainFunc.numberWithCommas(MainData.screenSM002.cost) +
        " " +
        MainFunc.getLabelDtos("L0104"),
      MainData.screenSM002.planName,
      true
    );
  };

  scrollContentSectionTop = () => {
    document
      .getElementById("contentSection")
      .scrollTo({ top: 0, behavior: "smooth" });
  };
  callChildMethod = () => {
    this.activeCompornent("planselect");
    if (this.state.stepSelectPlan === 2) {
      if (this.planSelectComponent) {
        this.planSelectComponent.current.getPlanSummary(); // Calling the method from the child component
      }
    }
    if (this.state.stepSelectPlan === 3) {
      MainFunc.sendDataToGTMKbank({
        event: "track_event",
        event_category: "link_click",
        event_action: "click",
        event_label: `ซื้อเลย_${MainData.userSelectedData.selectedPlan.planName.replace(
          / /g,
          "_"
        )}_${MainData.userSelectedData.selectedPlan.planId}`,
        position: "bottom_btn",
        plan_code: `${MainData.userSelectedData.selectedPlan.planId}`,
      });
      if (this.planSelectComponent) {
        this.planSelectComponent.current.nextPage(); // Calling the method from the child component
      }
    }
  };

  serviceDroplead = () => {
    this.setState({
      nameerror: "",
      emailerror: "",
      failPhone: "",
      failselectDate: "",
    });
    this.setState({
      validateDroplead: true,
      checkInputname: false,
      checkInputemail: false,
      checkInputemail: false,
    });
    let validate = false;
    if (
      !this.state.name ||
      !this.state.phoneNumber ||
      !this.state.email ||
      !this.state.selectDate
    ) {
      validate = true;
    }
    if (this.state.name === "") {
      this.setState({ nameerror: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (
      !this.state.name.split(/ (.*)/s)[0] ||
      !this.state.name.split(/ (.*)/s)[1]
    ) {
      this.setState({ nameerror: "กรุณาตรวจสอบข้อมูล", checkInputname: true });
      validate = true;
    }

    if (this.state.email === "") {
      this.setState({ emailerror: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (this.validateEmail(this.state.email) !== true) {
      this.setState({
        emailerror: "กรุณาตรวจสอบข้อมูล",
        checkInputemail: true,
      });
      validate = true;
    }

    if (this.state.phoneNumber == "") {
      this.setState({ failPhone: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (this.state.phoneNumber.length <= 9) {
      this.setState({
        failPhone: "กรุณาตรวจสอบข้อมูล",
        checkInputphoneNumber: true,
      });
      validate = true;
    }

    if (this.state.selectDate == "") {
      this.setState({ failselectDate: "กรุณาระบุข้อมูล" });
      validate = true;
    }

    if (validate) {
      return;
    }

    this.setState({ step: "2" });

    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      channel: MainData.initialData.channelCode,
      firstName: this.state.name.split(" ")[0].trim(),
      lastName: this.state.name.split(" ").slice(1).filter(Boolean).join(" "),
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      productId: MainData.initialData.productId,
      contactTimeName: this.state.selectDate,
    };

    axios
      .post(MainData.serviceURL + "/Customer/GetDropLead", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (res.data.success) {
          this.setState({ step: "3" });
          this.setState({
            contactTimeId: data.contactTimeId,
            contactTimeCode: data.contactTimeCode,
            contactTimeName: data.contactTimeName,
          });
        } else {
          this.setState({ step: "2" });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          this.loadController(false);
          this.validateBadRequest(error.response.data.errors);
        } else {
          this.setState({ step: "4" });
        }
      });
  };

  validateBadRequest = (fieldNameList) => {
    let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG018");
    this.errorAlert(
      true,
      !errMsg ? "กรุณาตรวจสอบข้อมูล" : errMsg.msgDescription,
      " "
    );
    if (
      fieldNameList.find((data) => data.field === "firstName") ||
      fieldNameList.find((data) => data.field === "lastName")
    ) {
      this.setState({ nameerror: errMsg, checkInputname: true });
    }
    if (fieldNameList.find((data) => data.field === "phoneNumber")) {
      this.setState({ failPhone: errMsg, checkInputphoneNumber: true });
    }
    if (fieldNameList.find((data) => data.field === "email")) {
      this.setState({ emailerror: errMsg, checkInputemail: true });
    }
    if (fieldNameList.find((data) => data.field === "contactTimeName")) {
      this.setState({ failselectDate: errMsg });
    }
  };
  serviceDroplead = () => {
    this.setState({
      nameerror: "",
      emailerror: "",
      failPhone: "",
      failselectDate: "",
    });
    this.setState({
      validateDroplead: true,
      checkInputname: false,
      checkInputemail: false,
      checkInputemail: false,
    });
    let validate = false;
    if (
      !this.state.name ||
      !this.state.phoneNumber ||
      !this.state.email ||
      !this.state.selectDate
    ) {
      validate = true;
    }
    if (this.state.name === "") {
      this.setState({ nameerror: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (
      !this.state.name.split(/ (.*)/s)[0] ||
      !this.state.name.split(/ (.*)/s)[1]
    ) {
      this.setState({ nameerror: "กรุณาตรวจสอบข้อมูล", checkInputname: true });
      validate = true;
    }

    if (this.state.email === "") {
      this.setState({ emailerror: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (this.validateEmail(this.state.email) !== true) {
      this.setState({
        emailerror: "กรุณาตรวจสอบข้อมูล",
        checkInputemail: true,
      });
      validate = true;
    }

    if (this.state.phoneNumber == "") {
      this.setState({ failPhone: "กรุณาระบุข้อมูล" });
      validate = true;
    } else if (this.state.phoneNumber.length <= 9) {
      this.setState({
        failPhone: "กรุณาตรวจสอบข้อมูล",
        checkInputphoneNumber: true,
      });
      validate = true;
    }

    if (this.state.selectDate == "") {
      this.setState({ failselectDate: "กรุณาระบุข้อมูล" });
      validate = true;
    }

    if (validate) {
      return;
    }

    this.setState({ step: "2" });

    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      channel: MainData.initialData.channelCode,
      firstName: this.state.name.split(" ")[0].trim(),
      lastName: this.state.name.split(" ").slice(1).filter(Boolean).join(" "),
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      productId: MainData.initialData.productId,
      contactTimeName: this.state.selectDate,
    };

    axios
      .post(MainData.serviceURL + "/Customer/GetDropLead", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (res.data.success) {
          this.setState({ step: "3" });
          this.setState({
            contactTimeId: data.contactTimeId,
            contactTimeCode: data.contactTimeCode,
            contactTimeName: data.contactTimeName,
          });
        } else {
          this.setState({ step: "2" });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          this.loadController(false);
          this.validateBadRequest(error.response.data.errors);
        } else {
          this.setState({ step: "4" });
        }
      });
  };

  validateBadRequest = (fieldNameList) => {
    let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG018");
    this.errorAlert(
      true,
      !errMsg ? "กรุณาตรวจสอบข้อมูล" : errMsg.msgDescription,
      " "
    );
    if (
      fieldNameList.find((data) => data.field === "firstName") ||
      fieldNameList.find((data) => data.field === "lastName")
    ) {
      this.setState({ nameerror: errMsg, checkInputname: true });
    }
    if (fieldNameList.find((data) => data.field === "phoneNumber")) {
      this.setState({ failPhone: errMsg, checkInputphoneNumber: true });
    }
    if (fieldNameList.find((data) => data.field === "email")) {
      this.setState({ emailerror: errMsg, checkInputemail: true });
    }
    if (fieldNameList.find((data) => data.field === "contactTimeName")) {
      this.setState({ failselectDate: errMsg });
    }
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  handleInputChange = (name, val) => {
    switch (name) {
      case "name":
        if (val[0] === " ") {
          break;
        }
        let checkWord = /^[ก-์ ]+$/.test(val);
        this.setState((state) => ({
          name: !checkWord && val ? state.name : val,
        }));
        this.setState({ checkInputname: false });
        if (this.state.name.split(" ")[0] || this.state.name.split(" ")[1]) {
          this.setState({ nameerror: "" });
        }
        break;
      case "phoneNumber":
        val = val.replace(/[^\d]/g, "");
        if (val.length === 1 && val !== "0") {
          this.setState({ phoneNumber: "0" + val });
        } else if (val.length <= 10) {
          this.setState({ phoneNumber: val, failPhone: "" });
        }
        this.setState({ checkInputphoneNumber: false });
        break;
      case "email":
        let checkWord4 = /^[a-zA-Z0-9-@_.]+$/.test(val);
        this.setState((state) => ({
          email: !checkWord4 && val ? state.email : val,
          emailerror: "",
        }));
        this.setState({ checkInputemail: false });
        break;
      case "selectDate":
        this.setState({ selectDate: val, failselectDate: "" });
        break;
      default:
        break;
    }
  };

  closeDroplead = () => {
    this.setState({
      showDropLead: false,
      step: "1",
      validateDroplead: false,
      checkboxDroplead: false,
      checkInputname: false,
      checkInputemail: false,
      checkInputphoneNumber: false,
    });
    this.setState({ name: "", phoneNumber: "", email: "", selectDate: "" });
    this.setState({
      nameerror: "",
      failPhone: "",
      emailerror: "",
      failselectDate: "",
      validateDroplead: false,
    });
  };

  render() {
    return (
      <Fragment>
        <SweetAlert
          show={this.state.isError}
          title={""}
          customIcon={
            <GoAlert size={70} className="text-center w-100 text-danger pb-2" />
          }
          custom
          onConfirm={this.errorConfirm}
          showCancel
          closeOnClickOutside={false}
          customButtons={
            <Row>
              <Button onClick={this.errorConfirm} className="mainBtnRadius">
                {MainFunc.getLabelDtos("L0188") /* ปิด */}
              </Button>
            </Row>
          }
        >
          <h5
            className={!this.state.redirectFromKplus ? "text-danger" : ""}
            dangerouslySetInnerHTML={{ __html: this.state.errMsg }}
          ></h5>
          <span
            className="text--muted"
            dangerouslySetInnerHTML={{
              __html: !this.state.errSubMsg
                ? "ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา"
                : this.state.errSubMsg,
            }}
          ></span>
        </SweetAlert>
        {this.state.channel.name == "KBANK" ? (
          <div className={styles.boxWidgetsDesktop}>
            <BoxWidgets
              contactWidget={this.state.dataDeteil.contactWidget}
              isOpenContact={this.state.isOpenContact}
              setIsOpenContact={() => {
                this.setState({
                  isOpenContact: !this.state.isOpenContact,
                  isOpenContactAuto: this.state.isOpenContact === true && true,
                });
                if (!this.state.isOpenContact) {
                  MainFunc.sendDataToGTMKbank({
                    event: "track_event",
                    event_category: "link_click",
                    event_action: "click",
                    event_label: "contact_icon",
                  });
                }
              }}
            />
          </div>
        ) : (
          <div className={styles.boxWidgetsDesktop}>
            <Image
              src={IcoCallCenter}
              className={"drop--lead--ico"}
              style={{ position: "fixed", zIndex: 9999 }}
              id={`${MainData.initialData.codeTracking}Click_BtnChat`}
              title="DropLead"
              onClick={() => {
                this.setState({ showDropLead: !this.state.showDropLead });
              }}
            ></Image>
            <Collapse in={this.state.showDropLead} timeout="auto" unmountOnExit>
              <div className={"drop--lead--box"}>
                <Card
                  class="text-center csMainBG text-white card-header"
                  border="light"
                >
                  <Card.Header class="text-center csMainBG text-white card-header">
                    <Image
                      src={IcoCrossSign}
                      rounded
                      onClick={() => this.closeDroplead()}
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "10px",
                        fontSize: "2rem",
                        width: 20,
                        margin: 20,
                        marginTop: 22,
                      }}
                    ></Image>
                    <Row
                      id="logoSection"
                      className="pl-2 pr-2 pt-2 pb-2 csMainBG d-flex align-items-center"
                      style={{ height: "45px" }}
                    >
                      <Image
                        id="channelLogo"
                        src={
                          !this.state.channel.logo
                            ? logo2
                            : this.state.channel.logo
                        }
                        rounded
                        style={{
                          height: "95px",
                          position: "absolute",
                          left: "0",
                          top: "-5px",
                        }}
                        onLoad={(e) =>
                          this.setState({ logoWidth: e.target.width + 5 })
                        }
                      />
                      <div>
                        <Form.Label className="labelDropLead">
                          {
                            MainFunc.getLabelDtos(
                              "L0466"
                            ) /* ให้เจ้าหน้าที่ติดต่อกลับ */
                          }
                        </Form.Label>
                      </div>
                    </Row>
                  </Card.Header>
                  {this.state.step == "1" ? (
                    <Card.Body>
                      <div
                        style={{
                          height: "420px",
                          overflow: "hidden",
                          overflowY: "scroll",
                          padding: "12px",
                        }}
                      >
                        <span className="" style={{ fontSize: "1.1rem" }}>
                          {
                            MainFunc.getLabelDtos(
                              "L0472"
                            ) /* ติดต่อเจ้าหน้าที่ */
                          }
                        </span>
                        <br></br>
                        <span className="" style={{ fontSize: "1.1rem" }}>
                          {
                            MainFunc.getLabelDtos(
                              "L0473"
                            ) /* ทุกวันทำการ จันทร์ - ศุกร์ เวลา 08:00 - 18:00 */
                          }
                        </span>
                        <br></br>
                        <Row>
                          <div style={{ marginTop: 10, paddingLeft: "15px" }}>
                            <Image
                              className="mb-2"
                              src={IcoTelGreen}
                              rounded
                              style={{
                                height: "30px",
                                left: "0",
                                top: "-5px",
                              }}
                              onLoad={(e) =>
                                this.setState({
                                  logoWidth: e.target.width + 5,
                                })
                              }
                            />
                          </div>
                          <Col className="drop--lead--call">
                            <a
                              href={`tel:${MainFunc.getLabelDtos("L0183")}`}
                              className="font-weight-bold mt-1 telClass"
                              style={{ fontSize: "1.6rem" }}
                            >
                              {MainFunc.getLabelDtos("L0183")}
                            </a>
                            <a
                              className="font-weight-bold mt-1 callToClass"
                              style={{ fontSize: "1.6rem", width: 60 }}
                            >
                              {MainFunc.getLabelDtos("L0183")}
                            </a>
                            <br></br>
                          </Col>
                        </Row>
                        <Form>
                          <Form.Group
                            className="font-weight-bold "
                            hasValidation
                          >
                            <Form.Label>
                              {
                                MainFunc.getLabelDtos(
                                  "L0467"
                                ) /* ชื่อ - นามสกุล */
                              }
                            </Form.Label>
                            <Form.Control
                              className={`input-background ${
                                (this.state.validateDroplead &&
                                  !this.state.name) ||
                                this.state.checkInputname
                                  ? "input--error"
                                  : ""
                              }`}
                              type="text"
                              name="name"
                              value={this.state.name}
                              onChange={(e) =>
                                this.handleInputChange(
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              autoComplete="off"
                            />
                            <span style={{ fontWeight: "bold", color: "red" }}>
                              {this.state.nameerror}
                            </span>
                          </Form.Group>
                          <Form.Group className="font-weight-bold ">
                            <Form.Label>
                              {MainFunc.getLabelDtos("L0468") /* เบอร์มือถือ */}
                            </Form.Label>
                            <Form.Control
                              className={`input-background ${
                                (this.state.validateDroplead &&
                                  !this.state.phoneNumber) ||
                                this.state.checkInputphoneNumber
                                  ? "input--error"
                                  : ""
                              }`}
                              type="phoneNumber"
                              name="phoneNumber"
                              value={this.state.phoneNumber}
                              onChange={(e) =>
                                this.handleInputChange(
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              autoComplete="off"
                            />
                            <span style={{ fontWeight: "bold", color: "red" }}>
                              {this.state.failPhone}
                            </span>
                          </Form.Group>
                          <Form.Group className="font-weight-bold ">
                            <Form.Label>
                              {MainFunc.getLabelDtos("L0469") /* อีเมล */}
                            </Form.Label>
                            <Form.Control
                              className={`input-background ${
                                (this.state.validateDroplead &&
                                  !this.state.email) ||
                                this.state.checkInputemail
                                  ? "input--error"
                                  : ""
                              }`}
                              type="text"
                              name="email"
                              placeholder="yourmail@mail.com"
                              value={this.state.email}
                              onChange={(e) =>
                                this.handleInputChange(
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              autoComplete="off"
                            />
                            <span style={{ fontWeight: "bold", color: "red" }}>
                              {this.state.emailerror}
                            </span>
                          </Form.Group>
                          <Form.Label className="font-weight-bold ">
                            {
                              MainFunc.getLabelDtos(
                                "L0470"
                              ) /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */
                            }
                          </Form.Label>
                          <Form.Group>
                            <Form.Control
                              className={`input-background ${
                                this.state.validateDroplead &&
                                !this.state.selectDate
                                  ? "input--error"
                                  : ""
                              }`}
                              as="select"
                              name="selectDate"
                              placeholder="ช่วงเวาลาที่สะดวกให้ติดต่อกลับ"
                              value={this.state.selectDate}
                              onChange={(e) =>
                                this.handleInputChange(
                                  e.target.name,
                                  e.target.value
                                )
                              }
                            >
                              <option value="" disabled selected>
                                {
                                  MainFunc.getLabelDtos(
                                    "L0470"
                                  ) /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */
                                }
                              </option>
                              {MainData.dropleadDtos.map((item, key) => {
                                return (
                                  <option value={item.contactTimeName}>
                                    {item.contactTimeName}
                                  </option>
                                );
                              })}
                            </Form.Control>
                            <span style={{ fontWeight: "bold", color: "red" }}>
                              {this.state.failselectDate}
                            </span>
                          </Form.Group>
                          <Form.Group
                            style={{ marginTop: 20 }}
                            className="mb-3"
                            controlId="formBasicCheckbox"
                          >
                            <Form.Check
                              type="checkbox"
                              value={this.state.checkboxDroplead}
                              onChange={(e) => {
                                this.setState({
                                  checkboxDroplead: e.target.checked,
                                });
                              }}
                              label="ข้าพเจ้ายินยอมให้ บมจ.เมืองไทยประกันชีวิต ทำการเก็บรวบรวมและใช้ข้อมูลที่ข้าพเจ้าให้ไว้ นายหน้าประกันชีวิต และพันธมิตร/คู่ค้า เพื่อวัตถุประสงค์ในการนำเสนอขายประกันชีวิตแบบต่างๆ และ/หรือนำเสนอสิทธิประโยชน์อื่นๆ ให้แก่ข้าพเจ้าได้"
                            />
                          </Form.Group>
                        </Form>
                        <Col className="d-flex justify-content-center pt-3">
                          <Button
                            variant=""
                            className="mainBtnRadius font-weight-bold"
                            style={{ fontSize: "1.1rem" }}
                            onClick={() => {
                              this.serviceDroplead();
                            }}
                            disabled={!this.state.checkboxDroplead}
                          >
                            {
                              MainFunc.getLabelDtos(
                                "L0471"
                              ) /* ส่งข้อมูลสอบถาม */
                            }
                          </Button>
                        </Col>
                      </div>
                    </Card.Body>
                  ) : (
                    [
                      this.state.step == "2" ? (
                        <Card.Body>
                          <div style={{ marginTop: 150 }}>
                            <Spinner
                              className="img-center"
                              animation="border"
                              role="status"
                              style={{ width: "60px", height: "60px" }}
                            >
                              <span className="visually-hidden"></span>
                            </Spinner>
                          </div>
                        </Card.Body>
                      ) : (
                        [
                          this.state.step == "3" ? (
                            <Card.Body>
                              <div style={{ margin: 40 }}>
                                <Image
                                  className="img-center"
                                  src={IcoCorrect}
                                ></Image>
                                <div className="text-align-center ">
                                  <span
                                    className="font-weight-bold text-center"
                                    style={{ fontSize: "1.2rem" }}
                                  >
                                    {
                                      MainFunc.getLabelDtos(
                                        "L0474"
                                      ) /* บริษัทฯ ได้รับข้อมูลเรียบร้อยแล้ว กรุณารอเจ้าหน้าที่ติดต่อกลับภายใน 1-2 วันทำการ */
                                    }
                                  </span>
                                  <br></br>
                                  <span
                                    className="font-weight-bold text-center font-collor1 "
                                    style={{ fontSize: "1.2rem" }}
                                  >
                                    {
                                      MainFunc.getLabelDtos(
                                        "L0475"
                                      ) /* ขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน) */
                                    }
                                  </span>
                                  <br></br>
                                </div>
                                <Col className="d-flex justify-content-center pt-3">
                                  <Button
                                    variant=""
                                    className="mainBtnRadius font-weight-bold"
                                    style={{ fontSize: "1.1rem", width: 200 }}
                                    onClick={() => this.closeDroplead()}
                                  >
                                    {MainFunc.getLabelDtos("L0185") /** ตกลง */}
                                  </Button>
                                </Col>
                              </div>
                            </Card.Body>
                          ) : (
                            <Card.Body>
                              <div style={{ margin: 70 }}>
                                <Image
                                  className="img-center"
                                  src={IcoError}
                                ></Image>
                                <div
                                  className="text-align-center "
                                  style={{ marginTop: 30 }}
                                >
                                  <span
                                    className="font-weight-bold text-center"
                                    style={{ fontSize: "1.2rem" }}
                                  >
                                    {
                                      MainFunc.getLabelDtos(
                                        "L0476"
                                      ) /** ขออภัยในความไม่สะดวก */
                                    }
                                  </span>
                                  <br></br>
                                  <span
                                    className="font-weight-bold text-center font-collor1 "
                                    style={{ fontSize: "1.2rem" }}
                                  >
                                    {
                                      MainFunc.getLabelDtos(
                                        "L0477"
                                      ) /** กรุณาลองใหม่อีกครั้ง */
                                    }
                                  </span>
                                  <br></br>
                                </div>
                                <Col
                                  className="d-flex justify-content-center pt-3"
                                  style={{ marginTop: 30 }}
                                >
                                  <Button
                                    variant=""
                                    className="mainBtnRadius font-weight-bold"
                                    style={{ fontSize: "1.1rem", width: 200 }}
                                    onClick={() => this.closeDroplead()}
                                  >
                                    {MainFunc.getLabelDtos("L0188") /* ปิด */}
                                  </Button>
                                </Col>
                              </div>
                            </Card.Body>
                          ),
                        ]
                      ),
                    ]
                  )}
                </Card>
              </div>
            </Collapse>
          </div>
        )}
        <div className={styles.boxWidgetsMobile}>
          <BoxWidgetsMobile
            contactWidget={this.state.dataDeteil.contactWidget}
            data={this.state.dataDeteil}
            setActiveCompornent={(activeCompornent) =>
              this.activeCompornent(activeCompornent, true)
            }
            activeCompornent={this.state.activeCompornent}
            onProcessData={() => this.callChildMethod()}
            stepSelectPlan={this.state.stepSelectPlan}
            isOpenContact={this.state.isOpenContact}
            setIsOpenContact={() =>
              this.setState({
                isOpenContact: !this.state.isOpenContact,
                isOpenContactAuto: this.state.isOpenContact === true && true,
              })
            }
            channel={this.state.channel}
          />
        </div>
        {this.state.dataDeteil.bannerHighlight && (
          <Fragment>
            <div className={styles.bannerHighlightPC}>
              <img
                src={this.state.dataDeteil.bannerHighlight?.bannerHighlightPC}
              />
            </div>
            <div className={styles.bannerHighlightMB}>
              <img
                src={this.state.dataDeteil.bannerHighlight?.bannerHighlightMB}
              />
            </div>
          </Fragment>
        )}

        <div
          className={`${styles.navBarBroker} ${
            this.state.activeNavBroker ? styles.active : ""
          } broker`}
        >
          <NavBarBroker
            data={this.state.dataDeteil}
            setActiveCompornent={(activeCompornent) =>
              this.activeCompornent(activeCompornent)
            }
            activeCompornent={this.state.activeCompornent}
            onProcessData={() => this.callChildMethod()}
            stepSelectPlan={this.state.stepSelectPlan}
          />
        </div>
        <section className={styles.planselect} ref={this.planselect}>
          <Planselect
            ref={this.planSelectComponent}
            loadController={this.loadController}
            errorAlert={this.errorAlert}
            setHeaderText={this.setHeaderText}
            scrollContentSectionTop={() => this.props.scrollContentSectionTop()}
            nextPage={() => this.props.setPage(this.props.next)}
            setStepSelectPlan={(step) =>
              this.setState({ stepSelectPlan: step })
            }
            showKplus={() => this.props.showKplus()}
            activeCompornent={(page) => this.activeCompornent(page)}
            isDropLead={this.state.dataDeteil.isDropLead}
            channel={this.state.channel}
          />
        </section>
        {this.state.dataDeteil.highlights &&
          this.state.dataDeteil.highlights?.highlightDetails.length !== 0 && (
            <section className={styles.highlight} ref={this.highlightRef}>
              {/* จุดเด่นประกันสุขภาพ */}
              <HighlightProduct data={this.state.dataDeteil.highlights} />
            </section>
          )}
        {this.state.dataDeteil.briefBenefit && (
          <section className={styles.insuranceCompare} ref={this.briefBenefit}>
            {/* ประกันสุขภาพป่วยมีจ่าย ดีกว่าประกันสุขภาพทั่วไปยังไง? */}
            <InsuranceCompare data={this.state.dataDeteil.briefBenefit} />
          </section>
        )}
        {this.state.dataDeteil.iFrameContent && (
          <section className={styles.video} ref={this.iFrameContent}>
            <Video data={this.state.dataDeteil.iFrameContent} />
          </section>
        )}
        {this.state.dataDeteil.warrantys && (
          <section className={styles.insuranceTerms} ref={this.warrantys}>
            {/* เงื่อนไขการรับประกัน */}
            <InsuranceTerms data={this.state.dataDeteil.warrantys} />
          </section>
        )}
        {this.state.dataDeteil.coverages && (
          <section className={styles.coverageTerm} ref={this.coverages}>
            {/* เงื่อนไขความคุ้มครอง */}
            <CoverageTerms data={this.state.dataDeteil.coverages} />
          </section>
        )}
        {this.state?.dataDeteil?.promotions && this.state?.dataDeteil?.promotions?.length !== 0 && (
          <section className={styles.benefit} ref={this.promotions}>
            {/* สิทธิพิเศษสำหรับผู้ทำประกันสุขภาพ */}
            <Benefit data={this.state.dataDeteil.promotions} channel={this.state.channel}/>
          </section>
        )}
        {this.state.dataDeteil.isDropLead && (
          <Fragment>
            {/* กรุณากรอกข้อมูล เพื่อให้เจ้าหน้าที่ติดต่อกลับ */}
            {this.state.stepContactBack === 1 && (
              <section className={styles.sectionAapply} ref={this.isDropLead}>
                <ContactBack
                  setStepContactBack={(step) =>
                    this.setState({ stepContactBack: step })
                  }
                  loadController={this.loadController}
                />
              </section>
            )}
            {this.state.stepContactBack === 2 && (
              <section className={styles.contactBackSuccess}>
                <ContactBackSuccess
                  setStepContactBack={(step) =>
                    this.setState({ stepContactBack: step })
                  }
                />
              </section>
            )}
            {this.state.stepContactBack === 3 && (
              <section className={styles.contactBackSuccess}>
                <ContactBackFail
                  setStepContactBack={(step) =>
                    this.setState({ stepContactBack: step })
                  }
                />
              </section>
            )}
            {this.state.stepContactBack === 4 && (
              <section className={styles.contactBackSuccess}>
                <ContactBackCaptchaFail
                  setStepContactBack={(step) =>
                    this.setState({ stepContactBack: step })
                  }
                />
              </section>
            )}
          </Fragment>
        )}
        {this.state.channel.name == "KBANK" && (
          <section className={styles.consultExpert} ref={this.consultExpert}>
            {/* ปรึกษาผู้เชี่ยวชาญทันที */}
            <ConsultExpert
              contactImg={this.state.dataDeteil.contactImg}
              contactWidget={this.state.dataDeteil.contactWidget}
            />
          </section>
        )}
        {this.state.dataDeteil.faqProducts && (
          <section className={styles.faq} ref={this.faqProducts}>
            {/* คำถามที่พบบ่อย */}
            <Faq data={this.state.dataDeteil.faqProducts} />
          </section>
        )}
        {(this.state.dataDeteil.remark ||
          this.state.dataDeteil.contactDetail) && (
          <Fragment>
            {/* ผู้ให้บริการประกัน */}
            <section className={styles.service} ref={this.remark}>
              <Service
                dataRemark={this.state.dataDeteil.remark}
                dataContactDetail={this.state.dataDeteil.contactDetail}
              />
            </section>
          </Fragment>
        )}
        {this.state.dataDeteil.productRelate && (
          <section
            className={styles.relatedProducts}
            ref={this.productRelate}
            style={{
              backgroundImage: `url(${this.state.dataDeteil.productRelate?.imgProductRelate})`,
              backgroundSize: `cover`,
            }}
          >
            {/* ผลิตภัณฑ์ที่เกี่ยวข้อง */}
            <RelatedProducts data={this.state.dataDeteil.productRelate} />
          </section>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(BrokerPage);
