import React, { Component } from "react";
import styles from "../../styles/navBarBroker.module.scss";
import MainFunc from "../../models/MainFunc";
class NavBarBroker extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      // JSX code for rendering your component
      <div className={styles.navBerBroker}>
        <ul>
          <li
            className={`${styles.li} ${
              this.props.activeCompornent === "planselect" ? styles.active : ""
            }`}
            onClick={() => {
              this.props.setActiveCompornent("planselectMain");
            }}
          >
            {MainFunc.getLabelDtos("L0771") /*เลือกแผนประกัน*/}
          </li>
          {((this.props.data.highlights &&
            this.props.data.highlights?.highlightDetails.length !== 0) ||
            this.props.data.briefBenefit ||
            this.props.data.iFrameContent) && (
            <li
              className={`${styles.li} ${
                this.props.activeCompornent === "highlight" ? styles.active : ""
              }`}
              onClick={() => {
                if (
                  this.props.data.highlights &&
                  this.props.data.highlights?.highlightDetails.length !== 0
                ) {
                  this.props.setActiveCompornent("highlight");
                } else if (this.props.data.briefBenefit) {
                  this.props.setActiveCompornent("briefBenefit");
                } else if (this.props.data.iFrameContent) {
                  this.props.setActiveCompornent("iFrameContent");
                }
              }}
            >
              {MainFunc.getLabelDtos("L0772") /*จุดเด่น*/}
            </li>
          )}
          {this.props.data.warrantys && (
            <li
              className={`${styles.li} ${
                this.props.activeCompornent === "warrantys" ? styles.active : ""
              }`}
              onClick={() => {
                this.props.setActiveCompornent("warrantys");
              }}
            >
              {MainFunc.getLabelDtos("L0010") /*เงื่อนไขการรับประกัน*/}
            </li>
          )}
          {this.props.data.coverages && (
            <li
              className={`${styles.li} ${
                this.props.activeCompornent === "coverages" ? styles.active : ""
              }`}
              onClick={() => {
                this.props.setActiveCompornent("coverages");
              }}
            >
              {MainFunc.getLabelDtos("L0773") /*เงื่อนไขความคุ้มครอง*/}
            </li>
          )}
          {this.props.data.promotions?.length !== 0 && (
            <li
              className={`${styles.li} ${
                this.props.activeCompornent === "promotions"
                  ? styles.active
                  : ""
              }`}
              onClick={() => {
                this.props.setActiveCompornent("promotions");
              }}
            >
              {MainFunc.getLabelDtos("L0774") /*สิทธิพิเศษ*/}
            </li>
          )}
          {this.props.data.isDropLead && (
            <li
              className={`${styles.li} ${
                this.props.activeCompornent === "isDropLead"
                  ? styles.active
                  : ""
              }`}
              onClick={() => {
                this.props.setActiveCompornent("isDropLead");
              }}
            >
              {MainFunc.getLabelDtos("L0775") /*ให้เราติดต่อกลับ*/}
            </li>
          )}
          {this.props.data.faqProducts && (
            <li
              className={`${styles.li} ${
                this.props.activeCompornent === "faqProducts"
                  ? styles.active
                  : ""
              }`}
              onClick={() => {
                this.props.setActiveCompornent("faqProducts");
              }}
            >
              {MainFunc.getLabelDtos("L0776") /*คำถามที่พบบ่อย*/}
            </li>
          )}
          {this.props.data.contactDetail && (
            <li
              className={`${styles.li} ${
                this.props.activeCompornent === "remark" ? styles.active : ""
              }`}
              onClick={() => {
                this.props.setActiveCompornent("remark");
              }}
            >
              {MainFunc.getLabelDtos("L0777") /*ผู้ให้บริการประกัน*/}
            </li>
          )}
        </ul>
        <ul>
          <li>
            <a
              onClick={() => {
                this.props.setActiveCompornent("planselect");
              }}
              className={styles.btnInsure}
              title="คำนวณเบี้ยของคุณ"
            >
              {MainFunc.getLabelDtos("L0770") /*คำนวณเบี้ยของคุณ*/}
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default NavBarBroker;
