import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Col, Row, Image } from "react-bootstrap";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IcoAutosearch from "../images/Vector.svg";

class MAutocompleteInList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM014",
      inputValueDefault: "",
      value: "",
      inputValue: "",
      selectValue: "",
      dataSelect: this.props.dataSelect,
      open: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.inputValue !== prevProps.inputValue ||
      this.props.choices !== prevProps.choices
    ) {
    }
  }

  onChangeAutocomplete = (val) => {
    this.props.onChange(val);
    this.setState({ open: false, inputValue: "" });
  };

  onInputChangeAutocomplete = (val) => {
    this.setState({ inputValue: val, selectValue: val });
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClickAway = () => {
    this.setState({ open: false, inputValue: "" });
  };

  keyPress = (e) => {
    if (e.keyCode == 13) {
      //ดักกด enter
      if (this.props.onEnter) {
        //props อาชีพ
        if (
          e.key === "Enter" &&
          !this.props.choices?.find(
            (data) => data.name == this.state.inputValue
          )
        ) {
          this.setState({ inputValue: "" });
          this.props.onEnter();
        } else {
          this.props.matchJob(
            this.props.choices?.find(
              (data) => data.name == this.state.inputValue
            ).value
          );
        }
      }
      if (this.props.onEnterJobDescriptionText) {
        //props ลักษณะงาน
        if (
          e.key === "Enter" &&
          !this.props.choices?.find(
            (data) => data.name == this.state.inputValue
          )
        ) {
          this.setState({ inputValue: "" });
          this.props.onEnterJobDescriptionText(this.state.inputValue);
        } else {
          this.props.matchJobDescription(
            this.props.choices?.find(
              (data) => data.name == this.state.inputValue
            ).value
          );
        }
      }
      this.setState({ open: false });
    }
  };

  render() {
    return (
      <Fragment>
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <div className={"rootMAutocompleteInList"}>
            <TextField
              placeholder={this.props.placeholder}
              name={this.props.name}
              id={this.props.id}
              margin="normal"
              error={this.props.error}
              type={this.props.type ?? "text"}
              fullWidth
              value={
                this.props.value && this.props.choices
                  ? this.props.choices?.find(
                      (data) => data.value == this.props.value
                    )?.name
                    ? this.props.choices?.find(
                        (data) => data.value == this.props.value
                      )?.name
                    : ""
                  : ""
              }
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      style={
                        this.state.open
                          ? { transform: "rotate(180deg) translate(0px, 0px)" }
                          : {}
                      }
                    >
                      <ArrowDropDownIcon />
                    </div>
                  </InputAdornment>
                ),
                onClick: () => this.handleClick(),
              }}
            />
            <div
              className={`dropdownMAutocompleteInList ${
                this.state.open ? "" : "d-none"
              }`}
            >
              <Row>
                <Col xs={12}>
                  <TextField
                    inputProps={{ autocomplete: "off" }}
                    value={this.state.inputValue}
                    onChange={(e) =>
                      this.onInputChangeAutocomplete(e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    onKeyDown={this.keyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Image src={IcoAutosearch} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
              </Row>
              <Row className={"menuList"}>
                {this.props.choices !== undefined &&
                  this.props.choices !== null &&
                  this.props.choices.length !== 0 &&
                  this.props.choices.map((item, index) => {
                    if (
                      item.name.includes(this.state.inputValue) ||
                      !this.state.inputValue
                    ) {
                      return (
                        <Col
                          xs={12}
                          className="list"
                          onClick={() => this.onChangeAutocomplete(item.value)}
                        >
                          {item.name}
                        </Col>
                      );
                    }
                  })}
              </Row>
            </div>
          </div>
        </ClickAwayListener>
      </Fragment>
    );
  }
}

export default MAutocompleteInList;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    top: "86px",
    right: 0,
    left: 0,
    zIndex: 9999,
    border: "1px solid #DDDEE0",
    borderRadius: "6px",
    padding: "15px 15px 0px 15px",
    backgroundColor: "#fff",
    "& .menuList": {
      maxHeight: "200px",
      marginTop: "16px",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "12px",
        borderBottomRightRadius: "6px",
        backgroundColor: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c1c1c1",
      },
      "& .list": {
        paddingTop: "15px",
        paddingBottom: "15px",
        backgroundColor: "#fff",
        color: "#383838",
        fontSize: "20px",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
        },
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
        "&.active": {
          backgroundColor: "#DDDEE0",
        },
      },
    },
  },
}));
