import React, { Component } from 'react';
import { Link, Routes, Route} from 'react-router-dom';

import Error404 from './components/404';
import Checkout from './components/checkout';
import Shutdown from './components/shutdown';
import NotFound from './components/notFound';
import MainPage from  './components/MainPage';
import LandingPage from './components/Landing';
import ContinuePage from './components/Continue';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import "./i18n";
import "./SiteCollectionDocuments/assets/theme-navigation/css/font-navigation.css"
import "./SiteCollectionDocuments/assets/theme-navigation/css/font-icon-navigation.css"

import MainData from './models/MainData';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '-'
    };
  }

  UNSAFE_componentWillMount(){
  }

  componentDidMount(){
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.src = "https://www.google.com/recaptcha/enterprise.js?render=6LfHd6QpAAAAAEcUg4RjPaPk8rtLiyHulY0YrUJV"; //render ตาม sitekey ด้วย
    script.async = true;
    script.defer = true;
    head.appendChild(script);
  }

  render() {
    return (
        <Routes>
          <Route path={MainData.mainPath+"checkout"} element={<Checkout />} />
          <Route path={MainData.mainPath+"continue"} element={<ContinuePage/>} />
          <Route path={MainData.mainPath+":channelCodeSlug/continue"} element={<ContinuePage/>} />
          <Route path={MainData.mainPath+"shutdown"} element={<Shutdown />} />
          <Route path={MainData.mainPath+"notfound"} element={<NotFound />} />
          <Route path={MainData.mainPath} element={<MainPage ssrStatus={this.props.ssrStatus} productDetailData={this.props.productDetailData} productName={this.props.productName}/>} />
          <Route path={MainData.mainPath+":productIdSlug"} element={<MainPage ssrStatus={this.props.ssrStatus} productDetailData={this.props.productDetailData} productName={this.props.productName}/>} />
          <Route path={MainData.mainPath+":channelCodeSlug/:productIdSlug"} element={<MainPage ssrStatus={this.props.ssrStatus} productDetailData={this.props.productDetailData} productName={this.props.productName}/>} />
          <Route element={<Error404 />} />
        </Routes>
    );
  }
}
export default App

// const App = () => {
//   return (
//     <div className="app">
//       <h1>Hello Monsterlessons</h1>
//       <Link to="/">Home</Link>
//       <Link to="/about">About</Link>
//       <Routes>
//         <Route path="/" element={<Home />}></Route>
//         <Route path="/about" element={<About />}></Route>
//       </Routes>
//     </div>
//   );
// };

// export default App;
