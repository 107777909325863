import axios from "axios";
import React, { Component, Fragment } from "react";
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import {Row, Col, Button, Card} from "react-bootstrap";
import FormControl from '@material-ui/core/FormControl';
import {ImRadioUnchecked, ImRadioChecked} from "react-icons/im";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import parse from "html-react-parser";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

class SM010 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM010",
      validation: false,
      heir: MainData.screenSM010.heir?MainData.screenSM010.heir:MainData.screenSM010.heirPreview,
      isHeire: MainData.screenSM010.isHeire,
      isAgreeRD: MainData.screenSM010.isAgreeRD,
      taxBreakQuestionResponse: [],
      requireTaxConsent:false
    };
  }

  changeHeir = (key, name, val) => {
    let heir = [...this.state.heir];
    let obj = {...heir[key]};
    switch(name){
      case "isHeir": obj.isHeire = val==="true"?true:false; break;
      case "firstName":
        if (val[0] === " ") { break; }
        let checkWord1 = /^[ก-์ ]+$/.test(val);
        obj.firstName = !checkWord1 && val ? obj.firstName : val
        obj.firstNameValid = obj.firstName.trim() !== obj.firstName || obj.firstName.includes("  ") || !obj.firstName ? false : true
        break;
      case "lastName": 
        if (val[0] === " ") { break; }
        let checkWord2 = /^[ก-์ ]+$/.test(val);
        obj.lastName = !checkWord2 && val ? obj.lastName : val
        obj.lastNameValid = obj.lastName.trim() !== obj.lastName || obj.lastName.includes("  ") || !obj.lastName ? false : true
        break;
      case "relation": obj.relation = val; break;
      case "percen": obj.percen = val; break;
      case "titleName": obj.titleName = val; obj.relation = ""; break;
      default: break;
    }
    heir[key] = obj;
    this.setState({heir});
  }

  addHeir = () => {
    if(this.state.heir.length<3){
      var joined = this.state.heir.concat({isHeire: true,titleName:"", firstName: "", firstNameValid: false, lastName: "", lastNameValid: false, relation: "", percen: 100});
      this.setState({ heir: joined }, () => {
        setTimeout(() => { this.setPercen() }, 100);
      })
    }
  }

  componentDidMount(){
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
    if(MainData.screenSM010.hasBeneficiary == true){
    this.setState({isHeire: false})
    }
    this.TaxBreakQuestionResponse();
}

  removeHeir = (index) => {
    var heir = [...this.state.heir];
    heir.splice(index, 1);
    this.setState({heir}, () => {
      setTimeout(() => { this.setPercen() }, 100);
    });
  }

  setPercen = () => {
    for (const [index, obj] of this.state.heir.entries()) {
      if(this.state.heir.length===3){
        this.changeHeir(index, "percen", index===0?34:33)
      }else if(this.state.heir.length===2){
        this.changeHeir(index, "percen", 50)
      }else if(this.state.heir.length===1){
        this.changeHeir(index, "percen", 100)
      }
    }
  }

  processBack = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    MainFunc.sendDataToGTMKbank({
      'event': 'track_event',
      'event_category': 'link_click',
      'event_action': 'click',
      'event_label': 'ย้อนกลับ',
      'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
      'flag_page' : 'onboard09'
     });
    this.props.setPage(this.props.prev)
  }

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    let dataPDPAOldValue = {};
    let heir =MainData.screenSM010.heir?MainData.screenSM010.heir:MainData.screenSM010.heirPreview
    if(!MainData.screenSM010.isHeire){
      dataPDPAOldValue.hasBeneficiary = "true";
      heir.forEach((item,index)=>{
        if(item.firstName){
          dataPDPAOldValue[`firstName_${index+1}`] = item.firstName.toString()
        }
        if(!item.firstName){
          dataPDPAOldValue[`firstName_${index+1}`] = ''
        }
        if(item.lastName){
          dataPDPAOldValue[`lastName_${index+1}`] = item.lastName.toString()
        }
        if(!item.lastName){
          dataPDPAOldValue[`lastName_${index+1}`] = ''
        }
        if(item.titleName){
          dataPDPAOldValue[`titleName_${index+1}`] = item.titleName.toString()
        }
        if(!item.titleName){
          dataPDPAOldValue[`titleName_${index+1}`] = ''
        }
        if(item.relation){
          dataPDPAOldValue[`relationShipName_${index+1}`] = item.relation.toString()
        }
        if(!item.relation){
          dataPDPAOldValue[`relationShipName_${index+1}`] = ''
        }
      })
    }else{
      dataPDPAOldValue.hasBeneficiary = "false";
    }

    let dataPDPANewValue = {};
    if(!this.state.isHeire){
      dataPDPANewValue.hasBeneficiary = "true";
    this.state.heir.forEach((item,index)=>{
      if(item.firstName){
        dataPDPANewValue[`firstName_${index+1}`] = item.firstName.toString()
      }
      if(!item.firstName){
        dataPDPANewValue[`firstName_${index+1}`] = ''
      }
      if(item.lastName){
        dataPDPANewValue[`lastName_${index+1}`] = item.lastName.toString()
      }
      if(!item.lastName){
        dataPDPANewValue[`lastName_${index+1}`] = ''
      }
      if(item.titleName){
        dataPDPANewValue[`titleName_${index+1}`] = item.titleName.toString()
      }
      if(!item.titleName){
        dataPDPANewValue[`titleName_${index+1}`] = ''
      }
      if(item.relation){
        dataPDPANewValue[`relationShipName_${index+1}`] = item.relation.toString()
      }
      if(!item.relation){
        dataPDPANewValue[`relationShipName_${index+1}`] = ''
      }
    })
    }else{
      dataPDPANewValue.hasBeneficiary = "false";
    }
    if(this.state.isHeire === false){
      if(this.state.heir.length<1){
        MainFunc.sendDataToGTMKbank({
          'event': 'track_event',
          'event_category': 'link_click',
          'event_action': 'click',
          'event_label': 'step_fail' ,
          'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
          'flag_page' : 'onboard09',
          'flag_verify' : "2" 
        });
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG003');
        this.props.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
        if(MainData.screenSM010.heir){
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, dataPDPAOldValue, dataPDPANewValue, 'Fail', errMsg.msgDescription,)
          MainData.screenSM010.heir = this.state.heir;
          MainData.screenSM010.isHeire = this.state.isHeire;
        }else{
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, dataPDPANewValue, 'Fail', errMsg.msgDescription,)
          MainData.screenSM010.heir = this.state.heir;
          MainData.screenSM010.isHeire = this.state.isHeire;
        }
        this.setState({ validation: true })
        return;
      }else{
        for(let obj of this.state.heir){
          if(!obj.firstName || !obj.lastName || !obj.relation || !obj.titleName){
            MainFunc.sendDataToGTMKbank({
              'event': 'track_event',
              'event_category': 'link_click',
              'event_action': 'click',
              'event_label': 'step_fail' ,
              'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
              'flag_page' : 'onboard09',
              'flag_verify' : "2" 
            });
            let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG003');
            this.props.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ")
            if(MainData.screenSM010.heir){
              MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, dataPDPAOldValue, dataPDPANewValue, 'Fail', errMsg.msgDescription,)
              MainData.screenSM010.heir = this.state.heir;
              MainData.screenSM010.isHeire = this.state.isHeire;
            }else{
              MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, dataPDPANewValue, 'Fail', errMsg.msgDescription,)
              MainData.screenSM010.heir = this.state.heir;
              MainData.screenSM010.isHeire = this.state.isHeire;
            }
            this.setState({ validation: true })
            return;
          }
        }
      }
    }

    if(this.state.requireTaxConsent && this.state.isAgreeRD == null && this.state.taxBreakQuestionResponse.length > 0){
      MainFunc.sendDataToGTMKbank({
        'event': 'track_event',
        'event_category': 'link_click',
        'event_action': 'click',
        'event_label': 'step_fail' ,
        'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
        'flag_page' : 'onboard09',
        'flag_verify' : "3" 
      });
      this.props.errorAlert(true, "กรุณาเลือกความประสงค์ใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร", " ")
      if(MainData.screenSM010.heir){
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, dataPDPAOldValue, dataPDPANewValue, 'Fail', "กรุณาเลือกความประสงค์ใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร",)
        MainData.screenSM010.heir = this.state.heir;
        MainData.screenSM010.isHeire = this.state.isHeire;
      }else{
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, dataPDPANewValue, 'Fail', "กรุณาเลือกความประสงค์ใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร",)
        MainData.screenSM010.heir = this.state.heir;
        MainData.screenSM010.isHeire = this.state.isHeire;
      }
      return;
    }

    let screen013HeireData = [];
    let logHeire = [];
    if(this.state.isHeire!==true){
      for(let obj of this.state.heir){
        let getRelationCode = MainData.initialData.relationship.find(x=>x.relationshipTitle === obj.relation).relationshipCode
        let gettitleCode = MainData.initialData.title.find(x=>x.titleTitles === obj.titleName).titleCode
        screen013HeireData.push({ "relationShipCode": !getRelationCode?null:getRelationCode, "beneficiaryInformation": { "titleId": gettitleCode, "firstName": obj.firstName.trim(), "lastName": obj.lastName.trim() } })
        logHeire.push({ "relationShipCode": !getRelationCode?null:getRelationCode, "beneficiaryInformation": { "titleId": gettitleCode, "firstName": MainFunc.blurName(obj.firstName), "lastName": MainFunc.blurName(obj.lastName) } })
      }
    }

    MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลผู้รับผลประโยชน์', { heire: MainData.screenSM013.logHeire }, { heire: this.state.isHeire!==true?logHeire:MainFunc.getLabelDtos("L0072") });
    MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร', { isAgreeRD: MainData.screenSM010.isAgreeRD }, { isAgreeRD: this.state.isAgreeRD });
  
    

    if(MainData.screenSM010.heir){
      MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, dataPDPAOldValue, dataPDPANewValue, 'Success', null)
    }else{
      MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, dataPDPANewValue, 'Success', null)
    }
    MainData.screenSM010.heir = this.state.heir.map((item) =>{ 
      return {
        firstName: item.firstName.trim(),
        firstNameValid: item.firstNameValid,
        isHeire: item.isHeire,
        lastName: item.lastName.trim(),
        lastNameValid: item.lastNameValid,
        percen: item.percen,
        relation: item.relation,
        titleName: item.titleName
      }
    });
    MainData.screenSM010.isHeire = this.state.isHeire;
    MainData.screenSM010.isAgreeRD = this.state.isAgreeRD;
    MainData.screenSM010.hasBeneficiary = !this.state.isHeire; //กรณีที่ Preview ระบุแต่รอบสองไม่อยากระบุผู้รับผลประโยชน์
    MainData.screenSM013.beneficiariesOfOrder = screen013HeireData;
    MainData.screenSM013.logHeire = logHeire;
    this.props.loadController(true)
    this.AddOrUpdateCustomerBeneInfo(screen013HeireData)
  }

  AddOrUpdateCustomerBeneInfo = (beneficiariesOfOrder) => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "beneficiariesOfOrder": beneficiariesOfOrder,
      "hasBeneficiary": this.state.isHeire===true?false:true,
      "taxIdentification": this.state.isAgreeRD,
      "submenuCode": this.state.screen,
    }
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerBeneInfo', data, {
      headers: headers
    })
    .then(res => {
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        MainData.screenSM005.cusId = res.data.data;
        let beneficiaryTo = "";
        if(beneficiariesOfOrder.find(item => item.relationShipCode == "028")){
          beneficiaryTo = beneficiaryTo?beneficiaryTo+"|1":"1"
        }
        if(beneficiariesOfOrder.find(item => item.relationShipCode == "048")){
          beneficiaryTo = beneficiaryTo?beneficiaryTo+"|2":"2"
        }
        if(beneficiariesOfOrder.find(item => item.relationShipCode == "056")){
          beneficiaryTo = beneficiaryTo?beneficiaryTo+"|3":"3"
        }
        if(beneficiariesOfOrder.find(item => item.relationShipCode == "032")){
          beneficiaryTo = beneficiaryTo?beneficiaryTo+"|4":"4"
        }
        if(beneficiariesOfOrder.find(item => item.relationShipCode == "063")){
          beneficiaryTo = beneficiaryTo?beneficiaryTo+"|5":"5"
        }
        if(beneficiariesOfOrder.find(item => item.relationShipCode == "029")){
          beneficiaryTo = beneficiaryTo?beneficiaryTo+"|6":"6"
        }
        MainFunc.sendDataToGTMKbank({
          'event': 'track_event',
          'event_category': 'link_click',
          'event_action': 'click',
          'event_label': 'step_success' ,
          'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
          'flag_page' : 'onboard09',
          'flag_verify' : 'y',
          'beneficiary_type' : !this.state.isHeire?"1":"0",
          'beneficiary_to' : beneficiaryTo,
          "tax-reques":this.state.isAgreeRD?"y":"n"
        });
        // this.props.loadController(false)
        // this.props.setPage(this.props.next)
        this.AddOrUpdateCustomerConsent();
      }else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription, ' ')
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        this.validateBadRequest(error.response.data.errors)
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  AddOrUpdateCustomerConsent = () => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "channelCode": MainData.initialData.channelCode,
      "consentInfo": [
          {
              "consentCode": 1,
              "consentResult": true
          },
           {
              "consentCode": 2,
              "consentResult": true
          },
           {
              "consentCode": 3,
              "consentResult": this.state.isAgreeRD?this.state.isAgreeRD:false
          }
      ]
  }
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerConsent', data, {
      headers: headers
    })
    .then(res => {
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
        this.props.setPage(this.props.next)
      }else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription, ' ')
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        this.validateBadRequest(error.response.data.errors)
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  validateBadRequest = (fieldNameList) => {
    let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG018');
    this.props.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
    this.setState({validation: true})
    MainFunc.sendDataToGTMKbank({
      'event': 'track_event',
      'event_category': 'link_click',
      'event_action': 'click',
      'event_label': 'step_fail' ,
      'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
      'flag_page' : 'onboard09',
      'flag_verify' : "1|2" 
    });
  }

  
  TaxBreakQuestionResponse = () => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
        "productId" : MainData.initialData.productId,
        "channelCode": MainData.initialData.channelCode,
        "lang" : "TH"
    }
    axios.post(MainData.serviceURL+'/Sales/GetTaxConsentQuestion', data, {
      headers: headers
    })
    .then(res => {
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        this.props.loadController(false)
        this.setState({taxBreakQuestionResponse: res.data.data.taxConsentQuestion,requireTaxConsent:res.data.data.requireTaxConsent});
      }else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription, ' ')
      }
    })
    .catch(error => {
      this.props.loadController(false)
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
      this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
    });
  }

  render(){
    return (
      <div className="container-fulid">
        <Row>
          <Col id="titelForScreen" xs={12} className="oppositeWhiteStep">
            <span className="font-weight-bold" dangerouslySetInnerHTML={{__html: this.props.subMenuName}}></span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>

          <Col xs={12} className="pt-2 pb-3 subFontFamily">
            <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0069")}<span className="text-danger">*</span></span>
            <br />
            <small className="text--muted">{MainFunc.getLabelDtos("L0070")}</small>
            <FormControl component="fieldset" className="w-100">
              <RadioGroup aria-label="" name="isHire" onChange={(e)=> this.setState({isHeire: e.target.value==="true"?true:false,heir:e.target.value==="true" ? []:this.state.heir})}>
                <FormControlLabel value={true} label={MainFunc.getLabelDtos("L0072")} checked={this.state.isHeire} control={<Radio icon={<ImRadioUnchecked size={18} />} checkedIcon={<ImRadioChecked size={18} className="csMainColor" />} />} className="mb-0" />
                <small className="text--muted">{this.state.isHeire===true?MainFunc.getLabelDtos("L0121"):null}</small>
                <FormControlLabel value={false} label={MainFunc.getLabelDtos("L0073")} checked={!this.state.isHeire} control={<Radio icon={<ImRadioUnchecked size={18} />} checkedIcon={<ImRadioChecked size={18} className="csMainColor" />} />} className="mb-0" />
                <small className="text--muted">{this.state.isHeire===false?MainFunc.getLabelDtos("L0078"):null}</small>
              </RadioGroup>
            </FormControl>

            {!this.state.isHeire && this.state.heir?
            <Row>
              {this.state.heir?.map((item, key)=>{
                return(
                  <Col xs={12} key={key} className={key===2?"pt-3 pb-3":"pt-3"}>
                    <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0071")} {key+1} {<Button variant="" className="heirDelBtn" onClick={()=>this.removeHeir(key)}>{MainFunc.getLabelDtos("L0086")}</Button>}</span>
                    <Card className="p-3 heirBoxShadow mt-1">
                      <FormControl className="w-100" error={this.state.validation&&!item.titleName?true:false}>
                        <InputLabel id={"titleName"+key}>{MainFunc.getLabelDtos("L0031")}</InputLabel>
                        <Select labelId={"titleName"+key} className={!item.titleName?"text-placeholder":null} value={!item.titleName?"none":item.titleName} onChange={(e)=>this.changeHeir(key, "titleName" ,e.target.value)} >
                          <MenuItem value={"none"} hidden>{MainFunc.getLabelDtos("L0032")}</MenuItem>
                          {MainData.initialData.title.map((item, key)=>{
                            return(
                              <MenuItem key={key} value={item.titleTitles}>{item.titleTitles}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                      <TextField inputProps={{autocomplete: 'off'}} label={MainFunc.getLabelDtos("L0080")} placeholder={MainFunc.getLabelDtos("L0081")} value={item.firstName} onChange={(e)=>this.changeHeir(key, "firstName", e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&!item.firstNameValid} />
                      <TextField inputProps={{autocomplete: 'off'}} label={MainFunc.getLabelDtos("L0082")} placeholder={MainFunc.getLabelDtos("L0083")} value={item.lastName} onChange={(e)=>this.changeHeir(key, "lastName", e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&!item.lastNameValid} />
                      <FormControl className="w-100 mt-3" error={this.state.validation&&!item.relation?true:false}>
                        <InputLabel id={"relation"+key}>{MainFunc.getLabelDtos("L0084")}</InputLabel>
                        <Select labelId={"relation"+key} className={!item.relation?"text-placeholder":null} value={!item.relation?"none":item.relation} onChange={(e)=>this.changeHeir(key, "relation", e.target.value)} >
                          <MenuItem value={"none"} hidden>{MainFunc.getLabelDtos("L0085")}</MenuItem>
                          {item.titleName && MainData.initialData.mappingRelationships?.find((data) => data.titleTitles === this.state.heir[key]?.titleName)?.mappingRelations.filter((data) => this.state.heir[key]?.relation == data.relationshipTitle || data.multipleSelect ||!this.state.heir.map((data) => {return data.relation})?.includes(data.relationshipTitle)).map((item, keyRelationship)=>{ //กรณีที่1 มี titleName  เลือก title ต้อง check mapping
                           return(
                              <MenuItem key={keyRelationship} value={item.relationshipTitle}>{item.relationshipTitle}</MenuItem>
                            )
                          })}
                          {!item.titleName && MainData.initialData.relationship?.filter(data=>this.state.heir[key]?.relation == data.relationshipTitle || data.multipleSelect || !this.state.heir.map((data) => {return data.relation})?.includes(data.relationshipTitle)).map((item, keyRelationship)=>{ //กรณีที่ 2 ไม่มี titleName ยังไม่เลือก title ก็ไม่ต้องเช็ค 
                            return(
                              <MenuItem key={keyRelationship} value={item.relationshipTitle}>{item.relationshipTitle}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                      <small className="text-danger pt-3"><b>{MainFunc.getLabelDtos("L0015")}</b>
                        <div dangerouslySetInnerHTML={{__html: MainFunc.getLabelDtos("L0858")}}></div>
                      </small>

                      <small className="text--muted pt-3" hidden>{MainFunc.getLabelDtos("L0478") /** เปอร์เซนต์ผลประโยชน์ */}</small>
                      <span hidden>{item.percen} %</span>
                    </Card>
                  </Col>
                )
              })}
            </Row>
            :null}
          </Col>
              
          {this.state.isHeire===true?null:this.state.heir.length>2?null:
            <Col xs={12} className="pb-3 ">
              <Button variant="" className="subBtn" onClick={()=>{this.addHeir()}}>+ {MainFunc.getLabelDtos("L0079")}</Button>
            </Col>
          }

          <Col xs={12}>
            <div style={{borderBottom: "0.2px solid rgba(0,0,0,0.1)"}}></div>
          </Col>

          <Col xs={12} className="pt-4">
            {this.state.taxBreakQuestionResponse.map((item, key)=>{
              const taxBreakQuestionAnswer = item.taxConsentAnswer.map((ansItem, ansKey)=>{
                return(
                  <FormControlLabel 
                    value={ansItem.isReject} 
                    label={ansItem.taxConsentAnswerTitle} 
                    checked={this.state.isAgreeRD == null ? false : ansItem.isReject?this.state.isAgreeRD:!this.state.isAgreeRD}
                    control={
                      <Radio 
                        icon={<ImRadioUnchecked size={18} />} 
                        checkedIcon={<ImRadioChecked size={18} className="csMainColor" />} 
                      />
                    } 
                    className="mb-0 align-items-start ImRadioChecked--Custom" 
                  />
                )
              })
                  return(
                    <Fragment>
                      <p className="subFontFamily">{parse(item.taxConsentQuestionTitle?item.taxConsentQuestionTitle:"")}</p>
                      <FormControl component="fieldset" className="w-100">
                        <RadioGroup aria-label="" name="isAgreeRD" onChange={(e)=> this.setState({isAgreeRD: e.target.value==="true"?true:false})}>
                          {taxBreakQuestionAnswer}
                        </RadioGroup>
                      </FormControl>
                      </Fragment>
                  )
                }
              )}
          </Col>

          {!this.props.prev?null:
            <Col xs={!this.props.next?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pr-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-7_Click_BtnBack":"STEP2-6_Click_BtnBack"}`} variant="" className="subBtnRadius font-weight-bold" onClick={() => {this.processBack()}}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
          {!this.props.next?null:
            <Col xs={!this.props.prev?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pl-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-7_Click_BtnNext":"STEP2-6_Click_BtnNext"}`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processNext() }}>{MainFunc.getLabelDtos("L0021")}</Button>
            </Col>
          }
        </Row>
      </div>
    )
  }
}

export default SM010;