import React, { Component } from "react";
import styles from "../../styles/planSelected.module.scss";
import { Row, Col } from "react-bootstrap";
class ContentPlanSelected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  incrementCount() {
    this.setState((prevState) => ({
      count: prevState.count + 1,
    }));
  }

  render() {
    return (
      <div className={styles.contentPlanSelected}>
        <div className={styles.line}></div>
        <div className={styles.group}>
          <span className={styles.groupTitle}>
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.data?.groupTitle,
              }}
            />
          </span>
          <span className={styles.groupValue}>
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.data?.groupValue,
              }}
            />
          </span>
        </div>
        <Row>
          {this.props.data.titles.map((item, index) => {
            return (
              <Col md={4} className={styles.contentPlanSelectedData}>
                <span className={styles.contentPlanSelectedDataTitle}>
                  <div dangerouslySetInnerHTML={{ __html: item.title }} />
                </span>
                <span className={styles.contentPlanSelectedDataDetail}>
                  <div dangerouslySetInnerHTML={{ __html: item.titleValue }} />
                </span>
                {item.subTitles?.map((subItem, subIndex) => {
                  return (
                    <div className={styles.sub}>
                      <div className={styles.subtitle}>
                        <div
                          dangerouslySetInnerHTML={{ __html: subItem.subTitle }}
                        />
                      </div>
                      <span className={styles.subValue}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: subItem.subTitleValue,
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

export default ContentPlanSelected;
