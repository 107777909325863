import React from "react";
import styles from "../../styles/box-widget.module.scss";
import IconBoxWidgetPhone from "../../images/icon_box-widgets-phone.svg";
import IconBoxWidgetLine from "../../images/icon_box-widgets-line.svg";
import IconBoxWidgetWifiCalling from "../../images/icon_box-widgets-wifi_calling.svg";

import IconChatbot from "../../images/icon-chatbot.png";
import MainFunc from "../../models/MainFunc";
import MainData from "../../models/MainData";
class MyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device: "",
      browsers: "",
    };
  }
  componentDidMount() {
    this.setState({
      device: this.getMobileOperatingSystem(),
      browsers: this.detectBrowsers(),
    });
  }

  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "";
  };

  detectBrowsers = () => {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      return "Opera";
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
      return "Edge";
    } else if (
      navigator.userAgent.indexOf("Chrome") != -1 ||
      navigator.userAgent.indexOf("CriOS") != -1
    ) {
      return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return "Firefox";
    } else if (
      navigator.userAgent.indexOf("MSIE") != -1 ||
      !!document.documentMode == true
    ) {
      //IF IE > 10
      return "IE";
    } else {
      return "unknown";
    }
  };

  render() {
    return (
      // JSX code for rendering the component
      <div
        className={`${styles.body} ${
          this.props.mainPage ? styles.mainPage : ""
        } ${this.props.isOpen ? styles.active : ""}`}
      >
        <a
          href={this.props.data?.contactKbankLive}
          target="_blank"
          onClick={() => {
            if (this.props.activePage) {
              MainFunc.sendDataToGTMKbank({
                event: "track_event",
                event_category: "chatbox",
                event_action: `${MainData.initialData.productId}`,
                event_label: "line_chat",
                flag_kplus: MainData.screenMenuKPlus.onKPlus ? 1 : 0,
                flag_page: this.props.checkKplus
                  ? "onboard01"
                  : MainData.mapSubMenuCodeAndFlagPageGA.find(
                      (item) => this.props.activePage == item.subMenuCode
                    )?.flagPage,
              });
            } else {
              MainFunc.sendDataToGTMKbank({
                event: "track_event",
                event_category: "chatbox",
                event_action: `${MainData.initialData.productId}`,
                event_label: "line_chat",
              });
            }
          }}
        >
          <div className={styles.line}>
            <img src={IconBoxWidgetLine} alt="icon-box-widget" />
            <div className={styles.lineText}>
              <div className={styles.lineTitle}>
                <img src={IconChatbot} alt="icon-box-widget" />
                <strong>{MainFunc.getLabelDtos("L0785") /*Kbank Live*/}</strong>
              </div>
              <div className={styles.lineSubTitle}>
                <span>{MainFunc.getLabelDtos("L0786") /*Kbank Live*/}</span>
              </div>
            </div>
            <div className={styles.arrow} />
          </div>
        </a>
        <a
          href={`tel:${this.props.data?.contactPhoneNumber}`}
          target="_blank"
          onClick={() => {
            if (this.props.activePage) {
              MainFunc.sendDataToGTMKbank({
                event: "track_event",
                event_category: "hotline",
                event_action: "click",
                event_label: "ติดต่อสอบถาม",
                position: "bottom_btn",
                flag_kplus: MainData.screenMenuKPlus.onKPlus ? 1 : 0,
                flag_page: this.props.checkKplus
                  ? "onboard01"
                  : MainData.mapSubMenuCodeAndFlagPageGA.find(
                      (item) => this.props.activePage == item.subMenuCode
                    )?.flagPage,
              });
            } else {
              MainFunc.sendDataToGTMKbank({
                event: "track_event",
                event_category: "hotline",
                event_action: "click",
                event_label: "ติดต่อสอบถาม",
                position: "bottom_btn",
              });
            }
          }}
        >
          <div className={styles.phone}>
            <img src={IconBoxWidgetPhone} alt="icon-box-widget" />
            <div className={styles.phoneText}>
              <span>
                {MainFunc.getLabelDtos("L0783") /*02-8888888 กด 862*/}
              </span>
            </div>
            <div className={styles.arrow} />
          </div>
        </a>
        <span
          target="_blank"
          onClick={() => {
            if (this.props.activePage) {
              MainFunc.sendDataToGTMKbank({
                event: "track_event",
                event_category: "link_click",
                event_action: "click",
                event_label: "wifi_calling_icon",
                position: "bottom_btn",
                flag_kplus: MainData.screenMenuKPlus.onKPlus ? 1 : 0,
                flag_page: this.props.checkKplus
                  ? "onboard01"
                  : MainData.mapSubMenuCodeAndFlagPageGA.find(
                      (item) => this.props.activePage == item.subMenuCode
                    )?.flagPage,
              });
            } else {
              MainFunc.sendDataToGTMKbank({
                event: "track_event",
                event_category: "link_click",
                event_action: "click",
                event_label: "wifi_calling_icon",
                position: "bottom_btn",
              });
            }
            if (this.state.device) {
                window.location.href = this.props.data?.contactWifiMb;
              } else {
                window.location.href = this.props.data?.contactWifiPc;
              }
          }}
        >
          <div className={styles.phone}>
            <img src={IconBoxWidgetWifiCalling} alt="icon-box-widget" />
            <div className={styles.phoneText}>
              <span>
                {MainFunc.getLabelDtos("L0828") /*โทรปรึกษาฟรีผ่าน K PLUS*/}
              </span>
            </div>
            <div className={styles.arrow} />
          </div>
        </span>
      </div>
    );
  }
}

export default MyComponent;
