import parse from "html-react-parser";
import React, { Component } from "react";
import styles from "../../styles/CarouselBanner.module.scss";
import { Carousel } from "react-bootstrap";
import 'moment/locale/th';
import logomobile from '../../images/logo.png';
import logodesktop from '../../images/logo.png';

class CarouselBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(){

  }

  gotoProductDetail = (item) => {
    let data = item
    data.view = "readBanner";
    this.props.clickView(data)
  }


  render(){
    return (
      <div className="components hero-banner">
        <Carousel
          className={styles.mainCarousel}
          indicators={true}
          interval={3000}
          nextIcon={""}
          prevIcon={""}
        >
          {this.props.images?.map((item, index) => {
            return (
              <Carousel.Item key={index}>
                <div className={styles.imageContainer}>
                  <img
                    src={item.bannerHiglightPC}
                    alt=""
                    className={`${styles.image} ${styles.desktop}`}
                    style={{cursor: "pointer"}}
                    onClick={() => this.gotoProductDetail(item)}
                  />
                  <img
                    src={item.bannerHiglightMB}
                    alt=""
                    className={`${styles.image} ${styles.mobile}`}
                    style={{cursor: "pointer"}}
                    onClick={() => this.gotoProductDetail(item)}
                  />
                </div>
                <Carousel.Caption className={`${styles.captionbanner} container`}>
                  {parse(``)}
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
          {!this.props.images || this.props.images.length === 0 ? (
                <Carousel.Item>
                <div className={styles.imageContainer}>
                  <img
                    src={logodesktop}
                    alt=""
                    className={`${styles.image} ${styles.desktop}`}
                    style={{filter: "opacity(0)"}}
                  />
                  <img
                    src={logomobile}
                    alt=""
                    className={`${styles.image} ${styles.mobile}`}
                  />
                </div>
                <Carousel.Caption className={`${styles.captionbanner} container`}>
                  {parse(``)}
                </Carousel.Caption>
              </Carousel.Item>
              ) : ""}
        </Carousel>
      </div>
    )
  }
}

export default CarouselBanner;