import axios from "axios";
import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { GoAlert } from "react-icons/go";
import SweetAlert from "react-bootstrap-sweetalert";
import CardHistoryProduct from "./CardHistoryProduct";
import MainFunc from "../../models/MainFunc";
import MainData from "../../models/MainData";
import IconEmpty from "../../images/icon_empty.svg";
import styles from "../../styles/HistorySms.module.scss";
class HistorySms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "Otp",
      openToggle: 0,
      orderHistoryListSmsData: null,
      selectOrderHistoryData: null,
      healthQuestion: null,
      fatcaQuestion: null,
      fatcaInformationConsent: null,
      crsQuestion: null,
      isErrorPromotion: false,
      errMsgPromotion: "",
      errSubMsgPromotion: "",
      lastMenu: null,
      scrollYButton: false,
      scroll: 0,
      innerWidth: 0,
    };
    this.promotionBlock = React.createRef();
    this.buttonRow = React.createRef();
    this.colBlock = React.createRef();
    this.informationBlock = React.createRef();
  }

  componentDidMount() {
    this.props.loadController(true);
    this.setState({
      scroll: this.props.scroll,
      innerWidth: this.props.innerWidth,
    });
    this.getMasterData();
    this.getReceiveInsuranceQuestion();
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      citizenNo: MainData.screenSM005.idCard,
      channelCode: MainData.initialData.channelCode,
    };
    axios
      .post(MainData.serviceURL + "/SMS/GetOrderHistoryListSms", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (res.data.success === true) {
          this.setState({ orderHistoryListSmsData: data });
          setTimeout(() => {
            if (
              window.innerHeight >
              this.promotionBlock.current.getBoundingClientRect().bottom
            ) {
              this.colBlock.current.className = `buttonContainer d-none`;
              this.setState({ scrollYButton: false });
            } else {
              this.setState({ scrollYButton: true });
              this.colBlock.current.className = `buttonContainer fixedContinue`;
            }
            this.props.loadController(false);
          }, 100);
        } else {
          if (res.data.message == "MSG181") {
            this.props.setPage("NoInformationFound");
          } else {
            this.props.loadController(false);
            this.props.errorAlert(
              true,
              "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ",
              " "
            );
          }
        }
      })
      .catch((error) => {
        this.props.loadController(false);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
      });
  }

  getMasterData = () => {
    axios
      .get(MainData.serviceURL + "/MasterData/getMasterData", {
        headers: {
          Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
        },
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let newmasterDataRes = res.data.data;
        MainData.initialData.title = newmasterDataRes.title;
        MainData.initialData.titleEN = newmasterDataRes.titleEN;
        MainData.initialData.province = newmasterDataRes.province;
        MainData.initialData.occupation = newmasterDataRes.occupation;
        MainData.initialData.relationship = newmasterDataRes.relationship;
        MainData.initialData.confirmMessage = newmasterDataRes.confirmMessage;
        MainData.initialData.importantInformation =
          newmasterDataRes.importantInformation;
        MainData.initialData.natureOfBusinesses =
          newmasterDataRes.natureOfBusinesses;
        MainData.initialData.mappingRelationships =
          newmasterDataRes.mappingRelationships;
      })
      .catch((error) => {
        if (
          error?.response?.status == 404 &&
          error?.response?.data?.data?.messageCode == "MSG1001"
        ) {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.props.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
        } else {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.props.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
        }
      });
  };

  handleClick = () => {
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      AuthId: this.state.selectOrderHistoryData.token,
      "Language-Locale": "th",
    };
    const data = {};
    axios
      .post(MainData.serviceURL + "/PurchaseHistory/ReEntryHistory", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (res.data.success === true) {
          if (data.dataPages.find((item) => item.page == "SM003")?.pageDetail) {
            this.getQuestion(data);
          } else if (
            data.dataPages.find((item) => item.page == "SM004")?.pageDetail
          ) {
            this.getFATCAQuestion(data);
          } else {
            this.mapData(data);
          }
        } else {
          if (data.messageCode == "MSG182") {
            this.props.setPage("LinkExpired");
          } else {
            this.props.loadController(false);
            this.props.errorAlert(
              true,
              "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ",
              " "
            );
          }
        }
      })
      .catch((error) => {
        this.props.loadController(false);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
      });
  };

  getReceiveInsuranceQuestion = () => {
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      AuthId: !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId,
    };
    const data = {
      channelCode: MainData.initialData.channelCode,
      lang: "TH",
    };
    axios
      .post(MainData.serviceURL + "/Sales/GetReceiveInsuranceQuestion", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        if (res.data.success === true) {
          MainData.screenSM009Preview.receiveInsuranceQuestion =
            res.data.data.receiveInsuranceQuestion;
        }
      })
      .catch((error) => {
        this.props.loadController(false);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
      });
  };

  getQuestion = (reEntryHistoryData) => {
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      channelCode: MainData.initialData.channelCode,
      productId: this.state.selectOrderHistoryData.productId,
    };
    axios
      .post(MainData.serviceURL + "/Sales/GetQuestion", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (!data) {
          this.props.loadController(false);
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.props.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription,
            " "
          );
          return;
        }
        this.props.loadController(false);
        this.setState({ healthQuestion: data.healthQuestion });
        if (
          reEntryHistoryData.dataPages.find((item) => item.page == "SM004")
            ?.pageDetail
        ) {
          this.getFATCAQuestion(reEntryHistoryData);
        } else {
          this.mapData(reEntryHistoryData);
        }
      })
      .catch((error) => {
        this.props.loadController(false);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
      });
  };

  getFATCAQuestion = (reEntryHistoryData) => {
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      channelCode: MainData.initialData.channelCode,
      productId: this.state.selectOrderHistoryData.productId,
    };
    axios
      .post(MainData.serviceURL + "/Sales/GetFATCAQuestion", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        this.props.loadController(false);
        if (!data) {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.props.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription,
            " "
          );
          return;
        }
        this.setState({
          fatcaQuestion: data.fatcaQuestion,
          fatcaInformationConsent: data.fatcaInformationConsent,
          crsQuestion: data.crsQuestion,
        });
        this.mapData(reEntryHistoryData);
      })
      .catch((error) => {
        this.props.loadController(false);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response?.data?.data?.messageDescription
              ),
          " "
        );
      });
  };

  mapData = (reEntryHistoryData) => {
    let lastMenu = reEntryHistoryData.lastMenu;
    let dataPages = reEntryHistoryData.dataPages;
    MainData.screenMenuKPlus.onKPlus = reEntryHistoryData.isKplusAuth;
    let screenSM001 = dataPages.find(
      (item) => item.page == "SM001"
    )?.pageDetail;
    let screenSM002 = dataPages.find(
      (item) => item.page == "SM002"
    )?.pageDetail;
    let screenSM003 = dataPages.find(
      (item) => item.page == "SM003"
    )?.pageDetail;
    let screenSM004 = dataPages.find(
      (item) => item.page == "SM004"
    )?.pageDetail;
    let screenSM005 = dataPages.find(
      (item) => item.page == "SM005"
    )?.pageDetail;
    let screenSM006 = dataPages.find(
      (item) => item.page == "SM006"
    )?.pageDetail;
    let screenSM007 = dataPages.find(
      (item) => item.page == "SM007"
    )?.pageDetail;
    let screenSM008 = dataPages.find(
      (item) => item.page == "SM008"
    )?.pageDetail;
    let screenSM009 = dataPages.find(
      (item) => item.page == "SM009"
    )?.pageDetail;
    let screenSM010 = dataPages.find(
      (item) => item.page == "SM010"
    )?.pageDetail;
    let screenSM011 = dataPages.find(
      (item) => item.page == "SM011"
    )?.pageDetail;
    let screenSM012 = dataPages.find(
      (item) => item.page == "SM012"
    )?.pageDetail;
    let screenSM013 = dataPages.find(
      (item) => item.page == "SM013"
    )?.pageDetail;
    if (screenSM001) {
      let year = parseInt(screenSM001.birthDate.split("/")[2]);
      year = year + 543;
      let month = screenSM001.birthDate.split("/")[1];
      if (month < 10 && month.length === 1) {
        month = "0" + month;
      }
      let day = screenSM001.birthDate.split("/")[0];
      if (day < 10 && day.length === 1) {
        day = "0" + day;
      }
      MainData.screenSM001.birthdate = day + "/" + month + "/" + year;
      MainData.screenSM001.gender = screenSM001.gender;
      MainData.screenSM001.screen = "SM001";
    }
    if (screenSM002) {
      MainData.screenSM002.paymentCode = screenSM002.paymentCode;
      MainData.screenSM002.planId = screenSM002.planId;
      MainData.screenSM002.productName = screenSM002.productName;
      MainData.screenSM002.planName = screenSM002.planName;
      MainData.screenSM002.paymentName = screenSM002.paymentName;
      MainData.screenSM002.cost = screenSM002.cost;
      MainData.screenSM002.costPayment = screenSM002.costPayment;
      MainData.screenSM002.sumInsured = screenSM002.sumInsured;
      MainData.screenSM002.paymentId = screenSM002.paymentId;
      MainData.screenSM002.insureRequiredIdentification =
        screenSM002.insureRequiredIdentification;
      MainData.screenSM002.insureSelfie = screenSM002.insureSelfie;
      MainData.screenSM002.screen = "SM002";
    }
    if (screenSM003) {
      MainData.screenSM003.userHealthAns = screenSM003.healthQuestions.map(
        (item) => {
          return {
            questionID: item.questionCode,
            ansCode: item.answerCode,
            ans: this.state.healthQuestion
              .find((itemQ) => itemQ.healthQuestionCode == item.questionCode)
              ?.healthAnswer.find(
                (itemA) => itemA.healthAnswerCode == item.answerCode
              )?.isReject,
            condition: [],
          };
        }
      );
      MainData.screenSM003.healthQuestions = this.state.healthQuestion;
      MainData.screenSM003.screen = "SM003";
      MainData.screenSM013.healthQuestions = screenSM003.healthQuestions;
    }
    if (screenSM004) {
      let fatcaAnsAndFatcaAnsForservice = screenSM004.fatcaQuestions.map(
        (item) => {
          return {
            questionCode: item.questionCode,
            answerCode: item.answerCode,
            condition: [],
          };
        }
      );
      let crsAnsAndCrsAnsForservice = screenSM004.crsQuestions.map((item) => {
        return {
          questionCode: item.questionCode,
          answerCode: item.answerCode,
          condition: [],
        };
      });
      MainData.screenSM004.fatcaAns = fatcaAnsAndFatcaAnsForservice;
      MainData.screenSM004.fatcaAnsForservice = fatcaAnsAndFatcaAnsForservice;
      MainData.screenSM004.crsAns = crsAnsAndCrsAnsForservice;
      MainData.screenSM004.crsAnsForservice = crsAnsAndCrsAnsForservice;
      MainData.screenSM004.taxIdentificationNumber = false;
      MainData.screenSM004.acceptSensitive = true;
    }
    if (screenSM005) {
      MainData.screenSM005.idCard = screenSM005;
      MainData.screenSM005.cusId = this.state.selectOrderHistoryData.token;
    }
    if (screenSM006) {
      MainData.screenSM006.phoneNumber = screenSM006;
    }
    if (screenSM008) {
      let checkTitle = MainData.initialData.title.find(
        (x) => x.titleCode === screenSM008.titleCode
      );
      let checkTitleEN = MainData.initialData.titleEN.find(
        (x) => x.titleCode === screenSM008.titleCode
      );
      MainData.screenSM008.titleName = checkTitle?.titleTitles;
      MainData.screenSM008.firstName = screenSM008.firstName;
      MainData.screenSM008.lastName = screenSM008.lastName;
      MainData.screenSM008.firstNameEN = screenSM008.firstNameEn;
      MainData.screenSM008.lastNameEN = screenSM008.lastNameEn;
      MainData.screenSM008.job = screenSM008.occupationCode;
      MainData.screenSM008.jobDescription = screenSM008.natureOfBusiness;
      MainData.screenSM008.email = screenSM008.email;

      let newOccupation = MainData.initialData.occupation?.find(
        (x) => x.occupationCode === screenSM008.occupationCode
      );
      MainData.screenSM013.jobInformationsType = newOccupation?.occupationId;
      MainData.screenSM013.jobInformationsOccupation =
        newOccupation?.occupationCode;
      MainData.screenSM013.jobInformationsDescription =
        screenSM008.natureOfBusiness;
      MainData.screenSM013.titleCode = screenSM008.titleCode;
      MainData.screenSM013.titleCodeEN = screenSM008.titleCodeEn;
    }
    if (screenSM009) {
      let checkCurrentProvince = MainData.initialData.province.find(
        (x) => x.provinceCode === screenSM009.currentAddressProvinceCode
      );
      let checkCurrentDistrict = checkCurrentProvince?.district.find(
        (x) => x.districtCode === screenSM009.currentAddressDistrictCode
      );
      let checkCurrentsubDistrict = checkCurrentDistrict?.subDistrict.find(
        (x) => x.subDistrictCode === screenSM009.currentAddressSubdistrictCode
      );

      let checkAddressProvince = MainData.initialData.province.find(
        (x) => x.provinceCode === screenSM009.addressProvinceCode
      );
      let checkAddressDistrict = checkAddressProvince?.district.find(
        (x) => x.districtCode === screenSM009.addressDistrictCode
      );
      let checkAddresssubDistrict = checkAddressDistrict?.subDistrict.find(
        (x) => x.subDistrictCode === screenSM009.addressSubdistrictCode
      );
      MainData.screenSM009.address = screenSM009.currentAddressNo?.substring(
        0,
        30
      );
      MainData.screenSM009.province = checkCurrentProvince?.provinceTitle;
      MainData.screenSM009.district = checkCurrentDistrict?.districtTitle;
      MainData.screenSM009.subDistrict =
        checkCurrentsubDistrict?.subDistrictTitle;
      MainData.screenSM009.village =
        screenSM009?.currentAddressBuilding?.substring(0, 50);
      MainData.screenSM009.moo = screenSM009?.currentAddressMoo?.substring(
        0,
        50
      );
      MainData.screenSM009.soi = screenSM009?.currentAddressSoi?.substring(
        0,
        50
      );
      MainData.screenSM009.street = screenSM009?.currentAddressRoad?.substring(
        0,
        50
      );
      MainData.screenSM009.zipCode =
        screenSM009.currentAddressZipcode?.substring(0, 50);
      MainData.screenSM009.isSameAddress =
        screenSM009.addressType === "Home" ? true : false;

      MainData.screenSM009.formalProvince = checkAddressProvince?.provinceTitle;
      MainData.screenSM009.formalDistrict = checkAddressDistrict?.districtTitle;
      MainData.screenSM009.formalSubDistrict =
        checkAddresssubDistrict?.subDistrictTitle;
      MainData.screenSM009.formalAddress = screenSM009.addressNo?.substring(
        0,
        30
      );
      MainData.screenSM009.formalVillage =
        screenSM009.addressBuilding?.substring(0, 50);
      MainData.screenSM009.formalMoo = screenSM009.addressMoo?.substring(0, 50);
      MainData.screenSM009.formalSoi = screenSM009.addressSoi?.substring(0, 50);
      MainData.screenSM009.formalStreet = screenSM009.addressRoad?.substring(
        0,
        50
      );
      MainData.screenSM009.formalZipCode =
        screenSM009.addressZipcode?.substring(0, 50);
      MainData.screenSM009.isDocToNowAddress =
        screenSM009.contactAddress === "Home" ? false : true;
      MainData.screenSM009.insuranceTrack =
        screenSM009.receiveInsuranceChannel === "email"
          ? "electronic"
          : screenSM009.receiveInsuranceChannel === "post"
          ? "paper"
          : screenSM009.receiveInsuranceChannel === "paper"
          ? "paper"
          : "electronic";
      MainData.screenSM009.insuranceTrack1 =
        screenSM009.receiveInsuranceChannelOther;
      MainData.screenSM013.provinceCode =
        screenSM009.currentAddressProvinceCode;
      MainData.screenSM013.districtCode =
        screenSM009.currentAddressDistrictCode;
      MainData.screenSM013.subDistrictCode =
        screenSM009.currentAddressSubdistrictCode;
      if (MainData.screenSM009.isSameAddress === false) {
        MainData.screenSM013.formalProvinceCode =
          screenSM009.addressProvinceCode;
        MainData.screenSM013.formalDistrictCode =
          screenSM009.addressDistrictCode;
        MainData.screenSM013.formalSubDistrictCode =
          screenSM009.addressSubdistrictCode;
      } else {
        MainData.screenSM013.formalProvinceCode =
          screenSM009.currentAddressProvinceCode;
        MainData.screenSM013.formalDistrictCode =
          screenSM009.currentAddressDistrictCode;
        MainData.screenSM013.formalSubDistrictCode =
          screenSM009.currentAddressSubdistrictCode;
      }
    }
    if (screenSM010) {
      let data1 = screenSM010.beneficiariesOfOrder;
      let i;
      MainData.screenSM010.heir = [];
      MainData.screenSM010.hasBeneficiary = screenSM010.hasBeneficiary;
      if (screenSM010.hasBeneficiary) {
        for (i = 0; i < data1.length; i++) {
          let titleId = data1[i].beneficiaryInformation.titleId;
          let checkTitle = MainData.initialData.title.find(
            (x) => x.titleCode === titleId
          );
          let relationShipCode = data1[i].relationShipCode;
          let checkrelationShip = MainData.initialData.relationship.find(
            (x) => x.relationshipCode === relationShipCode
          );
          MainData.screenSM010.heir.push({
            titleName: checkTitle.titleTitles,
            firstName: data1[i].beneficiaryInformation.firstName,
            lastName: data1[i].beneficiaryInformation.lastName,
            relation: checkrelationShip.relationshipTitle,
          });
        }
      }
      MainData.screenSM010.isHeire = !screenSM010.hasBeneficiary;
      MainData.screenSM010.isAgreeRD = screenSM010.taxIdentification;
      MainData.screenSM013.beneficiariesOfOrder =
        screenSM010.beneficiariesOfOrder;
    }
    if (screenSM011) {
      MainData.screenSM011.agreeTerms = "checked";
    }
    if (screenSM013) {
      MainData.screenSM013.promotionCode = screenSM013.usePromotionCode
        ? screenSM013.promotionMKTCode
        : "";
      MainData.screenSM013.checkRetryPayment = screenSM013.checkRetryPayment
        ? screenSM013.checkRetryPayment
        : false;
      if (screenSM013.messageCode) {
        this.props.loadController(false);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === screenSM013.messageCode
        );
        this.setState({
          isErrorPromotion: true,
          errMsgPromotion: errMsg.msgDescription,
          lastMenu: lastMenu,
        });
        return;
      }
    }
    this.props.continuePage(
      lastMenu,
      this.state.selectOrderHistoryData.productIdCode,
      this.state.selectOrderHistoryData.productIdSlug
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.scroll != this.state.scroll) {
      if (this.promotionBlock === null) {
        return;
      }
      if (
        this.promotionBlock.current.getBoundingClientRect().bottom >=
          window.innerHeight &&
        this.state.scrollYButton
      ) {
        this.colBlock.current.className = `buttonContainer fixedContinue`;
      } else {
        this.colBlock.current.className = `buttonContainer d-none`;
      }
      this.setState({ scroll: nextProps.scroll });
    }
    if (nextProps.innerWidth != this.state.innerWidth) {
      setTimeout(() => {
        if (
          window.innerHeight >
          this.promotionBlock.current.getBoundingClientRect().bottom
        ) {
          this.colBlock.current.className = `buttonContainer d-none`;
          this.setState({ scrollYButton: false });
        } else {
          this.setState({ scrollYButton: true });
          this.colBlock.current.className = `buttonContainer fixedContinue`;
        }
        this.setState({ innerWidth: nextProps.innerWidth });
      }, 100);
    }
  }

  render() {
    return (
      <div className="container-fulid">
        <SweetAlert
          show={this.state.isErrorPromotion}
          title={""}
          customIcon={
            <GoAlert size={70} className="text-center w-100 text-danger pb-2" />
          }
          custom
          onConfirm={this.errorConfirm}
          showCancel
          closeOnClickOutside={false}
          customButtons={
            <Row>
              <Button
                onClick={() => {
                  this.setState({
                    isErrorPromotion: false,
                    errMsgPromotion: "ERROR!",
                  });
                  this.props.continuePage(
                    this.state.lastMenu,
                    this.state.selectOrderHistoryData.productIdCode,
                    this.state.selectOrderHistoryData.productIdSlug
                  );
                }}
                className="mainBtnRadius"
              >
                {MainFunc.getLabelDtos("L0167") /* ดำเนินการต่อ */}
              </Button>
            </Row>
          }
        >
          <h5
            className={!this.state.redirectFromKplus ? "" : ""}
            dangerouslySetInnerHTML={{ __html: this.state.errMsgPromotion }}
          ></h5>
          <span
            className="text--muted"
            dangerouslySetInnerHTML={{
              __html: !this.state.errSubMsg
                ? "ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา"
                : this.state.errSubMsgPromotion,
            }}
          ></span>
        </SweetAlert>
        <div ref={this.promotionBlock} className={styles.historySms}>
          <div className={styles.historySmsPage}>
            <div className={styles.title}>
              <img src={IconEmpty} />
              <span className={styles.titleText}>
                {
                  MainFunc.getLabelDtos(
                    "L0703"
                  ) /* ระวัติการสั่งซื้อที่ท่านทำรายการค้างไว้ */
                }
              </span>
            </div>
            <div className={styles.content}>
              <Row className={styles.contentList}>
                {this.state.orderHistoryListSmsData !== undefined &&
                  this.state.orderHistoryListSmsData !== null &&
                  this.state.orderHistoryListSmsData.length !== 0 &&
                  this.state.orderHistoryListSmsData.map((item, index) => (
                    <Col
                      key={index}
                      mb={12}
                      sm={6}
                      lg={4}
                      className={styles.contentCard}
                    >
                      <CardHistoryProduct
                        openToggle={
                          this.state.openToggle == index + 1 ? true : false
                        }
                        setOpenToggle={(number, data) =>
                          this.setState({
                            openToggle: number,
                            selectOrderHistoryData: data,
                          })
                        }
                        data={item}
                        index={index + 1}
                      />
                    </Col>
                  ))}
              </Row>
            </div>
          </div>
          <div
            className={`${styles.btn} ${
              !this.state.orderHistoryListSmsData ? "d-none" : ""
            }`}
          >
            <Button
              variant=""
              className="mainBtnRadius font-weight-bold"
              onClick={() => this.handleClick()}
              disabled={!this.state.selectOrderHistoryData ? true : false}
            >
              {MainFunc.getLabelDtos("L0705") /* ทำรายการต่อ */}
            </Button>
          </div>
          <div
            ref={this.colBlock}
            className={`featuresWrapper ${
              !this.state.orderHistoryListSmsData ? "d-none" : ""
            }`}
          >
            <Row className="buttonContainer">
              <Button
                variant=""
                className="mainBtnRadius font-weight-bold"
                onClick={() => this.handleClick()}
                disabled={!this.state.selectOrderHistoryData ? true : false}
              >
                {MainFunc.getLabelDtos("L0705") /* ทำรายการต่อ */}
              </Button>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default HistorySms;
