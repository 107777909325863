import React, { Component, Fragment } from "react";
import axios from "axios";
import styles from "../../styles/planselect.module.scss";
import { Row, Col } from "react-bootstrap";
import PreDatePicker from "./preDatePicker.js";
import PreRadio from "./preRadio.js";
import PreSlider from "./preSlider.js";
import PreSelectOnlyOne from "./preSelectOnlyOne.js";
import PreSelectMuti from "./preSelectMuti.js";
import PlanSelected from "./planSelected.js";
import SummaryPlanSelect from "./summaryPlanSelect.js";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
import moment from "moment";
import SummaryBar from "./summaryBar.js";
import _ from "lodash";
class Planselect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepSelectPan: 1,
      IsBodySummaryNormalPlanDetail: false,
      IsConditionsCoverageDetail: false,
      dataPreSlider: null,
      preSlider: null,
      data: {},

      error: {},
      planSelects: null,
      benefitQuestions: null,
      mappingBenefit: null,
      planInfo: null,
      benefit: null,
      benefitSelectValue: null,
      paymentModeDefault: "",
      planDefault: "",
      riderPlan: null,
      riderSpecials: null,
      notRiderPlan: MainData.userSelectedData.notRiderPlan
        ? MainData.userSelectedData.notRiderPlan
        : null,
      currentPlan: MainData.userSelectedData.selectedPlan
        ? MainData.userSelectedData.selectedPlan
        : null,
      paymentMode: "",
      planSumary: null,
      selectedRider: [],

      screen: "SM001",
      gender: MainData.dataBroker.gender ? MainData.dataBroker.gender : "MALE",
      birthdate: MainData.dataBroker.birthdate
        ? MainData.dataBroker.birthdate
        : "",
      checkAgeMinUnit: MainData.checkAgeMinUnit,
      checkAgeMaxUnit: MainData.checkAgeMaxUnit,
      checkGender: MainData.checkGender,
      checkAgeMin: MainData.checkAgeMin,
      checkAgeMax: MainData.checkAgeMax,
      validate: false,
      failAgeRange: false,
      failAgeRangeMsg: "",

      productName: null,
      paymentName: null,
      cost: 0,
      costPayment: 0,
      sumInsured: 0,
      userSelectPlan: null,
      dataSelectRiderPremium: null,
      sliderPlanStatus: false,
      selectBenefitStatus: false,
      premiumCalStatus: false,
    };
  }

  incrementCount() {}
  async componentDidMount() {
    if (
      this.state.gender &&
      this.state.birthdate.length == 10 &&
      !MainData.dataBroker.dopaFail
    ) {
      await this.props.loadController(true);
      await this.onProcessData(
        MainData.userSelectedData.notRiderPlan?.paymentCode
          ? MainData.userSelectedData.notRiderPlan?.paymentCode
          : null
      );
      await this.getPlanSummary();
      await this.props.loadController(false);
    } else {
      this.setState({ planSelects: null, premiumCalStatus: false });
      MainData.userSelectedData.selectedPlan = null;
      this.props.setStepSelectPlan(1);
      if (this.state.gender && this.state.birthdate.length == 10) {
        this.setState({ premiumCalStatus: true });
      }
    }
    MainData.dataBroker.dopaFail = false;
  }

  handleChange(e) {
    this.setState({ planSelects: null, premiumCalStatus: false });
    MainData.userSelectedData.selectedPlan = null;
    this.props.setStepSelectPlan(1);
    let birthDate = this.state.birthdate;
    if (e.target.name === "birthDate") {
      let val = e.target.value;
      let key = e.nativeEvent;
      let valeplace = val.replace(/[^\d]/g, "");
      if (valeplace.length <= 8) {
        let setValNew = MainFunc.genTextToDate(valeplace);
        MainData.screenMenuKPlus.onKPlus = false;
        if (val === setValNew && setValNew.slice(-1) === "/") {
          setValNew = setValNew.slice(0, setValNew.length - 1);
        } else if (
          key.inputType === "deleteContentBackward" &&
          setValNew.slice(-1) === "/"
        ) {
          setValNew = setValNew.slice(0, setValNew.length - 2);
        }
        this.setState({
          birthdate: setValNew,
          failAgeRange: false,
          failAgeRangeMsg: this.textfailAgeRange(true),
        });
        birthDate = setValNew;
      }
    }
    if (e.target.name === "gender") {
      this.setState({
        gender: e.target.value,
      });
    }
    if (this.state.gender && birthDate.length == 10) {
      this.setState({ premiumCalStatus: true });
    }
  }

  convertBirthDate(data) {
    let year = data.split("/")[2];
    year = (parseInt(year) - 543).toString();
    let month = data.split("/")[1];
    let day = data.split("/")[0];

    return year + "-" + month + "-" + day;
  }

  textfailAgeRange = (IncorrectInformation) => {
    return `${MainFunc.getLabelDtos("L0744")} ${this.state.checkAgeMin} - ${
      this.state.checkAgeMax
    } ${
      this.state.checkAgeMaxUnit === "M"
        ? MainFunc.getLabelDtos("L0129")
        : this.state.checkAgeMaxUnit === "D"
        ? MainFunc.getLabelDtos("L0128")
        : MainFunc.getLabelDtos("L0130")
    }`;
  };

  selectPaymentMode = (value) => {
    this.setState({ paymentMode: value });
    this.onProcessData(value);
  };

  selectPlan = async (value) => {
    let data = this.state.planSelects?.planDetails?.planLists.find(
      (item) => item?.planId === value
    );

    let planPremiumMode = data.planPremiumMode?.find(
      (item) =>
        item.paymentCode === MainData.userSelectedData.selectedPlan?.paymentCode
    );
    let planSelect = {
      productName: MainData.userSelectedData.selectedPlan?.productName,
      planId: value,
      planName: data.planName,
      paymentCode: planPremiumMode?.paymentCode,
      paymentName: this.state.planSelects?.planDetails?.paymentModeLists?.find(
        (item) => item?.paymentCode === planPremiumMode?.paymentCode
      )?.paymentName,
      sumInsured: planPremiumMode?.sumInsured,
      premium: planPremiumMode?.premium,
      premiumPayment: planPremiumMode?.premiumPayment || 0,
      paymentId: planPremiumMode?.paymentId,
      paymentUnitTitle: planPremiumMode?.paymentUnitTitle,
      insureSelfie: planPremiumMode?.insureSelfie,
      insureRequiredIdentification:
        planPremiumMode?.insureRequiredIdentification,
    };
    this.setState({ currentPlan: planSelect });
    let notRiderPlan = _.cloneDeep(planSelect);
    this.setState({ notRiderPlan: notRiderPlan });
    MainData.userSelectedData.selectedPlan = planSelect;
    MainData.userSelectedData.notRiderPlan = notRiderPlan;
    this.setState({ planSumary: null });
    await this.getPlanInfo(value);
    await this.getRider(value, planSelect.paymentCode);
  };

  selectSliderPlan = async (value) => {
    this.setState({ preSlider: value });
    let mappingBenefit = _.cloneDeep(this.state.mappingBenefit);
    let benefitPlan = mappingBenefit?.find(
      (item) => item.planId === value.value
    )?.benefits;
    await this.setState({
      benefit: benefitPlan,
    });
    await this.getMasterBenefit(value.value);
    this.selectPlan(value.value);
  };

  selectBenefit = async (questionCode, valueCode) => {
    let benefit = _.cloneDeep(this.state.benefit);
    let index = benefit?.findIndex(
      (item) => item.questionCode === questionCode
    );
    if (benefit) {
      if (index !== -1) {
        benefit[index].valueCode = valueCode;
      }
      this.setState({
        benefit: benefit,
      });
    }
    let plan = this.state.mappingBenefit?.find((item) => {
      return JSON.stringify(item.benefits) === JSON.stringify(benefit);
    });
    if (!plan) {
      let mappingBenefit = this.state.mappingBenefit?.filter((mapping) => {
        return (
          mapping.benefits.find((item) => item.questionCode === questionCode)
            .valueCode === valueCode
        );
      });
      if (mappingBenefit.length > 0) {
        plan = mappingBenefit?.[mappingBenefit.length - 1];
        this.setState({
          benefit: plan?.benefits,
        });
      } else {
        let dataPreSlider = _.cloneDeep(this.state.dataPreSlider);
        let lastplan = dataPreSlider
          .sort((a, b) => a.label - b.label)
          .pop()?.value;
        plan = this.state.mappingBenefit.find(
          (item) => item.planId === lastplan
        );
        this.setState({
          benefit: plan?.benefits,
        });
      }
    }
    if (plan) {
      let selectSliderPlan = this.state.dataPreSlider.find(
        (item) => item.value === plan.planId
      );
      this.selectSliderPlan(selectSliderPlan);
    }
  };

  selectRider = async (value) => {
    if (value.length !== 0) {
      this.setState({ selectedRider: value });
      this.getRiderPremium(value, this.state.notRiderPlan?.paymentCode);
      MainData.dataBroker.selectRider = value;
    } else {
      this.props.loadController(true);
      let notRiderPlan = _.cloneDeep(this.state.notRiderPlan);
      this.getRiderPremium(value, this.state.notRiderPlan?.paymentCode);
      await this.setState({ selectedRider: [] });
      await this.setState({ dataSelectRiderPremium: null });
      await this.setState({ currentPlan: notRiderPlan });
      MainData.userSelectedData.selectedPlan = notRiderPlan;
      MainData.dataBroker.selectRider = [];

      this.props.loadController(false);
    }
  };

  onProcessData = async (paymentMode) => {
    this.props.loadController(true);
    MainData.screenSM001.birthdate = this.state.birthdate;
    MainData.screenSM001.gender = this.state.gender;
    MainData.dataBroker.birthdate = this.state.birthdate;
    MainData.dataBroker.gender = this.state.gender;
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "language-locale": "th",
    };
    let data = {
      productId: MainData.initialData.productId,
      channelCode: MainData.initialData.channelCode,
      birthDate: MainFunc.getUserBirthDate("ac"),
      age: moment().diff(MainFunc.getUserBirthDate("ac"), "years"),
      gender: this.state.gender,
      grantee: "SELF",
      paymentMode: paymentMode ? paymentMode : null,
      ageUnit: "Y",
    };
    await axios
      .post(MainData.serviceURL + "/Broker/SelectPlan", data, { headers })
      .then(async (response) => {
        // handle success
        let data = response.data.data;
        if (response.data.success && response.data.data) {
          this.setState({ planSelects: data });
          if (data.planDetails?.paymentModeDefault) {
            this.setState({
              paymentMode: data.planDetails?.paymentModeDefault,
            });
          }
          if (data?.planDetails?.planDefault && !this.state.notRiderPlan) {
            this.getPlanInfo(data.planDetails?.planDefault);
            let planDetails = data.planDetails?.planLists.find(
              (item) => item?.planId === data.planDetails?.planDefault
            );
            let planPremiumMode = planDetails.planPremiumMode?.find(
              (item) =>
                item.paymentCode === data?.planDetails?.paymentModeDefault
            );
            let planSelect = {
              productName: data.planDetails?.productName,
              planId: data.planDetails?.planDefault,
              planName: planDetails.planName,
              paymentCode: planPremiumMode?.paymentCode,
              paymentName: data.planDetails?.paymentModeLists?.find(
                (item) => item?.paymentCode === planPremiumMode?.paymentCode
              )?.paymentName,
              sumInsured: planPremiumMode?.sumInsured,
              premium: planPremiumMode?.premium,
              premiumPayment: planPremiumMode?.premiumPayment || 0,
              paymentId: planPremiumMode?.paymentId,
              paymentUnitTitle: planPremiumMode?.paymentUnitTitle,
              insureSelfie: planPremiumMode?.insureSelfie,
              insureRequiredIdentification:
                planPremiumMode?.insureRequiredIdentification,
            };
            MainData.userSelectedData.selectedPlan = planSelect;
            this.getRider(
              data.planDetails?.planDefault,
              planPremiumMode?.paymentCode
            );
            this.setState({ currentPlan: planSelect });
            let temp = _.cloneDeep(planSelect);
            this.setState({ notRiderPlan: temp });
            MainData.userSelectedData.notRiderPlan = temp;
          } else if (
            data?.planDetails?.planDefault &&
            this.state.notRiderPlan
          ) {
            this.getPlanInfo(this.state.notRiderPlan.planId);
            let planDetails = data.planDetails?.planLists.find(
              (item) => item?.planId === this.state.notRiderPlan.planId
            );
            let planPremiumMode = planDetails.planPremiumMode?.find(
              (item) =>
                item.paymentCode === data?.planDetails?.paymentModeDefault
            );
            let planSelect = {
              productName: data.planDetails?.productName,
              planId: this.state.notRiderPlan.planId,
              planName: planDetails.planName,
              paymentCode: planPremiumMode?.paymentCode,
              paymentName: data.planDetails?.paymentModeLists?.find(
                (item) => item?.paymentCode === planPremiumMode?.paymentCode
              )?.paymentName,
              sumInsured: planPremiumMode?.sumInsured,
              premium: planPremiumMode?.premium,
              premiumPayment: planPremiumMode?.premiumPayment || 0,
              paymentId: planPremiumMode?.paymentId,
              paymentUnitTitle: planPremiumMode?.paymentUnitTitle,
              insureSelfie: planPremiumMode?.insureSelfie,
              insureRequiredIdentification:
                planPremiumMode?.insureRequiredIdentification,
            };
            MainData.userSelectedData.selectedPlan = planSelect;
            this.setState({ currentPlan: planSelect });
            let temp = _.cloneDeep(planSelect);
            this.setState({ notRiderPlan: temp });
            MainData.userSelectedData.notRiderPlan = temp;
            this.getRider(
              this.state.notRiderPlan.planId,
              planPremiumMode?.paymentCode
            );
          }
          if (data?.isDisplayType === "SCALE_BAR") {
            let dataPreSlider = data?.planDetails?.planLists.map(
              (plan, index) => ({
                id: index + 1,
                value: plan.planId,
                label: plan.planPremiumMode.find(
                  (item) => item?.paymentCode === "ONE_MONTH"
                )?.premium,
              })
            );

            this.setState({
              dataPreSlider: dataPreSlider,
            });
            let preSliders = _.cloneDeep(dataPreSlider);
            let preSlider;
            if (this.state.notRiderPlan) {
              preSlider = preSliders.find(
                (item) => item.value === this.state.notRiderPlan?.planId
              );
            } else {
              preSlider = preSliders.find(
                (item) => item.value === data.planDetails?.planDefault
              );
            }
            this.setState({
              preSlider: preSlider,
            });
            this.getMasterBenefit(
              this.state.notRiderPlan
                ? this.state.notRiderPlan.planId
                : data?.planDetails?.planDefault
            );
            this.getMappingBenefit(
              this.state.notRiderPlan
                ? this.state.notRiderPlan.planId
                : data?.planDetails?.planDefault
            );
          }

          this.props.setStepSelectPlan(2);
        } else {
          console.log("response.data null");
        }
        this.props.loadController(false);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
        this.setState({ isLoading: false, serviceError: true });
        this.props.loadController(false);
      });
  };

  getPlanInfo = async (planId) => {
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "language-locale": "th",
    };
    let data = {
      productId: MainData.initialData.productId,
      channelCode: MainData.initialData.channelCode,
      planId: planId,
    };
    await axios
      .post(MainData.serviceURL + "/Broker/PlanInfo", data, { headers })
      .then((response) => {
        // handle success
        let data = response.data.data;
        if (data) {
          this.setState({ planInfo: data });
          MainData.dataBroker.planInfo = data;
        }
        this.props.loadController(false);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
        this.setState({ isLoading: false, serviceError: true });
        this.props.loadController(false);
      });
  };

  getMasterBenefit = async (planId) => {
    this.props.loadController(true);

    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "language-locale": "th",
    };
    let data = {
      productId: MainData.initialData.productId,
      channelCode: MainData.initialData.channelCode,
      planId: planId,
    };
    await axios
      .post(MainData.serviceURL + "/Broker/GetMasterBenefit", data, { headers })
      .then((response) => {
        // handle success
        let data = response.data.data;
        if (data?.benefitQuestions) {
          this.setState({ benefitQuestions: data.benefitQuestions });
        }
        this.props.loadController(false);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
        this.setState({ isLoading: false, serviceError: true });
        this.props.loadController(false);
      });
  };

  getMappingBenefit = async (planId) => {
    this.props.loadController(true);

    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "language-locale": "th",
    };
    let data = {
      productId: MainData.initialData.productId,
      channelCode: MainData.initialData.channelCode,
    };
    await axios
      .post(MainData.serviceURL + "/Broker/GetMappingBenefit", data, {
        headers,
      })
      .then((response) => {
        // handle success
        let data = response.data.data;
        if (data?.mappingBenefits) {
          let mappingBenefit = _.cloneDeep(data?.mappingBenefits);
          this.setState({ mappingBenefit: mappingBenefit });

          this.setState({
            benefit: data.mappingBenefits.find((item) => item.planId === planId)
              .benefits,
          });
        }
        this.props.loadController(false);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
        this.setState({ isLoading: false, serviceError: true });
        this.props.loadController(false);
      });
  };

  getRider = async (planId, paymentCode) => {
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "language-locale": "th",
    };
    let data = {
      channelCode: MainData.initialData.channelCode,
      productId: MainData.initialData.productId,
      planId: planId,
      paymentMode: paymentCode ? paymentCode : "TWELVE_MONTHS",
      grantee: "SELF",
      gender: "MALE",
      birthDate: MainFunc.getUserBirthDate("ac"),
      age: moment().diff(MainFunc.getUserBirthDate("ac"), "years"),
    };
    await axios
      .post(MainData.serviceURL + "/Broker/GetRider", data, { headers })
      .then(async (response) => {
        // handle success
        let data = response.data.data;
        if (data) {
          if (!this.state.riderPlan) {
            this.setState({ riderPlan: data });
            MainData.dataBroker.riderPlan = data;
          } else {
            this.state.riderPlan = this.state.riderPlan.map((item, index) => {
              if (item.groupCode === data[index].groupCode) {
                item.groupDescription = data[index].groupDescription;
                item.riderBenefits = data[index].riderBenefits;
                if (this.state.selectedRider?.length === 0) {
                  item.riderSpecials = data[index].riderSpecials;
                }
                item.riderTypeCode = data[index].riderTypeCode;
                item.riderTypeName = data[index].riderTypeName;
              }
              return item;
            });
          }
          data.map(
            (item, index) =>
              item.riderSpecials &&
              this.setState({ riderSpecials: item.riderSpecials })
          );
          if (
            MainData.dataBroker.riderPlan == null ||
            MainData.dataBroker.riderPlan?.length === 0
          ) {
            MainData.dataBroker.riderPlan = data;
          } else {
            MainData.dataBroker.riderPlan = MainData.dataBroker.riderPlan?.map(
              (item, index) => {
                if (item.groupCode === data[index].groupCode) {
                  item.groupDescription = data[index].groupDescription;
                  item.riderBenefits = data[index].riderBenefits;
                  if (this.state.selectedRider?.length === 0) {
                    item.riderSpecials = data[index].riderSpecials;
                  }
                  item.riderTypeCode = data[index].riderTypeCode;
                  item.riderTypeName = data[index].riderTypeName;
                }
                return item;
              }
            );
          }
        } else {
          this.setState({ riderSpecials: null });
          this.setState({ riderPlan: null });
          MainData.dataBroker.riderPlan = null;
        }
        if (this.state.selectedRider.length !== 0) {
          await this.getRiderPremium(this.state.selectedRider, paymentCode);
        }
        this.props.loadController(false);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
        this.setState({ isLoading: false, serviceError: true });
        this.props.loadController(false);
      });
  };

  getRiderPremium = async (riderId, paymentCode) => {
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "language-locale": "th",
    };
    let data = {
      channelCode: MainData.initialData.channelCode,
      planId: this.state.notRiderPlan?.planId,
      productId: MainData.initialData.productId,
      paymentMode: paymentCode
        ? paymentCode
        : this.state.notRiderPlan?.paymentCode,
      paymentDesc: this.state.notRiderPlan?.paymentName,
      birthDate: MainFunc.getUserBirthDate("ac"),
      gender: this.state.gender,
      grantee: "SELF",
      riders: riderId,
    };
    await axios
      .post(MainData.serviceURL + "/Broker/GetRiderPremium", data, { headers })
      .then(async (response) => {
        let data = response.data.data;
        let newRiderSpecials = data.riderGroupSpecials?.find((riderSpecial) =>
          this.state.riderPlan.map(
            (item) => item.groupCode === riderSpecial.riderGroupCode
          )
        );
        if (newRiderSpecials) {
          await this.setState((prevState) => ({
            riderPlan: prevState.riderPlan.map((group) => {
              if (group.groupCode === newRiderSpecials.riderGroupCode) {
                return {
                  ...group,
                  riderSpecials: newRiderSpecials.riderSpecials,
                };
              }
              return group;
            }),
          }));
          MainData.dataBroker.riderPlan = MainData.dataBroker.riderPlan.map(
            (group) => {
              if (group.groupCode === newRiderSpecials.riderGroupCode) {
                return {
                  ...group,
                  riderSpecials: newRiderSpecials.riderSpecials,
                };
              }
            }
          );
        }

        if (data.riderPremium.length !== 0) {
          await this.setState({ dataSelectRiderPremium: data.riderPremium });
          let riderPremium = data.riderPremium.find(
            (riderPremium) =>
              riderPremium.paymentCode ===
              (paymentCode ? paymentCode : this.state.notRiderPlan?.paymentCode)
          );
          MainData.userSelectedData.selectedPlan.planId = data.planId;
          MainData.userSelectedData.selectedPlan.planName = data.planName;
          MainData.userSelectedData.selectedPlan.paymentCode =
            riderPremium.paymentCode;
          MainData.userSelectedData.selectedPlan.paymentName =
            riderPremium.paymentName;
          MainData.userSelectedData.selectedPlan.sumInsured =
            riderPremium.sumInsured;
          MainData.userSelectedData.selectedPlan.premium = riderPremium.premium;
          MainData.userSelectedData.selectedPlan.premiumPayment =
            riderPremium.premiumPayment;

          await this.setState({
            currentPlan: {
              ...this.state.currentPlan,
              planId: data.planId,
              planName: data.planName,
              paymentCode: riderPremium.paymentCode,
              paymentName: riderPremium.paymentName,
              sumInsured: riderPremium.sumInsured,
              premium: riderPremium.premium,
              premiumPayment: riderPremium.premiumPayment,
              paymentId: riderPremium.paymentId,
            },
          });
          await this.setState({ dataSelectRiderPremium: data.riderPremium });
        } else {
          await this.setState({ currentPlan: this.state.notRiderPlan });
        }

        this.props.loadController(false);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
        this.setState({ isLoading: false, serviceError: true });
        this.props.loadController(false);
      });
  };

  getPlanSummary = async () => {
    let benefit = "";
    if (this.state.benefit) {
      this.state.benefit.forEach((item, index) => {
        benefit += `|${this.state.benefitQuestions
          .find((supItem) => supItem.questionCode == item.questionCode)
          .benefitValues.find((supItem) => supItem.valueCode == item.valueCode)
          ?.valueText.replace(/ /g, "_")}`;
      });
    }
    let benefitRider = "";
    if (this.state.selectedRider.length > 0) {
      this.state.selectedRider.forEach((item, index) => {
        benefitRider += benefitRider
          ? `,${this.state.riderPlan[0].riderBenefits
              .find((supItem) => supItem.riderCode == item)
              .riderName?.replace(/ /g, "_")}`
          : `|${this.state.riderPlan[0].riderBenefits
              .find((supItem) => supItem.riderCode == item)
              .riderName?.replace(/ /g, "_")}`;
      });
    }
    if (
      this.state.riderPlan &&
      this.state.riderPlan.length > 0 &&
      this.state.riderPlan[0].riderSpecials &&
      this.state.riderPlan[0].riderSpecials.length > 0
    ) {
      benefitRider += `${
        benefitRider ? "," : "|"
      }${this.state.riderPlan[0].riderSpecials[0].riderSpecialName?.replace(
        / /g,
        "_"
      )}`;
    }
    MainFunc.sendDataToGTMKbank({
      event: "track_event",
      event_category: `${MainData.initialData.productId}`,
      event_action: "ins_form_step2",
      event_label: `select_${this.state.currentPlan.premium}${benefit}${benefitRider}|${this.state.currentPlan.paymentName}`,
    });
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "language-locale": "th",
    };
    let data = {
      productId: MainData.initialData.productId,
      planIdFinal: this.state.currentPlan?.planId,
      channelCode: MainData.initialData.channelCode,
      paymentMode: MainData.userSelectedData.selectedPlan?.paymentCode,
      grantee: "SELF",
      gender: this.state.gender,
      birthDate: MainFunc.getUserBirthDate("ac"),
      age: moment().diff(MainFunc.getUserBirthDate("ac"), "years"),
      riders: this.state.selectedRider,
    };
    await axios
      .post(MainData.serviceURL + "/Broker/PlanSummary", data, { headers })
      .then((response) => {
        let data = response.data.data;
        if (data) {
          this.setState({ planSumary: data });
          this.setState({ stepSelectPan: 2 });
          this.props.setStepSelectPlan(3);
          this.props.activeCompornent("planselect");
        } else if (data === null) {
          this.setState({ planSumary: null });
          this.setState({ stepSelectPan: 2 });
          this.props.setStepSelectPlan(3);
          this.props.activeCompornent("planselect");
        }
        this.props.loadController(false);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
        this.setState({ isLoading: false, serviceError: true });
        this.props.loadController(false);
      });
  };

  processForNextPage = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if (statusSystemConfig) {
      window.location.href = MainData.mainPath + "shutdown";
      return;
    } else {
      this.props.loadController(false);
    }
    var getDate = this.state.birthdate.split("/");
    var nowDate = moment(
      [
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate(),
      ],
      "YYYY-MM-DDTHH:mm:ssZ"
    );
    var birthDate = moment(
      [parseInt(getDate[2]) - 543, parseInt(getDate[1]), parseInt(getDate[0])],
      "YYYY-MM-DDTHH:mm:ssZ"
    ).format();
    var birthDateYears = moment(
      [parseInt(getDate[2]) - 543],
      "YYYY-MM-DDTHH:mm:ssZ"
    );
    var diffYearsMin = nowDate.diff(birthDate, "years");
    var diffYearsMax = nowDate.diff(birthDateYears, "years");
    var diffMonths = nowDate.diff(birthDate, "months");
    var diffDays = nowDate.diff(birthDate, "days");
    var userAgeMin;
    var userAgeMax;

    switch (this.state.checkAgeMinUnit) {
      case "M":
        userAgeMin = diffMonths;
        break;
      case "D":
        userAgeMin = diffDays;
        break;
      default:
        userAgeMin = diffYearsMin;
    }
    switch (this.state.checkAgeMaxUnit) {
      case "M":
        userAgeMax = diffMonths;
        break;
      case "D":
        userAgeMax = diffDays;
        break;
      default:
        userAgeMax = diffYearsMax;
    }

    if (!this.state.gender || !this.state.birthdate) {
      this.setState({ validate: true });
      let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG002");
      this.props.errorAlert(
        true,
        !errMsg ? "กรุณาระบุข้อมูล" : errMsg.msgDescription,
        " "
      );
      if (
        MainData.screenSM001.birthdatePDPA ||
        MainData.screenSM001.genderPDPA ||
        MainData.screenSM001.birthdate ||
        MainData.screenSM001.gender
      ) {
        MainFunc.logPDPA(
          "Edit",
          this.props.mainMenuName,
          this.props.name,
          null,
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(
              MainData.screenSM001.birthdatePDPA
                ? MainData.screenSM001.birthdatePDPA
                : MainData.screenSM001.birthdate
            ),
            gender: MainData.screenSM001.genderPDPA
              ? MainData.screenSM001.genderPDPA
              : MainData.screenSM001.gender,
            grantee: "SELF",
          },
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(this.state.birthdate),
            gender: this.state.gender,
            grantee: "SELF",
          },
          "Fail",
          errMsg.msgDescription
        );
      } else {
        MainFunc.logPDPA(
          "Add",
          this.props.mainMenuName,
          this.props.name,
          null,
          null,
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(this.state.birthdate),
            gender: this.state.gender,
            grantee: "SELF",
          },
          "Fail",
          "กรุณาระบุข้อมูล"
        );
      }
      MainData.screenSM001.birthdatePDPA = this.state.birthdate;
      MainData.screenSM001.genderPDPA = this.state.gender;
    } else if (
      this.state.birthdate.length < 10 ||
      parseInt(getDate[1]) > 12 ||
      parseInt(getDate[0]) > 31
    ) {
      this.setState({
        validate: true,
        failAgeRange: true,
        failAgeRangeMsg: this.textfailAgeRange(true),
        premiumCalStatus: false,
      });
      let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG019");
      if (
        MainData.screenSM001.birthdatePDPA ||
        MainData.screenSM001.genderPDPA ||
        MainData.screenSM001.birthdate ||
        MainData.screenSM001.gender
      ) {
        MainFunc.logPDPA(
          "Edit",
          this.props.mainMenuName,
          this.props.name,
          null,
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(
              MainData.screenSM001.birthdatePDPA
                ? MainData.screenSM001.birthdatePDPA
                : MainData.screenSM001.birthdate
            ),
            gender: MainData.screenSM001.genderPDPA
              ? MainData.screenSM001.genderPDPA
              : MainData.screenSM001.gender,
            grantee: "SELF",
          },
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(this.state.birthdate),
            gender: this.state.gender,
            grantee: "SELF",
          },
          "Fail",
          errMsg.msgDescription
        );
      } else {
        MainFunc.logPDPA(
          "Add",
          this.props.mainMenuName,
          this.props.name,
          null,
          null,
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(this.state.birthdate),
            gender: this.state.gender,
            grantee: "SELF",
          },
          "Fail",
          "ขออภัย ท่านกรอกข้อมูลไม่ถูกต้อง"
        );
      }
      MainData.screenSM001.birthdatePDPA = this.state.birthdate;
      MainData.screenSM001.genderPDPA = this.state.gender;
    } else if (
      userAgeMin < this.state.checkAgeMin ||
      userAgeMax > this.state.checkAgeMax
    ) {
      if (
        MainData.screenSM001.birthdatePDPA ||
        MainData.screenSM001.genderPDPA ||
        MainData.screenSM001.birthdate ||
        MainData.screenSM001.gender
      ) {
        MainFunc.logPDPA(
          "Edit",
          this.props.mainMenuName,
          this.props.name,
          null,
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(
              MainData.screenSM001.birthdatePDPA
                ? MainData.screenSM001.birthdatePDPA
                : MainData.screenSM001.birthdate
            ),
            gender: MainData.screenSM001.genderPDPA
              ? MainData.screenSM001.genderPDPA
              : MainData.screenSM001.gender,
            grantee: "SELF",
          },
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(this.state.birthdate),
            gender: this.state.gender,
            grantee: "SELF",
          },
          "Fail",
          "รับประกันอายุระหว่าง 21 ปี - 60 ปี"
        );
      } else {
        MainFunc.logPDPA(
          "Add",
          this.props.mainMenuName,
          this.props.name,
          null,
          null,
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(this.state.birthdate),
            gender: this.state.gender,
            grantee: "SELF",
          },
          "Fail",
          "รับประกันอายุระหว่าง 21 ปี - 60 ปี"
        );
      }
      this.setState({
        validate: true,
        failAgeRange: true,
        failAgeRangeMsg: this.textfailAgeRange(),
        premiumCalStatus: false,
      });
      MainData.screenSM001.birthdatePDPA = this.state.birthdate;
      MainData.screenSM001.genderPDPA = this.state.gender;
    } else if (
      userAgeMin >= this.state.checkAgeMin ||
      userAgeMax <= this.state.checkAgeMax
    ) {
      if (
        (this.props.checkCookie === true &&
          !sessionStorage.getItem("_cookie")) ||
        sessionStorage.getItem("_cookie") === "close"
      ) {
        if (this.props.screenIndex === 0) {
          this.props.setHeaderText(MainData.productTitle, "", "", true);
        }
        if (
          MainData.screenSM001.birthdatePDPA ||
          MainData.screenSM001.genderPDPA
        ) {
          MainFunc.logPDPA(
            "Edit",
            this.props.mainMenuName,
            this.props.name,
            null,
            {
              channelCode: MainData.initialData.channelCode,
              productId: MainData.initialData.productId,
              birthDate: this.convertBirthDate(
                MainData.screenSM001.birthdatePDPA
              ),
              gender: MainData.screenSM001.genderPDPA,
              grantee: "SELF",
            },
            {
              channelCode: MainData.initialData.channelCode,
              productId: MainData.initialData.productId,
              birthDate: this.convertBirthDate(this.state.birthdate),
              gender: this.state.gender,
              grantee: "SELF",
            },
            "Success",
            null
          );
        } else if (
          MainData.screenSM001.birthdate ||
          MainData.screenSM001.gender
        ) {
          MainFunc.logPDPA(
            "Edit",
            this.props.mainMenuName,
            this.props.name,
            null,
            {
              channelCode: MainData.initialData.channelCode,
              productId: MainData.initialData.productId,
              birthDate: this.convertBirthDate(MainData.screenSM001.birthdate),
              gender: MainData.screenSM001.gender,
              grantee: "SELF",
            },
            {
              channelCode: MainData.initialData.channelCode,
              productId: MainData.initialData.productId,
              birthDate: this.convertBirthDate(this.state.birthdate),
              gender: this.state.gender,
              grantee: "SELF",
            },
            "Success",
            null
          );
        } else {
          MainFunc.logPDPA(
            "Add",
            this.props.mainMenuName,
            this.props.name,
            null,
            null,
            {
              channelCode: MainData.initialData.channelCode,
              productId: MainData.initialData.productId,
              birthDate: this.convertBirthDate(this.state.birthdate),
              gender: this.state.gender,
              grantee: "SELF",
            },
            "Success",
            null
          );
        }
        MainData.screenSM001.birthdatePDPA = "";
        MainData.screenSM001.genderPDPA = null;
        MainData.screenSM001.birthdate = this.state.birthdate;
        MainData.screenSM001.gender = this.state.gender;
        MainData.screenSM001.screen = this.state.screen;
        MainData.screenSM001.WindowLocationHref = window.location.href;
        this.onProcessData();
      } else {
        if (this.props.screenIndex === 0) {
          this.props.setHeaderText(MainData.productTitle, "", "", true);
        }
        if (
          MainData.screenSM001.birthdatePDPA ||
          MainData.screenSM001.genderPDPA
        ) {
          MainFunc.logPDPA(
            "Edit",
            this.props.mainMenuName,
            this.props.name,
            null,
            {
              channelCode: MainData.initialData.channelCode,
              productId: MainData.initialData.productId,
              birthDate: this.convertBirthDate(
                MainData.screenSM001.birthdatePDPA
              ),
              gender: MainData.screenSM001.genderPDPA,
              grantee: "SELF",
            },
            {
              channelCode: MainData.initialData.channelCode,
              productId: MainData.initialData.productId,
              birthDate: this.convertBirthDate(this.state.birthdate),
              gender: this.state.gender,
              grantee: "SELF",
            },
            "Success",
            null
          );
        } else if (
          MainData.screenSM001.birthdate ||
          MainData.screenSM001.gender
        ) {
          MainFunc.logPDPA(
            "Edit",
            this.props.mainMenuName,
            this.props.name,
            null,
            {
              channelCode: MainData.initialData.channelCode,
              productId: MainData.initialData.productId,
              birthDate: this.convertBirthDate(MainData.screenSM001.birthdate),
              gender: MainData.screenSM001.gender,
              grantee: "SELF",
            },
            {
              channelCode: MainData.initialData.channelCode,
              productId: MainData.initialData.productId,
              birthDate: this.convertBirthDate(this.state.birthdate),
              gender: this.state.gender,
              grantee: "SELF",
            },
            "Success",
            null
          );
        } else {
          MainFunc.logPDPA(
            "Add",
            this.props.mainMenuName,
            this.props.name,
            null,
            null,
            {
              channelCode: MainData.initialData.channelCode,
              productId: MainData.initialData.productId,
              birthDate: this.convertBirthDate(this.state.birthdate),
              gender: this.state.gender,
              grantee: "SELF",
            },
            "Success",
            null
          );
        }
        MainData.screenSM001.birthdatePDPA = "";
        MainData.screenSM001.genderPDPA = null;
        MainData.screenSM001.birthdate = this.state.birthdate;
        MainData.screenSM001.gender = this.state.gender;
        MainData.screenSM001.screen = this.state.screen;
        MainData.screenSM001.WindowLocationHref = window.location.href;
        MainFunc.sendDataToGTMKbank({
          event: "track_event",
          event_category: `${MainData.initialData.productId}`,
          event_action: "ins_form_step1",
          event_label: `select_${moment().diff(
            MainFunc.getUserBirthDate("ac"),
            "years"
          )}|${this.state.gender.slice(0, 1)}`,
        });
        this.addOrUpdateCustomerBirthday();
      }
    }
  };

  addOrUpdateCustomerBirthday = () => {
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      AuthId: !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId,
    };
    const data = {
      channelCode: MainData.initialData.channelCode,
      productId: MainData.initialData.productId,
      gender: this.state.gender,
      birthDate: MainFunc.getUserBirthDate("ac"),
      age: moment().diff(MainFunc.getUserBirthDate("ac"), "years"),
      transUrl: window.location.href,
      submenuCode: this.state.screen,
    };
    axios
      .post(
        MainData.serviceURL + "/Customer/AddOrUpdateCustomerBirthday",
        data,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        if (res.data.success === true) {
          MainData.screenSM005.cusId = res.data.data;
          this.props.loadController(false);
          this.onProcessData();
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          this.props.loadController(false);
          this.validateBadRequest(error.response.data.errors);
        } else {
          this.props.loadController(false);
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.props.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
        }
      });
  };

  validateBadRequest = (fieldNameList) => {
    if (
      fieldNameList.find((data) => data.field == "birthDate") ||
      fieldNameList.find((data) => data.field == "age")
    ) {
      if (
        MainData.screenSM001.birthdatePDPA ||
        MainData.screenSM001.genderPDPA ||
        MainData.screenSM001.birthdate ||
        MainData.screenSM001.gender
      ) {
        MainFunc.logPDPA(
          "Edit",
          this.props.mainMenuName,
          this.props.name,
          null,
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(
              MainData.screenSM001.birthdatePDPA
                ? MainData.screenSM001.birthdatePDPA
                : MainData.screenSM001.birthdate
            ),
            gender: MainData.screenSM001.genderPDPA
              ? MainData.screenSM001.genderPDPA
              : MainData.screenSM001.gender,
            grantee: "SELF",
          },
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(this.state.birthdate),
            gender: this.state.gender,
            grantee: "SELF",
          },
          "Fail",
          "รับประกันอายุระหว่าง 21 ปี - 60 ปี"
        );
      } else {
        MainFunc.logPDPA(
          "Add",
          this.props.mainMenuName,
          this.props.name,
          null,
          null,
          {
            channelCode: MainData.initialData.channelCode,
            productId: MainData.initialData.productId,
            birthDate: this.convertBirthDate(this.state.birthdate),
            gender: this.state.gender,
            grantee: "SELF",
          },
          "Fail",
          "รับประกันอายุระหว่าง 21 ปี - 60 ปี"
        );
      }
      this.setState({
        validate: true,
        failAgeRange: true,
        failAgeRangeMsg: this.textfailAgeRange(true),
        premiumCalStatus: false,
      });
      MainData.screenSM001.birthdatePDPA = this.state.birthdate;
      MainData.screenSM001.genderPDPA = this.state.gender;
    } else if (fieldNameList.find((data) => data.field == "gender")) {
      let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG019");
      this.props.errorAlert(
        true,
        !errMsg ? "ขออภัย ท่านกรอกข้อมูลไม่ถูกต้อง" : errMsg.msgDescription,
        " "
      );
      this.setState({ gender: "" });
    }
  };

  nextPage = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if (statusSystemConfig) {
      window.location.href = MainData.mainPath + "shutdown";
      return;
    } else {
      this.props.loadController(false);
    }
    MainFunc.sendDataToGTM(
      `${MainData.initialData.codeTracking}STEP1`,
      "Click",
      `${MainData.initialData.codeTracking}STEP1_Click_${this.state.selectedPlanId}_BtnBuy`
    );

    if (
      MainData.screenSM002.planId &&
      MainData.screenSM002.cost &&
      MainData.screenSM002.paymentName
    ) {
      MainFunc.logPDPA(
        "Edit",
        this.props.mainMenuName,
        this.props.name,
        null,
        {
          planPremiumCode: MainData.screenSM002.paymentCode,
          planId: MainData.screenSM002.planId,
          paymentName: MainData.screenSM002.paymentName,
          premium: MainData.screenSM002.cost,
          planName: MainData.screenSM002.planName,
          premiumPayment: "",
        },
        {
          planPremiumCode: this.state.selectedPlanCode,
          planId: this.state.selectedPlanId,
          paymentName: this.state.paymentName,
          premium: this.state.cost,
          planName: this.state.planName,
          premiumPayment: "",
        },
        "Success",
        null
      );
    } else {
      MainFunc.logPDPA(
        "Add",
        this.props.mainMenuName,
        this.props.name,
        null,
        null,
        {
          planPremiumCode: this.state.selectedPlanCode,
          planId: this.state.selectedPlanId,
          paymentName: this.state.paymentName,
          premium: this.state.cost,
          planName: this.state.planName,
          premiumPayment: "",
        },
        "Success",
        null
      );
    }

    MainData.screenSM002.cost = MainData.userSelectedData.selectedPlan.premium;
    MainData.screenSM002.costPayment =
      MainData.userSelectedData.selectedPlan.premiumPayment;
    MainData.screenSM002.planName =
      MainData.userSelectedData.selectedPlan.planName;
    MainData.screenSM002.planId = MainData.userSelectedData.selectedPlan.planId;
    MainData.screenSM002.productName =
      MainData.userSelectedData.selectedPlan.productName;
    MainData.screenSM002.paymentName =
      MainData.userSelectedData.selectedPlan.paymentName;
    MainData.screenSM002.paymentCode =
      MainData.userSelectedData.selectedPlan.paymentCode;
    MainData.screenSM002.sumInsured =
      MainData.userSelectedData.selectedPlan.sumInsured;
    MainData.screenSM002.paymentId =
      MainData.userSelectedData.selectedPlan.paymentId;
    MainData.screenSM002.insureRequiredIdentification =
      MainData.userSelectedData.selectedPlan.insureRequiredIdentification;
    MainData.screenSM002.insureSelfie =
      MainData.userSelectedData.selectedPlan.insureSelfie;
    MainData.screenSM002.screen = "SM002";
    MainData.screenSM002.WindowLocationHref = window.location.href;

    let subHeader = MainFunc.getLabelDtos("L0112") + " : ";
    this.props.setHeaderText(
      MainData.productTitle,
      subHeader +
        MainFunc.numberWithCommas(this.state.cost) +
        " " +
        MainFunc.getLabelDtos("L0104"),
      this.state.planName,
      true
    );
    if (sessionStorage.getItem("_cookie") === "true") {
      this.saveDataProduct();
    }
    if (!MainData.screenMenuKPlus.onKPlus) {
      this.props.showKplus();
      document
        .getElementById("navigation-header")
        .classList.remove("activeNavBroker");
      setTimeout(() => {
        this.props.scrollContentSectionTop();
      }, 100);
    }
    this.props.nextPage();
    document
      .getElementById("navigation-header")
      .classList.remove("activeNavBroker");
  };

  saveDataProduct = () => {
    this.props.loadController(true);
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      AuthId: MainData.screenSM005.cusId,
    };
    const data = {
      planId: MainData.userSelectedData.selectedPlan.planId,
      premium: MainData.userSelectedData.selectedPlan.premium,
      paymentId: MainData.userSelectedData.selectedPlan.paymentId,
      paymentMode: MainData.userSelectedData.selectedPlan.paymentCode,
      submenuCode: "SM002",
    };
    axios
      .post(
        MainData.serviceURL + "/Customer/AddOrUpdateCustomerPremiumCal",
        data,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        if (res.data.success === true) {
          MainData.screenSM005.cusId = res.data.data;
          this.props.loadController(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          this.props.loadController(false);
          this.validateBadRequest(error.response.data.errors);
        } else {
          this.props.loadController(false);
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.props.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription.replace(
                  "{{Service}}",
                  error.response.data.data?.messageDescription
                ),
            " "
          );
        }
      });
  };

  onClickDate = (value) => {
    this.setState({
      planSelects: null,
      premiumCalStatus: false,
      birthdate: value,
      failAgeRange: false,
      failAgeRangeMsg: this.textfailAgeRange(true),
    });
    MainData.userSelectedData.selectedPlan = null;
    this.props.setStepSelectPlan(1);
    if (this.state.gender && value.length == 10) {
      this.setState({ premiumCalStatus: true });
    }
  };
  render() {
    return (
      <Fragment>
        <div
          className={`${styles.planSelectMain} ${
            this.state.stepSelectPan === 1 ? "d-block" : "d-none"
          }`}
        >
          <div
            className="container-lg"
            style={{ textAlign: "-webkit-center", maxWidth: "960px" }}
          >
            <div className={styles.planSelect}>
              <div className={styles.scHeading}>
                <h2 className={styles.title}>
                  {MainFunc.getLabelDtos("L0755")
                    ? MainFunc.getLabelDtos("L0755")
                    : "เลือกความคุ้มครองที่เหมาะกับคุณ"}
                </h2>
              </div>
              <Row className={styles.content}>
                <Row>
                  <Col md={6}>
                    {this.props.channel?.css && (
                      <PreDatePicker
                        id="birthDate"
                        type="text"
                        className="Mandatory placeholder"
                        name="birthDate"
                        placeholder={
                          MainFunc.getLabelDtos(
                            "L0756"
                          ) /*ระบุวัน/เดือน/พ.ศ.เกิด (ตามบัตรประชาชน)*/ + "*"
                        }
                        tabindex="1"
                        maxlength="10"
                        label={
                          MainFunc.getLabelDtos(
                            "L0756"
                          ) /*ระบุวัน/เดือน/พ.ศ.เกิด (ตามบัตรประชาชน)*/
                        }
                        helpText={this.state.failAgeRangeMsg}
                        value={this.state.birthdate}
                        onClickDatePicker={(value) => this.onClickDate(value)}
                        onChange={(e) => this.handleChange(e)}
                        error={!this.state.failAgeRange ? false : true}
                        selectPlan
                        required
                        max={this.state.checkAgeMax}
                        min={this.state.checkAgeMin}
                      />
                    )}
                  </Col>
                  <Col md={5} className={styles.gender}>
                    <PreRadio
                      id="gender"
                      type="text"
                      className="Mandatory placeholder"
                      name="gender"
                      placeholder={
                        MainFunc.getLabelDtos("L0757") /*กรุณาเลือกเพศของคุณ*/ +
                        "*"
                      }
                      label={
                        MainFunc.getLabelDtos("L0757") /*กรุณาเลือกเพศของคุณ*/
                      }
                      helpText={
                        MainFunc.getLabelDtos("L0757") /*กรุณาเลือกเพศของคุณ*/ +
                        "*"
                      }
                      value={this.state.gender}
                      onChange={(e) => this.handleChange(e)}
                      required
                    />
                  </Col>
                </Row>
              </Row>
              {!this.state.planSelects && (
                <div className={styles.btnPremium}>
                  <button
                    onClick={() => this.processForNextPage()}
                    className={styles.btnInsure}
                    disabled={!this.state.premiumCalStatus}
                    title="คำนวณแผนประกัน"
                  >
                    {MainFunc.getLabelDtos("L0758") /*คำนวณแผนประกัน*/}
                  </button>
                </div>
              )}
              {this.state.planSelects?.isDisplayType === "SCALE_BAR" && (
                <Row className={styles.content}>
                  <div className="container p-0" style={{ maxWidth: "960px" }}>
                    <Col md={10} style={{ paddingLeft: 0, paddingRight: 0 }}>
                      {this.state.dataPreSlider && (
                        <Fragment>
                          <div className={styles.preSliderLabel}>
                            {
                              MainFunc.getLabelDtos(
                                "L0759"
                              ) /*ค่าเบี้ยต่อเดือน*/
                            }
                            <span className={styles.premium}>
                              {" "}
                              {MainFunc.numberWithCommas(
                                this.state.preSlider?.label
                              ) +
                                " " +
                                MainFunc.getLabelDtos("L0104")}
                            </span>
                          </div>
                          <PreSlider
                            data={this.state.dataPreSlider.sort(
                              (a, b) => a.label - b.label
                            )}
                            onChange={(value) => {
                              this.selectSliderPlan(value);
                            }}
                            defaultIndex={0}
                            step={1}
                            value={this.state.preSlider}
                            parameterValue={"id"}
                            parameterLabel={"label"}
                          />
                        </Fragment>
                      )}
                    </Col>
                    {this.state.benefitQuestions !== null &&
                      this.state.benefitQuestions !== undefined &&
                      this.state.benefitQuestions.length !== 0 &&
                      this.state.benefitQuestions.map((item, index) => (
                        <Col className="p-0">
                          {this.state.benefit?.find(
                            (benefit) =>
                              benefit.questionCode === item.questionCode
                          ) && (
                            <PreSelectOnlyOne
                              id={item.questionCode}
                              type="text"
                              className="Mandatory placeholder"
                              name={item.questionCode}
                              label={item.questionHeader}
                              select={item?.benefitValues}
                              value={
                                this.state.benefit?.find(
                                  (benefit) =>
                                    benefit.questionCode === item.questionCode
                                ).valueCode
                              }
                              onChange={(value) =>
                                this.selectBenefit(item.questionCode, value)
                              }
                              questionTitle={item?.questionTitle || ""}
                              required
                            />
                          )}
                        </Col>
                      ))}
                  </div>
                </Row>
              )}
            </div>
          </div>
          {this.state.planSelects && (
            <div className="container p-0" style={{ maxWidth: "960px" }}>
              <div className={styles.cardPlan}>
                {this.state.planSelects?.isDisplayType === "CARD_PLAN" && (
                  <Row className={styles.content}>
                    <>
                      {this.state?.paymentMode && (
                        <Col className="p-0">
                          <PreSelectOnlyOne
                            id="paymentMode"
                            type="text"
                            className="Mandatory placeholder"
                            name="paymentMode"
                            label={MainFunc.getLabelDtos("L0760") /*แผนประกัน*/}
                            select={
                              this.state.planSelects?.planDetails
                                ?.paymentModeLists
                            }
                            premiumSave={
                              this.state.planSelects?.planDetails?.premiumSave
                            }
                            value={this.state?.paymentMode}
                            onChange={(value) => this.selectPaymentMode(value)}
                            paymentMode={true}
                            required
                          />
                        </Col>
                      )}

                      {this.state?.notRiderPlan?.planId && (
                        <Col className="p-0">
                          <PreSelectOnlyOne
                            id="selectPlan"
                            type="text"
                            className="Mandatory placeholder"
                            name="selectPlan"
                            label={MainFunc.getLabelDtos("L0760") /*ค่าเบี้ย*/}
                            select={
                              this.state.planSelects?.planDetails?.planLists
                            }
                            planPaymentMode={
                              this.state?.planSelects?.paymentModeDefault
                            }
                            value={this.state?.notRiderPlan?.planId}
                            onChange={(value) => this.selectPlan(value)}
                            required
                          />
                        </Col>
                      )}
                    </>
                  </Row>
                )}

                {this.state.riderPlan &&
                  this.state.riderPlan?.map((item, index) => (
                    <Row>
                      {/* riderPlan */}
                      <Col>
                        <PreSelectMuti
                          id="selectRider"
                          type="text"
                          className="Mandatory placeholder"
                          name="selectRider"
                          label={item.groupDescription}
                          select={item}
                          riderValue={this.state?.selectedRider}
                          onChange={(value) => this.selectRider(value)}
                          required
                        />
                      </Col>
                    </Row>
                  ))}
                {this.state.planSelects?.isDisplayType === "SCALE_BAR" && (
                  <Col className="p-0">
                    {this.state?.paymentMode && (
                      <PreSelectOnlyOne
                        id="selectPlan"
                        type="text"
                        className="Mandatory placeholder"
                        name="selectPlan"
                        label={
                          MainFunc.getLabelDtos("L0761") /*เลือกงวดการชำระ*/
                        }
                        select={
                          this.state.planSelects?.planDetails?.paymentModeLists
                        }
                        premiumSave={
                          this.state.planSelects?.planDetails?.premiumSave
                        }
                        value={this.state?.paymentMode}
                        premium={
                          this.state.dataSelectRiderPremium
                            ? this.state.dataSelectRiderPremium
                            : this.state.planSelects?.planDetails?.planLists?.find(
                                (item) =>
                                  item?.planId ===
                                  MainData.userSelectedData?.selectedPlan
                                    ?.planId
                              )?.planPremiumMode
                        }
                        onChange={(value) => this.selectPaymentMode(value)}
                        paymentMode={true}
                        required
                      />
                    )}
                  </Col>
                )}
              </div>
            </div>
          )}
          {this.state.planSelects?.isDisplayType === "CARD_PLAN" &&
            this.state.planInfo && (
              <div className="container p-0" style={{ maxWidth: "960px" }}>
                <div className={styles.planSelected}>
                  <PlanSelected
                    data={this.state.planInfo}
                    productName={
                      this.state.planSelects?.planDetails?.productName
                    }
                    planSelected={this.state.planSelects?.planDetails?.planLists.find(
                      (item) => item?.planId === this.state.currentPlan
                    )}
                  />
                </div>
              </div>
            )}
          {this.state.planSelects && (
            <div className="container">
              <div className={styles.btnPremium}>
                <a
                  onClick={() => this.getPlanSummary()}
                  className={styles.btnInsure}
                  title="ดูสรุปข้อมูลแผนประกันของคุณ"
                >
                  {
                    MainFunc.getLabelDtos(
                      "L0763"
                    ) /*ดูสรุปข้อมูลแผนประกันของคุณ*/
                  }
                </a>
                <a
                  onClick={() => {
                    this.props.activeCompornent(
                      this.props.isDropLead ? "isDropLead" : "consultExpert"
                    );
                    MainFunc.sendDataToGTMKbank({
                      event: "track_event",
                      event_category: "link_click",
                      event_action: "click",
                      event_label: "ปรึกษาผู้เชี่ยวชาญ",
                    });
                  }}
                  className={styles.btnInsureSecond}
                  title="ปรึกษาผู้เชี่ยวชาญ"
                >
                  {MainFunc.getLabelDtos("L0764") /*ปรึกษาผู้เชี่ยวชาญ*/}
                </a>
              </div>
            </div>
          )}
        </div>
        {this.state.planSelects && this.state.stepSelectPan === 1 ? (
          <div className={styles.summaryBar}>
            <SummaryBar
              productName={this.state.planSelects?.planDetails?.productName}
              data={this.state.planSelects?.planDetails?.planLists.find(
                (item) => item?.planId === this.state.currentPlan
              )}
            />
          </div>
        ) : (
          ""
        )}
        <div
          className={`${styles.planSelectMain} ${
            this.state.stepSelectPan === 2 ? "d-block" : "d-none"
          }`}
        >
          {this.state.planSumary && (
            <SummaryPlanSelect
              backToSelectPlan={() => {
                this.setState({ stepSelectPan: 1 });
                this.props.setStepSelectPlan(2);
                MainFunc.sendDataToGTMKbank({
                  event: "track_event",
                  event_category: "link_click",
                  event_action: "click",
                  event_label: "คำนวณเบี้ยใหม่",
                });
              }}
              data={this.state.planSumary}
              notRiderPlan={this.state.notRiderPlan}
              selectedPlan={MainData.userSelectedData.selectedPlan}
              productName={this.state.planSelects?.planDetails?.productName}
              nextPage={() => this.nextPage()}
              activeCompornent={() => {
                this.props.activeCompornent(
                  this.props.isDropLead ? "isDropLead" : "consultExpert"
                );
                MainFunc.sendDataToGTMKbank({
                  event: "track_event",
                  event_category: "link_click",
                  event_action: "click",
                  event_label: "ขอคำอธิบายเพิ่มเติม",
                });
              }}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default Planselect;
