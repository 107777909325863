import React, { Component, Fragment } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Col } from "react-bootstrap";

class FourSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(){

  }


  render(){
    return (
      <Fragment>
        <Col lg={4} sm={6} xs={12} style={{ marginBottom: "30px" }}>
          <Skeleton variant="rect" height={220} />
          <Skeleton variant="text" width={220} style={{ marginTop: "25px" }} />
          <Skeleton variant="text" width={220} style={{ marginBottom: "18px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "24px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
          </div>
        </Col>
        <Col>
          <Skeleton variant="rect" height={220} />
          <Skeleton variant="text" width={220} style={{ marginTop: "25px" }} />
          <Skeleton variant="text" width={220} style={{ marginBottom: "18px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "24px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
          </div>
        </Col>
        <Col>
          <Skeleton variant="rect" height={220} />
          <Skeleton variant="text" width={220} style={{ marginTop: "25px" }} />
          <Skeleton variant="text" width={220} style={{ marginBottom: "18px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "24px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
          </div>
        </Col>
        <Col xl={4} lg={6} sm={12} style={{ marginBottom: "30px" }}>
          <Skeleton variant="rect" height={220} />
          <Skeleton variant="text" width={220} style={{ marginTop: "25px" }} />
          <Skeleton variant="text" width={220} style={{ marginBottom: "18px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "24px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
          </div>
        </Col>
        <Col>
          <Skeleton variant="rect" height={220} />
          <Skeleton variant="text" width={220} style={{ marginTop: "25px" }} />
          <Skeleton variant="text" width={220} style={{ marginBottom: "18px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "24px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
          </div>
        </Col>
        <Col>
          <Skeleton variant="rect" height={220} />
          <Skeleton variant="text" width={220} style={{ marginTop: "25px" }} />
          <Skeleton variant="text" width={220} style={{ marginBottom: "18px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "4px" }} />
          <Skeleton variant="text" style={{ marginBottom: "24px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
            <Skeleton
              variant="rect"
              width={"48%"}
              height={40}
              style={{ marginTop: "5px" }}
            />
          </div>
        </Col>
      </Fragment>
    )
  }
}

export default FourSteps;