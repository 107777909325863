// "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BORDER_RADIUS_3 = exports.BORDER_RADIUS_2 = exports.BORDER_RADIUS_1 = void 0;
var BORDER_RADIUS_1 = '0.875rem';
exports.BORDER_RADIUS_1 = BORDER_RADIUS_1;
var BORDER_RADIUS_2 = '12rem';
exports.BORDER_RADIUS_2 = BORDER_RADIUS_2;
var BORDER_RADIUS_3 = '0.4rem';
exports.BORDER_RADIUS_3 = BORDER_RADIUS_3;