import React, { Component } from "react";
import styles from "../../styles/consult-expert.module.scss";
import IconLine from "../../images/icon_line.svg";
import MainFunc from "../../models/MainFunc";
class ConsultExpert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      message: "",
    };
  }

  render() {
    return (
      <div className={styles.consultExpert}>
        <div className="container">
          <div className={styles.scHeading}>
            <div className={styles.h1}>
              {MainFunc.getLabelDtos("L0781") /*ปรึกษาผู้เชี่ยวชาญทันที*/}
            </div>
          </div>
          <div className={styles.consultCard}>
            <div className={styles.cardContainer}>
              <div className={styles.cardContent}>
                <div>
                  <div className={styles.headding}>
                    {MainFunc.getLabelDtos("L0790") /*แชทกับผู้เชี่ยวชาญผ่าน*/}{" "}
                    <br />
                    {MainFunc.getLabelDtos("L0791") /*LINE KBank Live*/}
                    <img src={IconLine} alt="" />
                  </div>
                </div>
                <div>
                  <a
                    href={this.props.contactWidget?.contactKbankLive}
                    class={styles.btnInsure}
                    title="เพิ่มเพื่อนเลย"
                    target="_blank"
                    onClick={() => {
                      MainFunc.sendDataToGTMKbank({
                        event: "track_event",
                        event_category: "link_click",
                        event_action: "click",
                        event_label: "เพิ่มเพื่อนเลย",
                      });
                    }}
                  >
                    {MainFunc.getLabelDtos("L0792") /*แชทเลย*/}
                  </a>
                </div>
              </div>
              <div className={styles.bgImage}>
                <img src={this.props.contactImg} alt="" />
              </div>
            </div>
            <div className={styles.cardContainer}>
              <div className={styles.cardContent}>
                <div>
                  <div className={styles.headding}>
                    {MainFunc.getLabelDtos("L0793") /*คุยกับผู้เชี่ยวชาญ*/}
                  </div>
                  <p className={styles.desc}>
                    {MainFunc.getLabelDtos("L0815") /*โทร*/}
                    {MainFunc.getLabelDtos("L0783") /*02-8888888 กด 862*/}{" "}
                    <br />
                    {MainFunc.getLabelDtos("L0794") /* ทุกวัน ตลอด 24 ชั่วโมง*/}
                  </p>
                </div>
                <div>
                  <a
                    href={`tel:${this.props.contactWidget?.contactPhoneNumber}`}
                    className={styles.btnInsure}
                    title="ติดต่อเลย"
                    target="_blank"
                    onClick={() => {
                      MainFunc.sendDataToGTMKbank({
                        event: "track_event",
                        event_category: "hotline",
                        event_action: "click",
                        event_label: "ติดต่อสอบถาม",
                        position: "inform_btn",
                      });
                    }}
                  >
                    {MainFunc.getLabelDtos("L0785") /*ติดต่อเลย*/}
                  </a>
                </div>
              </div>
              <div className={`${styles.bgImage} ${styles.custom}`}>
                <img
                  src="https://www.kasikornbank.com/SiteCollectionDocuments/personal/insure/life/ipdopd-revamp/img/bg-consult-expert-02.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsultExpert;
