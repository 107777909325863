import moment from "moment";
import React, { Component, Fragment } from "react";
import localization from "moment/locale/th";
import { Row, Col } from "react-bootstrap";
import styles from "../../styles/FourSteps.module.scss";
import MainFunc from "../../models/MainFunc";
import instr01 from '../../images/instr01.svg';
import instr02 from '../../images/instr02.svg';
import instr03 from '../../images/instr03.svg';
import instr04 from '../../images/instr04.svg';
moment().locale("th", localization).add(543, "year").format('LLL')

class FourSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(){

  }


  render(){
    return (
      <Fragment>
      <div className={styles.foursteps}>
        <Row>
          <Col lg={12}>
            <h2 className={styles.title}>{MainFunc.getLabelDtos("L0456")/*4 ขั้นตอนรับความคุ้มครอง*/}</h2>
          </Col>
          <Col lg={3}>
            <div className={styles.step}>
              <img src={instr01} alt="step 1" />
              <div className={styles.text}>
                <p>{MainFunc.getLabelDtos("L0457")/*1. เลือกแบบประกัน*/}</p>
                <p>{MainFunc.getLabelDtos("L0458")/*เลือกแบบประกันที่เหมาะกับคุณ*/}</p>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className={styles.step}>
              <img src={instr02} alt="step 2" />
              <div className={styles.text}>
                <p>{MainFunc.getLabelDtos("L0459")/*2. คำนวณเบี้ย*/}</p>
                <p>{MainFunc.getLabelDtos("L0460")/*คลิกคำนวณเบี้ยประกันภัยระบบจะคำนวนเบี้ยให้ท่าน*/}</p>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className={styles.step}>
              <img src={instr03} alt="step 3" />
              <div className={styles.text}>
                <p>{MainFunc.getLabelDtos("L0461")/*3. กรอกข้อมูล*/}</p>
                <p>{MainFunc.getLabelDtos("L0462")/*กรอกข้อมูลสมัคร ในใบสมัครให้ครบถ้วน พร้อมอัปโหลดเอกสาร*/}</p>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className={styles.step}>
              <img src={instr04} alt="step 4" />
              <div className={styles.text}>
                <p>{MainFunc.getLabelDtos("L0463")/*4. ชำระเงิน*/}</p>
                <p>{MainFunc.getLabelDtos("L0464")/*ชำระค่าเบี้ยประกันภัยและรอยืนยันจากทางระบบ*/}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
    )
  }
}

export default FourSteps;