import axios from "axios";
import React, { Component } from "react";
import {Row, Col, Button} from "react-bootstrap";
import TextField from '@material-ui/core/TextField';
import { GoAlert } from 'react-icons/go';
import SweetAlert from 'react-bootstrap-sweetalert';

import MainFunc from "../../models/MainFunc";
import MainData from "../../models/MainData";

class SM006 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM006",
      failPhone: false,
      phoneNumber: !MainData.screenSM006.phoneNumber?"":MainData.screenSM006.phoneNumber,
      isCheckOTPSendError: false,
      isCheckOTPSendErrorMsg: '',
      isFirst: false,
      failPhoneMSG: ""
    };
  }

  handleChange = (val) => {
    val = val.replace(/[^\d]/g, "");
    if (val.length == 1 && val != "0") { this.setState({phoneNumber: "0"+val}) } 
    else if (val.length <= 10) { this.setState({phoneNumber: val}) }
  }

  processBack = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    MainFunc.sendDataToGTMKbank({
      'event': 'track_event',
      'event_category': 'link_click',
      'event_action': 'click',
      'event_label': 'ย้อนกลับ',
      'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
      'flag_page' : 'onboard05'
     });
    this.props.setPage(this.props.prev)
  }

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    if(this.state.phoneNumber == ""){
      MainFunc.sendDataToGTMKbank({
        'event': 'track_event',
        'event_category': 'link_click',
        'event_action': 'click',
        'event_label': 'step_fail' ,
        'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
        'flag_page' : 'onboard05',
        'flag_verify' : 'n'  
      });
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG012');
      this.setState({failPhone: true, failPhoneMSG: errMsg.msgDescription})
      this.props.errorAlert(true, !errMsg?"กรุณาระบุเบอร์โทรศัพท์":errMsg.msgDescription, ' ')
    }
    else if(this.state.phoneNumber.length <= 9){
      MainFunc.sendDataToGTMKbank({
        'event': 'track_event',
        'event_category': 'link_click',
        'event_action': 'click',
        'event_label': 'step_fail' ,
        'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
        'flag_page' : 'onboard05',
        'flag_verify' : 'n'  
      });
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG109');
      this.setState({failPhone: true, failPhoneMSG: errMsg.msgDescription})
      this.props.errorAlert(true, !errMsg?"เบอร์โทรศัพท์มือถือไม่ถูกต้อง":errMsg.msgDescription, ' ')
      if(MainData.screenSM006.phoneNumber != null)
        {
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {phoneNumber: MainData.screenSM006.phoneNumber}, {phoneNumber: this.state.phoneNumber}, 'Fail', 'กรุณาระบุเบอร์โทรศัพท์')
        }
      else
        { 
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {phoneNumber: this.state.phoneNumber}, 'Fail', 'กรุณาระบุเบอร์โทรศัพท์')
          this.state.isFirst = true;
        }
        MainData.screenSM006.phoneNumber = this.state.phoneNumber

    }else{
      this.props.loadController(true)
      this.verifyOTP()
    }
  }

  componentDidMount(){
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
  }
  
  verifyOTP = () => {
    const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId }
    const data = {
      "phoneNumber": this.state.phoneNumber,
      "channelCode": MainData.initialData.channelCode,
      "isChangeNumber": this.state.phoneNumber == MainData.screenSM006.phoneNumber ? MainData.screenSM006.isChangeNumber? true : false : true
    }
    axios.post(MainData.serviceURL+'/VerifyOTP/OTP', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      let data = res.data.data;
      if(res.data.success === true){
        MainFunc.sendDataToGTMKbank({
          'event': 'track_event',
          'event_category': 'link_click',
          'event_action': 'click',
          'event_label': 'step_success' ,
          'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
          'flag_page' : 'onboard05',
          'flag_verify' : 'y'  
        });
        MainData.screenSM006.isChangeNumber = false;
        if(data.status === true){
          if(MainData.screenSM006.phoneNumber != null )
            {
              MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {phoneNumber: MainData.screenSM006.phoneNumber}, {phoneNumber: this.state.phoneNumber}, 'Success', null)
            }
          else
            {
              MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {phoneNumber: this.state.phoneNumber}, 'Success', null)
            }  
          MainData.screenSM006.refCode = data.refCode; 
          MainData.screenSM006.otpCode = data.otpCode; 
          MainData.screenSM006.phoneNumber = this.state.phoneNumber
          MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลเบอร์โทรศัพท์และการเรียก Service SMS เพื่อส่ง OTP ไปยังเบอร์ของผู้ใช้งาน', {phoneNumber: MainFunc.blurPhoneNum(MainData.screenSM006.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode}, {phoneNumber: MainFunc.blurPhoneNum(this.state.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode});
          this.AddOrUpdateCustomerPhoneNo()
        }else{
          MainFunc.sendLogToService(this.props.name, 'Insert', 'Fail', 'เก็บข้อมูลเบอร์โทรศัพท์และการเรียก Service SMS เพื่อส่ง OTP ไปยังเบอร์ของผู้ใช้งาน', {phoneNumber: MainFunc.blurPhoneNum(MainData.screenSM006.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode}, {phoneNumber: MainFunc.blurPhoneNum(this.state.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode});
        }
      }else{
        MainFunc.sendDataToGTMKbank({
          'event': 'track_event',
          'event_category': 'link_click',
          'event_action': 'click',
          'event_label': 'step_fail' ,
          'flag_kplus' : MainData.screenMenuKPlus.onKPlus?1:0,
          'flag_page' : 'onboard05',
          'flag_verify' : 'n'  
        });
        if(data.messageCode == 'MSG021' || data.messageCode =='MSG022' || data.messageCode =='MSG023' || data.messageCode == 'MSG024'){
          this.props.loadController(false)
          let errMsg = MainData.masterMessage.find(x => x.msgCode == data.messageCode)
          if(MainData.screenSM006.phoneNumber != null)
            {
              MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {phoneNumber: MainData.screenSM006.phoneNumber}, {phoneNumber: this.state.phoneNumber}, 'Fail', errMsg.msgDescription)
            }
          else
            { 
              MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {phoneNumber: this.state.phoneNumber}, 'Fail', errMsg.msgDescription)
            }
            MainData.screenSM006.phoneNumber = this.state.phoneNumber 
          this.setState({isCheckOTPSendError: true,isCheckOTPSendErrorMsg: data.messageCode});
        }
        else{
          MainFunc.sendLogToService(this.props.name, 'Insert', 'Fail', 'เก็บข้อมูลเบอร์โทรศัพท์และการเรียก Service SMS เพื่อส่ง OTP ไปยังเบอร์ของผู้ใช้งาน', {phoneNumber: MainFunc.blurPhoneNum(MainData.screenSM006.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode}, {phoneNumber: MainFunc.blurPhoneNum(this.state.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode});
          this.props.loadController(false)
          this.props.errorAlert(true, 'พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ', ' ')
        }
        
      }
    })
    .catch(error => {
      this.props.loadController(false)
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
      this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
    });
  }

  AddOrUpdateCustomerPhoneNo = () => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "phoneNumber": this.state.phoneNumber,
      "submenuCode": this.state.screen,
    }
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerPhoneNo', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
        this.props.setPage(this.props.next)
      }else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription, ' ')
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        this.validateBadRequest(error.response.data.errors)
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  validateBadRequest = (fieldNameList) => {
    if(fieldNameList.find(data => data.field === "phoneNumber") 
    ){
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG109');
      this.setState({failPhone: true, failPhoneMSG: errMsg.msgDescription})
      this.props.errorAlert(true, !errMsg?"เบอร์โทรศัพท์มือถือไม่ถูกต้อง":errMsg.msgDescription, ' ')
      if(MainData.screenSM006.phoneNumber != null)
        {
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {phoneNumber: MainData.screenSM006.phoneNumber}, {phoneNumber: this.state.phoneNumber}, 'Fail', 'กรุณาระบุเบอร์โทรศัพท์')
        }
      else
        { 
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {phoneNumber: this.state.phoneNumber}, 'Fail', 'กรุณาระบุเบอร์โทรศัพท์')
          this.state.isFirst = true;
        }
    }
  }

  render(){
    return (
      <div className="container-fulid">
        <SweetAlert show={this.state.isCheckOTPSendError} title={""} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2"/>} custom onConfirm={()=>window.close()} showCancel closeOnClickOutside={false} customButtons={ 
                <div>
                  <Row>
                      <Col xs={12} className="w-50 d-flex justify-content-center"><Button onClick={() => {this.setState({isCheckOTPSendError: false});MainData.screenSM005.cusId = 0; let editingPage = MainData.masPage.find(x=>x.subMenuCode==='SM001');if(!editingPage){/*eee*/}else{this.props.setPage(editingPage.seq);MainData.dataBroker.gender = "";MainData.dataBroker.birthdate = "";MainData.dataBroker.planInfo = null;MainData.dataBroker.selectRider = [];MainData.userSelectedData.notRiderPlan=null;MainData.userSelectedData.selectedPlan=null;}}} className="mainBtnRadius">{MainFunc.getLabelDtos("L0167")}</Button></Col>
                  </Row>
                </div>
            } ><h5 className="">{this.state.isCheckOTPSendErrorMsg=="MSG022"?MainData.masterMessage.find(x=>x.msgCode === 'MSG022').msgDescription:MainData.masterMessage.find(x=>x.msgCode === 'MSG023').msgDescription}</h5>
        </SweetAlert>
        <Row>
          
          <Col id="titelForScreen" xs={12} className="oppositeWhiteStep">
            <span className="font-weight-bold" dangerouslySetInnerHTML={{__html: this.props.subMenuName}}></span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>
          <Col xs={12} className="pt-3 pb-5">
            <TextField inputProps={{autocomplete: 'off'}}
              fullWidth
              id="phoneNumber"
              defaultValue=""
              autoComplete={"off"}
              error={this.state.failPhone}
              label={MainFunc.getLabelDtos("L0747")}
              onChange={(e)=>{this.handleChange(e.target.value)}}
              placeholder={MainFunc.getLabelDtos("L0746")}
              helperText={this.state.failPhone?this.state.failPhoneMSG:""}
              value={!this.state.phoneNumber?"":MainFunc.phoneFormat(this.state.phoneNumber)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>

          {!this.props.prev?null:
            <Col xs={!this.props.next?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pr-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-3_Click_BtnBack":"STEP2-2_Click_BtnBack"}`} variant="" className="subBtnRadius font-weight-bold" onClick={() => { this.processBack() }}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
          {!this.props.next?null:
            <Col xs={!this.props.prev?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pl-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-3_Click_BtnNext":"STEP2-2_Click_BtnNext"}`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processNext() }}>{MainFunc.getLabelDtos("L0029")}</Button>
            </Col>
          }
        </Row>
      </div>
    )
  }
}

export default SM006;