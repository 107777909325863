import React, { Component } from "react";
import styles from "../../styles/contact-back-success.module.scss";
import ContactBackSuccessIcon from "../../images/ContactBackSuccessIcon.png";
import MainFunc from "../../models/MainFunc";
class MyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initialize your state here
    };
  }

  render() {
    return (
      // JSX code for rendering your component
      <div className={styles.contactBackSuccessIcon}>
        <div className="container">
          <h1>
            {
              MainFunc.getLabelDtos(
                "L0788"
              ) /*ธนาคารได้รับข้อมูลของท่านเรียบร้อยแล้ว*/
            }{" "}
            <br />
            {
              MainFunc.getLabelDtos(
                "L0789"
              ) /* เจ้าหน้าที่จะติดต่อกลับ ภายใน 3 วันทำการ*/
            }
          </h1>
          <div className={styles.img}>
            <img src={ContactBackSuccessIcon} />
          </div>
        </div>
        <h2>{MainFunc.getLabelDtos("L0780") /*ขอบคุณค่ะ*/}</h2>
      </div>
    );
  }
}

export default MyComponent;
